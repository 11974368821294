import React, { Component } from "react";
import SortableTree from "react-sortable-tree";
import 'react-sortable-tree/style.css';
import Navbar from "./Navbar";
import MenuIcon from '@material-ui/icons/Menu';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { gv } from './globalvar'
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import InputComponent from "./InputComponent";
import { Helmet } from "react-helmet";
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CompleteNavbar from "./CompleteNavbar";

class Sorting extends Component {
    constructor(props) {
        super(props);

        this.state = {
            menuArr: [],
            getDataHeading: false,
            sorting: true,
            showInput: false,
            saveLoader: false,
            completePages: [],
            menuArrForPush: [],
            arrForMenuChange: [
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
                { titleInput: false, },
            ]
        };
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        if (accesstoken) {
            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/menu/order`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ menuArrForPush: result.data.menus_order })
                    let ordering = JSON.parse(JSON.stringify(result.data.menus_order))
                    let arr = []
                    ordering.map((e) => {
                        let obj = {}
                        if (e.has_sub_menu === "yes" && e.is_main_category === "no") {
                            let arrSec = []
                            e.sub_menus.splice(0, 1)
                            e.sub_menus.map((f) => {
                                let objSec = {
                                    title: f.show_as,
                                    name: f.name,
                                    children: [],
                                    route: f.route,
                                    isActive: f.isActive,
                                    is_main_category: f.is_main_category,
                                    has_sub_menu: f.has_sub_menu,
                                    show_as: f.show_as,
                                }
                                arrSec.push(objSec)
                            })
                            obj = {
                                title: e.show_as,
                                name: e.name,
                                expanded: true,
                                route: e.route,
                                isActive: e.isActive,
                                children: arrSec,
                                is_main_category: e.is_main_category,
                                has_sub_menu: e.has_sub_menu,
                                show_as: e.show_as,
                            }
                        } else {
                            obj = {
                                title: e.show_as,
                                name: e.name,
                                expanded: true,
                                route: e.route,
                                children: [],
                                isActive: e.isActive,
                                is_main_category: e.is_main_category,
                                has_sub_menu: e.has_sub_menu,
                                show_as: e.show_as,
                            }
                        }
                        arr.push(obj)
                    })
                    this.setState({ menuArr: arr })
                })
                .catch(error => console.log('error', error));

            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let arr = []
                    result.data.other_pages.map(() => {
                        let obj = {
                            titleInput: false,
                        }
                        arr.push(obj)
                    })
                    this.setState({ completePages: result.data.other_pages, titlesArr: arr })
                })
                .catch(error => console.log('error', error));
        } else {
            window.location.href = "/"
        }

    }

    updateMenu() {
        const { menuArr } = this.state
        let arr = []
        this.setState({ saveLoader: true })
        menuArr.map((e) => {
            let obj = {}
            if (e.children.length > 0) {
                let sub_menus = []
                let objSecF = {}
                this.state.completePages.map((f) => {
                    if (f.route === e.route) {
                        objSecF = {
                            name: e.name,
                            route: e.route,
                            isActive: f.isActive,
                            has_sub_menu: e.has_sub_menu,
                            is_main_category: e.is_main_category,
                            show_as: e.show_as,
                        }
                    }
                })
                sub_menus.push(objSecF)
                e.children.map((f) => {
                    let objSec = {
                        name: f.name,
                        route: f.route,
                        isActive: f.isActive,
                        has_sub_menu: f.has_sub_menu,
                        is_main_category: f.is_main_category,
                        show_as: f.show_as,
                    }
                    sub_menus.push(objSec)
                })
                obj = {
                    name: e.name,
                    has_sub_menu: "yes",
                    sub_menus,
                    isActive: e.isActive,
                    route: e.route,
                    is_main_category: e.is_main_category,
                    show_as: e.show_as,
                }
            } else {
                if (e.is_main_category === "yes") {
                    obj = {
                        name: e.name,
                        has_sub_menu: e.has_sub_menu,
                        isActive: e.isActive,
                        route: e.route,
                        is_main_category: e.is_main_category,
                        show_as: e.show_as,
                    }
                } else {
                    obj = {
                        name: e.name,
                        has_sub_menu: "no",
                        isActive: e.isActive,
                        route: e.route,
                        is_main_category: e.is_main_category,
                        show_as: e.show_as,
                    }
                }
            }
            arr.push(obj)
        })

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("menus_order", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/menu/order`, requestOptions)
            .then(response => response.json())
            .then(() => {
                fetch(`${gv}/api/v1/list/of/values`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data
                        localStorage.setItem("headerData", JSON.stringify(resp))
                        this.setState({ getDataHeading: true })
                        window.location.reload()
                    })
                    .catch(error => console.log('error', error));
            })
            .catch(error => {
                this.setState({ showErrorModal: true, saveLoader: false })
            });
    }

    deletePage(e) {
        this.setState({ deleteLoader: true })

        let completePages = this.state.completePages
        let spliced = completePages.splice(e, 1)

        this.state.menuArrForPush.map((e, i) => {
            if (e.has_sub_menu === "yes" && e.is_main_category === "no") {
                e.sub_menus.map((f, ist) => {
                    if (f.route === spliced[0].route) {
                        if (e.sub_menus[0].route === spliced[0].route) {
                            e.sub_menus.splice(ist, 1)
                            this.state.menuArrForPush.splice(i, 1)
                            e.sub_menus.map((f) => {
                                f.has_sub_menu = "no"
                                this.state.menuArrForPush.push(f)
                            })
                        } else {
                            e.sub_menus.splice(ist, 1)
                        }
                    }
                })
            } else {
                if (e.route === spliced[0].route) {
                    this.state.menuArrForPush.splice(i, 1)
                }
            }
        })

        let accesstoken = localStorage.getItem("adminKey")

        let stringified = JSON.stringify(completePages)

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);
        formdata.append("menus_order", JSON.stringify(this.state.menuArrForPush));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, saveLoader: false })
            });
    }

    updatePages() {
        let completePages = this.state.completePages

        this.setState({ saveLoader: true })

        completePages.map((g) => {
            this.state.menuArrForPush.map((e, i) => {
                if (e.has_sub_menu === "yes" && e.is_main_category === "no") {
                    e.sub_menus.map((f, ist) => {
                        e.name = e.sub_menus[0].name
                        e.show_as = e.sub_menus[0].show_as
                        if (f.route === g.route) {
                            e.sub_menus[ist].isActive = g.isActive
                            e.sub_menus[ist].name = g.name
                            e.sub_menus[ist].show_as = g.name
                            if (g.isActive) {
                                e.isActive = true
                            } else {
                                if (e.route === f.route) {
                                    e.isActive = false
                                }
                            }
                        }
                    })
                } else {
                    if (e.route === g.route) {
                        this.state.menuArrForPush[i].isActive = g.isActive
                        this.state.menuArrForPush[i].name = g.name
                        this.state.menuArrForPush[i].show_as = g.name
                    }
                }
            })
        })

        let accesstoken = localStorage.getItem("adminKey")

        let stringified = JSON.stringify(completePages)

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);
        formdata.append("menus_order", JSON.stringify(this.state.menuArrForPush));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, saveLoader: false })
            });
    }

    closeInput(e, f) {
        this.state.completePages[f].name = e
        this.state.titlesArr[f].titleInput = false
        this.setState({ completePages: this.state.completePages, titlesArr: this.state.titlesArr })
    }

    closeInputMenu(e, f) {
        this.state.menuArrForPush[f].show_as = e
        this.state.arrForMenuChange[f].titleInput = false
    }

    updateMenuChange() {
        let accesstoken = localStorage.getItem("adminKey")

        this.setState({ saveLoader: true })

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdataSec = new FormData();
        formdataSec.append("menus_order", JSON.stringify(this.state.menuArrForPush));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdataSec,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/menu/order`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, saveLoader: false })
            });
    }

    render() {
        return (
            <div className="sorting-main">
                <Helmet>
                    <title>Menu</title>
                </Helmet>
                <CompleteNavbar refreshData={this.state.getDataHeading} />
                <div className="sm-inner" style={{ position: "relative" }}>
                    <div className="header-sm">
                        <div>
                            <MenuIcon />
                            <label>MENU EN PAGINABEHEER</label>
                        </div>
                        <div>
                            {this.state.sorting ? !this.state.saveLoader && <button onClick={this.updateMenu.bind(this)}>
                                <SaveAltIcon />
                                Save
                            </button> : this.state.showWork ? !this.state.saveLoader && <button onClick={this.updateMenuChange.bind(this)}>
                                    <SaveAltIcon />
                                Save
                            </button> : !this.state.saveLoader && <button onClick={this.updatePages.bind(this)}>
                                        <SaveAltIcon />
                                Save
                            </button>}
                            <button onClick={() => this.props.history.goBack()}>
                                <CloseIcon />
                            SLUIT VENSTER
                            </button>
                        </div>
                    </div>
                    <div className="tabs-menu">
                        <ul>
                            {this.state.menuArr.length > 0 && <li className={this.state.sorting ? "active" : ""} onClick={() => this.setState({ sorting: true, showWork: false })}>SORTING</li>}
                            {this.state.completePages.length > 0 && <li className={!this.state.sorting && !this.state.showWork ? "active" : ""} onClick={() => this.setState({ sorting: false, showWork: false })}>ADJUSTING</li>}
                            {this.state.menuArrForPush.length > 0 && <li className={this.state.showWork ? "active" : ""} onClick={() => this.setState({ sorting: false, showWork: true })}>HOOFDMENU TITELS</li>}
                        </ul>
                    </div>
                    {this.state.saveLoader && <div className="loader-create-sm-inner">
                        <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                    </div>}
                    {this.state.sorting ? !this.state.saveLoader && <SortableTree
                        treeData={this.state.menuArr}
                        onChange={treeData => this.setState({ menuArr: treeData })}
                        canNodeHaveChildren={(e) => {
                            if (e.is_main_category === "yes") {
                                return false
                            } else {
                                return true
                            }
                        }}
                        canDrop={(e) => {
                            if (e.node.is_main_category === "yes") {
                                if (e.nextParent !== null) {
                                    if (e.nextParent.is_main_category === "yes") {
                                        return true
                                    } else {
                                        return false
                                    }
                                } else {
                                    return true
                                }
                            } else {
                                return true
                            }
                        }}
                        maxDepth={2}
                    /> : !this.state.saveLoader && this.state.showWork ? <div className="all-pages-settings" style={{ position: "relative" }}>
                        {this.state.deleteLoader && <div className="loader-create-sections">
                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>}
                        <div className="header-aps">
                            <label className="pn-haps">Page Name</label>
                            <label className="st-haps">Status</label>
                        </div>
                        {this.state.menuArrForPush.map((e, i) => {
                            if (e.is_main_category === "yes") {
                                return <div className="body-aps" key={Math.random()}>
                                    <label className="pn-haps" style={{ textTransform: "uppercase" }} onClick={() => {
                                        this.state.arrForMenuChange[i].titleInput = true
                                        this.setState({ arrForMenuChange: this.state.arrForMenuChange })
                                    }}>
                                        {!this.state.arrForMenuChange[i].titleInput ? e.show_as : <InputComponent dataText={e.show_as} blurInput={(e) => this.closeInputMenu(e, i)} />}
                                    </label>
                                    {e.route !== "/" && <label className="st-haps">
                                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={e.isActive} onChange={(e) => {
                                            let completePages = this.state.menuArrForPush
                                            completePages[i].isActive = !completePages[i].isActive
                                            this.setState({ menuArrForPush: completePages, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                        }} />
                                    </label>}
                                </div>
                            }
                        })}
                    </div> : !this.state.saveLoader && <div className="all-pages-settings" style={{ position: "relative" }}>
                        {this.state.deleteLoader && <div className="loader-create-sections">
                            <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                        </div>}
                        <div className="header-aps">
                            <label className="pn-haps">Page Name</label>
                            <label className="st-haps">Status</label>
                            <label className="ac-haps">Action</label>
                        </div>
                        {this.state.completePages.map((e, i) => {
                            return <div className="body-aps" key={Math.random()}>
                                <label className="pn-haps" style={{ textTransform: "uppercase" }} onClick={() => {
                                    this.state.titlesArr[i].titleInput = true
                                    this.setState({ titlesArr: this.state.titlesArr })
                                }}>
                                    {!this.state.titlesArr[i].titleInput ? e.name : <InputComponent dataText={e.name} blurInput={(e) => this.closeInput(e, i)} />}
                                </label>
                                {e.route !== "/" && <label className="st-haps">
                                    <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={e.isActive} onChange={(e) => {
                                        let completePages = this.state.completePages
                                        completePages[i].isActive = !completePages[i].isActive
                                        this.setState({ completePages, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                    }} />
                                </label>}
                                {e.route !== "/" && <label className="ac-haps">
                                    <DeleteIcon style={{ fill: "white", cursor: "pointer" }} onClick={this.deletePage.bind(this, i)} />
                                </label>}
                            </div>
                        })}
                    </div>}
                </div>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        );
    }
}

export default Sorting