import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';

export class HorizontalProducts extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            arr: ["1", "2", "3", "4", "5", "6"],
            right: false,
            rightSecond: false,
            selectedTemplate: '',
            heading1: "Stijvolle items",
            headingfInput: false,
            heading2: "Nieuw binnen",
            headingsInput: false,
            desc: "Bekijk ons nieuwe items in ons webwinkel.",
            descInput: false,
            buttonText: "SHOP NU",
            buttonInput: false,
            wholeData: '',
            mainArrs: [],
            nestedArrs: [],
            arrSelected: [],
            allProducts: [],
            open: false,
            saveButton: false,
            // Button link working
            allRoutes: [],
            buttonUrl: '',
            selectRoute: true,
            selectedRoute: "",
            urlValidate: false,
            brandRoute: false,
            brandCategories: [],
            selectedCategoryButton: "horloges",
            allBrands: [],
            childmenulists: [],
            selectedRouteBrand: "",
            selectedBrandList: "",
            brandlists: [],
            listRoute: false,
            selectedRouteurl: '',
            soortRoute: false,
            soortArr: [],
            selectedSoort: "",
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            createLoader: true,
            leftAreaHidden: false,
            buttonHidden: false,
            arrowIconColor: "#000",
            arrowBackgroundColor: "#fff"
        }
    }

    componentDidMount() {

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let data = JSON.parse(headerData)
            let inArr = Object.entries(data.menus.child_menus)
            this.setState({ mainArrs: data.main_categories })
        }

        // Broswe Detections
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));

        let allRoutes = localStorage.getItem("allRoutes")
        if (allRoutes) {
            let parsed = JSON.parse(allRoutes)
            let arr = []
            parsed.map((f) => {
                if (f.split("/")[1] === "") {
                    arr.push("landing-page")
                } else {
                    arr.push(f.split("/")[1])
                }
            })
            this.setState({ allRoutes: arr })
        }

        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            child_menus.map((f) => {
                if (f[0] === parsed.main_categories[0].id) {
                    let objected = Object.entries(f[1])
                    let filtered = objected.filter((u) => {
                        return u[0].toLowerCase() === f[0]
                    })
                    this.setState({ childmenulists: filtered[0][1] })
                }
            })

            this.setState({
                brandCategories: parsed.main_categories, selectedCategoryButton: parsed.main_categories[0].id,
            })
        }
    }

    slideLeft() {
        let element = document.getElementById("first-slider")
        element.scrollLeft += 200
        if (element.scrollLeft > -100) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    slideRight() {
        let element = document.getElementById("first-slider")
        element.scrollLeft -= 200
        if (element.scrollLeft > 250) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    selectChange(e) {
        if (e !== "yes" && e !== "no") {
            if (this.state.arrSelected.length > 0) {
                this.setState({ open: true, dataChaning: e.target.value })
            } else {
                let headerData = localStorage.getItem("headerData")
                if (headerData) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    inArr.map((f) => {
                        if (f[0] === e.target.value) {
                            let objected = Object.entries(f[1])
                            if (e.target.value === "horloges") {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === "modellen"
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            } else {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === f[0]
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            }
                        }
                    })
                }
            }
        } else {
            if (e === "yes") {
                let headerData = localStorage.getItem("headerData")
                if (headerData) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    inArr.map((f) => {
                        if (f[0] === this.state.dataChaning) {
                            let objected = Object.entries(f[1])
                            if (e.target.value === "horloges") {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === "MODELLEN"
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            } else {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === f[0]
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            }
                        }
                    })
                }
            } else {
                this.setState({ open: false })
            }
        }
    }

    selectChangeBrand(e) {
        this.setState({ selectedBrand: e.target.value, product_type: "", allProducts: [] })
        let adminKey = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", adminKey);

        var formdata = new FormData();
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("brand_id", e.target.value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/get/available/product/types?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (Number(result.data.data.sale_product) > 0) {
                    this.setState({ showSale: true })
                } else {
                    this.setState({ showSale: false })
                }
                if (Number(result.data.data.latest_products) > 0) {
                    this.setState({ showLS: true })
                } else {
                    this.setState({ showLS: false })
                }
            })
            .catch(error => console.log('error', error));
    }

    getskus(e) {
        if (e !== "") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("sku", e);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/product/skus`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    let arr = []
                    resp.map((e) => {
                        let id = e.sku
                        let label = e.sku
                        let obj = {
                            value: id,
                            label
                        }
                        arr.push(obj)
                    })
                    this.setState({ arrSuggestions: arr })
                })
                .catch(error => console.log('error', error));
        }
    }

    getProductsInfo(f) {
        if (f !== null) {
            if (f !== "sale" && f !== "latest") {
                this.setState({ arrSelected: f })
                let arr = []
                f.map((e) => {
                    arr.push(e.value)
                })
                let product_skus = arr

                var formdata = new FormData();
                formdata.append("product_skus", JSON.stringify(product_skus));
                formdata.append("product_type", "sku");
                formdata.append("brand_id", this.state.selectedBrand);
                formdata.append("main_category", this.state.selectedCategory);
                formdata.append("layout_type", "layout_7");

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ allProducts: result.data.data, saveButton: true })
                    })
                    .catch(error => console.log('error', error));
            } else {
                // this.setState({ arrSelected: f })
                var formdata = new FormData();
                formdata.append("product_type", f);
                formdata.append("layout_type", "layout_7");
                formdata.append("brand_id", this.state.selectedBrand);
                formdata.append("main_category", this.state.selectedCategory);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.setState({ allProducts: result.data.data, saveButton: true })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            this.setState({ allProducts: [], saveButton: false })
        }
    }

    saveBrands() {
        const { wholeData, buttonUrl } = this.state
        let arr = []
        this.state.arrSelected.map((e) => {
            arr.push(e.value)
        })
        let product_skus = arr
        // let urlBtn = ""
        // let is_button_route = false
        // if (this.state.selectRoute) {
        //     if (this.state.selectedRoute) {
        //         is_button_route = true
        //         if (this.state.selectedRoute === "/landing-page") {
        //             urlBtn = "/"
        //         } else if (this.state.selectedRoute === "/brand-products" || this.state.selectedRoute === "/merken" || this.state.selectedRoute === "/soort" || this.state.selectedRoute === "/brand-detail") {
        //             urlBtn = this.state.selectedRouteurl
        //         } else {
        //             urlBtn = this.state.selectedRoute
        //         }
        //     } else {
        //         is_button_route = false
        //         urlBtn = this.state.selectedRoute
        //     }
        // } else {
        //     var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
        //     var regex = new RegExp(expression);
        //     if (buttonUrl) {
        //         if (buttonUrl.match(regex)) {
        //             urlBtn = buttonUrl
        //             is_button_route = true
        //             this.setState({ urlValidate: false })
        //         } else {
        //             urlBtn = ""
        //             this.setState({ urlValidate: true })
        //         }
        //     } else {
        //         urlBtn = ""
        //         is_button_route = false
        //     }
        // }
        let obj = {
            type: "layout_7",
            heading1: this.state.heading1,
            product_skus,
            // is_button_route,
            // button_url: urlBtn,
            // urlType: this.state.selectRoute,
            button_text: this.state.buttonText,
            main_category: this.state.selectedCategory,
            brand_id: this.state.selectedBrand,
            product_type: this.state.product_type,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            left_area: this.state.leftAreaHidden,
            buttonHidden: this.state.buttonHidden,
            arrowBackgroundColor: this.state.arrowBackgroundColor,
            arrowIconColor: this.state.arrowIconColor,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    getBrand(e, f) {
        this.setState({ selectedCategoryButton: e.target.value })
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            let childmenulists = []
            let hello = child_menus.filter((f) => {
                return f[0] === e.target.value
            });
            childmenulists = hello[0][1]
            this.setState({ childmenulists, selectedRouteurl: `/${f}/${e.target.value}/${childmenulists[0].id}` })
        }
    }

    getBrandDetailsList(e, f) {
        this.setState({ selectedCategoryDetail: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsList: childmenulists, selectedRouteurl: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    handleChangeComplete = (color) => {
        this.setState({ arrowIconColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSec = (color) => {
        this.setState({ arrowBackgroundColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div className="third-sec-main sectiles" style={{ margin: 0, padding: 0, paddingTop: 50, minHeight: 500 }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                </div>
                <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }} >
                    <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                    <div className="color-working-popup">
                        <div className="first-div-cwp">
                            <label>Achtergrond kleur</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.arrowBackgroundColor}
                                onChangeComplete={this.handleChangeCompleteSec}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Icoon kleur</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.arrowIconColor}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        </div>
                    </div>
                </div>
                <div className="closer-working" style={{ top: "48%", width: "auto", height: "auto", left: 0, right: 0, bottom: 60 }} onClick={() => this.setState({ headingfInput: false, headingsInput: false, descInput: false })}>

                </div>
                <div style={{ zIndex: 9999 }}>
                    <div style={{ width: "100%", padding: 20 }}>
                        <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={this.selectChange.bind(this)}>
                            <option>Select Category</option>
                            {this.state.mainArrs.map((e) => {
                                if (e.show_for_products) {
                                    return <option key={Math.random()} selected={this.state.selectedCategory === e.id} value={e.id}>{e.id}</option>
                                }
                            })}
                        </select>
                    </div>
                    {this.state.nestedArrs.length > 0 && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20 }}>
                        <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={this.selectChangeBrand.bind(this)}>
                            <option>Select Category</option>
                            {this.state.nestedArrs.map((e) => {
                                return <option key={Math.random()} selected={this.state.selectedBrand === e.id} value={e.id}>{e.name}</option>
                            })}
                        </select>
                    </div>}
                    {this.state.selectedBrand && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20, textAlign: "left" }}>
                        <select value={this.state.product_type} style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", marginBottom: 20, letterSpacing: 1 }} onChange={(e) => {
                            if (e.target.value !== "sku") {
                                this.setState({ product_type: e.target.value, arrSelected: [], })
                                this.getProductsInfo(e.target.value)
                            } else {
                                this.setState({ product_type: e.target.value, arrSelected: [], allProducts: [] })
                            }
                        }}>
                            <option value="">Select Product Type</option>
                            {this.state.showLS && <option value="sku">SKU</option>}
                            {this.state.showSale && <option value="sale">Sale</option>}
                            {this.state.showLS && <option value="latest">Latest</option>}
                        </select>
                        {this.state.product_type === "sku" && <Select
                            isMulti
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            value={this.state.arrSelected}
                            options={this.state.arrSuggestions}
                            onChange={(e) => {
                                this.getProductsInfo(e)
                            }}
                            onInputChange={(e) => {
                                this.getskus(e)
                            }}
                        />}
                    </div>}
                </div>
                {!this.state.leftAreaHidden && <div className="sp-card hiddenMob" style={{ width: "100%", textAlign: "center" }}>
                    <div className="secchecks" style={{ width: "100%" }}>
                        {this.state.heading1 ? <h2 onClick={() => this.setState({ headingfInput: true })} onBlur={() => this.setState({ headingfInput: false })}>
                            {!this.state.headingfInput ? this.state.heading1 : <input type="text" autoFocus value={this.state.heading1} onChange={(e) => this.setState({ heading1: e.target.value })} onBlur={() => this.setState({ headingfInput: false })} />}
                        </h2> : <input type="text" value={this.state.heading1} onChange={(e) => this.setState({ heading1: e.target.value, headingfInput: true })} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onBlur={() => this.setState({ headingfInput: false })} />}
                    </div>
                    {/* <div className="secchecks" style={{ width: "100%" }}>
                        {!this.state.buttonInput ? <button onClick={() => this.setState({ buttonInput: true })} onBlur={() => this.setState({ buttonInput: false })}>{this.state.buttonText}</button>
                            : <div style={{ width: "100%" }}>
                                <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRoute ? "ps" : "link"} onChange={(e) => {
                                    if (e.target.value === "ps") {
                                        this.setState({ selectRoute: true })
                                    } else {
                                        this.setState({ selectRoute: false })
                                    }
                                }}>
                                    <option value="ps">Page selection</option>
                                    <option value="link">Own link</option>
                                </select>
                                {this.state.selectRoute ? <div style={{ width: "100%" }}>
                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRoute} onChange={(e) => {
                                        this.setState({ selectedRoute: e.target.value })
                                        if (e.target.value === "/brand-products") {
                                            this.setState({ brandRoute: true, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/brand-products/${this.state.selectedCategoryButton}/${this.state.childmenulists[0].id}` })
                                        } else if (e.target.value === "/merken") {
                                            this.setState({ listRoute: true, detailRoute: false, brandRoute: false, soortRoute: false, selectedRouteurl: `/${this.state.brandlists[0].id}/merken` })
                                        } else if (e.target.value === "/soort") {
                                            this.setState({ soortRoute: true, detailRoute: false, listRoute: false, brandRoute: false, selectedRouteurl: `/soort/${this.state.soortArr[0].id}` })
                                        } else if (e.target.value === "/brand-detail") {
                                            this.setState({ detailRoute: true, soortRoute: false, listRoute: false, brandRoute: false, selectedRouteurl: `/collection-story/${this.state.selectedCategoryButton}/${this.state.childmenulists[0].id}` })
                                        } else {
                                            this.setState({ brandRoute: false, listRoute: false, selectedRouteurl: "", detailRoute: false, soortRoute: false })
                                        }
                                    }}>
                                        <option value="">Select Route</option>
                                        {this.state.allRoutes.map((e) => {
                                            return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                        })}
                                    </select>
                                    {this.state.brandRoute && <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10, backgroundColor: "white" }} onChange={(e) => this.getBrand(e, "brand-products")} value={this.state.selectedCategoryButton}>
                                            {this.state.brandCategories.map((e) => {
                                                if (e.show_for_products) {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                }
                                            })}
                                        </select>
                                        <br />
                                        <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrandButton} onChange={(e) => this.setState({ selectedRouteurl: `/brand-products/${this.state.selectedCategoryButton}/${e.target.value}`, selectedBrandButton: e.target.value })}>
                                            {this.state.childmenulists.map((e) => {
                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                            })}
                                        </select>
                                    </div>}
                                    {this.state.detailRoute && <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrandDetailsList(e, "collection-story")} value={this.state.selectedCategoryDetail}>
                                            {this.state.brandCategories.map((e) => {
                                                if (e.show_for_brands) {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                }
                                            })}
                                        </select>
                                        <br />
                                        <select style={{ marginBottom: 10 }} value={this.state.selectedBrandDetail} onChange={(e) => this.setState({ selectedRouteurl: `/collection-story/${this.state.selectedCategoryDetail}/${e.target.value}`, selectedBrandDetail: e.target.value })}>
                                            {this.state.brandDetailsList.map((e) => {
                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                            })}
                                        </select>
                                    </div>}
                                    {this.state.listRoute && <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrandList} onChange={(e) => this.setState({ selectedRouteurl: `/${e.target.value}/merken`, selectedBrandList: e.target.value })}>
                                            {this.state.brandlists.map((e) => {
                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                            })}
                                        </select>
                                    </div>}
                                    {this.state.soortRoute && <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedSoort} onChange={(e) => this.setState({ selectedRouteurl: `/soort/${e.target.value}`, selectedSoort: e.target.value })}>
                                            {this.state.soortArr.map((e) => {
                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                            })}
                                        </select>
                                    </div>}
                                </div> : <input className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrl} onChange={(e) => {
                                    if (e.target.value !== "") {
                                        this.setState({ buttonUrl: e.target.value })
                                    } else {
                                        this.setState({ buttonUrl: e.target.value })
                                    }
                                }} />}
                                {this.state.urlValidate && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                <button style={{ padding: 10 }}>
                                    <input type="text" value={this.state.buttonText} onChange={(e) => this.setState({ buttonText: e.target.value })} onBlur={() => this.setState({ buttonInput: false })} />
                                </button>
                            </div>}
                    </div> */}
                </div>}
                <div className="slider-products" id="first-slider" style={{ zIndex: 99999, paddingBottom: 70 }}>
                    {this.state.allProducts.length > 0 && this.state.allProducts.map((e) => {
                        return <div className="sp-card" key={Math.random()} style={{ zIndex: 999 }}>
                            <div className="img-cardsp">
                                <img alt="showingyet" src={e.image_url} />
                            </div>
                            <div className="content-cardsp">
                                <h1 className="product-title">{e.name}</h1>
                                <p className="product-price" style={{ marginTop: 10 }}>
                                    {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) && <font style={{ textDecorationLine: "line-through", marginBottom: 10 }}>
                                        € {e.show_retail_price}
                                    </font>}
                                    <br />
                                    <font>
                                        € {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) ? e.show_sale_price : e.show_retail_price}
                                    </font>
                                </p>
                            </div>
                        </div>
                    })}
                    {this.state.allProducts.length > 0 && !this.state.buttonHidden && <button className="shop-sp-btn">SHOP {this.state.selectedBrand} </button>}
                </div>
                {!this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <ColorizeIcon />
                        <font>Button Colour</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.leftAreaHidden} onChange={() => {
                            this.setState({ leftAreaHidden: !this.state.leftAreaHidden })
                        }} />
                        <font>TEXT HIDDEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.buttonHidden} onChange={() => {
                            this.setState({ buttonHidden: !this.state.buttonHidden })
                        }} />
                        <font>BUTTON HIDDEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.allProducts.length > 0 && <div className="inner-faps" onClick={this.saveBrands.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Als je gaat wijzigen van categorie, dan dien je opnieuw de merken te selecteren.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.selectChange.bind(this, "no")} color="primary">No</Button>
                        <Button onClick={this.selectChange.bind(this, "yes")} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default HorizontalProducts
