import React, { Component } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import './index.css'
import CK from '../../Components/Ck'
import 'font-awesome/css/font-awesome.min.css';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import EditIcon from '@material-ui/icons/Edit';
import Select from 'react-select';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CompleteNavbar from '../../Components/CompleteNavbar';

AOS.init();

export class CollectionStory extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            popupright: false,
            wholeData: [],
            // Editing Data
            isAdmin: false,
            title_1: "",
            title_1Input: false,
            desc_1: "",
            desc_1Input: false,
            main_desc: "",
            main_descInput: false,
            title_4: "",
            title_4Input: false,
            desc_4: "",
            desc_4Input: false,
            left_title: "",
            left_titleInput: false,
            left_desc: "",
            left_descInput: false,
            right_title: "",
            right_titleInput: false,
            right_desc: "",
            right_descInput: false,
            firstModalProduct: false,
            secondModalProduct: false,
            product_type: "",
            product: [],
            showSale: false,
            showLS: false,
            shop_de_collectie:"SHOP DE COLLECTIE",
        }
    }

    componentDidMount() {

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", adminKey);

            var formdata = new FormData();
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/available/product/types?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (Number(result.data.data.sale_product) > 0) {
                        this.setState({ showSale: true })
                    } else {
                        this.setState({ showSale: false })
                    }
                    if (Number(result.data.data.latest_products) > 0) {
                        this.setState({ showLS: true })
                    } else {
                        this.setState({ showLS: false })
                    }
                })
                .catch(error => console.log('error', error));
        }

        fetch(`${gv}/api/v1/${this.props.match.params.csid}/${this.props.match.params.nestedid}/detail?browser_type=${browserType}`)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                if (resp.product_1 && resp.product_2) {
                    if (resp.product_1.long_desc) {
                        resp.product_1.long_desc = `${resp.product_1.long_desc.substr(0, 200)} ....`
                    }
                    if (resp.product_2.long_desc) {
                        resp.product_2.long_desc = `${resp.product_2.long_desc.substr(0, 200)} ....`
                    }
                }
                this.setState({
                    wholeData: resp,
                    title_1: resp.title_1,
                    desc_1: resp.real_desc_1,
                    main_desc: resp.description,
                    title_4: resp.title_4,
                    desc_4: resp.real_desc_4,
                    left_title: resp.left_image_title_2,
                    left_desc: resp.left_image_desc_2,
                    right_title: resp.right_image_title_1,
                    right_desc: resp.right_image_desc_1,
                    shop_de_collectie:resp.shop_de_collectie,
                })
            })
            .catch(error => console.log('error', error));
    }

    createMarkup() {
        if (this.state.main_desc) {
            return { __html: this.state.main_desc };
        }
    }

    getskus(e) {
        if (e !== "") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("sku", e);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/product/skus`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    let arr = []
                    resp.map((e) => {
                        let id = e.sku
                        let label = e.sku
                        let obj = {
                            value: id,
                            label
                        }
                        arr.push(obj)
                    })
                    this.setState({ arrSuggestions: arr })
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { title_1, desc_1, main_desc, title_4, desc_4, left_title, left_desc, right_title, right_desc } = this.state

        let arr = [
            { key: "category_brand_detail_page_title_1", value: title_1 },
            { key: "category_brand_detail_page_desc_1", value: desc_1 },
            { key: "category_brand_detail_page_description", value: main_desc },
            { key: "category_brand_detail_page_title_4", value: title_4 },
            { key: "category_brand_detail_page_desc_4", value: desc_4 },
            { key: "category_brand_detail_page_left_image_title_2", value: left_title },
            { key: "category_brand_detail_page_left_image_desc_2", value: left_desc },
            { key: "category_brand_detail_page_right_image_title_1", value: right_title },
            { key: "category_brand_detail_page_right_image_desc_1", value: right_desc },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/dynamic/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    changeAlert(e) {
        this.setState({ main_desc: e })
    }

    close() {
        this.setState({ main_descInput: false })
    }

    imgUploading(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderBa: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("section_type", "brand_detail_page_banner");
            formdata.append("image_type", "banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.success !== 0) {
                        this.state.wholeData.banner_image = result.data.image_url
                        this.state.wholeData.check_banner_image = true
                        this.setState({ wholeData: this.state.wholeData, loaderBa: false, })
                    } else {
                        this.setState({ loaderBa: false, })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderBa: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImage() {
        this.setState({ loaderBa: true })

        let splitted = this.state.wholeData.banner_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("section_type", "brand_detail_page_banner");
        formdata.append("image_type", "banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.banner_image = result.data.default_url
                this.state.wholeData.check_banner_image = false
                this.setState({ wholeData: this.state.wholeData, loaderBa: false, })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderBa: false })
            });
    }

    imgUploadingSec(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderSe: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("section_type", "brand_detail_page_left_image");
            formdata.append("image_type", "brand_detail_page_left_image");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.success !== 0) {
                        this.state.wholeData.left_image_2 = result.data.image_url
                        this.state.wholeData.check_left_image_2 = true
                        this.setState({ wholeData: this.state.wholeData, loaderSe: false, })
                    } else {
                        this.setState({ loaderSe: false, })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderSe: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageSec() {
        this.setState({ loaderSe: true })

        let splitted = this.state.wholeData.left_image_2.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("section_type", "brand_detail_page_left_image");
        formdata.append("image_type", "brand_detail_page_left_image");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.left_image_2 = result.data.default_url
                this.state.wholeData.check_left_image_2 = false
                this.setState({ wholeData: this.state.wholeData, loaderSe: false, })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderSe: false })
            });
    }

    imgUploadingTr(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderTr: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("section_type", "brand_detail_page_right_image");
            formdata.append("image_type", "brand_detail_page_right_image");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.success !== 0) {
                        this.state.wholeData.right_image_1 = result.data.image_url
                        this.state.wholeData.check_right_image_1 = true
                        this.setState({ wholeData: this.state.wholeData, loaderTr: false, })
                    } else {
                        this.setState({ loaderTr: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderTr: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageTr() {
        this.setState({ loaderTr: true })

        let splitted = this.state.wholeData.right_image_1.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("section_type", "brand_detail_page_right_image");
        formdata.append("image_type", "brand_detail_page_right_image");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.right_image_1 = result.data.default_url
                this.state.wholeData.check_right_image_1 = false
                this.setState({ wholeData: this.state.wholeData, loaderTr: false, })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderTr: false })
            });
    }


    getProductInfo(e) {
        if (e !== "sale" && e !== "latest") {
            this.setState({ arrSelected: e })

            let arrWork = [e.value]

            var formdata = new FormData();
            formdata.append("product_skus", JSON.stringify(arrWork));
            formdata.append("product_type", this.state.product_type);
            formdata.append("layout_type", "layout_1");
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("main_category", this.props.match.params.csid);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.data[0].long_desc) {
                            result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                        }
                        if (result.data.data[0].short_desc) {
                            result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                        }
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ arrSelected: e })

            var formdata = new FormData();
            formdata.append("product_type", e);
            formdata.append("layout_type", "layout_1");
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("main_category", this.props.match.params.csid);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.data[0].long_desc) {
                            result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                        }
                        if (result.data.data[0].short_desc) {
                            result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                        }
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    updateType(e) {
        const { product_type } = this.state

        let arr = []
        if (product_type === "sku") {
            arr = [
                { key: e, value: { product_type: "sku", sku: this.state.arrSelected.value } },
            ]
        } else {
            arr = [
                { key: e, value: { product_type: product_type } },
            ]
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/dynamic/page/content`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    imgUploadingBrandSec(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderBrandSec: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("section_type", "brand_detail_page_left_image_3");
            formdata.append("image_type", "right_banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.sucess !== 0) {
                        this.state.wholeData.left_image_3 = result.data.image_url
                        this.state.wholeData.check_left_image_3 = true
                        this.setState({ wholeData: this.state.wholeData, loaderBrandSec: false, })
                    } else {
                        this.setState({ loaderBrandSec: false, })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderBrandSec: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageBrandSec() {
        this.setState({ loaderBrandSec: true })

        let splitted = this.state.wholeData.left_image_3.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("section_type", "brand_detail_page_left_image_3");
        formdata.append("image_type", "right_banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.left_image_3 = result.data.default_url
                this.state.wholeData.check_left_image_3 = false
                this.setState({ wholeData: this.state.wholeData, loaderBrandSec: false, })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderBrandSec: false })
            });
    }


    imgUploadingBrand(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loaderBrand: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("main_category", this.props.match.params.csid);
            formdata.append("brand_id", this.props.match.params.nestedid);
            formdata.append("section_type", "brand_detail_page_left_image_1");
            formdata.append("image_type", "left_banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.success !== 0) {
                        this.state.wholeData.left_image_1 = result.data.image_url
                        this.state.wholeData.check_left_image_1 = true
                        this.setState({ wholeData: this.state.wholeData, loaderBrand: false, })
                    } else {
                        this.setState({ loaderBrand: false, })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loaderBrand: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImageBrand() {
        this.setState({ loaderBrand: true })

        let splitted = this.state.wholeData.left_image_1.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("main_category", this.props.match.params.csid);
        formdata.append("brand_id", this.props.match.params.nestedid);
        formdata.append("section_type", "brand_detail_page_left_image_1");
        formdata.append("image_type", "left_banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.left_image_1 = result.data.default_url
                this.state.wholeData.check_left_image_1 = false
                this.setState({ wholeData: this.state.wholeData, loaderBrand: false, })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loaderBrand: false })
            });
    }

    render() {
        return (
            <div style={{ overflowX: "hidden", position: "relative" }} className="collection-story">
                <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>
                <CompleteNavbar />

                <div className="modal-popup-new" style={{ opacity: this.state.firstModalProduct ? 1 : 0, pointerEvents: this.state.firstModalProduct ? "all" : "none" }}>

                </div>
                {this.state.firstModalProduct && <div className="modal-new" style={{ opacity: this.state.firstModalProduct ? 1 : 0, pointerEvents: this.state.firstModalProduct ? "all" : "none", minHeight: "50%", padding: 40 }}>
                    <ClearIcon onClick={() => this.setState({ firstModalProduct: false })} style={{ position: "absolute", top: 10, right: 10 }} />
                    <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20, textAlign: "left" }}>
                        <select value={this.state.product_type} style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", marginBottom: 20, letterSpacing: 1 }} onChange={(e) => {
                            if (e.target.value !== "sku") {
                                this.setState({ product_type: e.target.value, product: [], })
                                this.getProductInfo(e.target.value)
                            } else {
                                this.setState({ product_type: e.target.value, product: [] })
                            }
                        }}>
                            <option>Select Product Type</option>
                            {this.state.showLS && <option value="sku">SKU</option>}
                            {this.state.showSale && <option value="sale">Sale</option>}
                            {this.state.showLS && <option value="latest">Latest</option>}
                        </select>
                        {this.state.product_type === "sku" && <Select
                            // isMulti
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={this.state.arrSuggestions}
                            value={this.state.arrSelected}
                            onChange={(e) => {
                                this.getProductInfo(e)
                            }}
                            onInputChange={(e) => {
                                this.getskus(e)
                            }}
                        />}
                        {this.state.product.length > 0 && this.state.product.map((e) => {
                            return <div className="card-cslc" key={Math.random()} style={{ maxWidth: 500, margin: "0px auto", textAlign: "center" }}>
                                <div className="card-cs-img">
                                    <img src={e.image_url} alt="pi" width="100%" height="100%" />
                                </div>
                                <div className="content-cslc">
                                    <span style={{ textTransform: "uppercase" }} className="heading-hss">{e.name}</span>
                                    {e.short_desc ? <h1>{e.short_desc}</h1> : <h1>{e.long_desc}</h1>}
                                    {e.sale_price !== "0.00" && Number(e.sale_price) > Number(e.retail_price) ? <p>€ {e.show_sale_price}</p> : <p>€ {e.show_retail_price}</p>}
                                    <div className="secchecks" style={{ textAlign: "center" }}>
                                        <button>
                                            BEKJIK DE PRODUCT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {this.state.product.length > 0 && < div className="footer-allpopups-sections" style={{ width: "95%" }}>
                        <div className="inner-faps" onClick={this.updateType.bind(this, "category_brand_detail_page_product_1")}>
                            <SaveIcon />
                            <font>SAVE</font>
                        </div>
                    </div>}
                </div>}
                <div className="modal-popup-new" style={{ opacity: this.state.secondModalProduct ? 1 : 0, pointerEvents: this.state.secondModalProduct ? "all" : "none" }}>

                </div>
                {this.state.secondModalProduct && <div className="modal-new" style={{ opacity: this.state.secondModalProduct ? 1 : 0, pointerEvents: this.state.secondModalProduct ? "all" : "none", minHeight: "50%", padding: 40 }}>
                    <ClearIcon onClick={() => this.setState({ secondModalProduct: false })} style={{ position: "absolute", top: 10, right: 10 }} />
                    <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20, textAlign: "left" }}>
                        <select value={this.state.product_type} style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", marginBottom: 20, letterSpacing: 1 }} onChange={(e) => {
                            if (e.target.value !== "sku") {
                                this.setState({ product_type: e.target.value, product: [], })
                                this.getProductInfo(e.target.value)
                            } else {
                                this.setState({ product_type: e.target.value, product: [] })
                            }
                        }}>
                            <option>Select Product Type</option>
                            {this.state.showLS && <option value="sku">SKU</option>}
                            {this.state.showSale && <option value="sale">Sale</option>}
                            {this.state.showLS && <option value="latest">Latest</option>}
                        </select>
                        {this.state.product_type === "sku" && <Select
                            // isMulti
                            name="colors"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            options={this.state.arrSuggestions}
                            value={this.state.arrSelected}
                            onChange={(e) => {
                                this.getProductInfo(e)
                            }}
                            onInputChange={(e) => {
                                this.getskus(e)
                            }}
                        />}
                        {this.state.product.length > 0 && this.state.product.map((e) => {
                            return <div className="card-cslc" key={Math.random()} style={{ maxWidth: 500, margin: "0px auto", textAlign: "center" }}>
                                <div className="card-cs-img">
                                    <img src={e.image_url} alt="pi" width="100%" height="100%" />
                                </div>
                                <div className="content-cslc">
                                    <span style={{ textTransform: "uppercase" }} className="heading-hss">{e.name}</span>
                                    {e.short_desc ? <h1>{e.short_desc}</h1> : <h1>{e.long_desc}</h1>}
                                    {e.sale_price !== "0.00" && Number(e.sale_price) > Number(e.retail_price) ? <p>€ {e.show_sale_price}</p> : <p>€ {e.show_retail_price}</p>}
                                    <div className="secchecks" style={{ textAlign: "center" }}>
                                        <button>
                                            BEKJIK DE PRODUCT
                                        </button>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {this.state.product.length > 0 && < div className="footer-allpopups-sections" style={{ width: "95%" }}>
                        <div className="inner-faps" onClick={this.updateType.bind(this, "category_brand_detail_page_product_2")}>
                            <SaveIcon />
                            <font>SAVE</font>
                        </div>
                    </div>}
                </div>}
                <div className="bg-main-secondh">
                    {this.state.isAdmin ? <div
                        className="full-bg-hcs overlayone"
                        style={{
                            backgroundImage: `url(${this.state.wholeData.banner_image})`,
                            position: "relative"
                            // transform: this.state.transition ? "scale(1.1)" : "scale(1)"
                        }}>
                        {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20 }}>
                            <div className="uploading-beu">
                                <input type="file" onChange={(e) => this.imgUploading(e)} accept="image/*" />
                                <PublishIcon />
                            </div>
                            {this.state.wholeData.check_banner_image && <div className="uploading-beu">
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                        </div>}
                        {this.state.loaderBa && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                        <div className="overlay-full-bg-hcs">

                        </div>
                        <span className="the-ritual-title" style={{ color: "white", textAlign: "center" }} onClick={() => this.setState({ title_1Input: true })} >
                            {!this.state.title_1Input ? this.state.title_1 : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "white" }} value={this.state.title_1} onChange={(e) => this.setState({ title_1: e.target.value })} onBlur={() => this.setState({ title_1Input: false })} />}
                        </span>
                        <h1 className="cs-heading" style={{ color: "white", textAlign: "center" }}>{this.state.wholeData.heading_1}</h1>
                        {this.state.desc_1Input && <h2 onClick={() => this.setState({ desc_1: `${this.state.desc_1} [company_name]` })} style={{ width: "80%" }}>
                            Add Company Tag
                        </h2>}
                        <h2 onClick={() => this.setState({ desc_1Input: true })} onBlur={() => this.setState({ desc_1Input: false })} style={{ width: "80%" }}>
                            {!this.state.desc_1Input ? this.state.wholeData.desc_1 : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none", color: "white", width: "100%" }} value={this.state.desc_1} onChange={(e) => this.setState({ desc_1: e.target.value })} />}
                        </h2>
                        {/* <button onClick={() => window.location.href = `/brand-products/${this.props.match.params.csid}/${this.props.match.params.nestedid}`}>SHOP DE COLLECTIE</button> */}
                        {this.state.wholeData.products !== null && <button onClick={() => window.location.href = `/brand-products/${this.props.match.params.csid}/${this.props.match.params.nestedid}`}>{this.state.shop_de_collectie}</button>}
                    </div> : <div
                        className="full-bg-hcs overlayone"
                        style={{
                            backgroundImage: `url(${this.state.wholeData.banner_image})`,
                            position: "relative",
                            // transform: this.state.transition ? "scale(1.1)" : "scale(1)"
                        }}>
                            <div className="overlay-full-bg-hcs">

                            </div>
                            <span className="the-ritual-title" style={{ color: "white", textAlign: "center" }}>{this.state.title_1}</span>
                            <h1 className="cs-heading" style={{ color: "white", textAlign: "center" }}>{this.state.wholeData.heading_1}</h1>
                            <h2>{this.state.wholeData.desc_1}</h2>
                            {this.state.wholeData.products !== null && <button onClick={() => window.location.href = `/${this.props.match.params.csid}/${this.props.match.params.nestedid}`}>{this.state.shop_de_collectie}</button>}
                        </div>}
                </div>
                <div className="second-sec-cs">
                    {this.state.isAdmin ? <div className="inner-wrapper" onClick={() => this.setState({ main_descInput: true })}>
                        {!this.state.main_descInput ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.main_desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                        {this.state.wholeData.products && this.state.wholeData.description && <button onClick={() => window.location.href = `/${this.props.match.params.csid}/${this.props.match.params.nestedid}`}>{this.state.shop_de_collectie}</button>}
                    </div> : <div className="inner-wrapper">
                            <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                            {this.state.wholeData.products && <button onClick={() => window.location.href = `/${this.props.match.params.csid}/${this.props.match.params.nestedid}`}>{this.state.shop_de_collectie}</button>}
                        </div>}
                </div>
                {this.state.wholeData.horizontal_layout && this.state.wholeData.horizontal_layout.horizonta_name_1 && <div className="horizontal-layout-cs">
                    <div className="inner-hlcs">
                        <img className="logo-ihlcs" src={this.state.wholeData.horizontal_layout.horizontal_image_1} />
                        <h3>{this.state.wholeData.horizontal_layout.horizonta_name_1}</h3>
                        <p>{this.state.wholeData.horizontal_layout.horizontal_content_1}</p>
                    </div>
                    <div className="inner-hlcs">
                        <img className="logo-ihlcs" src={this.state.wholeData.horizontal_layout.horizontal_image_2} />
                        <h3>{this.state.wholeData.horizontal_layout.horizonta_name_2}</h3>
                        <p>{this.state.wholeData.horizontal_layout.horizontal_content_2}</p>
                    </div>
                    <div className="inner-hlcs">
                        <img className="logo-ihlcs" src={this.state.wholeData.horizontal_layout.horizontal_image_3} />
                        <h3>{this.state.wholeData.horizontal_layout.horizonta_name_3}</h3>
                        <p>{this.state.wholeData.horizontal_layout.horizontal_content_3}</p>
                    </div>
                </div>}
                {
                    this.state.wholeData.products && <div className="last-sec-cs">
                        {this.state.wholeData.product_1 !== null && <div>
                            <div className="first-content-last-cs">
                                <h4>{this.state.wholeData.title_2}</h4>
                                <h2>{this.state.wholeData.heading_2}</h2>
                                <p>{this.state.wholeData.desc_2}</p>
                            </div>
                            <div className="second-content-last-cs" style={{ position: "relative" }}>
                                {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20, zIndex: 999 }}>
                                    <div className="uploading-beu">
                                        <input type="file" onChange={(e) => this.imgUploadingBrand(e)} accept="image/*" />
                                        <PublishIcon />
                                    </div>
                                    {this.state.wholeData.check_left_image_1 && <div className="uploading-beu">
                                        <DeleteIcon onClick={this.removeImageBrand.bind(this)} />
                                    </div>}
                                </div>}
                                {this.state.isAdmin && <div className="left-menus-editor">
                                    <ul>
                                        <li onClick={() => {
                                            let product_1det = this.state.wholeData.category_brand_detail_page_product_1
                                            if (product_1det) {
                                                this.setState({ product_type: product_1det.product_type })
                                                if (product_1det.product_type !== "sku") {
                                                    var formdata = new FormData();
                                                    formdata.append("product_type", product_1det.product_type);
                                                    formdata.append("layout_type", "layout_1");
                                                    formdata.append("brand_id", this.props.match.params.nestedid);
                                                    formdata.append("main_category", this.props.match.params.csid);

                                                    var requestOptions = {
                                                        method: 'POST',
                                                        body: formdata,
                                                        redirect: 'follow'
                                                    };

                                                    fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                                                        .then(response => response.json())
                                                        .then(result => {
                                                            if (result.code === 200) {
                                                                if (result.data.data[0].long_desc) {
                                                                    result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                                                                }
                                                                if (result.data.data[0].short_desc) {
                                                                    result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                                                                }
                                                                this.setState({ product: result.data.data })
                                                            }
                                                        })
                                                        .catch(error => console.log('error', error));
                                                } else {
                                                    let obj = { value: product_1det.sku, label: product_1det.sku }

                                                    this.setState({ arrSelected: obj })

                                                    let arr = [product_1det.sku]

                                                    var formdata = new FormData();
                                                    formdata.append("product_type", "sku");
                                                    formdata.append("product_skus", JSON.stringify(arr));
                                                    formdata.append("layout_type", "layout_1");
                                                    formdata.append("brand_id", this.props.match.params.nestedid);
                                                    formdata.append("main_category", this.props.match.params.csid);

                                                    var requestOptions = {
                                                        method: 'POST',
                                                        body: formdata,
                                                        redirect: 'follow'
                                                    };

                                                    fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                                                        .then(response => response.json())
                                                        .then(result => {
                                                            if (result.code === 200) {
                                                                if (result.data.data[0].long_desc) {
                                                                    result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                                                                }
                                                                if (result.data.data[0].short_desc) {
                                                                    result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                                                                }
                                                                this.setState({ product: result.data.data })
                                                            }
                                                        })
                                                        .catch(error => console.log('error', error));
                                                }
                                            }
                                            this.setState({ firstModalProduct: true })
                                        }}>
                                            <EditIcon />
                                        </li>
                                    </ul>
                                </div>}
                                <div className="img-secc-last-cs" style={{ backgroundImage: `url(${this.state.wholeData.left_image_1})`, position: "relative" }} >
                                    {this.state.loaderBrand && <div className="loader-working-fb">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                </div>
                                <div className="content-secc-last-cs" data-aos="fade-left">
                                    <div className="slider-inner-wrap">
                                        <h3>{this.state.wholeData.title_3}</h3>
                                        <p>{this.state.wholeData.desc_3}</p>
                                        {this.state.wholeData.product_1 && <div className="card-cslc">
                                            <div className="card-cs-img">
                                                <img src={this.state.wholeData.product_1.image_url} width="100%" height="100%" />
                                            </div>
                                            <div className="content-cslc">
                                                <span style={{ textTransform: "uppercase" }} className="heading-hss">{this.state.wholeData.product_1.name}</span>
                                                <h1>{this.state.wholeData.product_1.long_desc}</h1>
                                                <p>€ {this.state.wholeData.product_1.sale_price !== "0.00" ? this.state.wholeData.product_1.show_sale_price : this.state.wholeData.product_1.show_retail_price}</p>
                                                <button onClick={() => window.location.href = `/product-detail/${this.props.match.params.csid}/${this.props.match.params.nestedid}/${this.state.wholeData.product_1.plain_sku}`}> BEKIJK PRODUCT</button>
                                            </div>
                                        </div>}
                                    </div>
                                </div>
                            </div>
                        </div>}
                        <div className="third-content-last-cs" style={{ paddingBottom: 0 }}>
                            {this.state.isAdmin ? <div className="inner-wrapper">
                                <h2 onClick={() => this.setState({ title_4Input: true })}>
                                    {!this.state.title_4Input ? this.state.title_4 : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none" }} value={this.state.title_4} onChange={(e) => this.setState({ title_4: e.target.value })} onBlur={() => this.setState({ title_4Input: false })} />}
                                </h2>
                                {this.state.desc_4Input && <p className="tags-para">
                                    <font onClick={() => this.setState({ desc_4: `${this.state.desc_4} [company_name]` })}>
                                        <i className="far fa-building"></i>
                                    VOEG DE NAAM VAN HET BEDRIJF TOE
                                    {/* <i className="fa fa-spinner fa-spin"></i> */}
                                    </font>&nbsp;&nbsp;
                                <font onClick={() => this.setState({ desc_4: `${this.state.desc_4} [brand_name]` })}>
                                        <i className="fa fa-tags"></i>
                                    VOEG NAAM VAN MERK TOE
                                    {/* Add Brand Name Tag */}
                                    </font>&nbsp;&nbsp;
                                <font onClick={() => this.setState({ desc_4: `${this.state.desc_4} [category_name]` })}>
                                        <i className="fa fa-th  w-10"></i>
                                    ADD NAME OF CATEGORY
                                    {/* Add Category Name Tag */}
                                    </font>&nbsp;&nbsp;
                                <font onClick={() => this.setState({ desc_4: `${this.state.desc_4} [delivery_code]` })}>
                                        <i className="fa fa-truck"></i>
                                    VOEG NAAM VAN AFLEVERCODE TOE
                                    {/* Add Delivery Code Tag */}
                                    </font>&nbsp;&nbsp;
                            </p>}
                                <p onClick={() => this.setState({ desc_4Input: true })} style={{ maxWidth: 440, margin: "0px auto" }} >
                                    {!this.state.desc_4Input ? this.state.wholeData.desc_4 : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none", width: "100%" }} value={this.state.desc_4} onChange={(e) => this.setState({ desc_4: e.target.value })} />}
                                </p>
                            </div> : <div className="inner-wrapper">
                                    <h2>
                                        {this.state.title_4}
                                    </h2>
                                    <p style={{ maxWidth: 440, margin: "0px auto" }}>{this.state.wholeData.desc_4}</p>
                                </div>}
                            <div className="card-sec-rit colsecrit">
                                <div className="inner-wrapper">
                                    {this.state.isAdmin ? <div className="all-cards-rit">
                                        <div className="card-rit" data-aos="fade-in">
                                            <div className="content-crit">
                                                {this.state.left_title ? <h3 onClick={() => this.setState({ left_titleInput: true })}>
                                                    {!this.state.left_titleInput ? this.state.left_title : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none" }} value={this.state.left_title} onChange={(e) => this.setState({ left_title: e.target.value })} onBlur={() => this.setState({ left_titleInput: false })} />}
                                                </h3> : <input autoFocus style={{ backgroundColor: "#e7e7e7", padding: 10, border: "none", outline: "none", marginBottom: 10 }} value={this.state.left_title} onChange={(e) => this.setState({ left_title: e.target.value, left_titleInput: true })} onBlur={() => this.setState({ left_titleInput: false })} />}
                                                {this.state.left_desc ? <p className="p-cr" onClick={() => this.setState({ left_descInput: true })}>
                                                    {!this.state.left_descInput ? this.state.left_desc : <textarea autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none", minHeight: 120, width: "100%" }} onChange={(e) => this.setState({ left_desc: e.target.value })} onBlur={() => this.setState({ left_descInput: false })}>{this.state.left_desc}</textarea>}
                                                </p> : <textarea autoFocus style={{ backgroundColor: "#e7e7e7", border: "none", outline: "none", marginBottom: 10, minHeight: 120, width: "100%" }} onChange={(e) => this.setState({ left_desc: e.target.value, left_descInput: true })} onBlur={() => this.setState({ left_descInput: false })}>{this.state.left_desc}</textarea>}
                                            </div>
                                            <div className="img-crit" style={{ position: "relative" }}>
                                                <img src={this.state.wholeData.left_image_2} />
                                                {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20 }}>
                                                    <div className="uploading-beu">
                                                        <input type="file" onChange={(e) => this.imgUploadingSec(e)} accept="image/*" />
                                                        <PublishIcon />
                                                    </div>
                                                    {this.state.wholeData.check_left_image_2 && <div className="uploading-beu">
                                                        <DeleteIcon onClick={this.removeImageSec.bind(this)} />
                                                    </div>}
                                                </div>}
                                                {this.state.loaderSe && <div className="loader-working-fb">
                                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                                </div>}
                                            </div>
                                        </div>
                                        <div className="card-rit" data-aos="fade-in">
                                            <div className="content-crit">
                                                {this.state.right_title ? <h3 onClick={() => this.setState({ right_titleInput: true })}>
                                                    {!this.state.right_titleInput ? this.state.right_title : <input autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none" }} value={this.state.right_title} onChange={(e) => this.setState({ right_title: e.target.value })} onBlur={() => this.setState({ right_titleInput: false })} />}
                                                </h3> : <input autoFocus style={{ backgroundColor: "#e7e7e7", padding: 10, border: "none", outline: "none", marginBottom: 10 }} value={this.state.right_title} onChange={(e) => this.setState({ right_title: e.target.value, right_titleInput: true })} onBlur={() => this.setState({ right_titleInput: false })} />}
                                                {this.state.right_desc ? <p className="p-cr" onClick={() => this.setState({ right_descInput: true })}>
                                                    {!this.state.right_descInput ? this.state.right_desc : <textarea autoFocus style={{ backgroundColor: "transparent", border: "none", outline: "none", minHeight: 120, width: "100%" }} onChange={(e) => this.setState({ right_desc: e.target.value })} onBlur={() => this.setState({ right_descInput: false })}>{this.state.right_desc}</textarea>}
                                                </p> : <textarea autoFocus style={{ backgroundColor: "#e7e7e7", border: "none", outline: "none", minHeight: 120, width: "100%" }} onChange={(e) => this.setState({ right_desc: e.target.value, right_descInput: true })} onBlur={() => this.setState({ right_descInput: false })}>{this.state.right_desc}</textarea>}
                                            </div>
                                            <div className="img-crit" style={{ position: "relative" }}>
                                                <img src={this.state.wholeData.right_image_1} />
                                                {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20 }}>
                                                    <div className="uploading-beu">
                                                        <input type="file" onChange={(e) => this.imgUploadingTr(e)} accept="image/*" />
                                                        <PublishIcon />
                                                    </div>
                                                    {this.state.wholeData.check_right_image_1 && <div className="uploading-beu">
                                                        <DeleteIcon onClick={this.removeImageTr.bind(this)} />
                                                    </div>}
                                                </div>}
                                                {this.state.loaderTr && <div className="loader-working-fb">
                                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                                </div>}
                                            </div>
                                        </div>
                                    </div> : <div className="all-cards-rit">
                                            <div className="card-rit" data-aos="fade-in">
                                                <div className="content-crit">
                                                    <h3>{this.state.left_title}</h3>
                                                    <p className="p-cr">{this.state.left_desc}</p>
                                                </div>
                                                <div className="img-crit">
                                                    <img src={this.state.wholeData.left_image_2} />
                                                </div>
                                            </div>
                                            <div className="card-rit" data-aos="fade-in">
                                                <div className="content-crit">
                                                    <h3>{this.state.wholeData.right_image_title_1}</h3>
                                                    <p className="p-cr">{this.state.wholeData.right_image_desc_1}</p>
                                                </div>
                                                <div className="img-crit">
                                                    <img src={this.state.wholeData.right_image_1} />
                                                </div>
                                            </div>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        {this.state.wholeData.vertical_layout && this.state.wholeData.vertical_layout.vertical_image_3_type && <div className="vertical-layout-cs">
                            <div className="inner-vlcs">
                                <div className="image-ivlcs" style={{ backgroundImage: this.state.wholeData.vertical_layout.vertical_image_1_type === "images" && `url(${this.state.wholeData.vertical_layout.vertical_image_1})` }}>

                                </div>
                                <div className="content-ivlcs">
                                    <h3>{this.state.wholeData.vertical_layout.vertical_name_1}</h3>
                                    <p>{this.state.wholeData.vertical_layout.vertical_content_1}</p>
                                </div>
                            </div>
                            <div className="inner-vlcs">
                                <div className="content-ivlcs">
                                    <h3>{this.state.wholeData.vertical_layout.vertical_name_2}</h3>
                                    <p>{this.state.wholeData.vertical_layout.vertical_content_2}</p>
                                </div>
                                <div className="image-ivlcs" style={{ backgroundImage: this.state.wholeData.vertical_layout.vertical_image_2_type === "images" && `url(${this.state.wholeData.vertical_layout.vertical_image_2})` }}>

                                </div>
                            </div>
                            <div className="inner-vlcs">
                                <div className="image-ivlcs" style={{ backgroundImage: this.state.wholeData.vertical_layout.vertical_image_3_type === "images" && `url(${this.state.wholeData.vertical_layout.vertical_image_3})` }}>

                                </div>
                                <div className="content-ivlcs">
                                    <h3>{this.state.wholeData.vertical_layout.vertical_name_3}</h3>
                                    <p>{this.state.wholeData.vertical_layout.vertical_content_3}</p>
                                </div>
                            </div>
                        </div>}
                        {this.state.wholeData.product_2 !== null && <div className="second-content-last-cs" style={{ marginTop: 90, position: "relative" }}>
                            {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20, zIndex: 999 }}>
                                <div className="uploading-beu">
                                    <input type="file" onChange={(e) => this.imgUploadingBrandSec(e)} accept="image/*" />
                                    <PublishIcon />
                                </div>
                                {this.state.wholeData.check_left_image_3 && <div className="uploading-beu">
                                    <DeleteIcon onClick={this.removeImageBrandSec.bind(this)} />
                                </div>}
                            </div>}
                            {this.state.isAdmin && <div className="left-menus-editor">
                                <ul>
                                    <li onClick={() => {
                                        let product_1det = this.state.wholeData.category_brand_detail_page_product_2
                                        if (product_1det) {
                                            this.setState({ product_type: product_1det.product_type })
                                            if (product_1det.product_type !== "sku") {
                                                var formdata = new FormData();
                                                formdata.append("product_type", product_1det.product_type);
                                                formdata.append("layout_type", "layout_1");
                                                formdata.append("brand_id", this.props.match.params.nestedid);
                                                formdata.append("main_category", this.props.match.params.csid);

                                                var requestOptions = {
                                                    method: 'POST',
                                                    body: formdata,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        if (result.code === 200) {
                                                            if (result.data.data[0].long_desc) {
                                                                result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                                                            }
                                                            if (result.data.data[0].short_desc) {
                                                                result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                                                            }
                                                            this.setState({ product: result.data.data })
                                                        }
                                                    })
                                                    .catch(error => console.log('error', error));
                                            } else {
                                                let obj = { value: product_1det.sku, label: product_1det.sku }

                                                this.setState({ arrSelected: obj })

                                                let arr = [product_1det.sku]

                                                var formdata = new FormData();
                                                formdata.append("product_type", "sku");
                                                formdata.append("product_skus", JSON.stringify(arr));
                                                formdata.append("layout_type", "layout_1");
                                                formdata.append("brand_id", this.props.match.params.nestedid);
                                                formdata.append("main_category", this.props.match.params.csid);

                                                var requestOptions = {
                                                    method: 'POST',
                                                    body: formdata,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        if (result.code === 200) {
                                                            if (result.data.data[0].long_desc) {
                                                                result.data.data[0].long_desc = result.data.data[0].long_desc.substr(0, 200)
                                                            }
                                                            if (result.data.data[0].short_desc) {
                                                                result.data.data[0].short_desc = result.data.data[0].short_desc.substr(0, 200)
                                                            }
                                                            this.setState({ product: result.data.data })
                                                        }
                                                    })
                                                    .catch(error => console.log('error', error));
                                            }
                                        }
                                        this.setState({ secondModalProduct: true })
                                    }}>
                                        <EditIcon />
                                    </li>
                                </ul>
                            </div>}
                            <div className="img-secc-last-cs" style={{ backgroundImage: `url(${this.state.wholeData.left_image_3})`, position: "relative" }} >
                                {this.state.loaderBrandSec && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                            </div>
                            <div className="content-secc-last-cs" data-aos="fade-left" >
                                <div className="slider-inner-wrap">
                                    <h3>{this.state.wholeData.title_5}</h3>
                                    <p>{this.state.wholeData.desc_5}</p>
                                    {this.state.wholeData.product_2 && <div className="card-cslc">
                                        <div className="card-cs-img">
                                            <img src={this.state.wholeData.product_2.image_url} width="100%" height="100%" />
                                        </div>
                                        <div className="content-cslc">
                                            <span style={{ textTransform: "uppercase" }} className="heading-hss">{this.state.wholeData.product_2.name}</span>
                                            <h1>{this.state.wholeData.product_2.long_desc}</h1>
                                            <p>€ {this.state.wholeData.product_2.sale_price !== "0.00" ? this.state.wholeData.product_2.show_sale_price : this.state.wholeData.product_2.show_retail_price}</p>
                                            <button onClick={() => window.location.href = `/product-detail/${this.props.match.params.csid}/${this.props.match.params.nestedid}/${this.state.wholeData.product_2.plain_sku}`}>BEKIJK PRODUCT</button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>}
                        {this.state.wholeData.products && this.state.wholeData.products.length > 0 && <div className="shop-sec-last">
                            <h1>{this.state.wholeData.heading_3}</h1>
                            <div className="inner-wrapper">
                                <div className="second-card-bath">
                                    {this.state.wholeData.products && this.state.wholeData.products.map((e) => {
                                        return <div className="card-bbath" data-aos="fade-in" key={Math.random()} onClick={() => {
                                            window.location.href = `/product-detail/${this.props.match.params.csid}/${e.brand_id}/${e.plain_sku}`
                                        }}>
                                            <div className="card-bimage">
                                                <img src={e.image_url} style={{ maxWidth: "100%" }} />
                                            </div>
                                            <div className="content-bimage">
                                                <span className="bodyText" style={{ textTransform: "uppercase", fontFamily: "Roboto" }} className="heading-hss">{e.name}</span>
                                                <h2 className="bodyText" style={{ fontSize: 16, fontFamily: "Roboto" }}>{e.plain_sku}</h2>
                                                <p className="short-desc-bi">{e.category_name}</p>
                                                {e.sale_price === "0.00" && Number(e.sale_price) > Number(e.retail_price) ? <p className="product-price-bi">€ {e.retail_price}</p> : <p className="product-price-bi">&nbsp;</p>}
                                                {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) ? <button className="bodyText" style={{ backgroundColor: "black", color: "white", borderRadius: 4, padding: "7px 12px", fontFamily: "Roboto" }}>€ {e.show_sale_price}</button> : <button className="bodyText" style={{ backgroundColor: "black", color: "white", borderRadius: 4, padding: "7px 12px", fontFamily: "Roboto" }}>€ {e.show_retail_price}</button>}
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>}
                    </div>
                }
                {
                    this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                        <SaveAltIcon />
                        <button onClick={this.updateSec.bind(this)}>SAVE</button>
                    </div>
                }
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default CollectionStory
