import React, { Component } from 'react'
import CheckIcon from '@material-ui/icons/Check';
import SearchIcon from '@material-ui/icons/Search';
import basket from '../assets/basketiconsec.png'
import CloseIcon from '@material-ui/icons/Close';
import { gv } from './globalvar'
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';

export class NavbarSecond extends Component {

    constructor() {
        super()
        this.state = {
            dropdownOpacity: 0,
            dropdownPointer: "none",
            dropdownOpacityHorloges: 0,
            dropdownPointerHorloges: "none",
            completeData: "",
            main_menus: [],
            childMenus: [],
            menuHovered: "",
            menuImage: "",
        }
    }

    componentDidMount() {
        let resp = this.props.data
        console.log(resp)
        this.setState({ completeData: resp, logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })

        this.getData()
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`${gv}/api/v1/auth/customer/get/cart/items?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    localStorage.setItem("cartNav", JSON.stringify(resp))
                    this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text })
                })
                .catch(error => console.log('error', error));
        } else {
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            if (!localStorage.getItem("guestUser")) {
                fetch(`${gv}/api/v1/get/customer/cookie?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        localStorage.setItem("guestUser", result.data.cart_items_cookie)
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                fetch(`${gv}/api/v1/customer/get/cart/items?browser_type=${browserType}&cart_items_cookie=${cookie_id}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartNav", JSON.stringify(resp))
                        this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text })
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    removeItem(e) {
        if (e.product_type === "gift") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })

                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    componentWillUpdate(e) {
        if (e.getData) {
            this.getData()
        }
    }


    componentWillReceiveProps(e) {
        if (e.refreshData) {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let resp = JSON.parse(headerData)
                this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text })
            }
        }
    }

    acceptCookie() {
        let guestUser = localStorage.getItem("guestUser")
        var formdata = new FormData();
        formdata.append("cart_items_cookie", guestUser);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/guest/customer/submit/accept/terms`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("readCookie", true)
                localStorage.setItem("tocp", false)
                this.setState({ show_tocp: false })
            })
            .catch(error => console.log('error', error));
    }

    declineCookie() {
        localStorage.setItem("tocp", false)
        localStorage.setItem("readCookie", false)
        this.setState({ show_tocp: false })
    }

    render() {
        return (
            <div style={{ backgroundColor: "white" }} className="hidingfooter">
                <div className="navbar-main-sec-other">
                    <div className="top-nmso">
                        <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_one}</span>
                        <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_two}</span>
                        <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_three}</span>
                    </div>
                    <div className="mid-nmso">
                        <div className="container-midnmso">
                            <div className="left-cmidnmso">
                                <p>Vragen? Bel: {this.state.completeData.phone}</p>
                                <div className="search-lcmid">
                                    <input type="text" placeholder={this.state.completeData.search_text} />
                                    <SearchIcon />
                                </div>
                            </div>
                            <div className="mid-cmidnmso">
                                <img src={this.state.logourl} />
                                <p>{this.state.completeData.store_name}</p>
                            </div>
                            <div className="right-cmidnmso">
                                <p>Mijn account</p>
                                <p>Winkelmand <img src={basket} className="cart-icon" />
                                    <div className="basket-icon">
                                        {/* <ShoppingCartRoundedIcon style={{ fill: "white", fontSize: 28 }} className="cart-icon" /> */}
                                        <span>{this.state.cartData ? this.state.cartData.total_products : 0}</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                    </div>
                    {this.state.menuHovered && <div className='closer-nmso' onMouseOver={() => this.setState({ childMenus: [], menuHovered: "", menuImage: "", dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })}>

                    </div>}
                    <div className="lists-nmso">
                        <ul>
                            {this.state.main_menus.length > 0 && this.state.main_menus.map((g) => {
                                if (g.isActive) {
                                    return <li key={Math.random()} onMouseOver={() => {
                                        console.log("mouse over working")
                                        // let child_menus = this.state.child_menus
                                        // let filtered = child_menus.filter((h) => {
                                        //     return h[0] === g.id
                                        // })
                                        // if (g.id === "horloges") {
                                        //     let objected = Object.entries(filtered[0][1])
                                        //     this.setState({ childMenus: objected, menuHovered: g.id, menuImage: g.image_url, dropdownPointer: "none", dropdownOpacity: 0, dropdownPointerHorloges: "all", dropdownOpacityHorloges: 1 })
                                        // } else {
                                        //     if (filtered.length > 0) {
                                        //         let objected = Object.entries(filtered[0][1])
                                        //         this.setState({ childMenus: objected, menuHovered: g.id, menuImage: g.image_url, dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                                        //     } else {
                                        //         this.setState({ childMenus: [], menuHovered: g.id, menuImage: "", dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })
                                        //     }
                                        // }
                                    }} onClick={() => {
                                        console.log(g)
                                        // if (g.has_sub_menu === "no") {
                                        //     window.location.href = `${g.route}`
                                        // }
                                    }} className={this.state.menuHovered === g.id ? "active" : ""} > {g.name}</li>
                                }
                            })}
                        </ul>
                    </div>
                    <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacity, pointerEvents: this.state.dropdownPointer }}>
                        <CloseIcon onClick={() => {
                            this.setState({ dropdownPointer: "none", dropdownOpacity: 0 })
                        }} style={{ position: "absolute", top: 10, right: 10 }} />
                        {this.state.childMenus.length > 0 && this.state.childMenus.map((h) => {
                            return <div className="inner-dropdown-list-nmso" key={Math.random()}>
                                <ul>
                                    <li>{h[0]}</li>
                                    {h[1].map((y) => {
                                        return <li onClick={() => {
                                            window.location.href = `/${this.state.menuHovered}/${y.id}`
                                        }}>{y.name}</li>
                                    })}
                                </ul>
                            </div>
                        })}
                        <div className="inner-dropdown-list-nmso">
                            <img src={this.state.menuImage} />
                            <p className="desc-idln">Bekijk alle {this.state.menuHovered}</p>
                            <button>SHOP NU</button>
                        </div>
                    </div>
                    <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacityHorloges, pointerEvents: this.state.dropdownPointerHorloges }}>
                        <CloseIcon onClick={() => {
                            this.setState({ dropdownPointerHorloges: "none", dropdownOpacity: 0 })
                        }} style={{ position: "absolute", top: 10, right: 10 }} />
                        {this.state.childMenus.length > 0 && this.state.childMenus.map((h) => {
                            return <div className="inner-dropdown-list-nmso" key={Math.random()}>
                                <ul>
                                    <li>{h[0]}</li>
                                    {h[1].map((y) => {
                                        return <li onClick={() => {
                                            window.location.href = `/${this.state.menuHovered}/${y.id}`
                                        }} style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                            <img src={y.image_url} style={{ maxWidth: 60 }} />
                                            <p>{y.name}</p>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        })}
                        <div className="inner-dropdown-list-nmso">
                            <img src={this.state.menuImage} />
                            <p className="desc-idln">Bekijk alle {this.state.menuHovered}</p>
                            <button>SHOP NU</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default NavbarSecond
