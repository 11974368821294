import React, { Component } from 'react'
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

export class FooterContactBannerData extends Component {


    constructor() {
        super()
        this.state = {
            phone_number: "",
            email: "123@gmail.com",
            address: "abc street",
            linkedInUrl: "",
            headerData: "",
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            headerData = JSON.parse(headerData)
            this.setState({ headerData, phone_number: headerData.phone, address: headerData.address, email: headerData.email, linkedInUrl: headerData.linked_in_url, fb_url: headerData.fb_url, insta_url: headerData.instagram_url, })
        }
    }

    render() {
        return (
            <div style={{ position: "relative" }}>
                <div className="bannersec-home" style={{ backgroundImage: `url(${this.props.data.image_url})`, marginTop: !this.props.data.space_top ? 0 : 60, maxWidth: !this.props.data.full_width && 1440, marginBottom: !this.props.data.space_bottom ? 0 : 60 }}>
                    <div className={`inner-bsh ${this.props.data.theming && "themingibsh"}`}>
                        <div className="overlay-bsh" style={{ backgroundColor: this.props.data.backgroundColor }}>

                        </div>
                        <div>
                            <p style={{ color: this.props.data.fontColor }} >{this.state.headerData.retailer_name}</p>
                            <p style={{ color: this.props.data.fontColor }} >{this.props.data.address}</p>
                            <p style={{ color: this.props.data.fontColor }} >{this.props.data.zip_code} {this.props.data.city}</p>
                            <p style={{ color: this.props.data.fontColor }} ><a style={{ color: this.props.data.fontColor }} href={`tel:${this.state.phone_number}`}>{this.state.phone_number}</a></p>
                            <p style={{ color: this.props.data.fontColor }} ><a style={{ color: this.props.data.fontColor }} href={`mailto:${this.state.email}`}>{this.state.email}</a></p>
                            <div className="icons-contactfb">
                                {this.state.fb_url && <div className="inner-icfb" onClick={() => {
                                    if (this.state.fb_url) {
                                        window.open(this.state.fb_url, "_blank")
                                        // window.location.href = this.state.fb_url
                                    }
                                }}>
                                    <FacebookIcon />
                                </div>}
                                {this.state.insta_url && <div className="inner-icfb" onClick={() => {
                                    if (this.state.insta_url) {
                                        window.open(this.state.insta_url, "_blank")
                                        // window.location.href = this.state.fb_url
                                    }
                                }}>
                                    <InstagramIcon />
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FooterContactBannerData
