import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class BrandsShow extends Component {
    constructor() {
        super()
        this.state = {
            wholeData: [1, 2, 3, 4],
            firstInput: false,
            mainArrs: [],
            nestedArrs: [],
            brand_ids: [],
            arrSuggestions: [],
            selectedCategory: '',
            arrSelected: [],
            wholeData: '',
            greaterLength: false,
            limited: false,
            allBrands: [],
            open: false,
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            fullWidth: true,
            // work done
            left_sec: {
                image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_left_image.jpg",
                label: "Shop nu",
                link: "",
                default_image: "layout_14_left_image",
                delete_image: false,
                is_brand: true,
                is_soort: false,
                button_colour: "#2C5A5B",
                onImageIndex: 0,
            },
            center_sec: {
                image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_center_image.jpg",
                label: "Shop nu",
                link: "",
                default_image: "layout_14_center_image",
                delete_image: false,
                is_brand: true,
                is_soort: false,
                button_colour: "#2C5A5B",
                onImageIndex: 0,
            },
            top_sec: {
                image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_top_image.jpg",
                label: "Shop nu",
                link: "",
                default_image: "layout_14_right_top_image",
                delete_image: false,
                is_brand: true,
                is_soort: false,
                button_colour: "#2C5A5B",
                onImageIndex: 0,
            },
            bottom_sec: {
                image: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_bottom_image.jpg",
                label: "Shop nu",
                link: "",
                default_image: "layout_14_right_bottom_image",
                delete_image: false,
                is_brand: true,
                is_soort: false,
                button_colour: "#2C5A5B",
                onImageIndex: 0,
            },
            left_loader: false,
            left_labelInput: false,
            center_loader: false,
            center_labelInput: false,
            top_loader: false,
            top_labelInput: false,
            bottom_loader: false,
            bottom_labelInput: false,
            left_image_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_left_image.jpg",
            left_delete_duplicate: false,
            center_image_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_center_image.jpg",
            center_delete_duplicate: false,
            top_image_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_top_image.jpg",
            top_delete_duplicate: false,
            bottom_image_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_14_right_bottom_image.jpg",
            bottom_delete_duplicate: false,
            crop: {
                // unit: '%',
                width: 662,
                height: 662,
                aspect: 16 / 9,
            },
            themePickerOpen: false,
            createLoader: true,
            showSubCategory: true,
            showSubCategoryTop: true,
            showSubCategoryBottom: true,
            showSubCategoryCenter: true,
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let data = JSON.parse(headerData)
            this.setState({ mainArrs: data.main_categories })
        }

        let currentPage = window.location.pathname
        let layout_type = "layout_1"
        let currentTime = Math.floor(Date.now() / 1000)
        let pageName = ""
        if (currentPage === "/") {
            pageName = "landing_page"
        } else {
            if (currentPage.includes("%20")) {
                pageName = currentPage.split("%20").join("_")
            } else {
                pageName = currentPage
            }
            pageName = pageName.split("/").join('')
        }
        let sectionType = pageName + '_' + layout_type + '_' + currentTime
        this.setState({ sectionType })

        // Broswe Detections
        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                this.setState({ browserType: "chrome" })
                browserType = "chrome"
            } else {
                this.setState({ browserType: "safari" })
                browserType = "safari"
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));

        let allRoutes = localStorage.getItem("allRoutes")
        if (allRoutes) {
            let parsed = JSON.parse(allRoutes)
            let arr = ["subcategory", "maincategory"]
            parsed.map((f) => {
                if (f.split("/")[1] === "") {
                    arr.push("landing-page")
                } else {
                    arr.push(f.split("/")[1])
                }
            })
            this.setState({ allRoutes: arr })
        }

        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            let childmenulists = []
            let hello = child_menus.filter((f) => {
                return f[0] === parsed.main_categories[0].id
            });
            childmenulists = hello[0][1]

            let objected = Object.entries(childmenulists)
            childmenulists = objected[0][1]

            fetch(`${gv}/api/v1/${objected[0][0].toLowerCase()}/merken?browser_type=${browserType}&category_type=sub_category`)
                .then(response => response.json())
                .then(result => {
                    console.log(result)
                    childmenulists = result.data.data
                    this.setState({
                        brandDetailsList: childmenulists,
                        brandDetailsListCenter: childmenulists,
                        brandDetailsListTop: childmenulists,
                        brandDetailsListBottom: childmenulists,
                        selectedBrandList: childmenulists[0].id,
                        selectedBrandListTop: childmenulists[0].id,
                        selectedBrandListBottom: childmenulists[0].id,
                        selectedBrandListCenter: childmenulists[0].id,
                    })
                })
                .catch(error => console.log('error', error));

            let arr = [childmenulists[0].id, childmenulists[1].id, childmenulists[2].id, childmenulists[3].id]

            var formdata = new FormData();
            formdata.append("main_category", parsed.main_categories[0].id);
            formdata.append("brand_id", JSON.stringify(arr));
            formdata.append("section_type", this.state.sectionType);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/brands/by/brand_id?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    this.state.left_sec.image = result.data.brands[0].left_banner
                    this.state.center_sec.image = result.data.brands[1].left_banner
                    this.state.top_sec.image = result.data.brands[2].left_banner
                    this.state.bottom_sec.image = result.data.brands[3].left_banner
                    this.setState({
                        left_sec: this.state.left_sec,
                        center_sec: this.state.center_sec,
                        top_sec: this.state.top_sec,
                        bottom_sec: this.state.bottom_sec,
                    })
                })
                .catch(error => console.log('error', error));

            this.setState({
                brandCategories: parsed.main_categories,
                selectedCategory: parsed.main_categories[0].id,
                selectedCategoryCenter: parsed.main_categories[0].id,
                selectedCategoryTop: parsed.main_categories[0].id,
                selectedCategoryBottom: parsed.main_categories[0].id,
                childmenulists,
                childmenulistsCenter: childmenulists,
                childmenulistsTop: childmenulists,
                childmenulistsBottom: childmenulists,
                selectedBrand: childmenulists[0].id,
                selectedBrandCenter: childmenulists[1].id,
                selectedBrandTop: childmenulists[2].id,
                selectedBrandBottom: childmenulists[3].id,
                selectRoute: true,
                selectedRoute: "/subcategory",
                brandRoute: true,
                selectRouteCenter: true,
                selectedRouteCenter: "/subcategory",
                brandRouteCenter: true,
                selectRouteTop: true,
                selectedRouteTop: "/subcategory",
                brandRouteTop: true,
                selectRouteBottom: true,
                selectedRouteBottom: "/subcategory",
                brandRouteBottom: true,
                selectedRouteurl: `/${parsed.main_categories[0].id}/${childmenulists[0].id}`,
                selectedRouteurlCenter: `/${parsed.main_categories[0].id}/${childmenulists[1].id}`,
                selectedRouteurlTop: `/${parsed.main_categories[0].id}/${childmenulists[2].id}`,
                selectedRouteurlBottom: `/${parsed.main_categories[0].id}/${childmenulists[3].id}`,
            })
        }
    }

    uploadImg(e, f, g) {
        e.preventDefault()

        let maxWidth = ''
        let maxHeight = ''

        if (g === "left" || g === "center") {
            maxWidth = 620
            maxHeight = 650
        } else {
            maxWidth = 570
            maxHeight = 325
        }

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0], imageName: f, imagePosition: g })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > maxWidth || height > maxHeight) {
                            if (g === "left" || g === "center") {
                                let crop = {
                                    unit: 'px',
                                    width: 620,
                                    height: 650,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            } else {
                                let crop = {
                                    unit: 'px',
                                    width: 570,
                                    height: 325,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            }
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    doneImage() {
        let f = this.state.imageName
        let g = this.state.imagePosition
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        let accesstoken = localStorage.getItem("adminKey")

        this.setState({ cropper: false, croppedImageUrl: "" })

        if (g === "left") {
            this.setState({ left_loader: true })
            if (this.state.left_sec.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoort.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ left_loader: false })
                        if (result.data.sucess !== 0) {
                            this.state.left_sec.image = result.data.image_url
                            this.state.left_sec.delete_image = true
                            this.setState({ left_sec: this.state.left_sec })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.left_sec.is_brand) {
                    if (this.state.selectedBrand) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrand.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ left_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.left_sec.image = result.data.image_url
                                    this.state.left_sec.delete_image = true
                                    this.setState({ left_sec: this.state.left_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulists[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ left_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.left_sec.image = result.data.image_url
                                    this.state.left_sec.delete_image = true
                                    this.setState({ left_sec: this.state.left_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ left_loader: false })
                            console.log(result)
                            if (result.data.sucess !== 0) {
                                this.state.left_sec.image = result.data.image_url
                                this.state.left_sec.delete_image = true
                                this.setState({ left_sec: this.state.left_sec })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "center") {
            this.setState({ center_loader: true })
            if (this.state.center_sec.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoortCenter.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ center_loader: false })
                        if (result.data.sucess !== 0) {
                            this.state.center_sec.image = result.data.image_url
                            this.state.center_sec.delete_image = true
                            this.setState({ center_sec: this.state.center_sec })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.center_sec.is_brand) {
                    if (this.state.selectedBrandCenter) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrandCenter.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryCenter);
                        formdata.append("image_type", "left_banner");



                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ center_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.center_sec.image = result.data.image_url
                                    this.state.center_sec.delete_image = true
                                    this.setState({ center_sec: this.state.center_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulistsCenter[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryCenter);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ center_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.center_sec.image = result.data.image_url
                                    this.state.center_sec.delete_image = true
                                    this.setState({ center_sec: this.state.center_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ center_loader: false })
                            if (result.data.sucess !== 0) {
                                this.state.center_sec.image = result.data.image_url
                                this.state.center_sec.delete_image = true
                                this.setState({ center_sec: this.state.center_sec })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "top") {
            this.setState({ top_loader: true })
            if (this.state.top_sec.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoortTop.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ top_loader: false })
                        if (result.data.sucess !== 0) {
                            this.state.top_sec.image = result.data.image_url
                            this.state.top_sec.delete_image = true
                            this.setState({ top_sec: this.state.top_sec })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.top_sec.is_brand) {
                    if (this.state.selectedBrandTop) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrandTop.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryTop);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ top_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.top_sec.image = result.data.image_url
                                    this.state.top_sec.delete_image = true
                                    this.setState({ top_sec: this.state.top_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulistsTop[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryTop);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ top_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.top_sec.image = result.data.image_url
                                    this.state.top_sec.delete_image = true
                                    this.setState({ top_sec: this.state.top_sec })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.state.top_sec.image = result.data.image_url
                                this.state.top_sec.delete_image = true
                                this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "bottom") {
            this.setState({ bottom_loader: true })
            if (this.state.bottom_sec.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoortBottom.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ bottom_loader: false })
                        if (result.data.sucess !== 0) {
                            this.state.bottom_sec.image = result.data.image_url
                            this.state.bottom_sec.delete_image = true
                            this.setState({ bottom_sec: this.state.bottom_sec })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.bottom_sec.is_brand) {
                    if (this.state.selectedBrandBottom) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrandBottom.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryBottom);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ bottom_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.bottom_sec.image = result.data.image_url
                                    this.state.bottom_sec.delete_image = true
                                    this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulistsBottom[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryBottom);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ bottom_loader: false })
                                if (result.data.sucess !== 0) {
                                    this.state.bottom_sec.image = result.data.image_url
                                    this.state.bottom_sec.delete_image = true
                                    this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.state.bottom_sec.image = result.data.image_url
                                this.state.bottom_sec.delete_image = true
                                this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    removeImage(e, f, g) {
        if (g === "left") {
            this.setState({ left_loader: true })
        } else if (g === "center") {
            this.setState({ center_loader: true })
        } else if (g === "top") {
            this.setState({ top_loader: true })
        } else if (g === "bottom") {
            this.setState({ bottom_loader: true })
        }

        if (g === "left") {
            if (this.state.left_sec.is_soort) {
                let imageName = e.split('/')

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image_name", imageName[imageName.length - 1]);
                formdata.append("brand_id", this.state.selectedSoort.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.state.left_sec.image = result.data.default_url
                            this.state.left_sec.delete_image = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.left_sec.is_brand) {
                    if (this.state.selectedBrand) {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrand.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.left_sec.image = result.data.images
                                    this.state.left_sec.delete_image = false
                                    this.state.left_sec.onImageIndex = 0
                                    this.setState({ left_sec: this.state.left_sec, left_loader: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.childmenulists[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.left_sec.image = result.data.images
                                    this.state.left_sec.delete_image = false
                                    this.state.left_sec.onImageIndex = 0
                                    this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    let imageName = e.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image_name", imageName[imageName.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.state.left_sec.image = result.data.default_url
                            this.state.left_sec.delete_image = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "center") {
            if (this.state.center_sec.is_soort) {
                let imageName = e.split('/')

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image_name", imageName[imageName.length - 1]);
                formdata.append("brand_id", this.state.selectedSoortCenter.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.state.center_sec.image = result.data.default_url
                            this.state.center_sec.delete_image = false
                            this.state.center_sec.onImageIndex = 0
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.center_sec.is_brand) {
                    if (this.state.selectedBrandCenter) {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrandCenter.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryCenter);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.center_sec.image = result.data.images
                                    this.state.center_sec.delete_image = false
                                    this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.childmenulistsCenter[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryCenter);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.center_sec.image = result.data.images
                                    this.state.center_sec.delete_image = false
                                    this.state.center_sec.onImageIndex = 0
                                    this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    let imageName = e.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image_name", imageName[imageName.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.state.center_sec.image = result.data.default_url
                            this.state.center_sec.delete_image = false
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "top") {
            if (this.state.top_sec.is_soort) {
                let imageName = e.split('/')

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image_name", imageName[imageName.length - 1]);
                formdata.append("brand_id", this.state.selectedSoortTop.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.state.top_sec.image = result.data.default_url
                            this.state.top_sec.delete_image = false
                            this.state.top_sec.onImageIndex = 0
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.top_sec.is_brand) {
                    if (this.state.selectedBrandTop) {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrandTop.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryTop);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.top_sec.image = result.data.images
                                    this.state.top_sec.delete_image = false
                                    this.state.top_sec.onImageIndex = 0
                                    this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.childmenulistsTop[0].id.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryTop);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.top_sec.image = result.data.images
                                    this.state.top_sec.delete_image = false
                                    this.state.top_sec.onImageIndex = 0
                                    this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    let imageName = e.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image_name", imageName[imageName.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.state.top_sec.image = result.data.default_url
                            this.state.top_sec.delete_image = false
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else if (g === "bottom") {
            if (this.state.bottom_sec.is_soort) {
                let imageName = e.split('/')

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image_name", imageName[imageName.length - 1]);
                formdata.append("brand_id", this.state.selectedSoortBottom.toLowerCase());
                formdata.append("main_category", "soort");
                formdata.append("image_type", "left_banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.state.bottom_sec.image = result.data.default_url
                            this.state.bottom_sec.delete_image = false
                            this.setState({ bottom_sec: this.state.top_sec, bottom_loader: false, })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                if (this.state.bottom_sec.is_brand) {
                    if (this.state.selectedBrandTop) {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrandBottom.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryBottom);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.bottom_sec.image = result.data.images
                                    this.state.bottom_sec.delete_image = false
                                    this.state.bottom_sec.onImageIndex = 0
                                    this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        let imageName = e.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrandBottom.toLowerCase());
                        formdata.append("main_category", this.state.selectedCategoryBottom);
                        formdata.append("image_type", "left_banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.state.bottom_sec.image = result.data.images
                                    this.state.bottom_sec.delete_image = false
                                    this.state.bottom_sec.onImageIndex = 0
                                    this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    let imageName = e.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image_type", f);
                    formdata.append("image_name", imageName[imageName.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.state.bottom_sec.image = result.data.default_url
                            this.state.bottom_sec.delete_image = false
                            this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }

    }

    getBrand(e, g, h) {
        if (g === "left") {
            this.setState({ left_loader: true })
        } else if (g === "center") {
            this.setState({ center_loader: true })
        } else if (g === "top") {
            this.setState({ top_loader: true })
        } else if (g === "bottom") {
            this.setState({ bottom_loader: true })
        }

        if (e === "firstTime") {
            if (g === "left") {
                this.setState({ left_image_duplicate: this.state.left_sec.image, left_delete_duplicate: this.state.left_sec.delete_image, })
            } else if (g === "center") {
                this.setState({ center_image_duplicate: this.state.center_sec.image, center_delete_duplicate: this.state.center_sec.delete_image, })
            } else if (g === "top") {
                this.setState({ top_image_duplicate: this.state.top_sec.image, top_delete_duplicate: this.state.top_sec.delete_image, })
            } else if (g === "bottom") {
                this.setState({ bottom_image_duplicate: this.state.bottom_sec.image, bottom_delete_duplicate: this.state.bottom_sec.delete_image, })
            }

            var formdata = new FormData();
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.childmenulists[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        if (g === "left") {
                            this.state.left_sec.image = result.data.image_url
                            this.state.left_sec.delete_image = false
                            this.state.left_sec.is_brand = true
                            this.state.left_sec.is_soort = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        } else if (g === "center") {
                            this.state.center_sec.image = result.data.image_url
                            this.state.center_sec.delete_image = false
                            this.state.center_sec.is_brand = true
                            this.state.center_sec.is_soort = false
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        } else if (g === "top") {
                            this.state.top_sec.image = result.data.image_url
                            this.state.top_sec.delete_image = false
                            this.state.top_sec.is_brand = true
                            this.state.top_sec.is_soort = false
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        } else if (g === "bottom") {
                            this.state.bottom_sec.image = result.data.image_url
                            this.state.bottom_sec.delete_image = false
                            this.state.bottom_sec.is_brand = true
                            this.state.bottom_sec.is_soort = false
                            this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                        }
                    } else {
                        if (g === "left") {
                            this.state.left_sec.image = result.data.image_url
                            this.state.left_sec.delete_image = true
                            this.state.left_sec.is_brand = true
                            this.state.left_sec.is_soort = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        } else if (g === "center") {
                            this.state.center_sec.image = result.data.image_url
                            this.state.center_sec.delete_image = true
                            this.state.center_sec.is_brand = true
                            this.state.center_sec.is_soort = false
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        } else if (g === "top") {
                            this.state.top_sec.image = result.data.image_url
                            this.state.top_sec.delete_image = true
                            this.state.top_sec.is_brand = true
                            this.state.top_sec.is_soort = false
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        } else if (g === "bottom") {
                            this.state.bottom_sec.image = result.data.image_url
                            this.state.bottom_sec.delete_image = true
                            this.state.bottom_sec.is_brand = true
                            this.state.bottom_sec.is_soort = false
                            this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        } else if (e === "secTime") {
            var selectedCateogrySend = ""
            if (h === "left") {
                selectedCateogrySend = this.state.selectedCategory
                this.setState({ selectedRouteurl: `/${this.state.selectedCategory}/${g.target.value}`, selectedBrand: g.target.value })
            } else if (h === "center") {
                selectedCateogrySend = this.state.selectedCategoryCenter
                this.setState({ selectedRouteurlCenter: `/${this.state.selectedCategoryCenter}/${g.target.value}`, selectedBrandCenter: g.target.value })
            } else if (h === "top") {
                selectedCateogrySend = this.state.selectedCategoryTop
                this.setState({ selectedRouteurlTop: `/${this.state.selectedCategoryTop}/${g.target.value}`, selectedBrandTop: g.target.value })
            } else if (h === "bottom") {
                selectedCateogrySend = this.state.selectedCategoryBottom
                this.setState({ selectedRouteurlBottom: `/${this.state.selectedCategoryBottom}/${g.target.value}`, selectedBrandBottom: g.target.value })
            }

            var formdata = new FormData();
            formdata.append("main_category", selectedCateogrySend);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", g.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        if (h === "left") {
                            this.state.left_sec.image = result.data.image_url
                            this.state.left_sec.delete_image = false
                            this.state.left_sec.is_brand = true
                            this.state.left_sec.is_soort = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        } else if (h === "center") {
                            this.state.center_sec.image = result.data.image_url
                            this.state.center_sec.delete_image = false
                            this.state.center_sec.is_brand = true
                            this.state.center_sec.is_soort = false
                            console.log(this.state.center_sec)
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        } else if (h === "top") {
                            this.state.top_sec.image = result.data.image_url
                            this.state.top_sec.delete_image = false
                            this.state.top_sec.is_brand = true
                            this.state.top_sec.is_soort = false
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        } else if (h === "bottom") {
                            this.state.bottom_sec.image = result.data.image_url
                            this.state.bottom_sec.delete_image = false
                            this.state.bottom_sec.is_brand = true
                            this.state.bottom_sec.is_soort = false
                            this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                        }
                    } else {
                        if (h === "left") {
                            this.state.left_sec.image = result.data.image_url
                            this.state.left_sec.delete_image = true
                            this.state.left_sec.is_brand = true
                            this.state.left_sec.is_soort = false
                            this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                        } else if (h === "center") {
                            this.state.center_sec.image = result.data.image_url
                            this.state.center_sec.delete_image = true
                            this.state.center_sec.is_brand = true
                            this.state.center_sec.is_soort = false
                            this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                        } else if (h === "top") {
                            this.state.top_sec.image = result.data.image_url
                            this.state.top_sec.delete_image = true
                            this.state.top_sec.is_brand = true
                            this.state.top_sec.is_soort = false
                            this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                        } else if (h === "bottom") {
                            this.state.bottom_sec.image = result.data.image_url
                            this.state.bottom_sec.delete_image = true
                            this.state.bottom_sec.is_brand = true
                            this.state.bottom_sec.is_soort = false
                            this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === e.target.value) {
                        let objected = Object.entries(f[1])
                        let filtered = objected.filter((h) => {
                            return h[0].toLowerCase() === e.target.value
                        })
                        f = filtered[0]
                        if (h === "left") {
                            if (this.state.showSubCategory) {
                                this.setState({ childmenulists: f[1], selectedCategory: e.target.value, selectedBrand: f[1][0].id, selectedRouteurl: `/${e.target.value}/${f[1][0].id}` })
                            } else {
                                this.setState({ childmenulists: f[1], selectedCategory: e.target.value, selectedBrand: f[1][0].id, selectedRouteurl: `/hoofcategorie/${e.target.value}/products` })
                            }
                        } else if (h === "center") {
                            if (this.state.showSubCategoryCenter) {
                                this.setState({ childmenulistsCenter: f[1], selectedCategoryCenter: e.target.value, selectedBrandCenter: f[1][0].id, selectedRouteurlCenter: `/${e.target.value}/${f[1][0].id}` })
                            } else {
                                this.setState({ childmenulistsCenter: f[1], selectedCategoryCenter: e.target.value, selectedBrandCenter: f[1][0].id, selectedRouteurlCenter: `/hoofcategorie/${e.target.value}/products` })
                            }
                        } else if (h === "top") {
                            if (this.state.showSubCategoryTop) {
                                this.setState({ childmenulistsTop: f[1], selectedCategoryTop: e.target.value, selectedBrandTop: f[1][0].id, selectedRouteurlTop: `/${e.target.value}/${f[1][0].id}` })
                            } else {
                                this.setState({ childmenulistsTop: f[1], selectedCategoryTop: e.target.value, selectedBrandTop: f[1][0].id, selectedRouteurlTop: `/hoofcategorie/${e.target.value}/products` })
                            }
                        } else if (h === "bottom") {
                            if (this.state.showSubCategoryBottom) {
                                this.setState({ childmenulistsBottom: f[1], selectedCategoryBottom: e.target.value, selectedBrandBottom: f[1][0].id, selectedRouteurlBottom: `/${e.target.value}/${f[1][0].id}` })
                            } else {
                                this.setState({ childmenulistsBottom: f[1], selectedCategoryBottom: e.target.value, selectedBrandBottom: f[1][0].id, selectedRouteurlBottom: `/hoofcategorie/${e.target.value}/products` })
                            }
                        }

                        var formdata = new FormData();
                        formdata.append("main_category", e.target.value);
                        formdata.append("image_type", "left_banner");
                        formdata.append("brand_id", f[1][0].id.toLowerCase());

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.message === "its demo image.") {
                                    if (h === "left") {
                                        this.state.left_sec.image = result.data.image_url
                                        this.state.left_sec.delete_image = false
                                        this.state.left_sec.is_brand = true
                                        this.state.left_sec.is_soort = false
                                        this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                                    } else if (h === "center") {
                                        this.state.center_sec.image = result.data.image_url
                                        this.state.center_sec.delete_image = false
                                        this.state.center_sec.is_brand = true
                                        this.state.center_sec.is_soort = false
                                        this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                                    } else if (h === "top") {
                                        this.state.top_sec.image = result.data.image_url
                                        this.state.top_sec.delete_image = false
                                        this.state.top_sec.is_brand = true
                                        this.state.top_sec.is_soort = false
                                        this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                                    } else if (h === "bottom") {
                                        this.state.bottom_sec.image = result.data.image_url
                                        this.state.bottom_sec.delete_image = false
                                        this.state.bottom_sec.is_brand = true
                                        this.state.bottom_sec.is_soort = false
                                        this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                    }
                                } else {
                                    if (h === "left") {
                                        this.state.left_sec.image = result.data.image_url
                                        this.state.left_sec.delete_image = true
                                        this.state.left_sec.is_brand = true
                                        this.state.left_sec.is_soort = false
                                        this.setState({ left_sec: this.state.left_sec, left_loader: false, })
                                    } else if (h === "center") {
                                        this.state.center_sec.image = result.data.image_url
                                        this.state.center_sec.delete_image = true
                                        this.state.center_sec.is_brand = true
                                        this.state.center_sec.is_soort = false
                                        this.setState({ center_sec: this.state.center_sec, center_loader: false, })
                                    } else if (h === "top") {
                                        this.state.top_sec.image = result.data.image_url
                                        this.state.top_sec.delete_image = true
                                        this.state.top_sec.is_brand = true
                                        this.state.top_sec.is_soort = false
                                        this.setState({ top_sec: this.state.top_sec, top_loader: false, })
                                    } else if (h === "bottom") {
                                        this.state.bottom_sec.image = result.data.image_url
                                        this.state.bottom_sec.delete_image = true
                                        this.state.bottom_sec.is_brand = true
                                        this.state.bottom_sec.is_soort = false
                                        this.setState({ bottom_sec: this.state.bottom_sec, bottom_loader: false, })
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                })
            }
        }
    }

    resetBrand(e) {
        if (e === "left") {
            this.state.left_sec.image = this.state.left_image_duplicate
            this.state.left_sec.delete_image = this.state.left_delete_duplicate
            this.state.left_sec.is_brand = false
            this.state.left_sec.is_soort = false
            this.setState({ left_sec: this.state.left_sec, selectedBrand: "" })
        } else if (e === "center") {
            this.state.center_sec.image = this.state.center_image_duplicate
            this.state.center_sec.delete_image = this.state.center_delete_duplicate
            this.state.center_sec.is_brand = false
            this.state.center_sec.is_soort = false
            this.setState({ center_sec: this.state.center_sec, selectedBrandCenter: "" })
        } else if (e === "top") {
            this.state.top_sec.image = this.state.top_image_duplicate
            this.state.top_sec.delete_image = this.state.top_delete_duplicate
            this.state.top_sec.is_brand = false
            this.state.top_sec.is_soort = false
            this.setState({ top_sec: this.state.top_sec, selectedBrandTop: "" })
        } else if (e === "bottom") {
            this.state.bottom_sec.image = this.state.bottom_image_duplicate
            this.state.bottom_sec.delete_image = this.state.bottom_delete_duplicate
            this.state.bottom_sec.is_brand = false
            this.state.bottom_sec.is_soort = false
            this.setState({ bottom_sec: this.state.bottom_sec, selectedBrandBottom: "" })
        }
    }

    getBrandDetailsListCenter(e, f) {
        this.setState({ selectedCategoryCenter: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsListCenter: childmenulists, selectedRouteurlCenter: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    getBrandDetailsListTop(e, f) {
        this.setState({ selectedCategoryTop: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsListTop: childmenulists, selectedRouteurlTop: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { wholeData, buttonUrl, buttonUrlCenter, buttonUrlTop, buttonUrlBottom } = this.state
        let urlBtn = ""
        let is_button_route = false
        if (this.state.selectRoute) {
            if (this.state.selectedRoute) {
                is_button_route = true
                if (this.state.selectedRoute === "/landing-page") {
                    urlBtn = "/"
                } else if (this.state.selectedRoute === "/subcategory" || this.state.selectedRoute === "/maincategory" || this.state.selectedRoute === "/soort" || this.state.selectedRoute === "/brand-detail") {
                    urlBtn = this.state.selectedRouteurl
                } else {
                    urlBtn = this.state.selectedRoute
                }
            } else {
                is_button_route = false
                urlBtn = this.state.selectedRoute
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrl) {
                if (buttonUrl.match(regex)) {
                    urlBtn = buttonUrl
                    is_button_route = true
                    this.setState({ urlValidate: false })
                } else {
                    urlBtn = ""
                    this.setState({ urlValidate: true })
                }
            } else {
                urlBtn = ""
                is_button_route = false
            }
        }
        let urlBtnCenter = ""
        let is_button_routeCenter = false
        if (this.state.selectRouteCenter) {
            if (this.state.selectedRouteCenter) {
                is_button_routeCenter = true
                if (this.state.selectedRouteCenter === "/landing-page") {
                    urlBtnCenter = "/"
                } else if (this.state.selectedRouteCenter === "/subcategory" || this.state.selectedRouteCenter === "/maincategory" || this.state.selectedRouteCenter === "/soort" || this.state.selectedRouteCenter === "/brand-detail") {
                    urlBtnCenter = this.state.selectedRouteurlCenter
                } else {
                    urlBtnCenter = this.state.selectedRouteCenter
                }
            } else {
                is_button_routeCenter = false
                urlBtnCenter = this.state.selectedRouteCenter
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrlCenter) {
                if (buttonUrlCenter.match(regex)) {
                    urlBtnCenter = buttonUrlCenter
                    is_button_routeCenter = true
                    this.setState({ urlValidateCenter: false })
                } else {
                    urlBtnCenter = ""
                    this.setState({ urlValidateCenter: true })
                }
            } else {
                urlBtnCenter = ""
                is_button_routeCenter = false
            }
        }
        let urlBtnTop = ""
        let is_button_routeTop = false
        if (this.state.selectRouteTop) {
            if (this.state.selectedRouteTop) {
                is_button_routeTop = true
                if (this.state.selectedRouteTop === "/landing-page") {
                    urlBtnTop = "/"
                } else if (this.state.selectedRouteTop === "/subcategory" || this.state.selectedRouteTop === "/maincategory" || this.state.selectedRouteTop === "/soort" || this.state.selectedRouteTop === "/brand-detail") {
                    urlBtnTop = this.state.selectedRouteurlTop
                } else {
                    urlBtnTop = this.state.selectedRouteTop
                }
            } else {
                is_button_routeTop = false
                urlBtnTop = this.state.selectedRouteTop
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrlTop) {
                if (buttonUrlTop.match(regex)) {
                    urlBtnTop = buttonUrlTop
                    is_button_routeTop = true
                    this.setState({ urlValidateTop: false })
                } else {
                    urlBtnTop = ""
                    this.setState({ urlValidateTop: true })
                }
            } else {
                urlBtnTop = ""
                is_button_routeTop = false
            }
        }
        let urlBtnBottom = ""
        let is_button_routeBottom = false
        if (this.state.selectRouteBottom) {
            if (this.state.selectedRouteBottom) {
                is_button_routeBottom = true
                if (this.state.selectedRouteBottom === "/landing-page") {
                    urlBtnBottom = "/"
                } else if (this.state.selectedRouteBottom === "/subcategory" || this.state.selectedRouteBottom === "/maincategory" || this.state.selectedRouteBottom === "/soort" || this.state.selectedRouteBottom === "/brand-detail") {
                    urlBtnBottom = this.state.selectedRouteurlBottom
                } else {
                    urlBtnBottom = this.state.selectedRouteBottom
                }
            } else {
                is_button_routeBottom = false
                urlBtnBottom = this.state.selectedRouteBottom
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrlBottom) {
                if (buttonUrlBottom.match(regex)) {
                    urlBtnBottom = buttonUrlBottom
                    is_button_routeBottom = true
                    this.setState({ urlValidateBottom: false })
                } else {
                    urlBtnBottom = ""
                    this.setState({ urlValidateBottom: true })
                }
            } else {
                urlBtnBottom = ""
                is_button_routeBottom = false
            }
        }
        let obj = {
            type: "layout_14",
            left_content: this.state.left_sec,
            center_content: this.state.center_sec,
            top_content: this.state.top_sec,
            bottom_content: this.state.bottom_sec,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            full_width: this.state.fullWidth,
            is_button_route,
            button_url: urlBtn,
            urlType: this.state.selectRoute,
            is_button_routeCenter,
            button_urlCenter: urlBtnCenter,
            urlTypeCenter: this.state.selectRouteCenter,
            is_button_routeTop,
            button_urlTop: urlBtnTop,
            urlTypeTop: this.state.selectRouteTop,
            is_button_routeBottom,
            button_urlBottom: urlBtnBottom,
            urlTypeBottom: this.state.selectRouteBottom,
            sectionType: this.state.sectionType,
            sub_category: this.state.showSubCategory,
            sub_category_bottom: this.state.showSubCategoryBottom,
            sub_category_center: this.state.showSubCategoryCenter,
            sub_category_top: this.state.showSubCategoryTop,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    getSoort(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurl: `/soort/${this.state.soortArr[0].id}`, selectedSoort: this.state.soortArr[0].id, is_soort: true, is_brand: false, })
            this.setState({ left_image_duplicate: this.state.left_sec.image, left_delete_duplicate: this.state.left_sec.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.left_sec.image = result.data.image_url
                        this.state.left_sec.delete_image = false
                        this.state.left_sec.is_brand = false
                        this.state.left_sec.is_soort = true
                        this.setState({ left_sec: this.state.left_sec })
                    } else {
                        this.state.left_sec.image = result.data.image_url
                        this.state.left_sec.delete_image = true
                        this.state.left_sec.is_brand = false
                        this.state.left_sec.is_soort = true
                        this.setState({ left_sec: this.state.left_sec })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurl: `/soort/${e.target.value}`, selectedSoort: e.target.value, is_soort: true, is_brand: false })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.left_sec.image = result.data.image_url
                        this.state.left_sec.delete_image = false
                        this.state.left_sec.is_brand = false
                        this.state.left_sec.is_soort = true
                        this.setState({ left_sec: this.state.left_sec })
                    } else {
                        this.state.left_sec.image = result.data.image_url
                        this.state.left_sec.delete_image = true
                        this.state.left_sec.is_brand = false
                        this.state.left_sec.is_soort = true
                        this.setState({ left_sec: this.state.left_sec })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    getSoortCenter(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurlCenter: `/soort/${this.state.soortArr[0].id}`, selectedSoortCenter: this.state.soortArr[0].id })
            this.setState({ center_image_duplicate: this.state.center_sec.image, center_delete_duplicate: this.state.center_sec.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.center_sec.image = result.data.image_url
                        this.state.center_sec.delete_image = false
                        this.state.center_sec.is_brand = false
                        this.state.center_sec.is_soort = true
                        this.setState({ center_sec: this.state.center_sec })
                    } else {
                        this.state.center_sec.image = result.data.image_url
                        this.state.center_sec.delete_image = true
                        this.state.center_sec.is_brand = false
                        this.state.center_sec.is_soort = true
                        this.setState({ center_sec: this.state.center_sec })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurlCenter: `/soort/${e.target.value}`, selectedSoortCenter: e.target.value })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.center_sec.image = result.data.image_url
                        this.state.center_sec.delete_image = false
                        this.state.center_sec.is_brand = false
                        this.state.center_sec.is_soort = true
                        this.setState({ center_sec: this.state.center_sec })
                    } else {
                        this.state.center_sec.image = result.data.image_url
                        this.state.center_sec.delete_image = true
                        this.state.center_sec.is_brand = false
                        this.state.center_sec.is_soort = true
                        this.setState({ center_sec: this.state.center_sec })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    getSoortTop(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurlTop: `/soort/${this.state.soortArr[0].id}`, selectedSoortTop: this.state.soortArr[0].id })
            this.setState({ top_image_duplicate: this.state.top_sec.image, top_delete_duplicate: this.state.top_sec.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.top_sec.image = result.data.image_url
                        this.state.top_sec.delete_image = false
                        this.state.top_sec.is_brand = false
                        this.state.top_sec.is_soort = true
                        this.setState({ top_sec: this.state.top_sec })
                    } else {
                        this.state.top_sec.image = result.data.image_url
                        this.state.top_sec.delete_image = true
                        this.state.top_sec.is_brand = false
                        this.state.top_sec.is_soort = true
                        this.setState({ top_sec: this.state.top_sec })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurlTop: `/soort/${e.target.value}`, selectedSoortTop: e.target.value })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.top_sec.image = result.data.image_url
                        this.state.top_sec.delete_image = false
                        this.state.top_sec.is_brand = false
                        this.state.top_sec.is_soort = true
                        this.setState({ top_sec: this.state.top_sec })
                    } else {
                        this.state.top_sec.image = result.data.image_url
                        this.state.top_sec.delete_image = true
                        this.state.top_sec.is_brand = false
                        this.state.top_sec.is_soort = true
                        this.setState({ top_sec: this.state.top_sec })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    getSoortBottom(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurlBottom: `/soort/${this.state.soortArr[0].id}`, selectedSoortBottom: this.state.soortArr[0].id })
            this.setState({ bottom_image_duplicate: this.state.bottom_sec.image, bottom_delete_duplicate: this.state.bottom_sec.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.bottom_sec.image = result.data.image_url
                        this.state.bottom_sec.delete_image = false
                        this.state.bottom_sec.is_brand = false
                        this.state.bottom_sec.is_soort = true
                        this.setState({ bottom_sec: this.state.bottom_sec })
                    } else {
                        this.state.bottom_sec.image = result.data.image_url
                        this.state.bottom_sec.delete_image = true
                        this.state.bottom_sec.is_brand = false
                        this.state.bottom_sec.is_soort = true
                        this.setState({ bottom_sec: this.state.bottom_sec })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurlBottom: `/soort/${e.target.value}`, selectedSoortBottom: e.target.value })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.state.bottom_sec.image = result.data.image_url
                        this.state.bottom_sec.delete_image = false
                        this.state.bottom_sec.is_brand = false
                        this.state.bottom_sec.is_soort = true
                        this.setState({ bottom_sec: this.state.bottom_sec })
                    } else {
                        this.state.bottom_sec.image = result.data.image_url
                        this.state.bottom_sec.delete_image = true
                        this.state.bottom_sec.is_brand = false
                        this.state.bottom_sec.is_soort = true
                        this.setState({ bottom_sec: this.state.bottom_sec })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        this.setState({ cropper: false, croppedImageUrl: "" })
    }

    handleChangeComplete = (color) => {
        this.state.left_sec.button_colour = color.hex
        this.setState({ left_sec: this.state.left_sec, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteCenter = (color) => {
        this.state.center_sec.button_colour = color.hex
        this.setState({ center_sec: this.state.center_sec, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteBottom = (color) => {
        this.state.bottom_sec.button_colour = color.hex
        this.setState({ bottom_sec: this.state.bottom_sec, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteTop = (color) => {
        this.state.top_sec.button_colour = color.hex
        this.setState({ top_sec: this.state.top_sec, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                </div>
                <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }} >
                    <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                    <div className="color-working-popup">
                        <div className="first-div-cwp">
                            <label>Left section colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.left_sec.button_colour}
                                onChangeComplete={this.handleChangeComplete}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Center section colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.center_sec.button_colour}
                                onChangeComplete={this.handleChangeCompleteCenter}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Top section colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.top_sec.button_colour}
                                onChangeComplete={this.handleChangeCompleteTop}
                            />
                        </div>
                        <div className="first-div-cwp">
                            <label>Bottom section colour</label>
                            {/* <input /> */}
                            <SketchPicker
                                color={this.state.bottom_sec.button_colour}
                                onChangeComplete={this.handleChangeCompleteBottom}
                            />
                        </div>
                    </div>
                </div>
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    {this.state.imagePosition === "left" ? <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_left_image", "left")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div> : this.state.imagePosition === "center" ? <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_center_image", "center")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div> : this.state.imagePosition === "top" ? <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_right_top_image", "top")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div> : this.state.imagePosition === "center" && <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_right_bottom_image", "bottom")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>}
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="brandsshow-main" style={{ paddingBottom: 60, paddingTop: 60 }}>
                        <div className="bsm-mainf">
                            <div className="first-sec-bsm" style={{ backgroundImage: `url(${Array.isArray(this.state.left_sec.image) ? this.state.left_sec.image[this.state.left_sec.onImageIndex] : this.state.left_sec.image})` }}>
                                <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                    <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_left_image", "left")} accept="image/*" />
                                    <PublishIcon style={{ color: "white" }} />
                                </div>
                                {this.state.left_sec.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                    <DeleteIcon onClick={this.removeImage.bind(this, this.state.left_sec.image, "layout_14_left_image", "left")} />
                                </div>}
                                {!this.state.left_sec.delete_image && Array.isArray(this.state.left_sec.image) && this.state.left_sec.image.length > 1 && this.state.left_sec.onImageIndex > 0 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 100, top: 10 }}>
                                    <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                        this.state.left_sec.onImageIndex = this.state.left_sec.onImageIndex - 1
                                        this.setState({ left_sec: this.state.left_sec })
                                    }} />
                                </div>
                                }
                                {!this.state.left_sec.delete_image && Array.isArray(this.state.left_sec.image) && this.state.left_sec.image.length > 1 && this.state.left_sec.onImageIndex < this.state.left_sec.image.length - 1 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 160, top: 10 }}>
                                    <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                        this.state.left_sec.onImageIndex = this.state.left_sec.onImageIndex + 1
                                        this.setState({ left_sec: this.state.left_sec })
                                    }} />
                                </div>
                                }
                                {this.state.left_loader && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                                <label className="label-bsm" onClick={() => this.setState({ left_labelInput: true })} style={{ backgroundColor: this.state.left_sec.button_colour }}>
                                    {!this.state.left_labelInput ? this.state.left_sec.label : <div className="secchecks" style={{ textAlign: "center" }}>
                                        <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRoute ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRoute: true })
                                                } else {
                                                    this.setState({ selectRoute: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRoute ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRoute} onChange={(e) => {
                                                    this.setState({ selectedRoute: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrand("firstTime", "left")
                                                        this.setState({ brandRoute: true, showSubCategory: true, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/${this.state.selectedCategory}/${this.state.childmenulists[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrand("firstTime", "left")
                                                        this.setState({ brandRoute: true, showSubCategory: false, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products` })
                                                    } else {
                                                        this.resetBrand("left")
                                                        this.setState({ brandRoute: false, listRoute: false, selectedRouteurl: "", detailRoute: false, soortRoute: false })
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRoute && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} onChange={(e) => this.getBrand(e, "brand-products", "left")} value={this.state.selectedCategory}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategory && <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrand} onChange={(e) => this.getBrand("secTime", e, "left")}>
                                                        {this.state.childmenulists.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input type="text" className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrl} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrl: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrl: e.target.value })
                                                }
                                            }} />}
                                            {this.state.urlValidate && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                            <button style={{ backgroundColor: "black" }}>
                                                <input value={this.state.left_sec.label} style={{ color: "white" }} autoFocus onChange={(e) => {
                                                    this.state.left_sec.label = e.target.value
                                                    this.setState({ left_sec: this.state.left_sec })
                                                }} />
                                            </button>
                                        </div>
                                    </div>}
                                </label>
                            </div>
                            {/* Center Sec */}
                            <div className="first-sec-bsm" style={{ backgroundImage: `url(${Array.isArray(this.state.center_sec.image) ? this.state.center_sec.image[this.state.center_sec.onImageIndex] : this.state.center_sec.image})` }}>
                                <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                    <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_center_image", "center")} accept="image/*" />
                                    <PublishIcon style={{ color: "white" }} />
                                </div>
                                {this.state.center_sec.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                    <DeleteIcon onClick={this.removeImage.bind(this, this.state.center_sec.image, "layout_14_center_image", "center")} />
                                </div>}
                                {this.state.center_loader && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                                {!this.state.center_sec.delete_image && Array.isArray(this.state.center_sec.image) && this.state.center_sec.image.length > 1 && this.state.center_sec.onImageIndex > 0 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 100, top: 10 }}>
                                    <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                        this.state.center_sec.onImageIndex = this.state.center_sec.onImageIndex - 1
                                        this.setState({ center_sec: this.state.center_sec })
                                    }} />
                                </div>
                                }
                                {!this.state.center_sec.delete_image && Array.isArray(this.state.center_sec.image) && this.state.center_sec.image.length > 1 && this.state.center_sec.onImageIndex < this.state.center_sec.image.length - 1 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 160, top: 10 }}>
                                    <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                        this.state.center_sec.onImageIndex = this.state.center_sec.onImageIndex + 1
                                        this.setState({ center_sec: this.state.center_sec })
                                    }} />
                                </div>
                                }
                                <label className="label-bsm" onClick={() => this.setState({ center_labelInput: true })} style={{ backgroundColor: this.state.center_sec.button_colour }}>
                                    {!this.state.center_labelInput ? this.state.center_sec.label : <div className="secchecks" style={{ textAlign: "center" }}>
                                        <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRouteCenter ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRouteCenter: true })
                                                } else {
                                                    this.setState({ selectRouteCenter: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRouteCenter ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRouteCenter} onChange={(e) => {
                                                    this.setState({ selectedRouteCenter: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrand("firstTime", "center")
                                                        this.setState({ brandRouteCenter: true, showSubCategoryCenter: true, detailRouteCenter: false, listRouteCenter: false, soortRouteCenter: false, selectedRouteurlCenter: `/${this.state.selectedCategoryCenter}/${this.state.childmenulistsCenter[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrand("firstTime", "center")
                                                        this.setState({ brandRouteCenter: true, showSubCategoryCenter: false, detailRouteCenter: false, listRouteCenter: false, soortRouteCenter: false, selectedRouteurlCenter: `/hoofcategorie/${this.state.selectedCategoryCenter}/products` })
                                                    } else {
                                                        this.resetBrand("center")
                                                        this.setState({ brandRouteCenter: false, listRouteCenter: false, selectedRouteurlCenter: "", detailRouteCenter: false, soortRouteCenter: false })
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRouteCenter && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} onChange={(e) => this.getBrand(e, "brand-products", "center")} value={this.state.selectedCategoryCenter}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategoryCenter && <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrandCenter} onChange={(e) => this.getBrand("secTime", e, "center")}>
                                                        {this.state.childmenulistsCenter.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input type="text" className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrlCenter} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrlCenter: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrlCenter: e.target.value })
                                                }
                                            }} />}
                                            <button style={{ backgroundColor: "black" }}>
                                                <input value={this.state.center_sec.label} style={{ color: "white" }} autoFocus onChange={(e) => {
                                                    this.state.center_sec.label = e.target.value
                                                    this.setState({ center_sec: this.state.center_sec })
                                                }} />
                                            </button>
                                        </div>
                                    </div>}
                                </label>
                            </div>
                        </div>
                        <div className="bsm-mains">
                            {/* Top Sec */}
                            <div className="third-sec-bsm" style={{ backgroundImage: `url(${Array.isArray(this.state.top_sec.image) ? this.state.top_sec.image[this.state.top_sec.onImageIndex] : this.state.top_sec.image})` }} >
                                <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                    <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_right_top_image", "top")} accept="image/*" />
                                    <PublishIcon style={{ color: "white" }} />
                                </div>
                                {this.state.top_sec.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                    <DeleteIcon onClick={this.removeImage.bind(this, this.state.top_sec.image, "layout_14_right_top_image", "top")} />
                                </div>}
                                {this.state.top_loader && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                                {!this.state.top_sec.delete_image && Array.isArray(this.state.top_sec.image) && this.state.top_sec.image.length > 1 && this.state.top_sec.onImageIndex > 0 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 80 }}>
                                    <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                        this.state.top_sec.onImageIndex = this.state.top_sec.onImageIndex - 1
                                        this.setState({ top_sec: this.state.top_sec })
                                    }} />
                                </div>
                                }
                                {!this.state.top_sec.delete_image && Array.isArray(this.state.top_sec.image) && this.state.top_sec.image.length > 1 && this.state.top_sec.onImageIndex < this.state.top_sec.image.length - 1 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 80 }}>
                                    <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                        this.state.top_sec.onImageIndex = this.state.top_sec.onImageIndex + 1
                                        this.setState({ top_sec: this.state.top_sec })
                                    }} />
                                </div>
                                }
                                <label className="label-bsm" onClick={() => this.setState({ top_labelInput: true })} style={{ backgroundColor: this.state.top_sec.button_colour }}>
                                    {!this.state.top_labelInput ? this.state.top_sec.label : <div className="secchecks" style={{ textAlign: "center" }}>
                                        <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRouteTop ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRouteTop: true })
                                                } else {
                                                    this.setState({ selectRouteTop: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRouteTop ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRouteTop} onChange={(e) => {
                                                    this.setState({ selectedRouteTop: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrand("firstTime", "top")
                                                        this.setState({ brandRouteTop: true, showSubCategoryTop: true, detailRouteTop: false, listRouteTop: false, soortRouteTop: false, selectedRouteurlTop: `/${this.state.selectedCategoryTop}/${this.state.childmenulists[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrand("firstTime", "top")
                                                        this.setState({ brandRouteTop: true, showSubCategoryTop: false, detailRouteTop: false, listRouteTop: false, soortRouteTop: false, selectedRouteurlTop: `/hoofcategorie/${this.state.selectedCategoryTop}/products` })
                                                    } else {
                                                        this.resetBrand("top")
                                                        this.setState({ brandRouteTop: false, listRouteTop: false, selectedRouteurlTop: "", detailRouteTop: false, soortRouteTop: false })
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRouteTop && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} onChange={(e) => this.getBrand(e, "brand-products", "top")} value={this.state.selectedCategoryTop}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategoryTop && <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrandTop} onChange={(e) => this.getBrand("secTime", e, "top")}>
                                                        {this.state.childmenulistsTop.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input type="text" className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrlTop} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrlTop: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrlTop: e.target.value })
                                                }
                                            }} />}
                                            <button style={{ backgroundColor: "black" }}>
                                                <input value={this.state.top_sec.label} style={{ color: "white" }} autoFocus onChange={(e) => {
                                                    this.state.top_sec.label = e.target.value
                                                    this.setState({ top_sec: this.state.top_sec })
                                                }} />
                                            </button>
                                        </div>
                                    </div>}
                                </label>
                            </div>
                            {/* Bottom Sec */}
                            <div className="fourth-sec-bsm" style={{ backgroundImage: `url(${Array.isArray(this.state.bottom_sec.image) ? this.state.bottom_sec.image[this.state.bottom_sec.onImageIndex] : this.state.bottom_sec.image})` }}>
                                <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                                    <input type="file" onChange={(e) => this.uploadImg(e, "layout_14_right_bottom_image", "bottom")} accept="image/*" />
                                    <PublishIcon style={{ color: "white" }} />
                                </div>
                                {this.state.bottom_sec.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                                    <DeleteIcon onClick={this.removeImage.bind(this, this.state.bottom_sec.image, "layout_14_right_bottom_image", "bottom")} />
                                </div>}
                                {this.state.bottom_loader && <div className="loader-working-fb">
                                    <div className="lds-facebook"><div></div><div></div><div></div></div>
                                </div>}
                                {!this.state.bottom_sec.delete_image && Array.isArray(this.state.bottom_sec.image) && this.state.bottom_sec.image.length > 1 && this.state.bottom_sec.onImageIndex > 0 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 80 }}>
                                    <ChevronLeftIcon style={{ color: "white" }} onClick={() => {
                                        this.state.bottom_sec.onImageIndex = this.state.bottom_sec.onImageIndex - 1
                                        this.setState({ bottom_sec: this.state.bottom_sec })
                                    }} />
                                </div>
                                }
                                {!this.state.bottom_sec.delete_image && Array.isArray(this.state.bottom_sec.image) && this.state.bottom_sec.image.length > 1 && this.state.bottom_sec.onImageIndex < this.state.bottom_sec.image.length - 1 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 80 }}>
                                    <ChevronRightIcon style={{ color: "white" }} onClick={() => {
                                        this.state.bottom_sec.onImageIndex = this.state.bottom_sec.onImageIndex + 1
                                        this.setState({ bottom_sec: this.state.bottom_sec })
                                    }} />
                                </div>
                                }
                                <label className="label-bsm" onClick={() => this.setState({ bottom_labelInput: true })} style={{ backgroundColor: this.state.bottom_sec.button_colour }}>
                                    {!this.state.bottom_labelInput ? this.state.bottom_sec.label : <div className="secchecks" style={{ textAlign: "center" }}>
                                        <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRouteBottom ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRouteBottom: true })
                                                } else {
                                                    this.setState({ selectRouteBottom: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRouteBottom ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRouteBottom} onChange={(e) => {
                                                    this.setState({ selectedRouteBottom: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrand("firstTime", "bottom")
                                                        this.setState({ brandRouteBottom: true, showSubCategoryBottom: true, detailRouteBottom: false, listRouteBottom: false, soortRouteBottom: false, selectedRouteurlBottom: `/${this.state.selectedCategoryBottom}/${this.state.childmenulists[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrand("firstTime", "bottom")
                                                        this.setState({ brandRouteBottom: true, showSubCategoryBottom: false, detailRouteBottom: false, listRouteBottom: false, soortRouteBottom: false, selectedRouteurlBottom: `/hoofcategorie/${this.state.selectedCategoryBottom}/products` })
                                                    } else {
                                                        this.resetBrand("bottom")
                                                        this.setState({ brandRouteBottom: false, listRouteBottom: false, selectedRouteurlBottom: "", detailRouteBottom: false, soortRouteBottom: false })
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRouteBottom && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} onChange={(e) => this.getBrand(e, "brand-products", "bottom")} value={this.state.selectedCategoryBottom}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategoryBottom && <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedBrandBottom} onChange={(e) => this.getBrand("secTime", e, "bottom")}>
                                                        {this.state.childmenulistsBottom.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input type="text" className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrlBottom} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrlBottom: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrlBottom: e.target.value })
                                                }
                                            }} />}
                                            <button style={{ backgroundColor: "black" }}>
                                                <input value={this.state.bottom_sec.label} style={{ color: "white" }} autoFocus onChange={(e) => {
                                                    this.state.bottom_sec.label = e.target.value
                                                    this.setState({ bottom_sec: this.state.bottom_sec })
                                                }} />
                                            </button>
                                        </div>
                                    </div>}
                                </label>
                            </div>
                        </div>
                    </div>}
                {
                    !this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                            <ColorizeIcon />
                            <font>Button Colour</font>
                        </div>
                        <div className="inner-faps">
                            <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                                this.setState({ spaceBottom: !this.state.spaceBottom })
                            }} />
                            <font>RUIMTE ONDER</font>
                        </div>
                        <div className="inner-faps">
                            <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                                this.setState({ spaceTop: !this.state.spaceTop })
                            }} />
                            <font>RUIMTE BOVEN</font>
                        </div>
                        <div className="inner-faps">
                            <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                                this.setState({ fullWidth: !this.state.fullWidth })
                            }} />
                            <font>PAGINABREEDTE</font>
                        </div>
                        {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                            <SaveIcon />
                            <font>SAVE</font>
                        </div>}
                    </div>
                }
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    {this.state.imagePosition === "left" ? <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 535x650, kindly provide valid image</DialogContentText>
                    </DialogContent> : this.state.imagePosition === "center" ? <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 535x650, kindly provide valid image</DialogContentText>
                    </DialogContent> : this.state.imagePosition === "top" ? <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 570x325, kindly provide valid image</DialogContentText>
                    </DialogContent> : this.state.imagePosition === "bottom" && <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 570x325, kindly provide valid image</DialogContentText>
                    </DialogContent>}
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default BrandsShow
