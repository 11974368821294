import React, { Component } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { gv } from '../Components/globalvar'
import Geocode from "react-geocode";
import './index.css'
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import InputComponent from '../Components/InputComponent';

AOS.init();

// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
Geocode.setApiKey("AIzaSyB-sooax1EiXpeAASfGxhn7e19kgog55To");
Geocode.setLanguage("en");
// Geocode.setRegion("es");
Geocode.enableDebug();

export class ContactEdit extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            modal: false,
            firstBlock: false,
            secondBlock: true,
            thirdBlock: false,
            wholeData: [],
            contactData: [],
            timings: [],
            latitudeMap: '',
            longitudeMap: '',
            // footer section work
            spaceTop: false,
            spaceBottom: false,
            createLoader: true,
            currentIndex: 0,
            wholeDataContact: [],
        }
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            this.setState({ spaceTop: parsedData.space_top, spaceBottom: parsedData.space_bottom, leftTitle: parsedData.leftTitle })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/shops/locations`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let data = result.data.shop_locations[parsedData.currentIndex]
                    let timings = Object.entries(JSON.parse(data.shop_timings))
                    this.setState({ contactData: data, timings, selectedCity: data.city, wholeDataContact: result.data.shop_locations, currentIndex: parsedData.currentIndex })
                    Geocode.fromAddress(data.address).then(
                        response => {
                            const { lat, lng } = response.results[0].geometry.location;
                            this.setState({ latitudeMap: lat, longitudeMap: lng })
                        },
                        error => {
                            console.error(error);
                        }
                    );
                })
                .catch(error => console.log('error', error));
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { wholeData } = this.state
        let obj = {
            type: "layout_6",
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            realData: this.state.contactData,
            currentIndex: this.state.currentIndex,
            leftTitle: this.state.leftTitle,
        }
        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    closeSec(e) {
        this.state.contactData.left_title = e
        this.setState({ contactData: this.state.contactData, leftTitleInput: false })
    }

    render() {
        const MyMapComponent = withScriptjs(withGoogleMap((props) =>
            <GoogleMap
                defaultZoom={14}
                defaultCenter={{ lat: this.state.latitudeMap, lng: this.state.longitudeMap }}
            >
                {/* {props.isMarkerShown && */}
                {props.isMarkerShown && <Marker position={{ lat: this.state.latitudeMap, lng: this.state.longitudeMap }} />}

                {/* < Marker position={{ lat: this.state.lat, lng: this.state.lng }} draggable={true} onDragEnd={(e) => this.setState({ lat: e.latLng.lat(), lng: e.latLng.lng() })} />} */}
            </GoogleMap>
        ))
        return (
            <div>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="bg-main-secondh brand-products">
                    <div className="checkout-holder" style={{ paddingBottom: 60, marginBottom: 0, paddingTop: 0 }}>
                        <div className="inner-wrapper">
                            <div className="inner-wrapper-small">
                                <div className="checkout-layout-left">
                                    <div className="checkout-inner">
                                        <div className="checkout-step-content">
                                            <div className="checkout-review-box contactpage-left">
                                                <h4 onClick={() => this.setState({ leftTitleInput: true })}>
                                                    {!this.state.leftTitleInput ? this.state.leftTitle : <InputComponent dataText={this.state.leftTitle} blurInput={this.closeSec.bind(this)} />}
                                                </h4>
                                                <h4 style={{ fontSize: 14, fontWeight: 600 }}>Uw lokale Zinzi dealer</h4>
                                                <h6>{this.state.contactData.left_desc}</h6>
                                                {this.state.latitudeMap && <MyMapComponent
                                                    isMarkerShown
                                                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyB-sooax1EiXpeAASfGxhn7e19kgog55To&callback=initMap"
                                                    loadingElement={<div style={{ height: "100%" }} />}
                                                    containerElement={<div style={{ height: 330, marginTop: 40 }} />}
                                                    mapElement={<div style={{ height: "100%" }} />}
                                                />}
                                            </div>
                                            <select className="option-crbcl" sele={this.state.selectedCity} onChange={(e) => {
                                                let data = this.state.wholeDataContact[Number(e.target.value)]
                                                let timings = Object.entries(JSON.parse(data.shop_timings))
                                                this.setState({ contactData: data, timings, leftTitle: data.left_title, selectedCity: data.city, currentIndex: Number(e.target.value) })
                                                Geocode.fromAddress(data.address).then(
                                                    response => {
                                                        const { lat, lng } = response.results[0].geometry.location;
                                                        this.setState({ latitudeMap: lat, longitudeMap: lng })
                                                    },
                                                    error => {
                                                        console.error(error);
                                                    }
                                                );
                                            }}>
                                                {this.state.wholeDataContact.length > 0 && this.state.wholeDataContact.map((e, i) => {
                                                    return <option value={i} selected={i === this.state.currentIndex}>{e.city}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-layout-right">
                                    <div className="checkout-summary-inner contactpage-right">
                                        <h4>{this.state.contactData.right_title}</h4>
                                        <h4 style={{ fontSize: 14, fontWeight: 600 }}>{this.state.contactData.right_title_1}</h4>
                                        <h6>{this.state.contactData.right_desc}</h6>
                                        <ul>
                                            {this.state.timings.length > 0 && this.state.timings.map((e) => {
                                                return <li key={Math.random()}>
                                                    <span>
                                                        {e[0]}
                                                    </span>
                                                    <span>
                                                        {e[1][0] !== "Gesloten" ? `${e[1][0]} - ${e[1][1]}` : `${e[1][0]}`}
                                                    </span>
                                                </li>
                                            })}
                                        </ul>
                                        <h6 className="heading6right">{this.state.contactData.contact_page_vakantiesluiting}</h6>
                                        <h6 className="heading6right" style={{ margin: "0px auto" }}>{this.state.contactData.contact_page_meldingen}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!this.state.createLoader && <div className="footer-allpopups-sections">
                        <div className="inner-faps">
                            <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                                this.setState({ spaceBottom: !this.state.spaceBottom })
                            }} />
                            <font>RUIMTE ONDER</font>
                        </div>
                        <div className="inner-faps">
                            <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                                this.setState({ spaceTop: !this.state.spaceTop })
                            }} />
                            <font>RUIMTE BOVEN</font>
                        </div>
                        {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                            <SaveIcon />
                            <font>SAVE</font>
                        </div>}
                    </div>}
                </div>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default ContactEdit
