import React, { Component } from 'react'
import NavCheck from '../../Components/NavCheck'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { gv } from '../../Components/globalvar'
import cities from '../../Data/cities.json'
import countries from '../../Data/countries.json'
import statesCountry from '../../Data/states.json'
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import './index.css'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class Checkout extends Component {

    constructor() {
        super()
        this.state = {
            popupright: false,
            wholeData: [],
            addressType: '',
            country: '',
            region: '',
            countries: [],
            countryid: '',
            allCities: [],
            allCitiesSec: [],
            stateid: '',
            statePopup: false,
            firstName: '',
            fnPopup: false,
            lastName: '',
            lnPopup: false,
            zipcode: '',
            zipPopup: false,
            houseNumber: '',
            hnPopup: false,
            country: '',
            countryPopup: false,
            city: '',
            cityPopup: false,
            street: '',
            streetPopup: false,
            phone: '',
            phonePopup: false,
            sex: '',
            sexPopup: false,
            dob: '',
            dobPopup: false,
            passwordType: true,
            passwordTypeSec: true,
            nestedFields: false,
            countryidSec: '',
            countryPopupSec: false,
            streetSec: '',
            streetPopupSec: false,
            zipcodeSec: '',
            zipPopupSec: false,
            houseNumberSec: '',
            hnPopupSec: false,
            citySec: '',
            cityPopupSec: false,
            addresstype: "",
            // Editing Working
            heading_1: "",
            heading_1Input: false,
            heading_2: "",
            heading_2Input: false,
            terms_text: "",
            terms_textInput: false,
            to_payment: "",
            to_paymentInput: false,
            isAdmin: false,
            headerData: "",
            cartNav: "",
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            this.setState({ headerData: JSON.parse(headerData) })
        }

        let cartNav = localStorage.getItem("cartNav")
        if (cartNav) {
            this.setState({ cartNav: JSON.parse(cartNav) })
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/two?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    let monthOfOrder = ""
                    let yearOfOrder = ""
                    let dateOfOrder = ""
                    let timeOfOrder = ""
                    if (resp.order_timings) {
                        let dayTimeOfOrder = JSON.parse(resp.order_timings)
                        monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                        yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                        dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                        timeOfOrder = dayTimeOfOrder.time
                    }
                    this.setState({
                        wholeData: resp, heading_1: resp.cart_heading_1, heading_2: resp.cart_heading_2, terms_text: resp.terms_text, to_payment: resp.button_text, coupon_code: resp.coupon_settings.coupon_code, monthOfOrder,
                        yearOfOrder,
                        dateOfOrder,
                        timeOfOrder,
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            console.log("it's guest user")
        }
    }

    addAddress() {
        const { firstName, lastName, zipcode, houseNumber, street, phone, city, countryid } = this.state
        let filteredCountry = []
        if (firstName === "") {
            this.setState({ fnPopup: true })
        } else {
            this.setState({ fnPopup: false })
        }
        if (lastName === "") {
            this.setState({ lnPopup: true })
        } else {
            this.setState({ lnPopup: false })
        }
        if (zipcode === "") {
            this.setState({ zipPopup: true })
        } else {
            this.setState({ zipPopup: false })
        }
        if (houseNumber === "") {
            this.setState({ hnPopup: true })
        } else {
            this.setState({ hnPopup: false })
        }
        if (street === "") {
            this.setState({ streetPopup: true })
        } else {
            this.setState({ streetPopup: false })
        }
        if (phone === "") {
            this.setState({ phonePopup: true })
        } else {
            this.setState({ phonePopup: false })
        }
        if (city === "") {
            this.setState({ cityPopup: true })
        } else {
            this.setState({ cityPopup: false })
        }
        if (countryid === "") {
            this.setState({ countryPopup: true })
        } else {
            this.setState({ countryPopup: false })
            filteredCountry = countries.filter((e) => {
                return e.id === Number(countryid)
            })
        }
        if (firstName !== "" && lastName !== "" && zipcode !== "" && houseNumber !== "" && phone !== "" && city !== "" && countryid !== "") {
            let usertoken = localStorage.getItem("usertoken")
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var formdata = new FormData();
            formdata.append("first_name", firstName);
            formdata.append("last_name", lastName);
            formdata.append("street", street);
            formdata.append("house_number", houseNumber);
            formdata.append("zip_code", zipcode);
            formdata.append("phone", phone);
            formdata.append("city", city);
            formdata.append("country", filteredCountry[0].name);
            formdata.append("address_type", this.state.addressType);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.getData()
                        this.setState({ popupright: false, firstName: '', lastName: '', street: '', houseNumber: '', zipcode: '', phone: '', city: '', countryid: '' })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { heading_1, heading_2, terms_text, to_payment } = this.state

        let arr = [
            { key: "checkout_page_cart_heading_1", value: heading_1 },
            { key: "checkout_page_cart_heading_2", value: heading_2 },
            { key: "checkout_page_terms_text", value: terms_text },
            { key: "checkout_page_button_text", value: to_payment },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    removeCoupon() {
        let usertoken = localStorage.getItem("usertoken")

        console.log(this.state.coupon_code)

        if (!usertoken) {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));

        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div className="checkoutfooterhide">
                <NavCheck />
                {this.state.wholeData && <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>}
                <div className="checkout-holder" style={{ position: this.state.popupright ? "fixed" : this.state.popupbilling ? "fixed" : null }}>
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="checkout-layout-left">
                                <div className="back-button-wrapper">

                                </div>
                                <div className="checkout-step-buttons-holder">
                                    <div className="checkout-steps-buttons-list">
                                        <div className="checkout-step-btn">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                <CheckIcon style={{ fontSize: 16 }} />
                                            </span>
                                            <span className="text">Shopping</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                2
                                            </span>
                                            <span className="text">Bezorging</span>
                                        </div>
                                        <div className="checkout-step-btn selected">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">3</span>
                                            <span className="text">Controleren</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">4</span>
                                            <span className="text">Betaling</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        {this.state.isAdmin ? <h2 className="checkout-step-title">
                                            <span className="number">3</span>
                                            <span className="text" onClick={() => this.setState({ heading_1Input: true })}>
                                                {!this.state.heading_1Input ? this.state.heading_1 : <input type="text" autoFocus value={this.state.heading_1} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </span>
                                        </h2> : <h2 className="checkout-step-title">
                                            <span className="number">3</span>
                                            <span className="text">
                                                {this.state.heading_1}
                                            </span>
                                        </h2>}
                                        <div className="checkout-review-box">
                                            {this.state.isAdmin ? <h3 className="checkout-review-box-title" onClick={() => this.setState({ heading_2Input: true })}>
                                                {!this.state.heading_2Input ? this.state.heading_2 : <input type="text" autoFocus value={this.state.heading_2} onChange={(e) => this.setState({ heading_2: e.target.value })} onBlur={() => this.setState({ heading_2Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </h3> : <h3 className="checkout-review-box-title">
                                                {this.state.heading_2}
                                            </h3>}
                                            {this.state.wholeData.diffrent_shipping ?
                                                <div className="read-only-summary" style={{ borderTop: 0, paddingTop: 0 }}>
                                                    <span className="name">{this.state.wholeData.diffrent_shipping.first_name} {this.state.wholeData.diffrent_shipping.last_name}</span>
                                                    <span>{this.state.wholeData.diffrent_shipping.street} {this.state.wholeData.diffrent_shipping.house_number}</span>
                                                    <span>{this.state.wholeData.diffrent_shipping.zip_code}</span>
                                                    <span>{this.state.wholeData.diffrent_shipping.city}</span>
                                                    <span>{this.state.wholeData.diffrent_shipping.country}</span>
                                                </div> : this.state.wholeData.shipping_address &&
                                                <div className="read-only-summary" style={{ borderTop: 0, paddingTop: 0 }}>
                                                    <span className="name">{this.state.wholeData.shipping_address.first_name} {this.state.wholeData.shipping_address.last_name}</span>
                                                    <span>{this.state.wholeData.shipping_address.street} {this.state.wholeData.shipping_address.house_number}</span>
                                                    <span>{this.state.wholeData.shipping_address.zip_code}</span>
                                                    <span>{this.state.wholeData.shipping_address.city}</span>
                                                    <span>{this.state.wholeData.shipping_address.country}</span>
                                                </div>}
                                            <div className="checkout-review-box-footer">
                                                <button onClick={() => {
                                                    if (this.state.wholeData.diffrent_shipping) {
                                                        let countryid = countries.filter((e) => {
                                                            return e.name === this.state.wholeData.diffrent_shipping.country
                                                        })
                                                        this.setState({
                                                            addressType: "diffrent_shipping",
                                                            popupright: true,
                                                            firstName: this.state.wholeData.diffrent_shipping.first_name,
                                                            lastName: this.state.wholeData.diffrent_shipping.last_name,
                                                            street: this.state.wholeData.diffrent_shipping.street,
                                                            city: this.state.wholeData.diffrent_shipping.city,
                                                            zipcode: this.state.wholeData.diffrent_shipping.zip_code,
                                                            countryid: countryid[0].id,
                                                            houseNumber: this.state.wholeData.diffrent_shipping.house_number,
                                                            phone: this.state.wholeData.diffrent_shipping.phone,
                                                        })
                                                    } else {
                                                        let countryid = countries.filter((e) => {
                                                            return e.name === this.state.wholeData.shipping_address.country
                                                        })
                                                        this.setState({
                                                            addressType: "shipping",
                                                            popupright: true,
                                                            firstName: this.state.wholeData.shipping_address.first_name,
                                                            lastName: this.state.wholeData.shipping_address.last_name,
                                                            street: this.state.wholeData.shipping_address.street,
                                                            city: this.state.wholeData.shipping_address.city,
                                                            zipcode: this.state.wholeData.shipping_address.zip_code,
                                                            countryid: countryid[0].id,
                                                            houseNumber: this.state.wholeData.shipping_address.house_number,
                                                            phone: this.state.wholeData.shipping_address.phone,
                                                        })
                                                    }
                                                }}>Edit Delivery Address</button>
                                            </div>
                                        </div>
                                        <div className="terms-and-conditions-info" style={{ cursor: "pointer" }} onClick={() => window.location.href = "/terms-and-conditions"}>
                                            {this.state.isAdmin ? <p onClick={() => this.setState({ terms_textInput: true })}>
                                                {!this.state.terms_textInput ? this.state.terms_text : <input type="text" autoFocus value={this.state.terms_text} onChange={(e) => this.setState({ terms_text: e.target.value })} onBlur={() => this.setState({ terms_textInput: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none", color: "black" }} />}
                                            </p> : <p>
                                                {this.state.terms_text}
                                            </p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout-layout-right">
                                <div className="checkout-summary-inner">
                                    <h3 className="section-header" style={{ marginBottom: 0 }}>
                                        <font><a href="/cart">WIJZIG</a> MIJN WINKELMAND</font>
                                    </h3>
                                    <div className="checkout-summary-minicart">
                                        {this.state.wholeData.cart_items && this.state.wholeData.cart_items.map((e) => {
                                            return <div className="mini-cart-product" style={{ padding: 15 }} key={Math.random()}>
                                                <div className="mini-cart-image">
                                                    <img src={e.image_url} alt="mci" />
                                                </div>
                                                <div className="mini-cart-info">
                                                    <div className="mini-cart-small-title">
                                                        <font>{e.name}</font>
                                                    </div>
                                                    <div className="mini-cart-name">
                                                        <font>{e.plain_sku}</font>
                                                    </div>
                                                    <div className="mini-cart-details-bottom">
                                                        <div className="mini-cart-pricing">
                                                            <span className="label">Aantal: </span>
                                                            <span className="value">{e.qty}</span>
                                                        </div>
                                                        <div className="mini-cart-price-wrapper">
                                                            {Number(e.sale_price) !== 0 ? <span className="mini-cart-price">€ {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="checkout-summary-order-totals">
                                        <div className="order-totals-table">
                                            <div className="order-SUBTOTAAL">
                                                <div className="cfin">
                                                    <font>SUBTOTAAL</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>€ {this.state.wholeData.total_retail_price}</font>
                                                </div>
                                            </div>
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option ? <div className="checkout-summary-price-row">
                                                {(() => {
                                                    if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "home_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_1}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_1_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "collection_point") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_2}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_2_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "local_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_3}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_3_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })()}
                                            </div> : <div className="checkout-summary-price-row">
                                                <div className="cfin">
                                                    <font>{this.state.wholeData.delivery_desc_1}</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>{this.state.wholeData.delivery_desc_1_price}</font>
                                                </div>
                                            </div>}
                                            {this.state.wholeData.coupon_settings && this.state.wholeData.coupon_settings.coupon_discount && <div className="checkout-summary-price-row">
                                                <span className="cfin">
                                                    Kortingscode
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.coupon_settings.coupon_code && <font style={{ color: "#865", textDecoration: "underline", textTransform: "uppercase", fontSize: 12, cursor: "pointer", marginRight: 30 }} className="bodyText" onClick={this.removeCoupon.bind(this)}>
                                                        verwijderen
                                                        </font>}
                                                    € {this.state.wholeData.coupon_settings.coupon_discount}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.gift_settings && this.state.wholeData.gift_settings.gift_price && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Cadeauverpakking
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.gift_settings.gift_price}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Datum
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.dateOfOrder + "-" + this.state.monthOfOrder + "-" + this.state.yearOfOrder}
                                                </span>
                                            </div>}
                                            {/* {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Tijdstip
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.timeOfOrder}
                                                </span>
                                            </div>} */}
                                            <div className="order-total">
                                                <div className="cart-footer-total">
                                                    <div className="cfin">
                                                        <font>Totaal incl. btw</font>
                                                    </div>
                                                    <div className="cfiv">
                                                        <font>{this.state.wholeData.default_total}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shipping-info">
                                            <div className="content-asset" style={{ paddingLeft: 15 }}>
                                                <ul>
                                                    {this.state.cartNav && this.state.cartNav.detail_page_delivery_text && this.state.online_payment && <li style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.cartNav.detail_page_delivery_text}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_1 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_1}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_2 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_2}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_3 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_3}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.pick_up_store && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.pick_up_store}
                                                        </span>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-footer-cart">
                    <div className="sticky-order-container">
                        <div className="details-container">
                            <span style={{ marginRight: 7 }}>{this.state.wholeData.total_products}</span>
                            <span>PRODUCT</span>
                        </div>
                        <div className="price-container">
                            <div style={{ display: "flex", alignItems: "center", marginleft: 10 }} >
                                <div className="order-total-label">
                                    <span>Total</span>
                                </div>
                                <div className="order-total-amount">
                                    <span>
                                        {this.state.wholeData.default_total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-button-container" onClick={() => window.location.href = "/payment"}>
                        <span>
                            {this.state.to_payment}
                        </span>
                    </div>
                </div>
                {this.state.popupright && <div className="bg-overlaymini" style={{ zIndex: 999, position: "fixed" }} onClick={() => this.setState({ popupright: false })}>

                </div>}
                <div className={`mnicart-flyout-inner deliver-options ${this.state.popupright ? 'open' : null}`}>
                    <div className="checkout-flyout-inner-content" style={{ backgroundColor: "#fcfaf7" }}>
                        <div className="flyout-section">
                            <div className="minicart-flyout-wrapper">
                                <CloseIcon style={{ position: "absolute", cursor: "pointer", top: 38, fontSize: 24, left: 27 }} onClick={() => this.setState({ popupright: false })} />
                                <div className="flyout-edit-delivery-options" style={{ marginTop: 60, paddingTop: 0 }}>
                                    <h3 className="flyout-title">I want to receive my order on...</h3>
                                    <div className="addresslist-fieldset-inner">
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Voornaam</label>
                                                <input type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                                {this.state.fnPopup && <span className="alert-fields">Fist name is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Achternaam</label>
                                                <input type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                                {this.state.lnPopup && <span className="alert-fields">Last name is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Straatnaam</label>
                                                <input className="inputfield-text" value={this.state.street} onChange={(e) => this.setState({ street: e.target.value })} />
                                                {this.state.streetPopup && <span className="alert-fields">Street is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Huisnummer</label>
                                                <input className="inputfield-text" value={this.state.houseNumber} onChange={(e) => this.setState({ houseNumber: e.target.value })} />
                                                {this.state.hnPopup && <span className="alert-fields">House number is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label>Postcode</label>
                                                <input type="text" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                {this.state.zipPopup && <span className="alert-fields">Zip code is required.</span>}
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Plaats</label>
                                                <input type="text" placeholder="Enter city" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                            </div>
                                            {this.state.cityPopup && <span className="alert-fields">City is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Land</label>
                                                <select onChange={(e) => {
                                                    if (e.target.value !== "Select Country...") {
                                                        this.setState({ countryid: e.target.value, stateid: '' })
                                                        let statesC = statesCountry.filter((f) => {
                                                            return f.country_id === Number(e.target.value)
                                                        })
                                                        let allCities = []
                                                        statesC.filter((g) => {
                                                            cities.map((h) => {
                                                                if (g.id === h.state_id) {
                                                                    allCities.push(h)
                                                                }
                                                            })
                                                        })
                                                        this.setState({ allCities })
                                                    } else {
                                                        this.setState({ countryid: '' })
                                                    }
                                                }}>
                                                    {countries.length > 0 && countries.map((e) => {
                                                        return <option selected={e.id === Number(this.state.countryid)} key={Math.random()} value={e.id}>{e.name}</option>
                                                    })}
                                                </select>
                                            </div>
                                            {this.state.countryPopup && <span className="alert-fields">Country is required.</span>}
                                        </div>
                                        <div className="form-row">
                                            <div className="field-wrapper">
                                                <label><span>Telefoonnummer</span></label>
                                                <input type="number" placeholder="Voorbeeld: +31621345678" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                            </div>
                                            {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>}
                                        </div>
                                        <div className="buttons-total" style={{ width: "100%", textAlign: "center" }}>
                                            <button className="btn-cart" style={{ margin: "0px auto" }} onClick={this.addAddress.bind(this)}>SAVE CHANGES</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Checkout
