import React, { Component } from 'react'

export class DoubleCardData extends Component {

    constructor() {
        super()
        this.state = {
            data: '',
        }
    }

    componentDidMount() {
        console.log(this.props.data)
        this.setState({ data: this.props.data })
    }

    createMarkup() {
        if (this.state.data.left_content) {
            return { __html: this.state.data.left_content.left_desc };
        }
    }

    createMarkupSec() {
        if (this.state.data.right_content) {
            return { __html: this.state.data.right_content.right_desc };
        }
    }

    render() {
        return (
            <div className="card-sec-rit" style={{ padding: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                <div className="inner-wrapper" style={{ maxWidth: 1350 }}>
                    {/* <div className="start-sec-second " style={{ position: "relative", zIndex: 9999, paddingTop: 0 }}>
                        <p className="heading-hss">
                            {this.state.data.heading_1}
                        </p>
                        <p className="cont-hss">
                            {this.state.data.heading_2}
                        </p>
                    </div> */}
                    {this.state.data && <div className="all-cards-rit" style={{ backgroundColor: "#f6f2f2" }}>
                        <div className="card-rit" data-aos="fade-in" style={{ marginBottom: 0 }}>
                            <div className="content-crit">
                                {/* <span style={{ textTransform: "uppercase" }} className="heading-hss">{this.state.data.left_content.left_heading1}</span> */}
                                <h3>{this.state.data.left_content.left_heading2}</h3>
                                <p className="p-cr">
                                    <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                                </p>
                                {this.state.data.left_content.is_button_route && <button onClick={() => {
                                    this.state.data.left_content.button_url.includes(".com") ? window.location.href = `https://${this.state.data.left_content.button_url}` : window.location.href = this.state.data.left_content.button_url
                                }}>{this.state.data.left_content.left_button}</button>}
                            </div>
                            <div className="img-crit">
                                <img alt="showingyet" src={this.state.data.left_content.left_image} width="100%" height="100%" onClick={() => {
                                    this.state.data.left_content.button_url.includes(".com") ? window.location.href = `https://${this.state.data.left_content.button_url}` : window.location.href = this.state.data.left_content.button_url
                                }} />
                            </div>
                        </div>
                        <div className="card-rit" data-aos="fade-in" style={{ marginBottom: 0 }}>
                            <div className="content-crit">
                                {/* <span style={{ textTransform: "uppercase" }} className="heading-hss">{this.state.data.right_content.right_heading1}</span> */}
                                <h3>{this.state.data.right_content.right_heading2}</h3>
                                <p className="p-cr">
                                    <div dangerouslySetInnerHTML={this.createMarkupSec()}></div>
                                </p>
                                {this.state.data.right_content.is_button_route && <button onClick={() => {
                                    this.state.data.right_content.button_url.includes(".com") ? window.location.href = `https://${this.state.data.right_content.button_url}` : window.location.href = this.state.data.right_content.button_url
                                }}>{this.state.data.right_content.right_button}</button>}
                            </div>
                            <div className="img-crit">
                                <img alt="showingyet" src={this.state.data.right_content.right_image} width="100%" height="100%" onClick={() => {
                                    this.state.data.right_content.button_url.includes(".com") ? window.location.href = `https://${this.state.data.right_content.button_url}` : window.location.href = this.state.data.right_content.button_url
                                }} />
                            </div>
                        </div>
                    </div>}
                </div>
            </div>
        )
    }
}

export default DoubleCardData
