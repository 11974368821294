import React, { Component } from 'react'
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import './index.css'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import CK from '../../Components/Ck'
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CompleteNavbar from '../../Components/CompleteNavbar';

export class PrivacyPolicy extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: '',
            isAdmin: false,
            desc: "",
            descInput: false,
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        this.getData()
    }

    getData() {
        var myHeaders = new Headers();

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/privacy-policy`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ wholeData: result.data, desc: result.data.content })
            })
            .catch(error => console.log('error', error));
    }

    createMarkup() {
        if (this.state.desc) {
            return { __html: this.state.desc };
        }
    }

    imgUploading(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("section_type", "privacy_policy_page_banner_image");
            formdata.append("image_type", "banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.state.wholeData.banner_image = result.data.image_url
                        this.state.wholeData.check_banner_image = true
                        this.setState({ wholeData: this.state.wholeData, loader: false })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }
    }

    removeImage() {
        this.setState({ loader: true })

        let splitted = this.state.wholeData.banner_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("section_type", "privacy_policy_page_banner_image");
        formdata.append("image_type", "banner");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.state.wholeData.banner_image = result.data.default_url
                this.state.wholeData.check_banner_image = false
                this.setState({ wholeData: this.state.wholeData, loader: false })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }


    changeAlert(e) {
        this.setState({ desc: e })
    }

    close() {
        this.setState({ descInput: false })
    }

    updateSec() {
        const { desc } = this.state

        let arr = [
            { key: "privacy_policy_content", value: desc },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }


    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>
                <CompleteNavbar />
                <div className="tc-main privacypolicy">
                    <div className="tc-content-banner" style={{ backgroundImage: `url(${this.state.wholeData.banner_image})`, position: "relative" }}>
                        {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20 }}>
                            <div className="uploading-beu">
                                <input type="file" onChange={(e) => this.imgUploading(e)} accept="image/*" />
                                <PublishIcon />
                            </div>
                            {this.state.wholeData.check_banner_image && <div className="uploading-beu">
                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                            </div>}
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                        <div className="text-holder" style={{ padding: 0 }}>
                            <div className="inner-wrapper">
                                <div className="inner-intended">
                                    <h2>{this.state.wholeData.banner_title}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.isAdmin ? <div className="tc-content-wrappper" onClick={() => this.setState({ descInput: true })} >
                        {!this.state.descInput ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                        {/* <div dangerouslySetInnerHTML={this.createMarkup()}></div> */}
                    </div> : <div className="tc-content-wrappper">
                            <div dangerouslySetInnerHTML={this.createMarkup()}></div>
                        </div>}
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default PrivacyPolicy
