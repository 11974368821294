import React, { Component } from 'react'
import HttpsIcon from '@material-ui/icons/Https';
import CloseIcon from '@material-ui/icons/Close';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import './index.css'
import DayPicker from 'react-day-picker';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import "react-datepicker/dist/react-datepicker.css";
import 'react-day-picker/lib/style.css';
import CompleteNavbar from '../../Components/CompleteNavbar';
import CheckIcon from '@material-ui/icons/Check';


const MONTHS = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
];

const WEEKDAYS_LONG = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
];

const WEEKDAYS_SHORT = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

export class Cart extends Component {

    constructor() {
        super()
        this.state = {
            individualpopup: false,
            giftwrapping: false,
            addCode: false,
            cartData: '',
            getDataHeading: false,
            coupon_code: '',
            browserType: '',
            codePopup: false,
            deliveryOption: "home_delivery",
            selectedOption: "",
            arrCheck: [],
            arrPopupItems: [],
            allItemsChecked: false,
            gift_note: '',
            shouldUpdate: false,
            // Edit Working
            isAdmin: false,
            heading_1: "",
            heading_1Input: false,
            timingsExact: [],
            selectedTime: "",
            selectedDay: new Date(),
            selectedTimeAlert: false,
            isMobile: false,
            headerData: "",
            cartNav: "",
            arrNotTime: []
        }
        this.getData = this.getData.bind(this)
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
        this.getData()

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            this.setState({ headerData: JSON.parse(headerData) })
        }

        let cartNav = localStorage.getItem("cartNav")
        if (cartNav) {
            this.setState({ cartNav: JSON.parse(cartNav) })
        }

        if (window.innerWidth > 768) {
            this.setState({ isMobile: false })
        } else {
            this.setState({ isMobile: true })
        }
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`${gv}/api/v1/auth/customer/get/shopping/cart?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.data.cart_items.length > 0) {
                        if (result.data.data.coupon_settings && result.data.data.coupon_settings.coupon_code) {
                            this.setState({ addCode: true, coupon_code: result.data.data.coupon_settings.coupon_code })
                        }
                        if (result.data.data.gift_settings && result.data.data.gift_settings.gift_type) {
                            this.setState({ giftwrapping: true, selectedOption: result.data.data.gift_settings.gift_type })
                        } else {
                            this.setState({ giftwrapping: false })
                        }
                        let arrCheck = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                checked: false,
                            }
                            arrCheck.push(obj)
                        })
                        let arrPopupItems = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                popupnotification: false,
                                popupaction: "",
                            }
                            arrPopupItems.push(obj)
                        })

                        this.setState({
                            cartData: result.data.data,
                            arrCheck,
                            arrPopupItems,
                            gift_note: result.data.data.gift_settings.gift_note_text,
                            getDataHeading: false,
                            heading_1: result.data.data.cart_heading_1,
                            deliveryOption: result.data.data.delivery_settings.delivery_option
                        })
                        if (result.data.data.gift_settings.gift_type === "wrap_individually") {
                            let giftProducts = JSON.parse(result.data.data.gift_settings.gift_products)
                            giftProducts.map((e) => {
                                result.data.data.cart_items.map((g, i) => {
                                    if (g.product_id === e) {
                                        this.state.arrCheck[i].checked = true
                                        this.setState({ arrCheck: this.state.arrCheck })
                                    }
                                })
                            })
                        }

                        if (result.data.data.order_timings) {
                            let parsed = JSON.parse(result.data.data.order_timings)
                            this.setState({ selectedDay: new Date(parsed.day), selectedTime: parsed.time })
                            let currentDateCheck = new Date(parsed.day)
                            let days = [];
                            let currentDay = new Date(parsed.day)
                            let date = new Date(currentDateCheck.getFullYear(), currentDateCheck.getMonth(), 1);
                            let today = new Date()
                            let daysToCloseDate = []
                            let openedDays = []
                            while (date.getMonth() === currentDay.getMonth()) {
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            let restricted_time = result.data.data.restricted_time
                            if (restricted_time.length > 0) {
                                restricted_time.map((h) => {
                                    let date = new Date(h)
                                    daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                    daysToCloseDate.push(date.getDate())
                                })
                            }
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                            daysToCloseDate.push(g.getDate())
                                        }
                                    }
                                }
                                if (!daysToCloseDate.includes(g.getDate())) {
                                    if (g.getDate() >= today.getDate()) {
                                        openedDays.push(g)
                                    }
                                }
                            })
                            daysToClose.push({ before: today })
                            this.setState({ daysToClose })
                        } else {
                            let currentDay = new Date()
                            let today = new Date()
                            let shop_timings = result.data.data.shop_timings
                            let days = []
                            let daysToCloseDate = []
                            let openedDays = []
                            if (shop_timings) {
                                let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                                while (date.getMonth() === currentDay.getMonth()) {
                                    days.push(new Date(date));
                                    date.setDate(date.getDate() + 1);
                                }
                                var options = { weekday: 'long' };
                                let daysToClose = []
                                let restricted_time = result.data.data.restricted_time
                                if (restricted_time.length > 0) {
                                    restricted_time.map((h) => {
                                        let date = new Date(h)
                                        daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                        daysToCloseDate.push(date.getDate())
                                    })
                                }
                                days.map((g) => {
                                    let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        if (filtered.length > 0) {
                                            if (filtered[0][1] === "Gesloten") {
                                                daysToClose.push(g)
                                                daysToCloseDate.push(g.getDate())
                                            }
                                        }
                                    }
                                    if (!daysToCloseDate.includes(g.getDate())) {
                                        if (g.getDate() >= today.getDate()) {
                                            openedDays.push(g)
                                        }
                                    }
                                })
                                daysToClose.push({ before: today })
                                this.setState({ daysToClose, selectedDay: openedDays[0] })
                            }
                        }
                    } else {
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            let cookie_id = localStorage.getItem("guestUser")
            fetch(`${gv}/api/v1/customer/get/shopping/cart?browser_type=${browserType}&cart_items_cookie=${cookie_id}`)
                .then(response => response.json())
                .then(result => {
                    if (result.data.data.cart_items.length > 0) {
                        if (result.data.data.coupon_settings && result.data.data.coupon_settings.coupon_code) {
                            this.setState({ addCode: true, coupon_code: result.data.data.coupon_settings.coupon_code })
                        }
                        if (result.data.data.gift_settings && result.data.data.gift_settings.gift_type) {
                            this.setState({ giftwrapping: true, selectedOption: result.data.data.gift_settings.gift_type })
                        } else {
                            this.setState({ giftwrapping: false })
                        }
                        let arrCheck = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                checked: false,
                            }
                            arrCheck.push(obj)
                        })
                        let arrPopupItems = []
                        result.data.data.cart_items.map(() => {
                            let obj = {
                                popupnotification: false,
                                popupaction: "",
                            }
                            arrPopupItems.push(obj)
                        })

                        this.setState({
                            cartData: result.data.data,
                            arrCheck,
                            arrPopupItems,
                            gift_note: result.data.data.gift_settings.gift_note_text,
                            getDataHeading: false,
                            heading_1: result.data.data.cart_heading_1,
                            deliveryOption: result.data.data.delivery_settings.delivery_option
                        })
                        if (result.data.data.gift_settings.gift_type === "wrap_individually") {
                            let giftProducts = JSON.parse(result.data.data.gift_settings.gift_products)
                            giftProducts.map((e) => {
                                result.data.data.cart_items.map((g, i) => {
                                    if (g.product_id === e) {
                                        this.state.arrCheck[i].checked = true
                                        this.setState({ arrCheck: this.state.arrCheck })
                                    }
                                })
                            })
                        }

                        if (result.data.data.order_timings) {
                            let parsed = JSON.parse(result.data.data.order_timings)
                            this.setState({ selectedDay: new Date(parsed.day), selectedTime: parsed.time })
                            let currentDateCheck = new Date(parsed.day)
                            let days = [];
                            let currentDay = new Date(parsed.day)
                            let date = new Date(currentDateCheck.getFullYear(), currentDateCheck.getMonth(), 1);
                            let today = new Date()
                            let daysToCloseDate = []
                            let openedDays = []
                            while (date.getMonth() === currentDay.getMonth()) {
                                days.push(new Date(date));
                                date.setDate(date.getDate() + 1);
                            }
                            var options = { weekday: 'long' };
                            let daysToClose = []
                            let restricted_time = result.data.data.restricted_time
                            if (restricted_time.length > 0) {
                                restricted_time.map((h) => {
                                    let date = new Date(h)
                                    daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                    daysToCloseDate.push(date.getDate())
                                })
                            }
                            days.map((g) => {
                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                let timingsToRender = result.data.data.shop_timings
                                if (timingsToRender) {
                                    let objected = Object.entries(timingsToRender)
                                    let filtered = objected.filter((g) => {
                                        return g[0] === dutchName
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(g)
                                            daysToCloseDate.push(g.getDate())
                                        }
                                    }
                                }
                                if (!daysToCloseDate.includes(g.getDate())) {
                                    if (g.getDate() >= today.getDate()) {
                                        openedDays.push(g)
                                    }
                                }
                            })
                            daysToClose.push({ before: today })
                            this.setState({ daysToClose })
                        } else {
                            let currentDay = new Date()
                            let today = new Date()
                            let shop_timings = result.data.data.shop_timings
                            let days = []
                            let daysToCloseDate = []
                            let openedDays = []
                            if (shop_timings) {
                                let date = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
                                while (date.getMonth() === currentDay.getMonth()) {
                                    days.push(new Date(date));
                                    date.setDate(date.getDate() + 1);
                                }
                                var options = { weekday: 'long' };
                                let daysToClose = []
                                let restricted_time = result.data.data.restricted_time
                                if (restricted_time.length > 0) {
                                    restricted_time.map((h) => {
                                        let date = new Date(h)
                                        daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                        daysToCloseDate.push(date.getDate())
                                    })
                                }
                                days.map((g) => {
                                    let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                    let timingsToRender = result.data.data.shop_timings
                                    if (timingsToRender) {
                                        let objected = Object.entries(timingsToRender)
                                        let filtered = objected.filter((g) => {
                                            return g[0] === dutchName
                                        })
                                        if (filtered.length > 0) {
                                            if (filtered[0][1] === "Gesloten") {
                                                daysToClose.push(g)
                                                daysToCloseDate.push(g.getDate())
                                            }
                                        }
                                    }
                                    if (!daysToCloseDate.includes(g.getDate())) {
                                        if (g.getDate() >= today.getDate()) {
                                            openedDays.push(g)
                                        }
                                    }
                                })
                                daysToClose.push({ before: today })
                                this.setState({ daysToClose, selectedDay: openedDays[0] })
                            }
                        }
                    } else {
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    removeItem(e) {
        if (e.product_type === "gift") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("price", e.retail_price);
                formdata.append("product_type", "gift");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }

                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }

                this.setState({ getDataHeading: true })
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        this.setState({ getDataHeading: false })
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    applyCode() {
        if (this.state.coupon_code !== "") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("coupon_code", this.state.coupon_code);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/redeem/coupon?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Coupon is redeemed") {
                            let price = result.data.data.default_total
                            this.state.cartData.default_total = price
                            this.setState({ cartData: this.state.cartData, codePopup: false })
                            this.getData()
                        } else {
                            this.setState({ codePopup: true, coupon_wrong_message: result.data[0] })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("coupon_code", this.state.coupon_code);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/redeem/coupon?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Coupon is redeemed") {
                            let price = result.data.data.default_total
                            this.state.cartData.default_total = price
                            this.setState({ cartData: this.state.cartData, codePopup: false })
                            this.getData()
                        } else {
                            this.setState({ codePopup: true, coupon_wrong_message: result.data[0] })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            this.setState({ codePopup: true, coupon_wrong_message: "Coupon should not be empty" })
        }
    }

    setDeliveryOption(e, day) {
        let usertoken = localStorage.getItem("usertoken")
        if (e === "collection_point") {
            let obj = {
                "day": `${day}`,
                // "time": `${time}`
            }
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("order_timings", JSON.stringify(obj));

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("cart_items_cookie", cookie_id);
                formdata.append("order_timings", JSON.stringify(obj));

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("delivery_option", e);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("delivery_option", e);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    itemUpdate(e, f, g, indexOfItem) {
        if (e.product_type === "gift") {
            if (Number(g) > 1 && f === "subtraction") {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            } else if (f === "addition") {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", 1);
                    formdata.append("qty", 1);
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "gift");
                    formdata.append("price", e.retail_price);
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.setState({ getDataHeading: true })
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "gift");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }

                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ getDataHeading: false })
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", 1);
                    formdata.append("product_type", "gift");
                    formdata.append("cart_items_cookie", cookie_id);
                    formdata.append("price", e.retail_price);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }

                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            this.setState({ getDataHeading: false })
                            let resp = result.data.data
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else {
            if (Number(g) > 1 && f === "subtraction") {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "subtraction");
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            } else if (f === "addition") {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("qty", "1");
                    formdata.append("operation", "addition");
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/update/cart/item/qty?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (g === result.data.data.cart_items[indexOfItem].qty) {
                                this.state.arrPopupItems[indexOfItem].popupnotification = true
                                this.state.arrPopupItems[indexOfItem].popupaction = "addition"
                                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: true })
                            } else {
                                this.getData()
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                this.setState({ getDataHeading: true })

                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("product_type", "product");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }
                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            this.setState({ getDataHeading: false })
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    this.setState({ getDataHeading: true })

                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("product_id", e.product_id);
                    formdata.append("product_type", "product");
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    let browserType = ""
                    var ua = navigator.userAgent.toLowerCase();
                    if (ua.indexOf('safari') !== -1) {
                        if (ua.indexOf('chrome') > -1) {
                            browserType = "chrome"
                        } else {
                            browserType = "safari"
                        }
                    }
                    this.setState({ getDataHeading: true })
                    fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            let resp = result.data.data
                            this.setState({ getDataHeading: false })
                            localStorage.setItem("cartData", JSON.stringify(resp))
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    checkGift(e) {
        if (e === "wrap_every_thing") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("gift_option", "wrap_every_thing");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("gift_option", "wrap_every_thing");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(() => {
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let arr = []
            this.state.arrCheck.map((e, i) => {
                if (e.checked === true) {
                    let name = this.state.cartData.cart_items[i]
                    arr.push(name.product_id)
                }
            })
            if (arr.length > 0) {
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    var formdata = new FormData();
                    formdata.append("gift_option", "wrap_individually");
                    formdata.append("gift_products", JSON.stringify(arr));

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.setState({ individualpopup: false })
                            document.getElementById("mainbody").style.position = "static"
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let cookie_id = localStorage.getItem("guestUser")

                    var formdata = new FormData();
                    formdata.append("gift_option", "wrap_individually");
                    formdata.append("gift_products", JSON.stringify(arr));
                    formdata.append("cart_items_cookie", cookie_id);

                    var requestOptions = {
                        method: 'POST',
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            this.setState({ individualpopup: false })
                            document.getElementById("mainbody").style.position = "static"
                            this.getData()
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    sendGiftEmpty() {
        let usertoken = localStorage.getItem("usertoken")
        this.setState({ selectedOption: "" })
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var formdata = new FormData();
            formdata.append("gift_option", "empty");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    this.getData()
                    this.setState({ giftwrapping: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("gift_option", "empty");
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/set/order/gift?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    this.getData()
                    this.setState({ giftwrapping: false })
                })
                .catch(error => console.log('error', error));
        }
    }

    checkoutContinue() {
        let usertoken = localStorage.getItem("usertoken")
        if (this.state.gift_note === undefined) {
            this.state.gift_note = ""
        }
        if (this.state.deliveryOption === "collection_point") {
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdata = new FormData();
                    formdata.append("delivery_option", "home_delivery");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                                .then(response => response.json())
                                .then(() => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                if (this.state.gift_note) {
                    formdata.append("gift_note_text", this.state.gift_note);
                }
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdataSec = new FormData();
                    formdataSec.append("delivery_option", "home_delivery");
                    formdataSec.append("cart_items_cookie", cookie_id);

                    var requestOptionsSec = {
                        method: 'POST',
                        body: formdataSec,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptionsSec)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                                .then(response => response.json())
                                .then((result) => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            }
        } else {
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdata = new FormData();
                    formdata.append("delivery_option", "home_delivery");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/auth/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                                .then(response => response.json())
                                .then(() => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${this.state.browserType}&gift_note_text=${this.state.gift_note}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                if (this.state.gift_note) {
                    formdata.append("gift_note_text", this.state.gift_note);
                }
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                if (!this.state.cartData.delivery_settings.delivery_option) {
                    var formdataSec = new FormData();
                    formdataSec.append("delivery_option", "home_delivery");
                    formdataSec.append("cart_items_cookie", cookie_id);

                    var requestOptionsSec = {
                        method: 'POST',
                        body: formdataSec,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/customer/get/delivery/option/price?browser_type=${this.state.browserType}`, requestOptionsSec)
                        .then(response => response.json())
                        .then(() => {
                            fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                                .then(response => response.json())
                                .then((result) => {
                                    window.location.href = "/delivery"
                                })
                                .catch(error => console.log('error', error));
                        })
                        .catch(error => console.log('error', error));
                } else {
                    fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${this.state.browserType}`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            window.location.href = "/delivery"
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    componentDidUpdate(props, state) {
        if (this.state.shouldUpdate) {
            setTimeout(() => {
                state.arrPopupItems.map((e) => {
                    if (e.popupnotification === true) {
                        e.popupnotification = false
                        e.popupaction = ""
                    }
                })
                this.setState({ arrPopupItems: this.state.arrPopupItems, shouldUpdate: false })
            }, 5000);
        }
    }

    removeCoupon() {
        let usertoken = localStorage.getItem("usertoken")

        if (!usertoken) {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));

        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));
        }
    }

    getMonthNameinDutch(e) {
        e = e.toLowerCase()
        if (e === "monday") {
            return "MAANDAG"
        } else if (e === "tuesday") {
            return "DINSDAG"
        } else if (e === "wednesday") {
            return "WOENSDAG"
        } else if (e === "thursday") {
            return "DONDERDAG"
        } else if (e === "friday") {
            return "VRIJDAG"
        } else if (e === "saturday") {
            return "ZATERDAG"
        } else if (e === "sunday") {
            return "ZONDAG"
        }
    }

    handleDayClick(day) {
        let currentDate = new Date(day)
        let allDates = []
        let result = this.state.cartData
        this.state.daysToClose.map((g, i) => {
            if (i !== this.state.daysToClose.length - 1) {
                if (currentDate.getMonth() === g.getMonth()) {
                    allDates.push(g.getDate())
                }
            } else {
                if (i === this.state.daysToClose.length - 1) {
                    let objected = Object.entries(this.state.daysToClose[i])
                    if (objected.length > 0) {
                        if (objected[0][0] === "before") {
                            for (var i = 0; i < objected[0][1].getDate(); i++) {
                                allDates.push(i)
                            }
                        }
                    }
                }
            }
        })
        console.log(allDates)
        if (allDates.includes(currentDate.getDate())) {
            console.log("included")
        } else {
            this.setState({ selectedDay: day, dateDropDown: false });
            this.setDeliveryOption("collection_point", day)
        }
    }

    render() {
        return (
            <div className="checkoutfooterhide">
                <CompleteNavbar getData={this.state.getDataHeading} removeItem={this.getData.bind(this)} />
                <Helmet>
                    <title>{this.state.cartData.page_title}</title>
                </Helmet>
                <div className="cart-main">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            {/* layout left */}
                            <div className="page-layout-left">
                                <div className="cart-header">
                                    <h1>{this.state.heading_1}</h1>
                                    <span>{this.state.cartData.total_products} products</span>
                                </div>
                                <div className="cart-body">
                                    <table className="table-cb">
                                        <thead>
                                            <tr className="cart-product cp-head">
                                                <th className="cp-image cp-sec-head">PRODUCT</th>
                                                <th className="cp-desc cp-sec-head">&nbsp;</th>
                                                <th className="cp-qty cp-sec-head">Aantal</th>
                                                <th className="cp-price cp-sec-head">Prijs</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.cartData && this.state.cartData.cart_items.map((e, i) => {
                                                return <tr className="cart-product cp-body" key={Math.random()}>
                                                    <td className="cp-image">
                                                        <img src={e.image_url} alt="cart" />
                                                    </td>
                                                    <td className="cp-desc">
                                                        <div className="cp-dtitle">
                                                            <div className="product-list-item">
                                                                <div className="name">
                                                                    <p>{e.name}</p>
                                                                </div>
                                                                <div className="description">
                                                                    <p>{e.plain_sku}</p>
                                                                    <br />
                                                                    <p style={{ backgroundColor: "white", padding: 8, fontSize: 15 }}>{e.title}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {this.state.isMobile && <div className="mobilecartwork">
                                                            <div className="cp-qty">
                                                                <div className="quantity-block">
                                                                    <button className="qty-minus" onClick={this.itemUpdate.bind(this, e, "subtraction", e.qty, i)}>-</button>
                                                                    <input size="3" maxLength="3" className="qty-input" readOnly defaultValue={e.qty} />
                                                                    <button style={{ marginBottom: 30 }} className="qty-plus" onClick={this.itemUpdate.bind(this, e, "addition", e.qty, i)}>+</button>
                                                                    {this.state.arrPopupItems[i].popupaction === "addition" ? <span className="dropdown-err">
                                                                        Wij hebben helaas geen extra voorraad beschikbaar van dit artikel
                                                                </span> : this.state.arrPopupItems[i].popupaction === "addition" && <span>
                                                                    </span>}
                                                                </div>
                                                            </div>
                                                            <div className="cp-uc">
                                                                <div className="cp-ci">
                                                                    <div className="item-user-actions">
                                                                        <button className="link link_large" onClick={this.removeItem.bind(this, e)}>
                                                                            <span>
                                                                                Verwijder
                                                                        </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        {!this.state.isMobile && <div className="cp-qty">
                                                            <div className="quantity-block">
                                                                <button className="qty-minus" onClick={this.itemUpdate.bind(this, e, "subtraction", e.qty, i)}>-</button>
                                                                <input size="3" maxLength="3" className="qty-input" readOnly defaultValue={e.qty} />
                                                                <button style={{ marginBottom: 30 }} className="qty-plus" onClick={this.itemUpdate.bind(this, e, "addition", e.qty, i)}>+</button>
                                                                {this.state.arrPopupItems[i].popupaction === "addition" ? <span className="dropdown-err">
                                                                    Wij hebben helaas geen extra voorraad beschikbaar van dit artikel
                                                                </span> : this.state.arrPopupItems[i].popupaction === "addition" && <span>
                                                                </span>}
                                                            </div>
                                                        </div>}
                                                        {!this.state.isMobile && <div className="cp-uc">
                                                            <div className="cp-ci">
                                                                <div className="item-user-actions">
                                                                    <button className="link link_large" onClick={this.removeItem.bind(this, e)}>
                                                                        <span>
                                                                            Verwijder
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                        <div className="dangerousgoods-error">
                                                            <p className="error"></p>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                    <td className="cp-price" style={{ paddingTop: 15 }}>
                                                        {Number(e.sale_price) !== 0 ? <span className="price-value">€ {e.show_sale_price}</span> : <span className="price-value">€ {e.show_retail_price}</span>}
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                    {!this.state.cartData.hide_delivery_text && <div className="gwp-cart-banner">
                                        <div className="gwp-banner-progress-holder">
                                            <span style={{ width: "59.9999%" }}></span>
                                        </div>
                                        <div>
                                            <div className="buttons-total">
                                                <button className="btn-continue" onClick={() => window.location.href = "/"}>VERDER WINKELEN</button>
                                                <p>{this.state.cartData.bottom_text}</p>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                            {/* Layout Right */}
                            <div className="page-layout-right">
                                <div className="cs-service-block">

                                </div>
                                {/* Bottom Cart */}
                                <div className="ct-bottom bg-color-white">
                                    <div className="cart-footer">
                                        <div className="cart-order-totals">
                                            <div className="order-subtotal">
                                                <div className="ositem-name">
                                                    <span>SUBTOTAAL</span>
                                                </div>
                                                <div className="ositem-val">
                                                    <span>€ {this.state.cartData.total_retail_price}</span>
                                                </div>
                                            </div>
                                            <div className="shipping-options-ct">
                                                <div>
                                                {this.state.cartData.only_collection_point && <div className="cart-delivery-options" onClick={() => {
                                                        this.setState({ deliveryOption: "home_delivery" })
                                                        this.setDeliveryOption("home_delivery")
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "home_delivery" ? true : !this.state.cartData.delivery_settings && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_1}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_1_price}
                                                            </span>
                                                        </label>
                                                    </div>}
                                                    {this.state.cartData.only_collection_point && this.state.headerData.local_delivery && <div className="cart-delivery-options" onClick={() => {
                                                        this.setState({ deliveryOption: "local_delivery" })
                                                        this.setDeliveryOption("local_delivery")
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "local_delivery" && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_3}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_3_price}
                                                            </span>
                                                        </label>
                                                    </div>}
                                                    <div className="cart-delivery-options" onClick={() => {
                                                        // this.setState({ deliveryOption: "collection_point" })
                                                        this.state.cartData.delivery_settings.delivery_option = "collection_point"
                                                        this.setDeliveryOption("collection_point", this.state.selectedDay)
                                                    }}>
                                                        <label className="cart-delivery-option-label">
                                                            <input type="radio" name="shippingOption" defaultChecked={this.state.cartData.delivery_settings && this.state.cartData.delivery_settings.delivery_option === "collection_point" && true} />
                                                            <span className="option-title">
                                                                <font>{this.state.cartData.delivery_desc_2}</font>
                                                            </span>
                                                            <span className="option-price">
                                                                {this.state.cartData.delivery_desc_2_price}
                                                            </span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* Gift Card Coupon */}
                                            <div className="coupon-giftcard-row">
                                                {this.state.cartData.check_coupon !== 0 && <div className="cart-coupon-code-trigger">
                                                    <label>Discount Code</label>
                                                    {!this.state.addCode && <button onClick={() => this.setState({ addCode: true })}>ADD CODE</button>}
                                                </div>}
                                                {this.state.addCode && <div className="field-wrapper" style={{ marginTop: 10 }}>
                                                    <input placeholder="Coupon code" type="text" value={this.state.coupon_code} onChange={(e) => this.setState({ coupon_code: e.target.value })} />
                                                    {this.state.codePopup && <span className="alert-fields">{this.state.coupon_wrong_message}</span>}
                                                    <button style={{
                                                        minWidth: "70%", padding: 10, margin: "0px auto", backgroundColor: "transparent", border: "1px solid #b4b4b4", letterSpacing: 2, color: "#1C1C1C", fontSize: 11, textTransform: "uppercase", marginTop: 15, fontFamily: "Roboto"
                                                    }} onClick={this.applyCode.bind(this)} className="bodyText">CODE TOEVOEGEN</button>
                                                </div>}
                                                {this.state.deliveryOption === "collection_point" && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                                    <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1 }}>Datum: </p>
                                                    <div className="timepickercart" onClick={() => this.setState({ dateDropDown: !this.state.dateDropDown })}>
                                                        <p>{this.state.selectedDay.getDate() + "-" + `${this.state.selectedDay.getMonth() + 1}` + "-" + this.state.selectedDay.getFullYear()}</p>
                                                        <ExpandMoreIcon />
                                                    </div>
                                                    {this.state.dateDropDown && <div className="inner-work-timepicker" style={{ top: "95%", zIndex: 99, maxHeight: "none" }}>
                                                        <DayPicker initialMonth={this.state.selectedDay} months={MONTHS} weekdaysLong={WEEKDAYS_LONG}
                                                            weekdaysShort={WEEKDAYS_SHORT} selectedDays={[
                                                                this.state.selectedDay
                                                            ]} fromMonth={new Date()} onDayClick={this.handleDayClick}
                                                            disabledDays={this.state.daysToClose}
                                                            onMonthChange={(e) => {
                                                                let result = this.state.cartData
                                                                if (result) {
                                                                    if (e.getMonth() === new Date().getMonth()) {
                                                                        let currentDay = e
                                                                        let today = new Date()
                                                                        let shop_timings = result.shop_timings
                                                                        let days = []
                                                                        let daysToCloseDate = []
                                                                        let openedDays = []
                                                                        if (shop_timings) {
                                                                            let date = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);
                                                                            while (date.getMonth() === currentDay.getMonth()) {
                                                                                days.push(new Date(date));
                                                                                date.setDate(date.getDate() + 1);
                                                                            }
                                                                            var options = { weekday: 'long' };
                                                                            let daysToClose = []
                                                                            let restricted_time = result.restricted_time
                                                                            if (restricted_time.length > 0) {
                                                                                restricted_time.map((h) => {
                                                                                    let date = new Date(h)
                                                                                    daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                                                                    daysToCloseDate.push(date.getDate())
                                                                                })
                                                                            }
                                                                            days.map((g) => {
                                                                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                                                let timingsToRender = result.shop_timings
                                                                                if (timingsToRender) {
                                                                                    let objected = Object.entries(timingsToRender)
                                                                                    let filtered = objected.filter((g) => {
                                                                                        return g[0] === dutchName
                                                                                    })
                                                                                    if (filtered.length > 0) {
                                                                                        if (filtered[0][1] === "Gesloten") {
                                                                                            daysToClose.push(g)
                                                                                            daysToCloseDate.push(g.getDate())
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (!daysToCloseDate.includes(g.getDate())) {
                                                                                    if (g.getDate() >= today.getDate()) {
                                                                                        openedDays.push(g)
                                                                                    }
                                                                                }
                                                                            })
                                                                            daysToClose.push({ before: today })
                                                                            this.setState({ daysToClose, selectedDay: openedDays[0] })
                                                                        }
                                                                    } else {
                                                                        let currentDay = e
                                                                        let today = new Date()
                                                                        let shop_timings = result.shop_timings
                                                                        let days = []
                                                                        let daysToCloseDate = []
                                                                        let openedDays = []
                                                                        if (shop_timings) {
                                                                            let date = new Date(currentDay.getFullYear(), currentDay.getMonth(), 1);
                                                                            while (date.getMonth() === currentDay.getMonth()) {
                                                                                days.push(new Date(date));
                                                                                date.setDate(date.getDate() + 1);
                                                                            }
                                                                            var options = { weekday: 'long' };
                                                                            let daysToClose = []
                                                                            let restricted_time = result.restricted_time
                                                                            if (restricted_time.length > 0) {
                                                                                restricted_time.map((h) => {
                                                                                    let date = new Date(h)
                                                                                    daysToClose.push(new Date(date.getFullYear(), date.getMonth(), date.getDate()))
                                                                                    daysToCloseDate.push(date.getDate())
                                                                                })
                                                                            }
                                                                            // daysToClose.push({ before: today })
                                                                            days.map((g) => {
                                                                                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                                                let timingsToRender = result.shop_timings
                                                                                if (timingsToRender) {
                                                                                    let objected = Object.entries(timingsToRender)
                                                                                    let filtered = objected.filter((g) => {
                                                                                        return g[0] === dutchName
                                                                                    })
                                                                                    if (filtered.length > 0) {
                                                                                        if (filtered[0][1] === "Gesloten") {
                                                                                            daysToClose.push(g)
                                                                                            daysToCloseDate.push(g.getDate())
                                                                                        }
                                                                                    }
                                                                                }
                                                                                if (!daysToCloseDate.includes(g.getDate())) {
                                                                                    openedDays.push(g)
                                                                                }
                                                                            })
                                                                            this.setState({ daysToClose, selectedDay: openedDays[0] })
                                                                            this.setDeliveryOption("collection_point", openedDays[0])
                                                                        }
                                                                    }
                                                                }
                                                            }
                                                            }
                                                        />
                                                    </div>}
                                                </div>}
                                                {this.state.selectedTimeAlert && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "relative" }}>
                                                    <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1, marginTop: 9, color: "red" }}>Please Selecteer uw tijd to continue</p>
                                                </div>}
                                            </div>
                                            {/* Order Total */}
                                            <div className="order-total">
                                                {this.state.cartData.delivery_settings && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>

                                                    {(function () {
                                                        if (this.state.cartData.delivery_settings.delivery_option === "collection_point") {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_2}
                                                            </span>
                                                        } else if (this.state.cartData.delivery_settings.delivery_option === "local_delivery") {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_3}
                                                            </span>
                                                        } else {
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_1}
                                                            </span>
                                                        }
                                                    }).call(this)}

                                                    {(function () {

                                                        if (this.state.cartData.delivery_settings.delivery_prices) {
                                                            if (this.state.cartData.delivery_settings.delivery_option === "collection_point") {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_2_price}
                                                                </span>
                                                            } else if (this.state.cartData.delivery_settings.delivery_option === "local_delivery") {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_3_price}
                                                                </span>
                                                            } else {
                                                                return <span className="shipping-opt-price">
                                                                    {this.state.cartData.delivery_desc_1_price}
                                                                </span>
                                                            }
                                                            return <span className="shipping-option">
                                                                {this.state.cartData.delivery_desc_2}
                                                            </span>
                                                        } else {
                                                            return <span className="shipping-opt-price">
                                                                {this.state.cartData.delivery_desc_1_price}
                                                            </span>
                                                        }

                                                    }).call(this)}


                                                </div>}
                                                {this.state.cartData.coupon_settings && this.state.cartData.coupon_settings.coupon_discount && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                    <span className="shipping-option" style={{ width: "50%" }}>
                                                        Kortingscode
                                                    </span>
                                                    <span className="shipping-opt-price" style={{ width: "50%", justifyContent: "space-between", alignItems: "center" }}>
                                                        {this.state.cartData.coupon_settings.coupon_code && <font style={{ color: "#865", textDecoration: "underline", textTransform: "uppercase", fontSize: 12, cursor: "pointer" }} className="bodyText" onClick={this.removeCoupon.bind(this)}>
                                                            verwijderen
                                                        </font>}
                                                        € {this.state.cartData.coupon_settings.coupon_discount}
                                                    </span>
                                                </div>}
                                                {this.state.cartData.gift_settings && this.state.cartData.gift_settings.gift_price && <div className="cart-shipping-option-wrapper" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                    <span className="shipping-option">
                                                        Cadeauverpakking
                                                    </span>
                                                    <span className="shipping-opt-price">
                                                        {this.state.cartData.gift_settings.gift_price}
                                                    </span>
                                                </div>}
                                                <div className="cf-total" style={{ marginTop: 30 }}>
                                                    <div className="cft-itemname">
                                                        <font>TOTAAL</font>
                                                        <span className="taxes-included">
                                                            (incl. btw)
                                                        </span>
                                                    </div>
                                                    <div className="cft-itemval">
                                                        <font style={{ fontWeight: 700, fontSize: 18, letterSpacing: 1 }}>{this.state.cartData.default_total}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="shipping-info">
                                        <div className="content-asset" style={{ paddingLeft: 15 }}>
                                            <ul>
                                                {this.state.cartNav && this.state.cartNav.detail_page_delivery_text && this.state.online_payment && <li style={{ display: "flex", alignItems: "center" }}>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartNav.detail_page_delivery_text}
                                                    </span>
                                                </li>}
                                                {this.state.cartData.point_1 && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartData.point_1}
                                                    </span>
                                                </li>}
                                                {this.state.cartData.point_2 && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartData.point_2}
                                                    </span>
                                                </li>}
                                                {this.state.cartData.point_3 && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartData.point_3}
                                                    </span>
                                                </li>}
                                                {this.state.cartData.pick_up_store && <li>
                                                    <span className="icon-check">
                                                        <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                    </span>
                                                    <span>
                                                        {this.state.cartData.pick_up_store}
                                                    </span>
                                                </li>}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* cart actions */}
                                <div className="cart-actions">
                                    <p className="secure-checkout">
                                        <HttpsIcon style={{ marginRight: 5, fontSize: 15 }} />
                                        Safe Shopping
                                    </p>
                                </div>
                            </div>
                            {/* Layout Right End */}
                        </div>
                    </div>
                </div>
                <div className="sticky-footer-cart">
                    <div className="sticky-order-container">
                        <div className="details-container">
                            <span style={{ marginRight: 7 }}>{this.state.cartData.total_products}</span>
                            <span>PRODUCTS</span>
                        </div>
                        <div className="price-container">
                            <div style={{ display: "flex", alignItems: "center", marginleft: 10 }} >
                                <div className="order-total-label">
                                    <span>Total</span>
                                </div>
                                <div className="order-total-amount">
                                    <span>
                                        {this.state.cartData.default_total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-button-container" onClick={this.checkoutContinue.bind(this)}>
                        <span>{this.state.cartData.cart_page_next_button_text}</span>
                    </div>
                </div>
                {
                    this.state.individualpopup && <div className="bg-overlaycart" onClick={() => {
                        let checkArr = this.state.arrCheck.filter((e) => {
                            return e.checked === true
                        })
                        if (this.state.selectedOption === "wrap_individually" && checkArr.length < 1) {
                            this.setState({ selectedOption: "wrap_every_thing" })
                        } else {
                            this.setState({ selectedOption: "wrap_individually" })
                        }
                        this.setState({ individualpopup: false });
                        document.getElementById("mainbody").style.position = "static"
                    }}>
                    </div>
                }
                <div className={`giftwrap-container-popup ${this.state.individualpopup ? "open" : null}`}>
                    <div className="gcp-inner">
                        <div className="giftwrap-title">
                            <CloseIcon className="close-cion-gt" onClick={() => {
                                let checkArr = this.state.arrCheck.filter((e) => {
                                    return e.checked === true
                                })
                                if (this.state.selectedOption === "wrap_individually" && checkArr.length < 1) {
                                    this.setState({ selectedOption: "wrap_every_thing" })
                                } else {
                                    this.setState({ selectedOption: "wrap_individually" })
                                }
                                this.setState({ individualpopup: false });
                                document.getElementById("mainbody").style.position = "static"
                            }} />
                            <h2>{this.state.cartData.cart_page_right_heading}</h2>
                            <p>
                                {this.state.cartData.cart_page_right_desc_1}
                                <br />
                                {this.state.cartData.cart_page_right_desc_2}
                            </p>
                        </div>
                        <div className="form-row">
                            <div className="checkall">
                                <input type="checkbox" checked={this.state.allItemsChecked} onChange={(e) => {
                                    if (e.target.checked) {
                                        this.state.arrCheck.map((e) => {
                                            e.checked = true
                                        })
                                        this.setState({ arrCheck: this.state.arrCheck, allItemsChecked: true })
                                    } else {
                                        this.state.arrCheck.map((e) => {
                                            e.checked = false
                                        })
                                        this.setState({ arrCheck: this.state.arrCheck, allItemsChecked: false })
                                    }
                                }} />
                                <label>
                                    Select all/Deselect all
                                 </label>
                            </div>
                        </div>
                        <ul className="giftwrap-products" style={{ paddingBottom: 60 }}>
                            {this.state.cartData && this.state.cartData.cart_items.map((e, i) => {
                                return <li key={Math.random()}>
                                    <input type="checkbox" checked={this.state.arrCheck[i].checked} onChange={(e) => {
                                        if (e.target.checked) {
                                            this.state.arrCheck[i].checked = true
                                            this.setState({ arrCheck: this.state.arrCheck })
                                        } else {
                                            this.state.arrCheck[i].checked = false
                                            this.setState({ arrCheck: this.state.arrCheck })
                                        }
                                        let checkedFilters = this.state.arrCheck.filter((e) => {
                                            return e.checked === true
                                        })
                                        if (checkedFilters.length === this.state.cartData.cart_items.length) {
                                            this.setState({ allItemsChecked: true })
                                        } else {
                                            this.setState({ allItemsChecked: false })
                                        }
                                    }} />
                                    <label>
                                        <img src={e.image_url} />
                                    </label>
                                </li>
                            })}
                        </ul>
                        <div className="giftwrap-bottom-controls">
                            <div className="buttons-total">
                                <button className="btn-continue" onClick={() => this.setState({ individualpopup: false })}>CLOSE</button>
                                <button className="btn-cart" onClick={() => {
                                    this.checkGift("wrap_individually")
                                }}>SAVE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default Cart
