import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import DayPicker from 'react-day-picker';

import 'react-day-picker/lib/style.css';

const MONTHS = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
];

const WEEKDAYS_LONG = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
];

const WEEKDAYS_SHORT = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

export class AppointmentTemplateData extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: [],
            imageURL: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg",
            timingsExact: [],
            selectedTime: "",
            selectedDay: new Date(),
            selectedTimeAlert: false,
            dateDropDown: false,
            timeDropDown: false,
            headerData: "",
            heading: "SHOPPEN OF AFSPRAAK",
            headingInput: false,
            headingSec: "MAAK EN AFSPRAAK",
            headingSecInput: false,
            descSec: "BOEK NU",
            descSecInput: false,
            name: "",
            nameAlert: false,
            email: "",
            emailAlert: false,
            telephone: "",
            telephoneAlert: false,
            stepOne: true,
            stepTwo: false,
            stepConfirmation: false,
            emailLogin: "",
            passwordLogin: "",
            emailLoginAlert: false,
            passwordAlert: false,
            allKeys: [],
            arrNotTime: [],
            note: "",
        }
        this.handleDayClick = this.handleDayClick.bind(this);
    }

    componentDidMount() {
        let usertoken = localStorage.getItem("usertoken")

        if (usertoken) {
            let result = this.props.headerDataToSetUser
            if (result) {
                let resp = result
                this.setState({ name: resp.name, email: resp.email, telephone: resp.phone })
                localStorage.setItem("dashboardData", JSON.stringify(resp))
            }
        }

        let result = this.props.headerDataToSet
        let arrNotTime = []
        if (result) {
            this.setState({ headerData: result })
            let currentDay = this.state.selectedDay
            let shop_timings = result.shop_timings
            if (shop_timings) {
                let days = [];
                let date = new Date();
                while (date.getMonth() === currentDay.getMonth()) {
                    days.push(new Date(date));
                    date.setDate(date.getDate() + 1);
                }
                var options = { weekday: 'long' };
                let daysToClose = []
                days.map((g) => {
                    let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                    let timingsToRender = result.shop_timings
                    if (timingsToRender) {
                        let objected = Object.entries(timingsToRender)
                        let filtered = objected.filter((g) => {
                            return g[0] === dutchName
                        })
                        if (filtered.length > 0) {
                            if (filtered[0][1] === "Gesloten") {
                                daysToClose.push(g)
                            }
                        }
                    }
                })
                let arrOfDays = []
                daysToClose.map((h, i) => {
                    arrOfDays.push(h.getDate())
                })
                daysToClose.push({ before: currentDay })
                this.setState({ daysToClose })
                let currentDaySelected = currentDay
                if (arrOfDays.includes(currentDay.getDate())) {
                    currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                    let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                    let daysToClose = []
                    let openedDays = []
                    for (let i = 0; i < daysOfWholeMonth; i++) {
                        let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                        let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                        let timingsToRender = result.shop_timings
                        let arrNotTime = []
                        if (timingsToRender) {
                            let objected = Object.entries(timingsToRender)
                            let filtered = objected.filter((g) => {
                                return g[0] === dutchName
                            })
                            let filteredTimings = objected.filter((g) => {
                                return g[0] === dutchName
                            })
                            result.restricted_time.map((g) => {
                                let currentDateF = new Date(g)
                                let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                if (wholeDay === selectedDay) {
                                    let splittedDays = g.split(" ")
                                    arrNotTime.push(splittedDays[1])
                                }
                            })
                            let filteredAnother = result.restricted_time.filter((g) => {
                                let currentDateF = new Date(g)
                                let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                return wholeDay === selectedDay
                            })
                            let arrOfTimesWhole = []
                            filteredAnother.map((h) => {
                                let splittedDau = h.split(" ")
                                arrOfTimesWhole.push(splittedDau[1])
                            })
                            if (filteredTimings.length > 0) {
                                if (filteredTimings[0][1] !== "Gesloten") {
                                    let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                    let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                    let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                        return filteredcompletedsec.includes(g)
                                    })
                                    let currenthour = date.getHours()
                                    if (date.getDate() === new Date().getDate()) {
                                        currenthour = new Date().getHours()
                                    }
                                    let filteredMain = filtered[0][1].filter((h) => {
                                        let splitted = h.split(":")
                                        return Number(splitted[0]) > currenthour
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(date)
                                        } else {
                                            if (filteredMain.length === 0) {
                                                daysToClose.push(date)
                                            } else {
                                                if (filteredcompletedsec.length === checkForWhole.length) {
                                                    daysToClose.push(date)
                                                } else {
                                                    if (new Date().getMonth() === date.getMonth()) {
                                                        if (date.getDate() > new Date().getDate()) {
                                                            openedDays.push(date)
                                                        }
                                                    } else {
                                                        openedDays.push(date)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    daysToClose.push(date)
                                }
                            } else {
                                daysToClose.push(date)
                            }
                        }
                    }
                    if (openedDays.length > 0) {
                        currentDay = openedDays[0]
                        daysToClose.push({ before: openedDays[0] })
                        this.setState({ daysToClose })
                    } else {
                        let contactedDay = currentDay
                        contactedDay.setMonth(contactedDay.getMonth() + 1, 1);
                        let daysOfWholeMonth = new Date(contactedDay.getFullYear(), contactedDay.getMonth() + 1, 0).getDate()
                        let daysToClose = []
                        let openedDays = []
                        for (let i = 0; i < daysOfWholeMonth; i++) {
                            let date = new Date(contactedDay.getFullYear(), contactedDay.getMonth(), i + 1);
                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                            let timingsToRender = result.shop_timings
                            let arrNotTime = []
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                let filteredTimings = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                result.restricted_time.map((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                    if (wholeDay === selectedDay) {
                                        let splittedDays = g.split(" ")
                                        arrNotTime.push(splittedDays[1])
                                    }
                                })
                                let filteredAnother = result.restricted_time.filter((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                    return wholeDay === selectedDay
                                })
                                let arrOfTimesWhole = []
                                filteredAnother.map((h) => {
                                    let splittedDau = h.split(" ")
                                    arrOfTimesWhole.push(splittedDau[1])
                                })
                                if (filteredTimings.length > 0) {
                                    if (filteredTimings[0][1] !== "Gesloten") {
                                        let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                        let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                        let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                            return filteredcompletedsec.includes(g)
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filtered.length > 0) {
                                            if (filtered[0][1] === "Gesloten") {
                                                daysToClose.push(date)
                                            } else {
                                                if (filteredMain.length === 0) {
                                                    daysToClose.push(date)
                                                } else {
                                                    if (filteredcompletedsec.length === checkForWhole.length) {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (new Date().getMonth() === date.getMonth()) {
                                                            if (date.getDate() > new Date().getDate()) {
                                                                openedDays.push(date)
                                                            }
                                                        } else {
                                                            openedDays.push(date)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        daysToClose.push(date)
                                    }
                                } else {
                                    daysToClose.push(date)
                                }
                            }
                        }
                        currentDay = openedDays[0]
                        daysToClose.push({ before: openedDays[0] })
                        this.setState({ daysToClose })
                    }
                } else {
                    let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                    let daysToClose = []
                    let openedDays = []
                    for (let i = 0; i < daysOfWholeMonth; i++) {
                        let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                        let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                        let timingsToRender = result.shop_timings
                        let arrNotTime = []
                        if (timingsToRender) {
                            let objected = Object.entries(timingsToRender)
                            let filtered = objected.filter((g) => {
                                return g[0] === dutchName
                            })
                            let filteredTimings = objected.filter((g) => {
                                return g[0] === dutchName
                            })
                            result.restricted_time.map((g) => {
                                let currentDateF = new Date(g)
                                let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                if (wholeDay === selectedDay) {
                                    let splittedDays = g.split(" ")
                                    arrNotTime.push(splittedDays[1])
                                }
                            })
                            let filteredAnother = result.restricted_time.filter((g) => {
                                let currentDateF = new Date(g)
                                let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                
                                return wholeDay === selectedDay
                            })
                            let arrOfTimesWhole = []
                            filteredAnother.map((h) => {
                                let splittedDau = h.split(" ")
                                arrOfTimesWhole.push(splittedDau[1])
                            })
                            if (filteredTimings.length > 0) {
                                if (filteredTimings[0][1] !== "Gesloten") {
                                    let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                    let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                    let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                        return filteredcompletedsec.includes(g)
                                    })
                                    let currenthour = date.getHours()
                                    if (date.getDate() === new Date().getDate()) {
                                        currenthour = new Date().getHours()
                                    }
                                    let filteredMain = filtered[0][1].filter((h) => {
                                        let splitted = h.split(":")
                                        return Number(splitted[0]) > currenthour
                                    })
                                    if (filtered.length > 0) {
                                        if (filtered[0][1] === "Gesloten") {
                                            daysToClose.push(date)
                                        } else {
                                            if (filteredMain.length === 0) {
                                                daysToClose.push(date)
                                            } else {
                                                if (filteredcompletedsec.length === checkForWhole.length) {
                                                    daysToClose.push(date)
                                                } else {
                                                    if (new Date().getMonth() === date.getMonth()) {
                                                        if (date.getDate() > new Date().getDate()) {
                                                            openedDays.push(date)
                                                        }
                                                    } else {
                                                        openedDays.push(date)
                                                    }
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    daysToClose.push(date)
                                }
                            } else {
                                daysToClose.push(date)
                            }
                        }
                    }
                    if (openedDays.length > 0) {
                        currentDay = openedDays[0]
                        daysToClose.push({ before: openedDays[0] })
                        this.setState({ daysToClose })
                    } else {
                        let contactedDay = currentDay
                        contactedDay.setMonth(contactedDay.getMonth() + 1, 1);
                        let daysOfWholeMonth = new Date(contactedDay.getFullYear(), contactedDay.getMonth() + 1, 0).getDate()
                        let daysToClose = []
                        let openedDays = []
                        for (let i = 0; i < daysOfWholeMonth; i++) {
                            let date = new Date(contactedDay.getFullYear(), contactedDay.getMonth(), i + 1);
                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                            let timingsToRender = result.shop_timings
                            let arrNotTime = []
                            if (timingsToRender) {
                                let objected = Object.entries(timingsToRender)
                                let filtered = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                let filteredTimings = objected.filter((g) => {
                                    return g[0] === dutchName
                                })
                                result.restricted_time.map((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                    if (wholeDay === selectedDay) {
                                        let splittedDays = g.split(" ")
                                        arrNotTime.push(splittedDays[1])
                                    }
                                })
                                let filteredAnother = result.restricted_time.filter((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                    return wholeDay === selectedDay
                                })
                                let arrOfTimesWhole = []
                                filteredAnother.map((h) => {
                                    let splittedDau = h.split(" ")
                                    arrOfTimesWhole.push(splittedDau[1])
                                })
                                if (filteredTimings.length > 0) {
                                    if (filteredTimings[0][1] !== "Gesloten") {
                                        let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                        let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                        let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                            return filteredcompletedsec.includes(g)
                                        })
                                        let currenthour = date.getHours()
                                        if (date.getDate() === new Date().getDate()) {
                                            currenthour = new Date().getHours()
                                        }
                                        let filteredMain = filtered[0][1].filter((h) => {
                                            let splitted = h.split(":")
                                            return Number(splitted[0]) > currenthour
                                        })
                                        if (filtered.length > 0) {
                                            if (filtered[0][1] === "Gesloten") {
                                                daysToClose.push(date)
                                            } else {
                                                if (filteredMain.length === 0) {
                                                    daysToClose.push(date)
                                                } else {
                                                    if (filteredcompletedsec.length === checkForWhole.length) {
                                                        daysToClose.push(date)
                                                    } else {
                                                        if (new Date().getMonth() === date.getMonth()) {
                                                            if (date.getDate() > new Date().getDate()) {
                                                                openedDays.push(date)
                                                            }
                                                        } else {
                                                            openedDays.push(date)
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    } else {
                                        daysToClose.push(date)
                                    }
                                } else {
                                    daysToClose.push(date)
                                }
                            }
                        }
                        currentDay = openedDays[0]
                        daysToClose.push({ before: openedDays[0] })
                        this.setState({ daysToClose })
                    }
                }
                if (result.restricted_time.length > 0) {
                    result.restricted_time.map((g) => {
                        let currentDateF = new Date(g)
                        let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                        let selectedDay = `${currentDay.getFullYear()}-${currentDay.getMonth() + 1}-${currentDay.getDate()}`
                        if (wholeDay === selectedDay) {
                            let splittedDays = g.split(" ")
                            arrNotTime.push(splittedDays[1])
                        }
                    })
                    this.setState({ arrNotTime })
                }
                this.setState({ selectedDay: currentDay, selectedDayDefault: currentDay })

                // above good (closed days move further)

                // Date WOrk
                let currentDate = currentDay
                let timingsExact = []
                var options = { weekday: 'long' };
                let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                let timingsToRender = result.shop_timings
                if (timingsToRender) {
                    let objected = Object.entries(timingsToRender)
                    let filtered = objected.filter((g) => {
                        return g[0] === dutchName
                    })
                    if (filtered.length > 0) {
                        let arr = []
                        filtered[0][1].map((g) => {
                            let splitted = g.split(":")
                            arr.push(splitted[0])
                        })
                        let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                        let arrSec = []
                        allKeys.map((g) => {
                            let filteredSecond = filtered[0][1].filter((h) => {
                                let splitted = h.split(":")
                                return splitted[0] === g
                            })
                            arrSec.push(filteredSecond)
                        })
                        this.setState({ timingsExact: arrSec, allKeys })
                    }
                }
                // Date WOrk
            }
        }
    }

    getMonthNameinDutch(e) {
        e = e.toLowerCase()
        if (e === "monday") {
            return "MAANDAG"
        } else if (e === "tuesday") {
            return "DINSDAG"
        } else if (e === "wednesday") {
            return "WOENSDAG"
        } else if (e === "thursday") {
            return "DONDERDAG"
        } else if (e === "friday") {
            return "VRIJDAG"
        } else if (e === "saturday") {
            return "ZATERDAG"
        } else if (e === "sunday") {
            return "ZONDAG"
        }
    }

    handleDayClick(day) {
        let currentDate = new Date(day)
        let allDates = []
        let arrNotTime = []
        let result = this.state.headerData
        if (result.restricted_time.length > 0) {
            let arrNotTime = []
            result.restricted_time.map((g) => {
                let currentDateF = new Date(g)
                let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                let selectedDay = `${day.getFullYear()}-${day.getMonth() + 1}-${day.getDate()}`
                if (wholeDay === selectedDay) {
                    let splittedDays = g.split(" ")
                    arrNotTime.push(splittedDays[1])
                }
            })
            this.setState({ arrNotTime })
        }
        this.state.daysToClose.map((g, i) => {
            if (i !== this.state.daysToClose.length - 1) {
                allDates.push(g.getDate())
            } else {
                if (i === this.state.daysToClose.length - 1) {
                    let objected = Object.entries(this.state.daysToClose[i])
                    if (objected.length > 0) {
                        if (objected[0][0] === "before") {
                            for (var i = 0; i < objected[0][1].getDate(); i++) {
                                allDates.push(i)
                            }
                        }
                    }
                }
            }
        })
        if (allDates.includes(currentDate.getDate())) {
            console.log("included")
        } else {
            var options = { weekday: 'long' };
            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
            let timingsToRender = this.state.headerData.shop_timings
            if (timingsToRender) {
                let objected = Object.entries(timingsToRender)
                let filtered = objected.filter((g) => {
                    return g[0] === dutchName
                })
                if (filtered.length > 0) {
                    let date = new Date(day)
                    let arr = []
                    filtered[0][1].map((g) => {
                        let splitted = g.split(":")
                        arr.push(splitted[0])
                    })
                    let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                    let arrSec = []
                    allKeys.map((g) => {
                        let filteredSecond = filtered[0][1].filter((h) => {
                            let splitted = h.split(":")
                            return splitted[0] === g
                        })
                        arrSec.push(filteredSecond)
                    })
                    this.setState({ timingsExact: arrSec, allKeys, selectedTime: "" })
                }
            }
            this.setState({ selectedDay: day, dateDropDown: false });
        }
    }

    moveStep() {
        const { selectedDay, selectedTime } = this.state
        if (selectedTime === "") {
            this.setState({ selectedTimeAlert: true })
        } else {
            this.setState({ selectedTimeAlert: false })
        }
        if (selectedDay !== "" && selectedTime !== "") {
            this.setState({ stepTwo: true, stepOne: false })
        }
    }

    showConfirmation() {
        const { name, email, telephone } = this.state
        if (name !== "") {
            this.setState({ nameAlert: false })
        } else {
            this.setState({ nameAlert: true })
        }
        if (email !== "") {
            this.setState({ emailAlert: false })
        } else {
            this.setState({ emailAlert: true })
        }
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            this.setState({ emailAlert: false })
        } else {
            this.setState({ emailAlert: true })
        }
        if (telephone !== "") {
            this.setState({ telephoneAlert: false })
        } else {
            this.setState({ telephoneAlert: true })
        }
        let selectedDate = this.state.selectedDay.getFullYear() + "-" + `${this.state.selectedDay.getMonth() + 1}` + "-" + this.state.selectedDay.getDate()
        let dateToUpload = selectedDate + " " + `00:00:00`

        if (name !== "" && email !== "" && telephone !== "" && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                let accesstoken = localStorage.getItem("usertoken")

                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${accesstoken}`);

                var formdata = new FormData();
                formdata.append("name", name);
                formdata.append("email", email);
                formdata.append("phone", telephone);
                formdata.append("time", this.state.selectedTime);
                formdata.append("booking_date", dateToUpload);
                formdata.append("note", this.state.note);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/auth/customer/reserve/booking`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        if (result.data.success === 0) {
                            this.setState({ stepOne: true, stepTwo: false, stepConfirmation: false })
                        } else {
                            let headerData = this.state.headerData
                            headerData.restricted_time = result.data.data.restricted_time
                            this.setState({ headerData, stepOne: false, stepTwo: false, stepConfirmation: true })
                            if (result.data.data.restricted_time.length > 0) {
                                let arrNotTime = []
                                result.data.data.restricted_time.map((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${this.state.selectedDay.getFullYear()}-${this.state.selectedDay.getMonth() + 1}-${this.state.selectedDay.getDate()}`
                                    if (wholeDay === selectedDay) {
                                        let splittedDays = g.split(" ")
                                        arrNotTime.push(splittedDays[1])
                                    }
                                })
                                this.setState({ arrNotTime })
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");

                var formdata = new FormData();
                formdata.append("name", name);
                formdata.append("email", email);
                formdata.append("phone", telephone);
                formdata.append("time", this.state.selectedTime);
                formdata.append("booking_date", dateToUpload);
                formdata.append("cart_items_cookie", cookie_id);
                formdata.append("note", this.state.note);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/customer/reserve/booking`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        if (result.code === 200) {
                            if (result.data.success === 0) {
                                this.setState({ stepOne: true, stepTwo: false, stepConfirmation: false })
                            } else {
                                let headerData = this.state.headerData
                                headerData.restricted_time = result.data.data.restricted_time
                                this.setState({ headerData })
                                localStorage.setItem("usertoken", result.data.token)
                                if (result.data.data.restricted_time.length > 0) {
                                    let arrNotTime = []
                                    result.data.data.restricted_time.map((g) => {
                                        let currentDateF = new Date(g)
                                        let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                        let selectedDay = `${this.state.selectedDay.getFullYear()}-${this.state.selectedDay.getMonth() + 1}-${this.state.selectedDay.getDate()}`
                                        if (wholeDay === selectedDay) {
                                            let splittedDays = g.split(" ")
                                            arrNotTime.push(splittedDays[1])
                                        }
                                    })
                                    this.setState({ arrNotTime })
                                }
                                this.setState({ stepOne: false, stepTwo: false, stepConfirmation: true })
                            }
                        } else {
                            if (result.code === 422) {
                                this.setState({ loginForm: true })
                            }
                        }
                    })
                    .catch(error => {
                        console.log(error)
                    });
            }
        }
    }

    cancelAll() {
        this.setState({
            nameAlert: false,
            emailAlert: false,
            telephoneAlert: false,
            stepOne: true,
            stepTwo: false,
            timingsExact: [],
            selectedTime: "",
            selectedDay: new Date(),
            selectedTimeAlert: false,
        })
        if (this.props.updateHeaderData) {
            this.props.updateHeaderData()
        }
        let result = this.props.headerDataToSet
        if (result) {
            this.setState({ headerData: result })
            var days = [];
            var date = new Date();

            while (date.getMonth() === this.state.selectedDay.getMonth()) {
                days.push(new Date(date));
                date.setDate(date.getDate() + 1);
            }
            var options = { weekday: 'long' };
            const today = new Date();
            let daysToClose = [{ before: today }]
            days.map((g) => {
                let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                let timingsToRender = result.shop_timings
                if (timingsToRender) {
                    let objected = Object.entries(timingsToRender)
                    let filtered = objected.filter((g) => {
                        return g[0] === dutchName
                    })
                    if (filtered.length > 0) {
                        if (filtered[0][1] === "Gesloten") {
                            daysToClose.push(g)
                        }
                    }
                }
            })
            this.setState({ daysToClose })

            let arrOfDays = []
            daysToClose.map((h, i) => {
                if (i !== 0) {
                    arrOfDays.push(h.getDate())
                }
            })
            let currentDaySelected = this.state.selectedDay
            if (arrOfDays.includes(this.state.selectedDay.getDate())) {
                currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                if (arrOfDays.includes(currentDaySelected.getDate())) {
                    currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                }
            }
            if (result.restricted_time.length > 0) {
                let arrNotTime = []
                result.restricted_time.map((g) => {
                    let currentDateF = new Date(g)
                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                    let selectedDay = `${currentDaySelected.getFullYear()}-${currentDaySelected.getMonth() + 1}-${currentDaySelected.getDate()}`
                    if (wholeDay === selectedDay) {
                        let splittedDays = g.split(" ")
                        arrNotTime.push(splittedDays[1])
                    }
                })
                this.setState({ arrNotTime })
            }
            this.setState({ selectedDay: currentDaySelected })
            // Days worki

            // Date WOrk
            let currentDate = currentDaySelected
            var options = { weekday: 'long' };
            let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
            let timingsToRender = result.shop_timings
            if (timingsToRender) {
                let objected = Object.entries(timingsToRender)
                let filtered = objected.filter((g) => {
                    return g[0] === dutchName
                })
                if (filtered.length > 0) {
                    let arrNotTime = []
                    if (this.state.selectedDay.getDate() === new Date().getDate()) {
                        let filteredMain = filtered[0][1].filter((h) => {
                            let splitted = h.split(":")
                            return Number(splitted[0]) > new Date().getHours()
                        })
                        if (filteredMain.length === 0) {
                            let date = new Date()
                            date.setDate(date.getDate() + 1)
                            this.setState({ selectedDay: date })
                            if (result.restricted_time.length > 0) {
                                result.restricted_time.map((g) => {
                                    let currentDateF = new Date(g)
                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                    if (wholeDay === selectedDay) {
                                        let splittedDays = g.split(" ")
                                        arrNotTime.push(splittedDays[1])
                                    }
                                })
                                this.setState({ arrNotTime })
                            }
                        }
                    }
                    let arr = []
                    filtered[0][1].map((g) => {
                        let splitted = g.split(":")
                        arr.push(splitted[0])
                    })
                    let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                    let arrSec = []
                    allKeys.map((g) => {
                        let filteredSecond = filtered[0][1].filter((h) => {
                            let splitted = h.split(":")
                            return splitted[0] === g
                        })
                        arrSec.push(filteredSecond)
                    })
                    this.setState({ timingsExact: arrSec, allKeys })
                }
            }
            // Date WOrk
        }
        setTimeout(() => {
            if (this.props.cancelHeaderData) {
                this.props.cancelHeaderData()
            }
        }, 10);
    }

    loginUser() {
        const { emailLogin, passwordLogin } = this.state
        let cookie_id = localStorage.getItem("guestUser")
        if (emailLogin === "") {
            this.setState({ emailLoginPopup: true })
        } else {
            this.setState({ emailLoginPopup: false })
        }
        if (passwordLogin === "") {
            this.setState({ passwordLoginPopup: true })
        } else {
            this.setState({ passwordLoginPopup: false })
        }
        if (emailLogin !== "" && passwordLogin !== "") {
            var formdata = new FormData();
            formdata.append("email", emailLogin);
            formdata.append("password", passwordLogin);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        localStorage.setItem("usertoken", result.data.token)
                        this.setState({ loginForm: false })

                        var myHeaders = new Headers();
                        myHeaders.append("Accept", "application/json");
                        myHeaders.append("Authorization", `Bearer ${result.data.token}`);

                        var requestOptions = {
                            method: 'GET',
                            headers: myHeaders,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/getuser`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                let resp = result.data
                                this.setState({ name: resp.name, email: resp.email, telephone: resp.phone })
                                localStorage.setItem("dashboardData", JSON.stringify(resp))
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        this.setState({ normalPopup: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div className="appointment-template datatemplateappointment" style={{
                maxWidth: this.props.data.full_width ? "100%" : "80%", margin: "0px auto",
                marginBottom: !this.props.data.space_bottom ? 0 : 60,
                marginTop: !this.props.data.space_top ? 0 : 60,
            }}>
                <div className="at-first" style={{ backgroundImage: `url(${this.props.data.left_image})` }}>
                    <h3 style={{ color: this.props.data.leftHeadingColor }}>{this.props.data.main_heading}</h3>
                </div>
                {this.state.loginForm ? <div className="at-second" style={{ backgroundColor: this.props.data.backgroundColor, padding: this.props.data.full_width ? "0px 250px" : "0px 170px" }}>
                    <h4 style={{ color: this.props.data.rightHeadingColor }}>INLOGGEN</h4>
                    <p>Er is al een account met je dit email adres. <br />Login om je afspraak af te ronden.</p>
                    <div className="form-row">
                        <div className="field-wrapper">
                            <input type="text" style={{ padding: 8 }} placeholder="Email" value={this.state.emailLogin} onChange={(e) => this.setState({ emailLogin: e.target.value })} />
                            {this.state.emailLoginAlert && <span className="alert-fields" style={{ color: this.props.data.alertColor }}>Email not valid.</span>}
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="field-wrapper">
                            <input type="password" style={{ padding: 8 }} placeholder="Password" value={this.state.passwordLogin} onChange={(e) => this.setState({ passwordLogin: e.target.value })} />
                            {this.state.passwordAlert && <span className="alert-fields" style={{ color: this.props.data.alertColor }}>Telephone is required.</span>}
                        </div>
                    </div>
                    <div className="form-ats" style={{ backgroundColor: "transparent", justifyContent: "flex-end" }}>
                        <button onClick={() => { this.setState({ loginForm: false }) }} style={{ backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor, marginRight: 8 }}>BACK</button>
                        <button onClick={() => this.loginUser()} style={{ backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor }}>LOGIN</button>
                    </div>
                </div> : this.state.stepOne ? <div className="at-second" style={{ backgroundColor: this.props.data.backgroundColor, padding: 0, display: "flex", alignItems: "center" }}>
                    <div className="container-ats" style={{ width: "70%" }}>
                        <h4 style={{ color: this.props.data.rightHeadingColor }}>{this.props.data.right_heading}</h4>
                        <p style={{ color: this.props.data.rightDescColor }}>{this.props.data.right_desc}</p>
                        <div className="form-ats">
                            <p onClick={() => this.setState({ dateDropDown: !this.state.dateDropDown, timeDropDown: false })}>
                                {this.state.selectedDay.getDate() + "-" + `${this.state.selectedDay.getMonth() + 1}` + "-" + this.state.selectedDay.getFullYear()}
                            </p>
                            <ArrowDropDownIcon onClick={() => this.setState({ dateDropDown: !this.state.dateDropDown, timeDropDown: false })} />
                            {this.state.dateDropDown && <div className="inner-popup-formats">
                                <DayPicker initialMonth={this.state.selectedDay} months={MONTHS} weekdaysLong={WEEKDAYS_LONG}
                                    weekdaysShort={WEEKDAYS_SHORT} selectedDays={[
                                        this.state.selectedDay
                                    ]} fromMonth={this.state.selectedDayDefault} onDayClick={this.handleDayClick}
                                    disabledDays={this.state.daysToClose}
                                    onMonthChange={(e) => {
                                        let result = this.state.headerData
                                        let arrNotTime = []
                                        if (result) {
                                            let currentDay = e
                                            let shop_timings = result.shop_timings
                                            if (shop_timings) {
                                                if (e.getMonth() === new Date().getMonth()) {
                                                    let days = [];
                                                    let date = new Date();
                                                    while (date.getMonth() === currentDay.getMonth()) {
                                                        days.push(new Date(date));
                                                        date.setDate(date.getDate() + 1);
                                                    }
                                                    var options = { weekday: 'long' };
                                                    let daysToClose = []
                                                    days.map((g) => {
                                                        let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                        let timingsToRender = result.shop_timings
                                                        if (timingsToRender) {
                                                            let objected = Object.entries(timingsToRender)
                                                            let filtered = objected.filter((g) => {
                                                                return g[0] === dutchName
                                                            })
                                                            if (filtered.length > 0) {
                                                                if (filtered[0][1] === "Gesloten") {
                                                                    daysToClose.push(g)
                                                                }
                                                            }
                                                        }
                                                    })
                                                    let arrOfDays = []
                                                    daysToClose.map((h, i) => {
                                                        arrOfDays.push(h.getDate())
                                                    })
                                                    daysToClose.push({ before: currentDay })
                                                    this.setState({ daysToClose })
                                                    let currentDaySelected = currentDay
                                                    if (arrOfDays.includes(currentDay.getDate())) {
                                                        currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                                        let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                        let daysToClose = []
                                                        let openedDays = []
                                                        for (let i = 0; i < daysOfWholeMonth; i++) {
                                                            let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                            let timingsToRender = result.shop_timings
                                                            let arrNotTime = []
                                                            if (timingsToRender) {
                                                                let objected = Object.entries(timingsToRender)
                                                                let filtered = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                let filteredTimings = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                result.restricted_time.map((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    if (wholeDay === selectedDay) {
                                                                        let splittedDays = g.split(" ")
                                                                        arrNotTime.push(splittedDays[1])
                                                                    }
                                                                })
                                                                let filteredAnother = result.restricted_time.filter((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    return wholeDay === selectedDay
                                                                })
                                                                let arrOfTimesWhole = []
                                                                filteredAnother.map((h) => {
                                                                    let splittedDau = h.split(" ")
                                                                    arrOfTimesWhole.push(splittedDau[1])
                                                                })
                                                                if (filteredTimings[0][1] !== "Gesloten") {
                                                                    let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                    let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                    let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                                                        return filteredcompletedsec.includes(g)
                                                                    })
                                                                    let currenthour = date.getHours()
                                                                    if (date.getDate() === new Date().getDate()) {
                                                                        currenthour = new Date().getHours()
                                                                    }
                                                                    let filteredMain = filtered[0][1].filter((h) => {
                                                                        let splitted = h.split(":")
                                                                        return Number(splitted[0]) > currenthour
                                                                    })
                                                                    if (filtered.length > 0) {
                                                                        if (filtered[0][1] === "Gesloten") {
                                                                            daysToClose.push(date)
                                                                        } else {
                                                                            if (filteredMain.length === 0) {
                                                                                daysToClose.push(date)
                                                                            } else {
                                                                                if (filteredcompletedsec.length === checkForWhole.length) {
                                                                                    daysToClose.push(date)
                                                                                } else {
                                                                                    if (new Date().getMonth() === date.getMonth()) {
                                                                                        if (date.getDate() > new Date().getDate()) {
                                                                                            openedDays.push(date)
                                                                                        }
                                                                                    } else {
                                                                                        openedDays.push(date)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                } else {
                                                                    daysToClose.push(date)
                                                                }
                                                            }
                                                        }
                                                        currentDay = openedDays[0]
                                                        this.setState({ daysToClose })
                                                    } else {
                                                        let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                        let daysToClose = []
                                                        let openedDays = []
                                                        for (let i = 0; i < daysOfWholeMonth; i++) {
                                                            let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                            let timingsToRender = result.shop_timings
                                                            let arrNotTime = []
                                                            if (timingsToRender) {
                                                                let objected = Object.entries(timingsToRender)
                                                                let filtered = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                let filteredTimings = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                result.restricted_time.map((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    if (wholeDay === selectedDay) {
                                                                        let splittedDays = g.split(" ")
                                                                        arrNotTime.push(splittedDays[1])
                                                                    }
                                                                })
                                                                let filteredAnother = result.restricted_time.filter((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    return wholeDay === selectedDay
                                                                })
                                                                let arrOfTimesWhole = []
                                                                filteredAnother.map((h) => {
                                                                    let splittedDau = h.split(" ")
                                                                    arrOfTimesWhole.push(splittedDau[1])
                                                                })
                                                                if (filteredTimings.length > 0) {
                                                                    if (filteredTimings[0][1] !== "Gesloten") {
                                                                        let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                        let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                        let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                                                            return filteredcompletedsec.includes(g)
                                                                        })
                                                                        let currenthour = date.getHours()
                                                                        if (date.getDate() === new Date().getDate()) {
                                                                            currenthour = new Date().getHours()
                                                                        }
                                                                        let filteredMain = filtered[0][1].filter((h) => {
                                                                            let splitted = h.split(":")
                                                                            return Number(splitted[0]) > currenthour
                                                                        })
                                                                        if (filtered.length > 0) {
                                                                            if (filtered[0][1] === "Gesloten") {
                                                                                daysToClose.push(date)
                                                                            } else {
                                                                                if (filteredMain.length === 0) {
                                                                                    daysToClose.push(date)
                                                                                } else {
                                                                                    if (filteredcompletedsec.length === checkForWhole.length) {
                                                                                        daysToClose.push(date)
                                                                                    } else {
                                                                                        if (new Date().getMonth() === date.getMonth()) {
                                                                                            if (date.getDate() > new Date().getDate()) {
                                                                                                openedDays.push(date)
                                                                                            }
                                                                                        } else {
                                                                                            openedDays.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        daysToClose.push(date)
                                                                    }
                                                                } else {
                                                                    daysToClose.push(date)
                                                                }
                                                            }
                                                        }
                                                        currentDay = openedDays[0]
                                                        daysToClose.push({ before: openedDays[0] })
                                                        this.setState({ daysToClose })
                                                    }
                                                    if (result.restricted_time.length > 0) {
                                                        result.restricted_time.map((g) => {
                                                            let currentDateF = new Date(g)
                                                            let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                            let selectedDay = `${currentDay.getFullYear()}-${currentDay.getMonth() + 1}-${currentDay.getDate()}`
                                                            if (wholeDay === selectedDay) {
                                                                let splittedDays = g.split(" ")
                                                                arrNotTime.push(splittedDays[1])
                                                            }
                                                        })
                                                        this.setState({ arrNotTime })
                                                    }
                                                    this.setState({ selectedDay: currentDay })

                                                    // above good (closed days move further)

                                                    // Date WOrk
                                                    let currentDate = currentDay
                                                    let timingsExact = []
                                                    var options = { weekday: 'long' };
                                                    let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                                                    let timingsToRender = result.shop_timings
                                                    if (timingsToRender) {
                                                        let objected = Object.entries(timingsToRender)
                                                        let filtered = objected.filter((g) => {
                                                            return g[0] === dutchName
                                                        })
                                                        if (filtered.length > 0) {
                                                            let arr = []
                                                            filtered[0][1].map((g) => {
                                                                let splitted = g.split(":")
                                                                arr.push(splitted[0])
                                                            })
                                                            let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                            let arrSec = []
                                                            allKeys.map((g) => {
                                                                let filteredSecond = filtered[0][1].filter((h) => {
                                                                    let splitted = h.split(":")
                                                                    return splitted[0] === g
                                                                })
                                                                arrSec.push(filteredSecond)
                                                            })
                                                            this.setState({ timingsExact: arrSec, allKeys })
                                                        }
                                                    }
                                                    // Date WOrk
                                                } else {
                                                    let days = [];
                                                    let date = new Date(e.getFullYear(), e.getMonth(), 1);
                                                    while (date.getMonth() === currentDay.getMonth()) {
                                                        days.push(new Date(date));
                                                        date.setDate(date.getDate() + 1);
                                                    }
                                                    var options = { weekday: 'long' };
                                                    let daysToClose = []
                                                    days.map((g) => {
                                                        let dutchName = this.getMonthNameinDutch(g.toLocaleDateString('en-US', options))
                                                        let timingsToRender = result.shop_timings
                                                        if (timingsToRender) {
                                                            let objected = Object.entries(timingsToRender)
                                                            let filtered = objected.filter((g) => {
                                                                return g[0] === dutchName
                                                            })
                                                            if (filtered.length > 0) {
                                                                if (filtered[0][1] === "Gesloten") {
                                                                    daysToClose.push(g)
                                                                }
                                                            }
                                                        }
                                                    })
                                                    let arrOfDays = []
                                                    daysToClose.map((h, i) => {
                                                        arrOfDays.push(h.getDate())
                                                    })
                                                    this.setState({ daysToClose })
                                                    let currentDaySelected = currentDay
                                                    if (arrOfDays.includes(currentDay.getDate())) {
                                                        currentDaySelected.setDate(currentDaySelected.getDate() + 1)
                                                        let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                        let daysToClose = []
                                                        let openedDays = []
                                                        for (let i = 0; i < daysOfWholeMonth; i++) {
                                                            let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                            let timingsToRender = result.shop_timings
                                                            let arrNotTime = []
                                                            if (timingsToRender) {
                                                                let objected = Object.entries(timingsToRender)
                                                                let filtered = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                let filteredTimings = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                result.restricted_time.map((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    if (wholeDay === selectedDay) {
                                                                        let splittedDays = g.split(" ")
                                                                        arrNotTime.push(splittedDays[1])
                                                                    }
                                                                })
                                                                let filteredAnother = result.restricted_time.filter((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    return wholeDay === selectedDay
                                                                })
                                                                let arrOfTimesWhole = []
                                                                filteredAnother.map((h) => {
                                                                    let splittedDau = h.split(" ")
                                                                    arrOfTimesWhole.push(splittedDau[1])
                                                                })
                                                                if (filteredTimings[0][1] !== "Gesloten") {
                                                                    let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                    let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                    let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                                                        return filteredcompletedsec.includes(g)
                                                                    })
                                                                    let currenthour = date.getHours()
                                                                    if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                        currenthour = new Date().getHours()
                                                                    }
                                                                    let filteredMain = filtered[0][1].filter((h) => {
                                                                        let splitted = h.split(":")
                                                                        return Number(splitted[0]) > currenthour
                                                                    })
                                                                    console.log(filteredMain)
                                                                    if (filtered.length > 0) {
                                                                        if (filtered[0][1] === "Gesloten") {
                                                                            daysToClose.push(date)
                                                                        } else {
                                                                            if (filteredMain.length === 0) {
                                                                                daysToClose.push(date)
                                                                            } else {
                                                                                if (filteredcompletedsec.length === checkForWhole.length) {
                                                                                    daysToClose.push(date)
                                                                                } else {
                                                                                    if (new Date().getMonth() === date.getMonth()) {
                                                                                        if (date.getDate() > new Date().getDate()) {
                                                                                            openedDays.push(date)
                                                                                        }
                                                                                    } else {
                                                                                        openedDays.push(date)
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    }
                                                                } else {
                                                                    daysToClose.push(date)
                                                                }
                                                            }
                                                        }
                                                        currentDay = openedDays[0]
                                                        this.setState({ daysToClose })
                                                    } else {
                                                        let daysOfWholeMonth = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth() + 1, 0).getDate()
                                                        let daysToClose = []
                                                        let openedDays = []
                                                        for (let i = 0; i < daysOfWholeMonth; i++) {
                                                            let date = new Date(currentDaySelected.getFullYear(), currentDaySelected.getMonth(), i + 1);
                                                            let dutchName = this.getMonthNameinDutch(date.toLocaleDateString('en-US', options))
                                                            let timingsToRender = result.shop_timings
                                                            let arrNotTime = []
                                                            if (timingsToRender) {
                                                                let objected = Object.entries(timingsToRender)
                                                                let filtered = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                let filteredTimings = objected.filter((g) => {
                                                                    return g[0] === dutchName
                                                                })
                                                                result.restricted_time.map((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    if (wholeDay === selectedDay) {
                                                                        let splittedDays = g.split(" ")
                                                                        arrNotTime.push(splittedDays[1])
                                                                    }
                                                                })
                                                                let filteredAnother = result.restricted_time.filter((g) => {
                                                                    let currentDateF = new Date(g)
                                                                    let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                                    let selectedDay = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
                                                                    return wholeDay === selectedDay
                                                                })
                                                                let arrOfTimesWhole = []
                                                                filteredAnother.map((h) => {
                                                                    let splittedDau = h.split(" ")
                                                                    arrOfTimesWhole.push(splittedDau[1])
                                                                })
                                                                if (filteredTimings.length > 0) {
                                                                    if (filteredTimings[0][1] !== "Gesloten") {
                                                                        let filteredcompletedsec = filteredTimings[0][1].filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                        let filteredcompletedsecWhole = arrOfTimesWhole.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                                        let checkForWhole = filteredcompletedsecWhole.filter((g) => {
                                                                            return filteredcompletedsec.includes(g)
                                                                        })
                                                                        let currenthour = date.getHours()
                                                                        if (date.getMonth() === new Date().getMonth() && date.getDate() === new Date().getDate()) {
                                                                            currenthour = new Date().getHours()
                                                                        }
                                                                        let filteredMain = filtered[0][1].filter((h) => {
                                                                            let splitted = h.split(":")
                                                                            return Number(splitted[0]) > currenthour
                                                                        })
                                                                        if (filtered.length > 0) {
                                                                            if (filtered[0][1] === "Gesloten") {
                                                                                daysToClose.push(date)
                                                                            } else {
                                                                                if (filteredMain.length === 0) {
                                                                                    daysToClose.push(date)
                                                                                } else {
                                                                                    if (filteredcompletedsec.length === checkForWhole.length) {
                                                                                        daysToClose.push(date)
                                                                                    } else {
                                                                                        if (new Date().getMonth() === date.getMonth()) {
                                                                                            if (date.getDate() > new Date().getDate()) {
                                                                                                openedDays.push(date)
                                                                                            }
                                                                                        } else {
                                                                                            openedDays.push(date)
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    } else {
                                                                        daysToClose.push(date)
                                                                    }
                                                                } else {
                                                                    daysToClose.push(date)
                                                                }
                                                            }
                                                        }
                                                        currentDay = openedDays[0]
                                                        daysToClose.push({ before: openedDays[0] })
                                                        this.setState({ daysToClose })
                                                    }
                                                    if (result.restricted_time.length > 0) {
                                                        result.restricted_time.map((g) => {
                                                            let currentDateF = new Date(g)
                                                            let wholeDay = `${currentDateF.getFullYear()}-${currentDateF.getMonth() + 1}-${currentDateF.getDate()}`
                                                            let selectedDay = `${currentDay.getFullYear()}-${currentDay.getMonth() + 1}-${currentDay.getDate()}`
                                                            if (wholeDay === selectedDay) {
                                                                let splittedDays = g.split(" ")
                                                                arrNotTime.push(splittedDays[1])
                                                            }
                                                        })
                                                        this.setState({ arrNotTime })
                                                    }
                                                    this.setState({ selectedDay: currentDay })

                                                    // above good (closed days move further)

                                                    // Date WOrk
                                                    let currentDate = currentDay
                                                    let timingsExact = []
                                                    var options = { weekday: 'long' };
                                                    let dutchName = this.getMonthNameinDutch(currentDate.toLocaleDateString('en-US', options))
                                                    let timingsToRender = result.shop_timings
                                                    if (timingsToRender) {
                                                        let objected = Object.entries(timingsToRender)
                                                        let filtered = objected.filter((g) => {
                                                            return g[0] === dutchName
                                                        })
                                                        if (filtered.length > 0) {
                                                            let arr = []
                                                            filtered[0][1].map((g) => {
                                                                let splitted = g.split(":")
                                                                arr.push(splitted[0])
                                                            })
                                                            let allKeys = arr.filter((v, i, a) => a.findIndex(t => (t === v)) === i)
                                                            let arrSec = []
                                                            allKeys.map((g) => {
                                                                let filteredSecond = filtered[0][1].filter((h) => {
                                                                    let splitted = h.split(":")
                                                                    return splitted[0] === g
                                                                })
                                                                arrSec.push(filteredSecond)
                                                            })
                                                            this.setState({ timingsExact: arrSec, allKeys })
                                                        }
                                                    }
                                                    // Date WOrk
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>}
                        </div>
                        <div className="form-ats">
                            <p onClick={() => this.setState({ timeDropDown: !this.state.timeDropDown, dateDropDown: false })}>{this.state.selectedTime ? this.state.selectedTime : "Tijd"}</p>
                            <ArrowDropDownIcon onClick={() => this.setState({ timeDropDown: !this.state.timeDropDown, dateDropDown: false })} />
                            {this.state.timeDropDown && <div className="inner-popup-formats">
                                <ul style={{ width: "100%" }}>
                                    <li style={{ padding: 10 }}>Selecteer tijd</li>
                                    {this.state.timingsExact.length > 0 && this.state.allKeys.map((g) => {
                                        return <li className="listmaintimings" key={Math.random()}>
                                            {this.state.timingsExact.map((t) => {
                                                let splittedSec = t[0].split(":")
                                                if (splittedSec[0] === g) {
                                                    return t.map((h) => {
                                                        if (this.state.selectedDay.getDate() === new Date().getDate()) {
                                                            if (!this.state.arrNotTime.includes(h)) {
                                                                let splitted = h.split(":")
                                                                if (Number(splitted[0]) > new Date().getHours()) {
                                                                    return <div key={Math.random()} className={this.state.selectedTime === h ? "activated" : ""} onClick={() => {
                                                                        this.setState({ selectedTime: h, timeDropDown: false })
                                                                    }}><p>{h}</p></div>
                                                                }
                                                            } else {
                                                                let splitted = h.split(":")
                                                                if (Number(splitted[0]) > new Date().getHours()) {
                                                                    return <div key={Math.random()} className="disabled"><p>{h}</p></div>
                                                                }
                                                            }
                                                        } else {
                                                            if (!this.state.arrNotTime.includes(h)) {
                                                                return <div key={Math.random()} className={this.state.selectedTime === h ? "activated" : ""} onClick={() => {
                                                                    this.setState({ selectedTime: h, timeDropDown: false })
                                                                }}><p>{h}</p></div>
                                                            } else {
                                                                return <div key={Math.random()} className="disabled"><p>{h}</p></div>
                                                            }
                                                        }
                                                    })
                                                }
                                            })}
                                        </li>
                                    })}
                                </ul>
                            </div>}
                            {this.state.selectedTimeAlert && <div className="delivery-work" style={{ width: "100%", display: "flex", alignItems: "center", position: "absolute", bottom: -25 }}>
                                <p style={{ marginBottom: 0, marginRight: 8, fontSize: 12, letterSpacing: 1, marginTop: 9, color: this.props.data.alertColor }}>Please Selecteer uw tijd to continue</p>
                            </div>}
                        </div>
                        <div className="form-ats">
                            <input type="text" placeholder="Afspraak notitie" value={this.state.note} onChange={(e) => this.setState({ note: e.target.value })} />
                        </div>
                        <div className="form-ats" style={{ backgroundColor: "transparent", marginTop: 30, justifyContent: "flex-end" }}>
                            <button onClick={() => this.moveStep()} style={{ backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor }}>VOLGENDE</button>
                        </div>
                    </div>
                </div> : this.state.stepTwo ? <div className="at-second" style={{ backgroundColor: this.props.data.backgroundColor, padding: 0, display: "flex", alignItems: "center" }}>
                    <div className="container-ats" style={{ width: "70%" }}>
                        <h4 style={{ color: this.props.data.rightHeadingColor }}>GEGEVENS</h4>
                        <div className="form-row">
                            <div className="field-wrapper">
                                <input type="text" style={{ padding: 8 }} placeholder="Naam" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                {this.state.nameAlert && <span className="alert-fields" style={{ color: this.props.data.alertColor }}>Name is required.</span>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="field-wrapper">
                                <input type="text" style={{ padding: 8 }} placeholder="Email" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value, emailLogin: e.target.value })} />
                                {this.state.emailAlert && <span className="alert-fields" style={{ color: this.props.data.alertColor }}>Email is not valid.</span>}
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="field-wrapper">
                                <input type="text" style={{ padding: 8 }} placeholder="Telefoon" value={this.state.telephone} onChange={(e) => this.setState({ telephone: e.target.value })} />
                                {this.state.telephoneAlert && <span className="alert-fields" style={{ color: this.props.data.alertColor }}>Telephone is required.</span>}
                            </div>
                        </div>
                        <div className="form-ats" style={{ marginTop: 25 }}>
                            <p>
                                Geselecteerde periode
                            <br />
                            Datum: {this.state.selectedDay.getDate() + "-" + `${this.state.selectedDay.getMonth() + 1}` + "-" + this.state.selectedDay.getFullYear()} &nbsp;
                            Time: {this.state.selectedTime}
                            </p>
                        </div>
                        <div className="form-ats" style={{ backgroundColor: "transparent", justifyContent: "flex-end" }}>
                            <button style={{ marginRight: 8, backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor }} onClick={() => this.setState({ stepOne: true, stepTwo: false })}>VORIGE</button>
                            <button onClick={() => this.showConfirmation()} style={{ backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor }}>VERSTUUR</button>
                        </div>
                    </div>
                </div> : this.state.stepConfirmation && <div className="at-second" style={{ backgroundColor: this.props.data.backgroundColor, textAlign: "center" }}>
                    <div className="container-ats">
                        <h4 style={{ color: this.props.data.rightHeadingColor }}>HARTELIJK DANK <br /> VOOR UW <br /> RESERVERING</h4>
                        <div className="form-ats" style={{ backgroundColor: "transparent", justifyContent: "center" }}>
                            <button onClick={() => this.cancelAll()} style={{ backgroundColor: this.props.data.buttonBackgroundColor, color: this.props.data.buttonFontColor }}>SLUIT</button>
                        </div>
                    </div>
                </div>}
            </div>
        )
    }
}

export default AppointmentTemplateData
