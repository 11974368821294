import React, { Component } from 'react'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { gv } from '../Components/globalvar'
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class DoubleCardEdit extends Component {

    constructor() {
        super()
        this.state = {
            left_heading1: "",
            left_headingInput: false,
            left_heading2: "",
            left_heading2Input: false,
            left_desc: "",
            left_descInput: false,
            left_image: "",
            left_button: "",
            left_buttonInput: false,
            delete_image: false,
            allRoutes: [],
            buttonUrl: '',
            selectRoute: true,
            selectedRoute: "",
            urlValidate: false,
            brandRoute: false,
            brandCategories: [],
            selectedCategory: "horloges",
            allBrands: [],
            childmenulists: [],
            selectedRouteBrand: "",
            selectedBrandList: "",
            brandlists: [],
            listRoute: false,
            selectedRouteurl: '',
            soortRoute: false,
            soortArr: [],
            selectedSoort: "",
            // Right
            right_heading1: "",
            right_headingInput: false,
            right_heading2: "",
            right_heading2Input: false,
            right_desc: "",
            right_descInput: false,
            right_image: "",
            right_button: "",
            right_buttonInput: false,
            delete_imagesec: false,
            buttonUrlSec: '',
            selectRouteSec: true,
            selectedRouteSec: "",
            urlValidateSec: false,
            brandRouteSec: false,
            selectedCategorySec: "horloges",
            allBrandsSec: [],
            childmenulistsSec: [],
            selectedRouteBrandSec: "",
            selectedBrandListSec: "",
            listRouteSec: false,
            selectedRouteurlSec: '',
            soortRouteSec: false,
            selectedSoortSec: "",
            heading_1: "",
            heading_1Input: false,
            heading_2: "",
            heading_2Input: false,
            // footer section work
            spaceTop: false,
            spaceBottom: false,
            is_brand: false,
            is_brandSec: false,
            // DUplicates
            image_url_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg",
            delete_image_dup: false,
            right_url_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/sixth.jpg",
            delete_image_dupSec: false,
            crop: {
                // unit: '%',
                width: 578,
                height: 470,
                aspect: 16 / 9,
            },
            createLoader: true,
            onImageIndexSec: 0,
            onImageIndex: 0,
            showSubCategory: true,
            showSubCategorySec: true,
        }
    }

    componentDidMount() {
        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            let childmenulists = []
            let hello = child_menus.filter((f) => {
                return f[0] === parsed.main_categories[0].id
            });
            childmenulists = hello[0][1]

            let arr = []
            let objected = Object.entries(childmenulists)
            objected.map((g) => {
                g[1].map((h) => {
                    arr.push(h)
                })
            })
            this.setState({
                brandCategories: parsed.main_categories, selectedCategory: parsed.main_categories[0].id, childmenulists: arr,
                brandCategoriesSec: parsed.main_categories, selectedCategorySec: parsed.main_categories[0].id, childmenulistsSec: arr
            })
        }

        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)

            let splitted = parsedData.left_content.left_image.split('/')
            if (splitted[splitted.length - 1] !== "fifth.jpg" && !parsedData.left_content.is_brand && !parsedData.left_content.is_soort) {
                this.setState({ delete_image: true })
            }

            let splittedSec = parsedData.right_content.right_image.split('/')
            if (splittedSec[splittedSec.length - 1] !== "sixth.jpg" && !parsedData.right_content.is_brand && !parsedData.right_content.is_soort) {
                this.setState({ delete_imagesec: true })
            }

            this.setState({
                left_heading1: parsedData.left_content.left_heading1,
                left_heading2: parsedData.left_content.left_heading2,
                left_desc: parsedData.left_content.left_desc,
                left_image: parsedData.left_content.left_image,
                left_button: parsedData.left_content.left_button,
                is_brand: parsedData.left_content.is_brand,
                is_soort: parsedData.left_content.is_soort,
                right_heading1: parsedData.right_content.right_heading1,
                right_heading2: parsedData.right_content.right_heading2,
                right_desc: parsedData.right_content.right_desc,
                right_image: parsedData.right_content.right_image,
                right_button: parsedData.right_content.right_button,
                is_brandSec: parsedData.right_content.is_brand,
                is_soortSec: parsedData.right_content.is_soort,
                heading_1: parsedData.heading_1,
                heading_2: parsedData.heading_2,
                browserType,
                spaceTop: parsedData.space_top,
                spaceBottom: parsedData.space_bottom,
                sectionType: parsedData.section_type,
                delete_image: parsedData.left_content.checkImageUpload,
                delete_imagesec: parsedData.right_content.checkImageUpload,
                onImageIndex: parsedData.left_content.onImageIndex,
                onImageIndexSec: parsedData.right_content.onImageIndex,
                showSubCategory: parsedData.showSubCategory,
                showSubCategorySec: parsedData.showSubCategorySec,
            })

            if (parsedData.left_content.urlType) {
                let splitted = parsedData.left_content.button_url.split("/")
                this.setState({ selectedRouteurl: parsedData.left_content.button_url })
                let arr = []
                if (parsedData.left_content.is_brand) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    if (parsedData.showSubCategory) {
                        inArr.map((f) => {
                            if (f[0] === splitted[1]) {
                                let objected = Object.entries(f[1])
                                objected.map((g) => {
                                    g[1].map((h) => {
                                        arr.push(h)
                                    })
                                })
                                this.setState({ childmenulists: arr })
                            }
                        })
                        this.setState({ selectedRoute: `/subcategory`, brandRoute: true, selectedCategory: splitted[1], selectedBrand: splitted[2] })
                        if (!parsedData.left_content.checkImageUpload) {
                            var formdata = new FormData();
                            formdata.append("main_category", splitted[1]);
                            formdata.append("image_type", "left_banner");
                            formdata.append("brand_id", splitted[2]);

                            var requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    this.setState({ left_image: result.data.image_url, delete_image: false, loaderSec: false })
                                })
                                .catch(error => console.log('error', error));
                        }
                    } else {
                        inArr.map((f) => {
                            if (f[0] === splitted[2]) {
                                let objected = Object.entries(f[1])
                                objected.map((g) => {
                                    g[1].map((h) => {
                                        arr.push(h)
                                    })
                                })
                                this.setState({ childmenulists: arr })
                            }
                        })
                        this.setState({ selectedRoute: `/maincategory`, brandRoute: true, selectedCategory: splitted[2] })
                        if (!parsedData.left_content.checkImageUpload) {
                            var formdata = new FormData();
                            formdata.append("main_category", splitted[2]);
                            formdata.append("image_type", "left_banner");
                            formdata.append("brand_id", arr[0].id);

                            var requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    this.setState({ left_image: result.data.image_url, delete_image: false, loaderSec: false })
                                })
                                .catch(error => console.log('error', error));
                        }
                    }

                } else {
                    if (splitted[1] === "") {
                        this.setState({ selectedRoute: `/landing-page` })
                    } else {
                        this.setState({ selectedRoute: `/${splitted[1]}` })
                    }
                }
            } else {
                this.setState({ buttonUrl: parsedData.left_content.button_url })
            }

            if (parsedData.right_content.urlType) {
                let splitted = parsedData.right_content.button_url.split("/")
                this.setState({ selectedRouteurlSec: parsedData.right_content.button_url })
                let arr = []
                if (parsedData.right_content.is_brand) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)

                    if (parsedData.showSubCategorySec) {
                        inArr.map((f) => {
                            if (f[0] === splitted[1]) {
                                let objected = Object.entries(f[1])
                                objected.map((g) => {
                                    g[1].map((h) => {
                                        arr.push(h)
                                    })
                                })
                                this.setState({ childmenulistsSec: arr })
                            }
                        })
                        this.setState({ selectedRouteSec: `/subcategory`, brandRouteSec: true, selectedCategorySec: splitted[1], selectedBrandSec: splitted[2] })
                        if (!parsedData.right_content.checkImageUpload) {
                            var formdata = new FormData();
                            formdata.append("main_category", splitted[1]);
                            formdata.append("image_type", "left_banner");
                            formdata.append("brand_id", splitted[2]);

                            var requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    this.setState({ right_image: result.data.image_url, delete_imagesec: false, loaderSec: false })
                                })
                                .catch(error => console.log('error', error));
                        }
                    } else {
                        inArr.map((f) => {
                            if (f[0] === splitted[2]) {
                                let objected = Object.entries(f[1])
                                objected.map((g) => {
                                    g[1].map((h) => {
                                        arr.push(h)
                                    })
                                })
                                this.setState({ childmenulistsSec: arr })
                            }
                        })
                        this.setState({ selectedRouteSec: `/maincategory`, brandRouteSec: true, selectedCategorySec: splitted[2] })
                        if (!parsedData.right_content.checkImageUpload) {
                            var formdata = new FormData();
                            formdata.append("main_category", splitted[2]);
                            formdata.append("image_type", "left_banner");
                            formdata.append("brand_id", arr[0].id);

                            var requestOptions = {
                                method: 'POST',
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    this.setState({ right_image: result.data.image_url, delete_imagesec: false, loaderSec: false })
                                })
                                .catch(error => console.log('error', error));
                        }
                    }

                    // this.setState({ selectedRouteSec: `/${splitted[1]}`, brandRouteSec: true, selectedCategorySec: splitted[2], selectedBrandSec: splitted[3] })


                } else {
                    if (splitted[1] === "") {
                        this.setState({ selectedRouteSec: `/landing-page` })
                    } else {
                        this.setState({ selectedRouteSec: `/${splitted[1]}` })
                    }
                }
            } else {
                this.setState({ buttonUrlSec: parsedData.right_content.button_url })
            }
        }

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));


        let allRoutes = localStorage.getItem("allRoutes")
        if (allRoutes) {
            let parsed = JSON.parse(allRoutes)
            let arr = ["subcategory", "maincategory"]
            parsed.map((f) => {
                if (f.split("/")[1] === "") {
                    arr.push("landing-page")
                } else {
                    arr.push(f.split("/")[1])
                }
            })
            this.setState({ allRoutes: arr })
        }
    }


    uploadImg(e, f) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0], selectedForUpload: f })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 612 || height > 720) {
                            let crop = {
                                unit: 'px',
                                width: 612,
                                height: 720,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 98,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });
        if (this.state.is_soort) {
            let accesstoken = localStorage.getItem("adminKey")

            this.setState({ loader: true })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_4");
            formdata.append("image", file);
            formdata.append("brand_id", this.state.selectedSoort);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    this.setState({ loader: false })
                    if (result.data.sucess !== 0) {
                        this.setState({ left_image: result.data.image_url, delete_image: true, croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            let accesstoken = localStorage.getItem("adminKey")

            this.setState({ loader: true })

            if (this.state.is_brand) {
                if (this.state.selectedBrand) {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image", file);
                    formdata.append("brand_id", this.state.selectedBrand);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.setState({ loader: false })
                            if (result.data.sucess !== 0) {
                                this.setState({ left_image: result.data.image_url, delete_image: true, loader: false, croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image", file);
                    formdata.append("brand_id", this.state.childmenulists[0].id);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.setState({ loader: false })
                            if (result.data.sucess !== 0) {
                                this.setState({ left_image: result.data.image_url, delete_image: true, loader: false, croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", "layout_4");
                formdata.append("image", file);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.setState({ left_image: result.data.image_url, image_url_duplicate: result.data.image_url, delete_image: true, loader: false, croppedImageUrl: "", cropper: false })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    doneImageSec(e) {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (this.state.is_soortSec) {
            let accesstoken = localStorage.getItem("adminKey")

            this.setState({ loaderSec: true })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_4");
            formdata.append("image", file);
            formdata.append("brand_id", this.state.selectedSoortSec);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    if (result.data.sucess !== 0) {
                        this.setState({ right_image: result.data.image_url, delete_imagesec: true, loaderSec: false, croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            e.preventDefault()

            let accesstoken = localStorage.getItem("adminKey")

            this.setState({ loaderSec: true })

            if (this.state.is_brandSec) {
                if (this.state.selectedBrandSec) {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image", file);
                    formdata.append("brand_id", this.state.selectedBrandSec);
                    formdata.append("main_category", this.state.selectedCategorySec);
                    formdata.append("image_type", "left_banner");

                    e.target.value = null

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            if (result.data.sucess !== 0) {
                                this.setState({ right_image: result.data.image_url, delete_imagesec: true, loaderSec: false, croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image", file);
                    formdata.append("brand_id", this.state.childmenulistsSec[0].id);
                    formdata.append("main_category", this.state.selectedCategorySec);
                    formdata.append("image_type", "left_banner");

                    e.target.value = null

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then((result) => {
                            this.setState({ loader: false })
                            if (result.data.sucess !== 0) {
                                this.setState({ right_image: result.data.image_url, right_url_duplicate: result.data.image_url, delete_imagesec: true, loaderSec: false, croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", "layout_4");
                formdata.append("image", file);

                e.target.value = null

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.setState({ right_image: result.data.image_url, delete_imagesec: true, loaderSec: false, croppedImageUrl: "", cropper: false })
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    removeImage() {
        if (this.state.is_soort) {
            let imageName = this.state.left_image.split('/')

            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_4");
            formdata.append("image_name", imageName[imageName.length - 1]);
            formdata.append("brand_id", this.state.selectedSoort);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ left_image: result.data.default_url, loader: false, delete_image: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            const { left_image } = this.state
            let splitted = left_image.split("/")

            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            if (this.state.is_brand) {
                if (this.state.selectedBrand) {
                    let imageName = this.state.left_image.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.selectedBrand);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ left_image: result.data.images, loader: false, delete_image: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let imageName = this.state.left_image.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.childmenulists[0].id);
                    formdata.append("main_category", this.state.selectedCategory);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ left_image: result.data.images, loader: false, delete_image: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                if (splitted[splitted.length - 1] !== "fifth.jpg") {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", splitted[splitted.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ left_image: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg", delete_image: false, loader: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    removeImageSec() {
        if (this.state.is_soortSec) {
            let imageName = this.state.right_image.split('/')

            this.setState({ loaderSec: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_4");
            formdata.append("image_name", imageName[imageName.length - 1]);
            formdata.append("brand_id", this.state.selectedSoortSec);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ right_image: result.data.default_url, loaderSec: false, delete_imagesec: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            const { right_image } = this.state
            let splitted = right_image.split("/")

            this.setState({ loaderSec: true })

            let accesstoken = localStorage.getItem("adminKey")

            if (this.state.is_brandSec) {
                if (this.state.selectedBrandSec) {
                    let imageName = this.state.right_image.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.selectedBrandSec);
                    formdata.append("main_category", this.state.selectedCategorySec);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ right_image: result.data.images, loaderSec: false, delete_imagesec: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                } else {
                    let imageName = this.state.right_image.split('/')

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", imageName[imageName.length - 1]);
                    formdata.append("brand_id", this.state.childmenulistsSec[0].id);
                    formdata.append("main_category", this.state.selectedCategorySec);
                    formdata.append("image_type", "left_banner");

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ right_image: result.data.images, loaderSec: false, delete_imagesec: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            } else {
                if (splitted[splitted.length - 1] !== "sixth.jpg") {
                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", "layout_4");
                    formdata.append("image_name", splitted[splitted.length - 1]);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ right_image: "https://saastoday.nl/public/retailer_phase_assets/sections/sixth.jpg", delete_imagesec: false, loaderSec: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    getBrand(e, g) {
        this.setState({ is_brand: true, loader: true, is_soort: false, onImageIndex: 0 })
        if (e === "firstTime") {
            this.setState({ image_url_duplicate: this.state.left_image, delete_image_dup: this.state.delete_image, })

            if (this.state.showSubCategory) {
                this.setState({ selectedRouteurl: `/${this.state.selectedCategory}/${this.state.childmenulists[0].id}`, selectedBrand: this.state.childmenulists[0].id })
            } else {
                this.setState({ selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrand: this.state.childmenulists[0].id })
            }

            var formdata = new FormData();
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.childmenulists[0].id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ left_image: result.data.image_url, delete_image: false, loader: false })
                })
                .catch(error => console.log('error', error));
        } else if (e === "secTime") {
            var selectedCateogrySend = ""
            selectedCateogrySend = this.state.selectedCategory
            if (this.state.showSubCategory) {
                this.setState({ selectedRouteurl: `/${this.state.selectedCategory}/${g.target.value}`, selectedBrand: g.target.value })
            } else {
                this.setState({ selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrand: g.target.value })
            }

            var formdata = new FormData();
            formdata.append("main_category", selectedCateogrySend);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", g.target.value);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ left_image: result.data.image_url, delete_image: false, loader: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === e.target.value) {
                        let arr = []
                        let objected = Object.entries(f[1])
                        objected.map((g) => {
                            g[1].map((h) => {
                                arr.push(h)
                            })
                        })
                        if (this.state.showSubCategory) {
                            this.setState({ childmenulists: arr, selectedCategory: e.target.value, selectedBrand: "", selectedRouteurl: `/${e.target.value}/${arr[0].id}` })
                        } else {
                            this.setState({ childmenulists: arr, selectedCategory: e.target.value, selectedBrand: "", selectedRouteurl: `/hoofcategorie/${e.target.value}/products` })
                        }

                        var formdata = new FormData();
                        formdata.append("main_category", e.target.value);
                        formdata.append("image_type", "left_banner");
                        formdata.append("brand_id", arr[0].id);

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                this.setState({ left_image: result.data.image_url, delete_image: false, loader: false })
                            })
                            .catch(error => console.log('error', error));
                    }
                })
            }
        }
    }

    getBrandSec(e, g) {
        this.setState({ is_brandSec: true, loaderSec: true, is_soortSec: false, onImageIndexSec: 0 })
        if (e === "firstTime") {
            this.setState({ right_url_duplicate: this.state.right_image, delete_image_dupSec: this.state.delete_imagesec, })

            if (this.state.showSubCategorySec) {
                this.setState({ selectedRouteurlSec: `/${this.state.selectedCategory}/${this.state.childmenulists[0].brand_id}`, selectedBrandSec: this.state.childmenulists[0].brand_id })
            } else {
                this.setState({ selectedRouteurlSec: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrandSec: this.state.childmenulists[0].brand_id })
            }

            var formdata = new FormData();
            formdata.append("main_category", this.state.selectedCategorySec);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.childmenulistsSec[0].id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ right_image: result.data.image_url, delete_imagesec: false, loaderSec: false })
                })
                .catch(error => console.log('error', error));
        } else if (e === "secTime") {
            var selectedCateogrySend = ""
            selectedCateogrySend = this.state.selectedCategorySec
            this.setState({ selectedRouteurlSec: `/brand-products/${this.state.selectedCategorySec}/${g.target.value}`, selectedBrandSec: g.target.value })

            if (this.state.showSubCategorySec) {
                this.setState({ selectedRouteurlSec: `/${this.state.selectedCategory}/${g.target.value}`, selectedBrandSec: g.target.value })
            } else {
                this.setState({ selectedRouteurlSec: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrandSec: g.target.value })
            }

            var formdata = new FormData();
            formdata.append("main_category", selectedCateogrySend);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", g.target.value);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ right_image: result.data.image_url, delete_imagesec: false, loaderSec: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === e.target.value) {
                        let arr = []
                        let objected = Object.entries(f[1])
                        objected.map((g) => {
                            g[1].map((h) => {
                                arr.push(h)
                            })
                        })
                        if (this.state.showSubCategorySec) {
                            this.setState({ childmenulistsSec: arr, selectedCategorySec: e.target.value, selectedBrandSec: arr[0].id, selectedRouteurlSec: `/${e.target.value}/${arr[0].id}` })
                        } else {
                            this.setState({ childmenulistsSec: arr, selectedCategorySec: e.target.value, selectedBrandSec: arr[0].id, selectedRouteurlSec: `/hoofcategorie/${e.target.value}/products` })
                        }
                        var formdata = new FormData();
                        formdata.append("main_category", e.target.value);
                        formdata.append("image_type", "left_banner");
                        formdata.append("brand_id", arr[0].id);

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                this.setState({ right_image: result.data.image_url, delete_imagesec: false, loaderSec: false })
                            })
                            .catch(error => console.log('error', error));
                    }
                })
            }
        }
    }

    getBrandDetailsList(e, f) {
        this.setState({ selectedCategory: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsList: childmenulists, selectedRouteurl: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    getBrandDetailsListSec(e, f) {
        this.setState({ selectedCategorySec: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsListSec: childmenulists, selectedRouteurlSec: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { heading_1, heading_2, left_heading1, left_heading2, left_desc, left_button, left_image, right_heading1, right_heading2, right_desc, right_button, right_image, wholeData, buttonUrl, buttonUrlSec } = this.state
        let urlBtn = ""
        let is_button_route = false
        if (this.state.selectRoute) {
            if (this.state.selectedRoute) {
                is_button_route = true
                if (this.state.selectedRoute === "/landing-page") {
                    urlBtn = "/"
                } else if (this.state.selectedRoute === "/subcategory" || this.state.selectedRoute === "/maincategory" || this.state.selectedRoute === "/soort" || this.state.selectedRoute === "/brand-detail") {
                    urlBtn = this.state.selectedRouteurl
                } else {
                    urlBtn = this.state.selectedRoute
                }
            } else {
                is_button_route = false
                urlBtn = this.state.selectedRoute
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrl) {
                if (buttonUrl.match(regex)) {
                    urlBtn = buttonUrl
                    is_button_route = true
                    this.setState({ urlValidate: false })
                } else {
                    urlBtn = ""
                    this.setState({ urlValidate: true })
                }
            } else {
                urlBtn = ""
                is_button_route = false
            }
        }
        let urlBtnSec = ""
        let is_button_routeSec = false
        if (this.state.selectRouteSec) {
            if (this.state.selectedRouteSec) {
                is_button_routeSec = true
                if (this.state.selectedRouteSec === "/landing-page") {
                    urlBtnSec = "/"
                } else if (this.state.selectedRouteSec === "/subcategory" || this.state.selectedRouteSec === "/maincategory" || this.state.selectedRouteSec === "/soort" || this.state.selectedRoute === "/brand-detail") {
                    urlBtnSec = this.state.selectedRouteurlSec
                } else {
                    urlBtnSec = this.state.selectedRouteSec
                }
            } else {
                is_button_routeSec = false
                urlBtnSec = this.state.selectedRouteSec
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrlSec) {
                if (buttonUrlSec.match(regex)) {
                    urlBtnSec = buttonUrlSec
                    is_button_routeSec = true
                    this.setState({ urlValidateSec: false })
                } else {
                    urlBtn = ""
                    this.setState({ urlValidateSec: true })
                }
            } else {
                urlBtnSec = ""
                is_button_routeSec = false
            }
        }

        // this.setState({ createLoader: true })

        let obj = {}
        if (Array.isArray(this.state.left_image) && Array.isArray(this.state.right_image)) {
            obj = {
                type: "layout_4",
                heading_1,
                heading_2,
                left_content: {
                    left_heading1,
                    left_heading2,
                    left_desc,
                    left_image: left_image[this.state.onImageIndex],
                    left_button,
                    is_button_route,
                    button_url: urlBtn,
                    urlType: this.state.selectRoute,
                    is_brand: this.state.is_brand,
                    is_soort: this.state.is_soort,
                    onImageIndex: this.state.onImageIndex,
                    section_type: this.state.sectionType,
                    checkImageUpload: this.state.delete_image,
                },
                right_content: {
                    right_heading1,
                    right_heading2,
                    right_desc,
                    right_image: right_image[this.state.onImageIndexSec],
                    right_button,
                    is_button_route: is_button_routeSec,
                    button_url: urlBtnSec,
                    urlType: this.state.selectRouteSec,
                    is_brand: this.state.is_brandSec,
                    is_soort: this.state.is_soortSec,
                    onImageIndex: this.state.onImageIndexSec,
                    section_type: this.state.sectionType,
                    checkImageUpload: this.state.delete_imagesec,
                },
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                showSubCategory: this.state.showSubCategory,
                showSubCategorySec: this.state.showSubCategorySec,
            }
        } else {
            if (Array.isArray(this.state.left_image)) {
                obj = {
                    type: "layout_4",
                    heading_1,
                    heading_2,
                    left_content: {
                        left_heading1,
                        left_heading2,
                        left_desc,
                        left_image: left_image[this.state.onImageIndex],
                        left_button,
                        is_button_route,
                        button_url: urlBtn,
                        urlType: this.state.selectRoute,
                        is_brand: this.state.is_brand,
                        is_soort: this.state.is_soort,
                        onImageIndex: this.state.onImageIndex,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_image,
                    },
                    right_content: {
                        right_heading1,
                        right_heading2,
                        right_desc,
                        right_image,
                        right_button,
                        is_button_route: is_button_routeSec,
                        button_url: urlBtnSec,
                        urlType: this.state.selectRouteSec,
                        is_brand: this.state.is_brandSec,
                        is_soort: this.state.is_soortSec,
                        onImageIndex: this.state.onImageIndexSec,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_imagesec,
                    },
                    space_top: this.state.spaceTop,
                    space_bottom: this.state.spaceBottom,
                    showSubCategory: this.state.showSubCategory,
                    showSubCategorySec: this.state.showSubCategorySec,
                }
            } else if (Array.isArray(this.state.right_image)) {
                obj = {
                    type: "layout_4",
                    heading_1,
                    heading_2,
                    left_content: {
                        left_heading1,
                        left_heading2,
                        left_desc,
                        left_image,
                        left_button,
                        is_button_route,
                        button_url: urlBtn,
                        urlType: this.state.selectRoute,
                        is_brand: this.state.is_brand,
                        is_soort: this.state.is_soort,
                        onImageIndex: this.state.onImageIndex,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_image,
                    },
                    right_content: {
                        right_heading1,
                        right_heading2,
                        right_desc,
                        right_image: right_image[this.state.onImageIndexSec],
                        right_button,
                        is_button_route: is_button_routeSec,
                        button_url: urlBtnSec,
                        urlType: this.state.selectRouteSec,
                        is_brand: this.state.is_brandSec,
                        is_soort: this.state.is_soortSec,
                        onImageIndex: this.state.onImageIndexSec,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_imagesec,
                    },
                    space_top: this.state.spaceTop,
                    space_bottom: this.state.spaceBottom,
                    showSubCategory: this.state.showSubCategory,
                    showSubCategorySec: this.state.showSubCategorySec,
                }
            } else {
                obj = {
                    type: "layout_4",
                    heading_1,
                    heading_2,
                    left_content: {
                        left_heading1,
                        left_heading2,
                        left_desc,
                        left_image,
                        left_button,
                        is_button_route,
                        button_url: urlBtn,
                        urlType: this.state.selectRoute,
                        is_brand: this.state.is_brand,
                        is_soort: this.state.is_soort,
                        onImageIndex: this.state.onImageIndex,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_image,
                    },
                    right_content: {
                        right_heading1,
                        right_heading2,
                        right_desc,
                        right_image,
                        right_button,
                        is_button_route: is_button_routeSec,
                        button_url: urlBtnSec,
                        urlType: this.state.selectRouteSec,
                        is_brand: this.state.is_brandSec,
                        is_soort: this.state.is_soortSec,
                        onImageIndex: this.state.onImageIndexSec,
                        section_type: this.state.sectionType,
                        checkImageUpload: this.state.delete_imagesec,
                    },
                    space_top: this.state.spaceTop,
                    space_bottom: this.state.spaceBottom,
                    showSubCategory: this.state.showSubCategory,
                    showSubCategorySec: this.state.showSubCategorySec,
                }
            }
        }

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    resetBrand() {
        this.setState({ left_image: this.state.image_url_duplicate, delete_image: this.state.delete_image_dup, is_brand: false, is_soort: false })
    }

    resetBrandSec() {
        this.setState({ right_image: this.state.right_url_duplicate, delete_imagesec: this.state.delete_image_dupSec, is_brandSec: false, is_soortSec: false })
    }


    getSoort(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurl: `/soort/${this.state.soortArr[0].id}`, selectedSoort: this.state.soortArr[0].id, is_soort: true, is_brand: false, })
            this.setState({ image_url_duplicate: this.state.left_image, delete_image_dup: this.state.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ left_image: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ left_image: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurl: `/soort/${e.target.value}`, selectedSoort: e.target.value, is_soort: true, is_brand: false })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ left_image: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ left_image: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    getSoortSec(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurlSec: `/soort/${this.state.soortArr[0].id}`, selectedSoortSec: this.state.soortArr[0].id, is_soortSec: true, is_brandSec: false, })
            this.setState({ right_url_duplicate: this.state.right_image, delete_image_dupSec: this.state.delete_imagesec, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ right_image: result.data.image_url, delete_imagesec: false })
                    } else {
                        this.setState({ right_image: result.data.image_url, delete_imagesec: true })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurlSec: `/soort/${e.target.value}`, selectedSoortSec: e.target.value, is_soortSec: true, is_brandSec: false })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ right_image: result.data.image_url, delete_imagesec: false })
                    } else {
                        this.setState({ right_image: result.data.image_url, delete_imagesec: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    showCurrentImage() {
        if (this.state.selectedForUpload === "left") {
            this.setState({ cropper: false, left_image: "https://saastoday.nl/public/retailer_phase_assets/sections/fifth.jpg", croppedImageUrl: "" })
        } else {
            this.setState({ cropper: false, right_image: "https://saastoday.nl/public/retailer_phase_assets/sections/sixth.jpg", croppedImageUrl: "" })
        }
    }

    render() {
        return (
            <div className="card-sec-rit">
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="closer-working" onClick={() => this.setState({ left_headingInput: false, left_heading2Input: false, left_buttonInput: false, left_descInput: false, right_headingInput: false, right_heading2Input: false, right_buttonInput: false, right_descInput: false, heading_1Input: false, heading_2Input: false })}>

                </div>
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                        maxHeight={500}
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    {this.state.selectedForUpload === "left" ? <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e, "left")} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div> : <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e, "right")} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>}
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        {this.state.selectedForUpload === "left" ? <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div> : <div className="inner-faps" onClick={this.doneImageSec.bind(this)}>
                                <font>DONE</font>
                            </div>}
                    </div>
                </div> : <div className="inner-wrapper" style={{ padding: "0px 40px", minHeight: 1100, marginBottom: 80 }}>
                        {/* <div className="start-sec-second " style={{ position: "relative", zIndex: 9999 }}>
                            {this.state.heading_1 ? <p className="heading-hss" onClick={() => this.setState({ heading_1Input: true })} onBlur={() => this.setState({ heading_1Input: false })}>
                                {!this.state.heading_1Input ? this.state.heading_1 : <input type="text" autoFocus value={this.state.heading_1} style={{ width: "50%" }} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} />}
                            </p> : <input type="text" value={this.state.heading_1} style={{ width: "50%", backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ heading_1: e.target.value, heading_1Input: true })} onBlur={() => this.setState({ heading_1Input: false })} />}
                            <br />
                            {this.state.heading_2 ? <p className="cont-hss" onClick={() => this.setState({ heading_2Input: true })} onBlur={() => this.setState({ heading_2Input: false })}>
                                {!this.state.heading_2Input ? this.state.heading_2 : <input autoFocus type="text" value={this.state.heading_2} style={{ width: "50%" }} onChange={(e) => this.setState({ heading_2: e.target.value })} onBlur={() => this.setState({ heading_2Input: false })} />}
                            </p> : <input type="text" value={this.state.heading_2} style={{ width: "50%", backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ heading_2: e.target.value, heading_2Input: true })} onBlur={() => this.setState({ heading_2Input: false })} />}
                        </div> */}
                        <div className="all-cards-rit sectiles" style={{ backgroundColor: "#f6f2f2", overflow: "hidden" }}>
                            <div className="card-rit" style={{ zIndex: 99999 }}>
                                <div className="content-crit">
                                    {/* <div className="secchecks">
                                        {this.state.left_heading1 ? <span onClick={() => this.setState({ left_headingInput: true })} onBlur={() => this.setState({ left_headingInput: false })} style={{ textTransform: "uppercase" }} className="heading-hss">
                                            {!this.state.left_headingInput ? this.state.left_heading1 : <input autoFocus type="text" value={this.state.left_heading1} onChange={(e) => this.setState({ left_heading1: e.target.value })} onBlur={() => this.setState({ left_headingInput: false })} />}
                                        </span> : <input type="text" value={this.state.left_heading1} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ left_heading1: e.target.value, left_headingInput: true })} onBlur={() => this.setState({ left_headingInput: false })} />}
                                    </div> */}
                                    <div className="secchecks">
                                        {this.state.left_heading2 ? <h3 onClick={() => this.setState({ left_heading2Input: true })} onBlur={() => this.setState({ left_heading2Input: false })}>
                                            {!this.state.left_heading2Input ? this.state.left_heading2 : <input type="text" autoFocus value={this.state.left_heading2} onChange={(e) => this.setState({ left_heading2: e.target.value })} onBlur={() => this.setState({ left_heading2Input: false })} />}
                                        </h3> : <input type="text" value={this.state.left_heading2} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ left_heading2: e.target.value, left_heading2Input: true })} onBlur={() => this.setState({ left_heading2Input: false })} />}
                                    </div>
                                    <div className="secchecks">
                                        {this.state.left_desc ? <p onClick={() => this.setState({ left_descInput: true })} onBlur={() => this.setState({ left_descInput: false })} className="p-cr">
                                            {!this.state.left_descInput ? this.state.left_desc : <textarea autoFocus value={this.state.left_desc} onChange={(e) => {
                                                if (e.target.value.length < 110) {
                                                    this.setState({ left_desc: e.target.value })
                                                }
                                            }} onBlur={() => this.setState({ left_descInput: false })}>
                                                {this.state.left_desc}
                                            </textarea>}
                                        </p> : <textarea value={this.state.left_desc} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => {
                                            if (e.target.value.length < 110) {
                                                this.setState({ left_desc: e.target.value, left_descInput: true })
                                            }
                                        }} onBlur={() => this.setState({ left_descInput: false })}>
                                                {this.state.left_desc}
                                            </textarea>}
                                    </div>
                                    <div className="secchecks">
                                        {!this.state.left_buttonInput ? <button onClick={() => this.setState({ left_buttonInput: true })} onBlur={() => this.setState({ left_buttonInput: false })} >{this.state.left_button}</button> : <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} value={this.state.selectRoute ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRoute: true })
                                                } else {
                                                    this.setState({ selectRoute: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRoute ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10 }} value={this.state.selectedRoute} onChange={(e) => {
                                                    this.setState({ selectedRoute: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrand("firstTime")
                                                        this.setState({ brandRoute: true, showSubCategory: true, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/${this.state.selectedCategory}/${this.state.childmenulists[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrand("firstTime")
                                                        this.setState({ brandRoute: true, showSubCategory: false, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products` })
                                                    } else {
                                                        this.setState({ brandRoute: false, listRoute: false, selectedRouteurl: "", detailRoute: false, soortRoute: false })
                                                        this.resetBrand()
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRoute && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrand(e, "brand-products", "left")} value={this.state.selectedCategory}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategory && <select style={{ marginBottom: 10 }} value={this.state.selectedBrand} onChange={(e) => this.getBrand("secTime", e, "left")}>
                                                        {this.state.childmenulists.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20 }} value={this.state.buttonUrl} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrl: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrl: e.target.value })
                                                }
                                            }} />}
                                            {this.state.urlValidate && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                            <button>
                                                <input value={this.state.left_button} onChange={(e) => this.setState({ left_button: e.target.value })} onBlur={() => this.setState({ left_buttonInput: false })} />
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                                <div className="img-crit" style={{ position: "relative" }}>
                                    <div className="file-uploadimg" style={{ zIndex: 9999, left: "100.5%" }}>
                                        <input type="file" onChange={(e) => this.uploadImg(e, "left")} accept="image/*" />
                                        <PublishIcon style={{ color: "white" }} />
                                    </div>
                                    {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, left: "calc(100.5% - 40px)", top: 75 }}>
                                        <DeleteIcon onClick={this.removeImage.bind(this)} />
                                    </div>}
                                    {Array.isArray(this.state.left_image) && this.state.left_image.length > 1 && this.state.onImageIndex > 0 && <div className="file-uploadimg" style={{ top: 124, zIndex: 9999, left: "93.5%" }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex - 1 })}>
                                        <ChevronLeftIcon />
                                    </div>}
                                    {Array.isArray(this.state.left_image) && this.state.left_image.length > 1 && this.state.onImageIndex < this.state.left_image.length - 1 && <div className="file-uploadimg" style={{ top: 174, zIndex: 9999, left: "100.5%", position: "absolute" }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex + 1 })}>
                                        <ChevronRightIcon />
                                    </div>}
                                    {this.state.loader && <div className="loader-working-fb">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                    <img alt="showingyet" src={Array.isArray(this.state.left_image) ? this.state.left_image[this.state.onImageIndex] : this.state.left_image} width="100%" height="100%" />
                                </div>
                            </div>
                            <div className="card-rit" style={{ zIndex: 99999 }}>
                                <div className="content-crit">
                                    {/* <div className="secchecks">
                                        {this.state.right_heading1 ? <span onClick={() => this.setState({ right_headingInput: true })} onBlur={() => this.setState({ right_headingInput: false })} style={{ textTransform: "uppercase" }} className="heading-hss">
                                            {!this.state.right_headingInput ? this.state.right_heading1 : <input type="text" autoFocus value={this.state.right_heading1} onChange={(e) => this.setState({ right_heading1: e.target.value })} onBlur={() => this.setState({ right_headingInput: false })} />}
                                        </span> : <input type="text" value={this.state.right_heading1} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ right_heading1: e.target.value, right_headingInput: true })} onBlur={() => this.setState({ right_headingInput: false })} />}
                                    </div> */}
                                    <div className="secchecks">
                                        {this.state.right_heading2 ? <h3 onClick={() => this.setState({ right_heading2Input: true })} onBlur={() => this.setState({ right_heading2Input: false })}>
                                            {!this.state.right_heading2Input ? this.state.right_heading2 : <input type="text" autoFocus value={this.state.right_heading2} onChange={(e) => this.setState({ right_heading2: e.target.value })} onBlur={() => this.setState({ right_heading2Input: false })} />}
                                        </h3> : <input type="text" value={this.state.right_heading2} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ right_heading2: e.target.value, right_heading2Input: true })} onBlur={() => this.setState({ right_heading2Input: false })} />}
                                    </div>
                                    <div className="secchecks">
                                        {this.state.right_desc ? <p onClick={() => this.setState({ right_descInput: true })} onBlur={() => this.setState({ right_descInput: false })} className="p-cr">
                                            {!this.state.right_descInput ? this.state.right_desc : <textarea autoFocus value={this.state.right_desc} onChange={(e) => {
                                                if (e.target.value.length < 110) {
                                                    this.setState({ right_desc: e.target.value })
                                                }
                                            }} onBlur={() => this.setState({ right_descInput: false })}>
                                                {this.state.right_desc}
                                            </textarea>}
                                        </p> : <textarea style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} value={this.state.right_desc} onChange={(e) => {
                                            if (e.target.value.length < 110) {
                                                this.setState({ right_desc: e.target.value, right_descInput: true })
                                            }
                                        }} onBlur={() => this.setState({ right_descInput: false })}>
                                                {this.state.right_desc}
                                            </textarea>}
                                    </div>
                                    <div className="secchecks">
                                        {!this.state.right_buttonInput ? <button onClick={() => this.setState({ right_buttonInput: true })} onBlur={() => this.setState({ right_buttonInput: false })} >{this.state.right_button}</button> : <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} value={this.state.selectRouteSec ? "ps" : "link"} onChange={(e) => {
                                                if (e.target.value === "ps") {
                                                    this.setState({ selectRouteSec: true })
                                                } else {
                                                    this.setState({ selectRouteSec: false })
                                                }
                                            }}>
                                                <option value="ps">Page selection</option>
                                                <option value="link">Own link</option>
                                            </select>
                                            {this.state.selectRouteSec ? <div style={{ width: "100%" }}>
                                                <select style={{ marginBottom: 10 }} value={this.state.selectedRouteSec} onChange={(e) => {
                                                    this.setState({ selectedRouteSec: e.target.value })
                                                    if (e.target.value === "/subcategory") {
                                                        this.getBrandSec("firstTime")
                                                        this.setState({ brandRouteSec: true, showSubCategorySec: true, detailRouteSec: false, listRouteSec: false, soortRouteSec: false, selectedRouteurlSec: `/${this.state.selectedCategorySec}/${this.state.childmenulistsSec[0].id}` })
                                                    } else if (e.target.value === "/maincategory") {
                                                        this.getBrandSec("firstTime")
                                                        this.setState({ brandRouteSec: true, showSubCategorySec: false, detailRouteSec: false, listRouteSec: false, soortRouteSec: false, selectedRouteurlSec: `/hoofcategorie/${this.state.selectedCategorySec}/products` })
                                                    } else {
                                                        this.setState({ brandRouteSec: false, listRouteSec: false, selectedRouteurlSec: "", soortRouteSec: false, })
                                                        this.resetBrandSec()
                                                    }
                                                }}>
                                                    <option value="">Select Route</option>
                                                    {this.state.allRoutes.map((e) => {
                                                        return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                                    })}
                                                </select>
                                                {this.state.brandRouteSec && <div style={{ width: "100%" }}>
                                                    <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrandSec(e, "brand-products", "left")} value={this.state.selectedCategorySec}>
                                                        {this.state.brandCategories.map((e) => {
                                                            if (e.show_for_products) {
                                                                return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                            }
                                                        })}
                                                    </select>
                                                    {this.state.showSubCategorySec && <select style={{ marginBottom: 10 }} value={this.state.selectedBrandSec} onChange={(e) => this.getBrandSec("secTime", e, "left")}>
                                                        {this.state.childmenulistsSec.map((e) => {
                                                            return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>}
                                                </div>}
                                            </div> : <input className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20 }} value={this.state.buttonUrlSec} onChange={(e) => {
                                                if (e.target.value !== "") {
                                                    this.setState({ buttonUrlSec: e.target.value })
                                                } else {
                                                    this.setState({ buttonUrlSec: e.target.value })
                                                }
                                            }} />}
                                            {this.state.urlValidateSec && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                            <button>
                                                <input value={this.state.right_button} onChange={(e) => this.setState({ right_button: e.target.value })} onBlur={() => this.setState({ right_buttonInput: false })} />
                                            </button>
                                        </div>}
                                    </div>
                                </div>
                                <div className="img-crit" style={{ position: "relative" }}>
                                    <div className="file-uploadimg" style={{ zIndex: 9999, left: "100.5%" }}>
                                        <input type="file" onChange={(e) => this.uploadImg(e, "right")} accept="image/*" />
                                        <PublishIcon style={{ color: "white" }} />
                                    </div>
                                    {this.state.delete_imagesec && <div className="file-uploadimg" style={{ zIndex: 9999, left: "calc(100.5% - 40px)", top: 75 }}>
                                        <DeleteIcon onClick={this.removeImageSec.bind(this)} />
                                    </div>}
                                    {Array.isArray(this.state.right_image) && this.state.right_image.length > 1 && this.state.onImageIndexSec > 0 && <div className="file-uploadimg" style={{ top: 124, zIndex: 9999, left: "93.5%" }} onClick={() => this.setState({ onImageIndexSec: this.state.onImageIndexSec - 1 })}>
                                        <ChevronLeftIcon />
                                    </div>}
                                    {Array.isArray(this.state.right_image) && this.state.right_image.length > 1 && this.state.onImageIndexSec < this.state.right_image.length - 1 && <div className="file-uploadimg" style={{ top: 174, zIndex: 9999, left: "100.5%", position: "absolute" }} onClick={() => this.setState({ onImageIndexSec: this.state.onImageIndexSec + 1 })}>
                                        <ChevronRightIcon />
                                    </div>}
                                    {this.state.loaderSec && <div className="loader-working-fb">
                                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                                    </div>}
                                    <img alt="showingyet" src={Array.isArray(this.state.right_image) ? this.state.right_image[this.state.onImageIndexSec] : this.state.right_image} width="100%" height="100%" />
                                </div>
                            </div>
                        </div >
                    </div >}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 578x470, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default DoubleCardEdit
