import React, { Component } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import './index.css'
import BannerData from '../../DataTemplates/Banner';
import DoubleCardData from '../../DataTemplates/DoubleCard';
import OntdekData from '../../DataTemplates/Ontdek';
import HorizontalProductsData from '../../DataTemplates/HorizontalProducts';
import { gv } from '../../Components/globalvar'
import TwoTileData from '../../DataTemplates/TwoTile';
import CollectieData from '../../DataTemplates/Collectie';
import BrandListData from '../../DataTemplates/BrandList';
import ProductCollectionData from '../../DataTemplates/ProductCollection';
import ContactData from '../../DataTemplates/Contact';
import { Helmet } from "react-helmet";
import 'react-sortable-tree/style.css';
import BannerSecData from '../../DataTemplates/BannerSec';
import FooterContactData from '../../DataTemplates/FooterContact';
import FrameSecData from '../../DataTemplates/FrameSec';
import BrandsShowData from '../../DataTemplates/BrandsShow';
import TextTemplateData from '../../DataTemplates/TextTemplate';
import SlidesData from '../../DataTemplates/Slides';
import MenuWorker from '../../Components/MenuWorker';
import CreaterSection from '../../Components/CreaterSection';
import { FaqTemplateData } from '../../DataTemplates/FaqTemplate';
import CompleteNavbar from '../../Components/CompleteNavbar';
import AppointmentTemplateData from '../../DataTemplates/Appointment';
import FooterContactBannerData from '../../DataTemplates/FooterContactBanner';

AOS.init();

export class HomeDuplicateForMenu extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            arr: ["1", "2", "3", "4", "5", "6"],
            right: false,
            rightSecond: false,
            wholeData: "",
            // arrModals: [{ createPopup: false, formCreate: false, editPopup: false, formEdit: false, createPage: false }, { createPopup: false }, { createPopup: false }, { createPopup: false }, { createPopup: false }, { createPopup: false }, { createPopup: false }, { createPopup: false }],
            selectedTemplate: '',
            currentIndex: 0,
            sendingData: '',
            onIndex: 0,
            isAdmin: false,
            pageName: "",
            pageNameAlert: false,
            pageTitle: "",
            pageTitleAlert: false,
            noData: false,
            gettingDataHP: true,
            gettingDataPC: true,
            gettingDataBL: true,
            gettingDataContact: true,
            domainName: '',
            sendingData: [],
            // Menu working
            menuType: "",
            isSub: false,
            // Pages Working
            completePages: [],
            // Background
            backgroundHeader: "",
            backgroundFooter: "",
            backgroundButton: "",
            productDetailFirstColour: "",
            productDetailSecondColour: "",
            deletePageModal: false,
            bodyFont: "Roboto",
            titleFont: "Times New Roman",
            startingArray: 0,
            endingArray: 6,
            noData: false,
            headerDataToSet: "",
            headerDataToSetUser: "",
            updatedHeader: false,
        }
    }

    componentDidMount() {

        let location = window.location.href
        var domain = location.replace('http://', '').replace('https://', '').split(/[/?#]/)[0]
        this.setState({ domainName: domain })

        setTimeout(() => {
            this.setState({ transition: true })
        }, 200);
        setTimeout(() => {
            this.setState({ transition: false })
        }, 300);

        localStorage.setItem("pageName", "")
        localStorage.setItem("getskus", true)

        // Admin key working

        if (this.props.match.params.id) {
            let splitted = this.props.match.params.id.split("=")
            if (splitted[0] === "authkey") {
                console.log("that worked")
                var myHeaders = new Headers();
                myHeaders.append("authkey", splitted[1]);

                var formdata = new FormData();

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/login`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "Loged In Successfully") {
                            localStorage.setItem("adminToken", splitted[1])
                            localStorage.setItem("adminKey", result.data.accesstoken)
                            window.location.href = "/"
                        } else {
                            window.location.href = "/"
                        }
                    })
                    .catch(error => console.log('error', error));
            }
        }

        let accesstoken = localStorage.getItem("adminKey")
        if (accesstoken) {
            this.setState({ isAdmin: true })
        } else {
            this.setState({ isAdmin: false })
        }

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        let pathName = window.location.pathname
        let splitted = pathName.split("")
        splitted.shift()
        splitted = splitted.join("")

        if (splitted.includes("%20")) {
            splitted = splitted.split("%20").join(" ")
        }

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("allPagesSetting", JSON.stringify(result.data.other_pages))
                this.setState({ completePages: result.data.other_pages })
                result.data.other_pages.map((e, i) => {
                    if (splitted !== "") {
                        localStorage.setItem("pageName", splitted)
                        let lenghtOgSplitted = e.route.split("")
                        if (lenghtOgSplitted[lenghtOgSplitted.length - 1] === " ") {
                            lenghtOgSplitted.splice(lenghtOgSplitted.length - 1, 1)
                        }
                        if (JSON.stringify(lenghtOgSplitted.join("")) === JSON.stringify(`/${splitted}`)) {
                            if (e.isActive) {
                                if (e.sections.length > 0) {
                                    let arr = []
                                    e.sections.map(() => {
                                        let obj = {
                                            createPopup: false,
                                            formCreate: false,
                                            editPopup: false,
                                            formEdit: false,
                                            createPage: false,
                                        }
                                        arr.push(obj)
                                    })
                                    this.setState({ wholeData: e, sendingData: result.data, onIndex: i, arrModals: arr, noData: false })
                                } else {
                                    this.setState({ noData: true, wholeData: "" })
                                }
                            } else if (accesstoken) {
                                if (e.sections.length > 0) {
                                    let arr = []
                                    e.sections.map(() => {
                                        let obj = {
                                            createPopup: false,
                                            formCreate: false,
                                            editPopup: false,
                                            formEdit: false,
                                            createPage: false,
                                        }
                                        arr.push(obj)
                                    })
                                    this.setState({ wholeData: e, sendingData: result.data, onIndex: i, arrModals: arr, noData: false })
                                } else {
                                    let arr = [{
                                        createPopup: false,
                                        formCreate: false,
                                        editPopup: false,
                                        formEdit: false,
                                        createPage: false,
                                    }]
                                    this.setState({ noData: true, wholeData: "", arrModals: arr })
                                }
                            } else {
                                window.location.href = "/"
                            }
                        }
                    } else {
                        if (e.route === "/") {
                            if (e.sections.length > 0) {
                                let arr = []
                                e.sections.map(() => {
                                    let obj = {
                                        createPopup: false,
                                        formCreate: false,
                                        editPopup: false,
                                        formEdit: false,
                                        createPage: false,
                                        colorPopup: false,
                                    }
                                    arr.push(obj)
                                })
                                this.setState({ wholeData: e, sendingData: result.data, onIndex: i, arrModals: arr, noData: false })
                            } else {
                                this.setState({ noData: true })
                            }
                        }
                    }
                    setTimeout(() => {
                        if (!this.state.sendingData.other_pages && !this.state.noData) {
                            window.location.href = "/"
                        }
                    }, 3000);
                })
            })
            .catch(error => console.log('error', error));


        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            this.setState({ headerDataWork: JSON.parse(headerData) })
            let theme_settings = JSON.parse(headerData).theme_settings
            let headerColor = theme_settings.header_background_color
            let footerColor = theme_settings.footer_background_color

            // document.getElementById("headercolourchanging").style.backgroundColor = headerColor
            // document.getElementById("headercolourchangingSec").style.backgroundColor = headerColor
            // document.getElementById("headercolourchangingTr").style.backgroundColor = headerColor
            // document.getElementById("headercolourchangingFr").style.backgroundColor = headerColor
            // document.getElementById("headercolourchangingFi").style.backgroundColor = headerColor
            setTimeout(() => {
                document.getElementById("footercolour").style.backgroundColor = footerColor
            }, 1200);
        }
    }

    sendData(e) {
        this.setState({ headerDataToSet: e })
    }

    sendDataUser(e) {
        this.setState({ headerDataToSetUser: e })
    }

    render() {
        return (
            <div className="adminsnection">
                <CompleteNavbar sendData={(e) => this.sendData(e)} sendDataUser={(e) => this.sendDataUser(e)} getNewDate={this.state.updatedHeader} />
                {this.state.wholeData && <Helmet>
                    <title>{this.state.wholeData.name}</title>
                </Helmet>}
                {/* Normal Working */}
                {
                    this.state.wholeData ? this.state.wholeData.sections.map((e, i) => {
                        if (e.type === "layout_8") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_2" />}
                                <div className="overlaypr">
                                </div>
                                <BannerData data={e} key={Math.random()} />
                            </div>
                        } else if (e.type === "layout_4") {
                            return <div key={Math.random()} className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_1" />}
                                <div className="overlaypr">
                                </div>
                                <DoubleCardData data={e} />
                            </div>
                        } else if (e.type === "layout_2") {
                            return <div key={Math.random()} className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_3" />}
                                <div className="overlaypr">
                                </div>
                                <TwoTileData data={e} />
                            </div>
                        } else if (e.type === "layout_1") {
                            return <div key={Math.random()} className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_4" />}
                                <div className="overlaypr">
                                </div>
                                <ProductCollectionData data={e} gettingData={this.state.gettingDataPC} />
                            </div>
                        } else if (e.type === "layout_5") {
                            return <div key={Math.random()} className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_5" />}
                                <div className="overlaypr">
                                </div>
                                <BrandListData data={e} gettingData={this.state.gettingDataBL} />
                            </div>
                        } else if (e.type === "layout_3") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_9" />}
                                <div className="overlaypr">
                                </div>
                                <OntdekData data={e} />
                            </div>
                        } else if (e.type === "layout_7") {
                            return <div className="rowsection" key={Math.random()} style={{ backgroundColor: "white" }}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_7" />}
                                <div className="overlaypr">
                                </div>
                                <HorizontalProductsData data={e} indexes={i} gettingData={this.state.gettingDataHP} />
                            </div>
                        } else if (e.type === "layout_9") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_8" />}
                                <div className="overlaypr">
                                </div>
                                <CollectieData data={e} />
                            </div>
                        } else if (e.type === "layout_6") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_6" />}
                                <div className="overlaypr">
                                </div>
                                <ContactData gettingData={this.state.gettingDataContact} data={e} />
                            </div>
                        } else if (e.type === "layout_13") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_13" />}
                                <div className="overlaypr">
                                </div>
                                <BannerSecData data={e} />
                            </div>
                        } else if (e.type === "layout_11") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_11" />}
                                <div className="overlaypr">
                                </div>
                                <FooterContactBannerData data={e} />
                            </div>
                        } else if (e.type === "layout_12") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_12" />}
                                <div className="overlaypr">
                                </div>
                                <FrameSecData data={e} />
                            </div>
                        } else if (e.type === "layout_14") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_14" />}
                                <div className="overlaypr">
                                </div>
                                <BrandsShowData data={e} />
                            </div>
                        } else if (e.type === "layout_15") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_15" />}
                                <div className="overlaypr">
                                </div>
                                <TextTemplateData data={e} />
                            </div>
                        } else if (e.type === "layout_10") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_10" />}
                                <div className="overlaypr">
                                </div>
                                <SlidesData data={e} />
                            </div>
                        } else if (e.type === "layout_16") {
                            return <div className="rowsection" key={Math.random()}>
                                {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_16" />}
                                <div className="overlaypr">
                                </div>
                                <FaqTemplateData data={e} />
                            </div>
                        } else if (e.type === "layout_17") {
                            if (this.state.headerDataWork.show_appointment_section === "yes") {
                                return <div className="rowsection" key={Math.random()}>
                                    {this.state.isAdmin && <MenuWorker index={i} data={e} onIndex={this.state.onIndex} completeLength={this.state.wholeData.sections.length - 1} wholeData={this.state.wholeData} sendingData={this.state.sendingData} currentTemplate="layout_17" />}
                                    <div className="overlaypr">
                                    </div>
                                    <AppointmentTemplateData data={e} headerDataToSet={this.state.headerDataToSet} headerDataToSetUser={this.state.headerDataToSetUser} updateHeaderData={() => this.setState({ updatedHeader: true })} cancelHeaderData={() => this.setState({ updatedHeader: false })} />
                                </div>
                            }
                        }
                    }) : this.state.noData && this.state.isAdmin && <CreaterSection />
                }
            </div >
        )
    }
}

export default HomeDuplicateForMenu
