import React, { Component } from 'react'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import SlidesEdit from '../EditTemplates/Slides';
import ContactEdit from '../EditTemplates/Contact';
import TextTemplateEdit from '../EditTemplates/TextTemplate';
import BrandsShowEdit from '../EditTemplates/BrandsShow';
import FrameSecEdit from '../EditTemplates/FrameSec';
import FooterContactEdit from '../EditTemplates/FooterContact';
import BannerSecEdit from '../EditTemplates/BannerSec';
import BannerEdit from '../EditTemplates/Banner';
import CollectieEdit from '../EditTemplates/Collectie';
import TowTileEdit from '../EditTemplates/TwoTile';
import OntdekEdit from '../EditTemplates/Ontdek';
import DoubleCardEdit from '../EditTemplates/DoubleCard';
import ProductCollectionEdit from '../EditTemplates/ProductCollection';
import BrandListEdit from '../EditTemplates/BrandList';
import HorizontalProductsEdit from '../EditTemplates/HorizontalProducts';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Slides from '../Templates/Slides';
import TextTemplate from '../Templates/TextTemplate';
import FrameSec from '../Templates/FrameSec';
import FooterContact from '../Templates/FooterContact';
import BannerSec from '../Templates/BannerSec';
import Banner from '../Templates/Banner';
import DoubleCard from '../Templates/DoubleCard';
import HorizontalProducts from '../Templates/HorizontalProducts';
import Collectie from '../Templates/Collectie';
import Ontdek from '../Templates/Ontdek';
import ProductCollection from '../Templates/ProductCollection';
import BrandList from '../Templates/BrandList';
import Contact from '../Templates/Contact';
import BrandsShow from '../Templates/BrandsShow';
import TwoTile from '../Templates/TwoTile';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import { gv } from '../Components/globalvar'
import FaqTemplate from '../Templates/FaqTemplate';
import FaqTemplateEdit from '../EditTemplates/FaqTemplate';
import AppointmentTemplate from '../Templates/Appointment';
import AppointmentTemplateEdit from '../EditTemplates/Appointment';
import FooterContactBanner from '../Templates/FooterContactBanner';
import FooterContactBannerEdit from '../EditTemplates/FooterContactBanner';

export class MenuWorker extends Component {
    constructor() {
        super()
        this.state = {
            arrTemplates: [
                { number: "4", templateType: "layout_4" },
                { number: "3", templateType: "layout_3" },
                { number: "9", templateType: "layout_9" },
                { number: "1", templateType: "layout_1" },
                { number: "5", templateType: "layout_5" },
                { number: "6", templateType: "layout_6" },
                { number: "7", templateType: "layout_7" },
                { number: "2", templateType: "layout_2" },
                { number: "8", templateType: "layout_8" },
                { number: "10", templateType: "layout_10" },
                { number: "11", templateType: "layout_11" },
                { number: "12", templateType: "layout_12" },
                { number: "13", templateType: "layout_13" },
                { number: "14", templateType: "layout_14" },
                { number: "15", templateType: "layout_15" },
                { number: "16", templateType: "layout_16" },
                { number: "17", templateType: "layout_17" },
            ],
            arrModals: {
                createPopup: false,
                editPopup: false,
            },
            startingArray: 0,
            endingArray: 6,
        }
    }

    goUp(e) {
        let currentIndex = e
        let beforeIndex = e - 1
        let dataCurrent = this.props.wholeData.sections[currentIndex]
        let dataBefore = this.props.wholeData.sections[beforeIndex]
        this.props.wholeData.sections[currentIndex] = dataBefore
        this.props.wholeData.sections[beforeIndex] = dataCurrent

        this.props.sendingData.other_pages.splice(this.props.onIndex, 1, this.props.wholeData)
        let stringified = JSON.stringify(this.props.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    goDown(e) {
        let currentIndex = e
        let afterIndex = e + 1
        let dataCurrent = this.props.wholeData.sections[currentIndex]
        let dataBefore = this.props.wholeData.sections[afterIndex]
        this.props.wholeData.sections[currentIndex] = dataBefore
        this.props.wholeData.sections[afterIndex] = dataCurrent

        this.props.sendingData.other_pages.splice(this.props.onIndex, 1, this.props.wholeData)
        let stringified = JSON.stringify(this.props.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    deleteSection(f, e) {
        if (f !== "yes" && f !== "no") {
            this.setState({ forDeleteIndex: e, deletePageModal: true })
        } else {
            if (f === "yes") {
                this.props.wholeData.sections.splice(this.state.forDeleteIndex, 1)
                this.props.sendingData.other_pages[this.props.onIndex] = this.props.wholeData

                let stringified = JSON.stringify(this.props.sendingData.other_pages)

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("builder_settings", stringified);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        console.log("working")
                        console.log(result)
                        // window.location.reload()
                    })
                    .catch(error => {
                        this.setState({ showErrorModal: true })
                    });
            } else {
                this.setState({ deletePageModal: false })
            }
        }
    }

    render() {
        return (
            <div>
                {/* Create New Modal */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals.createPopup ? 1 : 0, pointerEvents: this.state.arrModals.createPopup ? "all" : "none" }}>

                </div>
                <div className="modal-new" style={{ opacity: this.state.arrModals.createPopup ? 1 : 0, pointerEvents: this.state.arrModals.createPopup ? "all" : "none", width: this.state.selectedTemplate === "layout_14" ? "90%" : this.state.selectedTemplate === "layout_13" ? "95%" : this.state.selectedTemplate === "layout_11" ? "95%" : this.state.selectedTemplate === "layout_2" && "95%", height: this.state.selectedTemplate === "layout_11" && 700, display: this.state.selectedTemplate === "layout_11" && "flex" }}>
                    {!this.state.arrModals.formCreate && <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                        let arr = this.state.arrModals
                        arr.createPopup = false
                        this.setState({ arrModals: arr })
                    }} />}
                    {!this.state.arrModals.formCreate && <h3 className="heading-popup">
                        SELECTEER LAYOUT
                            </h3>}
                    {!this.state.arrModals.formCreate && <div className="create-popup" style={{ paddingTop: 20 }}>
                        <div className="pagination-cp">
                            <ul>
                                <li onClick={() => {
                                    this.setState({ startingArray: 0, endingArray: 6, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>1</li>
                                <li onClick={() => {
                                    this.setState({ startingArray: 6, endingArray: 12, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>2</li>
                                <li onClick={() => {
                                    this.setState({ startingArray: 12, endingArray: 17, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                }}>3</li>
                            </ul>
                        </div>
                        {this.state.arrTemplates.slice(this.state.startingArray, this.state.endingArray).map((e) => {
                            return <div className="all-templates" key={Math.random()} style={{ backgroundImage: `url(https://saastoday.nl/public/retailer_phase_assets/sections/template${e.number}.png)` }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = true
                                this.setState({ arrModals: arr, selectedTemplate: e.templateType })
                            }}></div>
                        })}
                    </div>}
                    {/* Form Create */}
                    {this.state.arrModals.formCreate && <div className="create-popup" style={{ width: "100%" }}>
                        {this.state.selectedTemplate === "layout_1" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <DoubleCard />
                        </div> : this.state.selectedTemplate === "layout_2" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <Banner />
                        </div> : this.state.selectedTemplate === "layout_3" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <TwoTile />
                        </div> : this.state.selectedTemplate === "layout_4" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <ProductCollection />
                        </div> : this.state.selectedTemplate === "layout_5" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <BrandList />
                        </div> : this.state.selectedTemplate === "layout_6" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <Contact />
                        </div> : this.state.selectedTemplate === "layout_7" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <HorizontalProducts />
                        </div> : this.state.selectedTemplate === "layout_8" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <Collectie />
                        </div> : this.state.selectedTemplate === "layout_9" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <Ontdek />
                        </div> : this.state.selectedTemplate === "layout_10" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <Slides />
                        </div> : this.state.selectedTemplate === "layout_11" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <FooterContactBanner />
                        </div> : this.state.selectedTemplate === "layout_12" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <FrameSec />
                        </div> : this.state.selectedTemplate === "layout_13" ? <div style={{ width: "100%", position: "relative", padding: 30, paddingBottom: 90 }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <BannerSec />
                        </div> : this.state.selectedTemplate === "layout_14" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <BrandsShow />
                        </div> : this.state.selectedTemplate === "layout_15" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <TextTemplate />
                        </div> : this.state.selectedTemplate === "layout_16" ? <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <FaqTemplate />
                        </div> : this.state.selectedTemplate === "layout_17" && <div style={{ width: "100%", position: "relative" }}>
                            <ArrowBackIcon style={{ position: "absolute", right: 10, zIndex: 99999, top: 10, cursor: "pointer" }} onClick={() => {
                                let arr = this.state.arrModals
                                arr.formCreate = false
                                this.setState({ arrModals: arr, selectedTemplate: "" })
                            }} />
                            <AppointmentTemplate />
                        </div>}
                    </div>}
                </div>
                {/* Create New Modal */}

                {/* Edit New Modal */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals.editPopup ? 1 : 0, pointerEvents: this.state.arrModals.editPopup ? "all" : "none" }}>

                </div>
                {this.state.arrModals.editPopup && <div className="modal-new" style={{ opacity: this.state.arrModals.editPopup ? 1 : 0, pointerEvents: this.state.arrModals.editPopup ? "all" : "none", width: this.state.selectedTemplate === "layout_14" ? "90%" : this.state.selectedTemplate === "layout_13" ? "95%" : this.state.selectedTemplate === "layout_11" ? "95%" : this.state.selectedTemplate === "layout_2" && "95%", height: this.state.selectedTemplate === "layout_11" && 700 }}>
                    <ClearIcon style={{ position: "absolute", right: 4, top: 10, cursor: "pointer" }} onClick={() => {
                        let arr = this.state.arrModals
                        arr.editPopup = false
                        this.setState({ arrModals: arr })
                    }} />
                    <div className="create-popup">
                        {this.state.selectedTemplate === "layout_1" ? <div style={{ width: "100%", position: "relative" }}>
                            <DoubleCardEdit />
                        </div> : this.state.selectedTemplate === "layout_2" ? <div style={{ width: "100%", position: "relative" }}>
                            <BannerEdit />
                        </div> : this.state.selectedTemplate === "layout_3" ? <div style={{ width: "100%", position: "relative" }}>
                            <TowTileEdit />
                        </div> : this.state.selectedTemplate === "layout_4" ? <div style={{ width: "100%", position: "relative" }}>
                            <ProductCollectionEdit />
                        </div> : this.state.selectedTemplate === "layout_5" ? <div style={{ width: "100%", position: "relative" }}>
                            <BrandListEdit />
                        </div> : this.state.selectedTemplate === "layout_6" ? <div style={{ width: "100%", position: "relative" }}>
                            <ContactEdit />
                        </div> : this.state.selectedTemplate === "layout_7" ? <div style={{ width: "100%", position: "relative" }}>
                            <HorizontalProductsEdit />
                        </div> : this.state.selectedTemplate === "layout_8" ? <div style={{ width: "100%", position: "relative" }}>
                            <CollectieEdit />
                        </div> : this.state.selectedTemplate === "layout_9" ? <div style={{ width: "100%", position: "relative" }}>
                            <OntdekEdit />
                        </div> : this.state.selectedTemplate === "layout_10" ? <div style={{ width: "100%", position: "relative" }}>
                            <SlidesEdit />
                        </div> : this.state.selectedTemplate === "layout_11" ? <div style={{ width: "100%", position: "relative" }}>
                            <FooterContactBannerEdit />
                        </div> : this.state.selectedTemplate === "layout_12" ? <div style={{ width: "100%", position: "relative" }}>
                            <FrameSecEdit />
                        </div> : this.state.selectedTemplate === "layout_13" ? <div style={{ width: "100%", position: "relative", padding: 30, paddingBottom: 80 }}>
                            <BannerSecEdit />
                        </div> : this.state.selectedTemplate === "layout_14" ? <div style={{ width: "100%", position: "relative" }}>
                            <BrandsShowEdit />
                        </div> : this.state.selectedTemplate === "layout_15" ? <div style={{ width: "100%", position: "relative" }}>
                            <TextTemplateEdit />
                        </div> : this.state.selectedTemplate === "layout_16" ? <div style={{ width: "100%", position: "relative" }}>
                            <FaqTemplateEdit />
                        </div> : this.state.selectedTemplate === "layout_17" && <div style={{ width: "100%", position: "relative" }}>
                            <AppointmentTemplateEdit />
                        </div>}
                    </div>
                </div>}
                {/* Edit Modal */}
                <div className="left-menus-editor">
                    <ul>
                        {this.props.index !== 0 && <li onClick={this.goUp.bind(this, this.props.index)}>
                            <KeyboardArrowUpIcon />
                        </li>}
                        <li className={`${this.state.arrModals.editPopup ? "active" : ""}`} onClick={() => {
                            let arr = this.state.arrModals
                            arr.editPopup = true
                            this.setState({ arrModals: arr, selectedTemplate: this.props.currentTemplate })
                            localStorage.setItem("currentOrder", this.props.index)
                            localStorage.setItem("currentDataEdit", JSON.stringify(this.props.data))
                        }}>
                            <EditIcon />
                        </li>
                        <li className={`${this.state.arrModals.createPopup ? "active" : ""}`} onClick={() => {
                            let arr = this.state.arrModals
                            arr.createPopup = true
                            this.setState({ arrModals: arr })
                            localStorage.setItem("currentOrder", this.props.index)
                        }}>
                            <PlaylistAddIcon />
                        </li>
                        <li onClick={this.deleteSection.bind(this, this.props.data, this.props.index)}>
                            <ClearIcon />
                        </li>
                        {this.props.index < this.props.completeLength && <li onClick={this.goDown.bind(this, this.props.index)}>
                            <KeyboardArrowDownIcon />
                        </li>}
                    </ul>
                </div>
                <Dialog
                    open={this.state.deletePageModal}
                    onClose={() => this.setState({ deletePageModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Weet u zeker dat u deze rij wilt verwijderen?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.deleteSection.bind(this, "no")} color="primary">NEE</Button>
                        <Button onClick={this.deleteSection.bind(this, "yes")} color="primary">JA</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default MenuWorker
