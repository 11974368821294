import React, { Component } from 'react'
import { gv } from './globalvar'
import basket from '../assets/basketiconsec.png'
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CheckIcon from '@material-ui/icons/Check';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ColorizeIcon from '@material-ui/icons/Colorize';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { SketchPicker } from 'react-color'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export class CompleteNavbar extends Component {

    constructor() {
        super()
        this.state = {
            isMobile: false,
            completeData: "",
            dropdownOpacity: 0,
            dropdownPointer: "none",
            dropdownOpacityHorloges: 0,
            dropdownPointerHorloges: "none",
            completeData: "",
            main_menus: [],
            childMenus: [],
            menuHovered: "",
            menuImage: "",
            cartData: "",
            seachValue: "",
            menuHoveredId: "",
            isAdmin: false,
            // arrModals: [
            //     {
            //         colorPopup: false,
            //         createPage: false,
            //     }
            // ],
            // allPagesSettings: [],
            // pageName: "",
            // pageNameAlert: false,
            // pageTitle: "",
            // pageTitleAlert: false,
            // selectedPageSections: [],
            dropdownAdmin: false,
            arrModals: [
                {
                    colorPopup: false,
                    createPage: false,
                }
            ],
            pageName: "",
            pageNameAlert: false,
            pageTitle: "",
            pageTitleAlert: false,
            hideImg: false,
            isMobile: false,
            online_payment: true,
            show_tocp: false,
            menuLeftSize: "14",
            menuSize: "14",
            menuHeight: "70",
            allPagesSettings: [],
            selectedPageSections: [],
            isBigScreen: false,
            menuArr: [],
            wholeDataOfUser: "",
            popupAccount: false,
            inspiratePresent: false,
        }
    }

    componentDidMount() {
        if (window.innerWidth > 768) {
            this.setState({ isMobile: false })
        } else {
            this.setState({ isMobile: true })
        }

        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            let resp = JSON.parse(loginData)
            this.setState({ completeData: resp, logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })
        }

        let checkTOCP = localStorage.getItem("tocp")
        if (checkTOCP) {
            checkTOCP = JSON.parse(checkTOCP)
            if (checkTOCP === true) {
                localStorage.setItem("tocp", true)
                this.setState({ show_tocp: true })
            }
        } else {
            localStorage.setItem("tocp", true)
            this.setState({ show_tocp: false })
        }
        localStorage.setItem("tocp", false)
        fetch(`${gv}/api/v1/list/of/values`)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                if (this.props.sendData) {
                    this.props.sendData(resp)
                }
                localStorage.setItem("headerData", JSON.stringify(resp))
                localStorage.setItem("theme_settings", JSON.stringify(resp.theme_settings))
                this.setState({ completeData: resp, logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })
            })
            .catch(error => console.log('error', error));

        this.getData()

        let accesstoken = localStorage.getItem("adminKey")
        if (accesstoken) {
            this.setState({ isAdmin: true })
        } else {
            this.setState({ isAdmin: false })
        }

        if (this.props.searchText) {
            this.setState({ seachValue: this.props.searchText })
        }
    }


    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/getuser`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    if (this.props.sendDataUser) {
                        this.props.sendDataUser(resp)
                    }
                    this.setState({ wholeDataOfUser: resp })
                })
                .catch(error => console.log('error', error));

            fetch(`${gv}/api/v1/auth/customer/get/cart/items?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    localStorage.setItem("cartNav", JSON.stringify(resp))
                    this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text })
                })
                .catch(error => console.log('error', error));
        } else {
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            if (!localStorage.getItem("guestUser")) {
                fetch(`${gv}/api/v1/get/customer/cookie?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        localStorage.setItem("guestUser", result.data.cart_items_cookie)
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                fetch(`${gv}/api/v1/customer/get/cart/items?browser_type=${browserType}&cart_items_cookie=${cookie_id}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartNav", JSON.stringify(resp))
                        this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text, wholeDataOfUser: "" })
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    removeItem(e) {
        if (e.product_type === "gift") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })

                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    componentWillUpdate(e) {
        if (e.getData) {
            this.getData()
        }
        if (e.getNewDate) {
            this.getData()
        }
    }

    componentWillReceiveProps(e) {
        if (e.refreshData) {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let resp = JSON.parse(headerData)
                this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text })
            }
        }
        if (e.getNewDate) {
            this.getData()
        }
    }

    acceptCookie() {
        let guestUser = localStorage.getItem("guestUser")
        var formdata = new FormData();
        formdata.append("cart_items_cookie", guestUser);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/guest/customer/submit/accept/terms`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("readCookie", true)
                localStorage.setItem("tocp", false)
                this.setState({ show_tocp: false })
            })
            .catch(error => console.log('error', error));
    }

    declineCookie() {
        localStorage.setItem("tocp", false)
        localStorage.setItem("readCookie", false)
        this.setState({ show_tocp: false })
    }


    adminLogout() {
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/logout`, requestOptions)
            .then(response => response.json())
            .then(() => {
                localStorage.setItem("adminKey", "")
                window.location.reload()
            })
            .catch(error => console.log('error', error));
    }

    createNew() {
        const { pageName, pageTitle } = this.state
        if (pageName === "") {
            this.setState({ pageNameAlert: true })
        } else {
            this.setState({ pageNameAlert: false })
        }
        if (pageTitle === "") {
            this.setState({ pageTitleAlert: true })
        } else {
            this.setState({ pageTitleAlert: false })
        }
        if (pageName !== "" && pageTitle !== "") {
            let obj = {}
            if (this.state.selectedPageSections.length > 0) {
                obj = {
                    name: pageTitle,
                    route: `/${pageName}`,
                    sections: [],
                    isActive: false,
                    sections: this.state.selectedPageSections
                }
            } else {
                obj = {
                    name: pageTitle,
                    route: `/${pageName}`,
                    sections: [],
                    isActive: false,
                }
            }

            let dataSettings = localStorage.getItem("allPagesSetting")
            if (dataSettings) {
                let sendingData = JSON.parse(dataSettings)

                let filterName = sendingData.filter((e) => {
                    return e.name === pageTitle
                })

                let filterRoute = sendingData.filter((e) => {
                    return e.route === `/${pageName}`
                })

                if (filterName.length <= 0 && filterRoute.length <= 0) {
                    this.setState({ loaderCreate: true })
                    sendingData.push(obj)

                    let objSec = {
                        name: pageTitle,
                        route: `/${pageName}`,
                        isActive: false,
                        has_sub_menu: "no",
                        is_main_category: "no",
                        show_as: pageTitle,
                    }
                    this.state.menuArr.push(objSec)

                    let stringified = JSON.stringify(sendingData)

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("builder_settings", stringified);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            var formdataSec = new FormData();
                            formdataSec.append("menus_order", JSON.stringify(this.state.menuArr));

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdataSec,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/admin/update/menu/order`, requestOptions)
                                .then(response => response.json())
                                .then(() => {
                                    window.location.href = `/${pageName}`
                                })
                                .catch(error => {
                                    this.setState({ showErrorModal: true })
                                });
                        })
                        .catch(error => {
                            this.setState({ showErrorModal: true })
                        });
                } else {
                    if (filterRoute.length > 0) {
                        this.setState({ routeTaken: true })
                    } else {
                        this.setState({ routeTaken: false })
                    }
                    if (filterName.length > 0) {
                        this.setState({ nameTaken: true })
                    } else {
                        this.setState({ nameTaken: false })
                    }
                }

            }
        }
    }



    // Theme update

    handleChangeComplete = (color) => {
        this.setState({ backgroundHeader: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteFooter = (color) => {
        this.setState({ backgroundFooter: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteButton = (color) => {
        this.setState({ backgroundButton: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteProductF = (color) => {
        this.setState({ productDetailFirstColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteProductS = (color) => {
        this.setState({ productDetailSecondColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenu = (color) => {
        this.setState({ backgroundMenu: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenuF = (color) => {
        this.setState({ menuFontColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenuI = (color) => {
        this.setState({ menuIconColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteLeftMenuF = (color) => {
        this.setState({ leftmenufontcolour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSaleLabel = (color) => {
        this.setState({ saleLabelColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMobile = (color) => {
        this.setState({ menuIconMobile: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteBrandPage = (color) => {
        this.setState({ brandPageColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteOverlayColor = (color) => {
        this.setState({ overlayColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteOverlayTextColor = (color) => {
        this.setState({ overlayTextColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteCloseColor = (color) => {
        this.setState({ menuIconCloseColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftTitle = (color) => {
        this.setState({ giftCardTitleColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftDescription = (color) => {
        this.setState({ giftCardDescriptionColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftCardCart = (color) => {
        this.setState({ giftCardCartColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftCardButton = (color) => {
        this.setState({ giftCardButtonsColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    updateTheme() {
        const { backgroundHeader, backgroundFooter, backgroundButton, backgroundMenu } = this.state

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        let obj = {
            header_background_color: backgroundHeader,
            footer_background_color: backgroundFooter,
            button_background_color: backgroundButton,
            menu_background_color: backgroundMenu,
            body_font_famiy: this.state.bodyFont,
            title_font_family: this.state.titleFont,
            product_detail_colour: this.state.productDetailFirstColour,
            product_detail_right_colour: this.state.productDetailSecondColour,
            menu_icon_colour: this.state.menuIconColour,
            menu_font_colour: this.state.menuFontColour,
            left_menu_font_colour: this.state.leftmenufontcolour,
            left_menu_font_size: this.state.menuLeftSize,
            menu_font_size: this.state.menuSize,
            menu_height: this.state.menuHeight,
            sale_label_colour: this.state.saleLabelColour,
            menu_icon_mobile: this.state.menuIconMobile,
            brand_page_colour: this.state.brandPageColour,
            overlay_color: this.state.overlayColor,
            overlay_text_color: this.state.overlayTextColor,
            background_opacity: this.state.backgroundOpacity,
            menu_close_colour: this.state.menuIconCloseColor,
            gift_card_buttons_color: this.state.giftCardButtonsColor,
            gift_card_cart_color: this.state.giftCardCartColor,
            gift_card_title_color: this.state.giftCardTitleColor,
            gift_card_desc_color: this.state.giftCardDescriptionColor,
        }

        var formdata = new FormData();
        formdata.append("theme_settings", JSON.stringify(obj));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/theme/settings`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                localStorage.setItem("theme_settings", JSON.stringify(result.data.theme_settings))
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }


    submitFunction(e) {
        e.preventDefault();
        return false;
    }


    logourAccount() {
        let usertoken = localStorage.getItem("usertoken")

        if (usertoken !== "") {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/logout`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "Successfully logged out.") {
                        localStorage.setItem("usertoken", "")
                        window.location.href = "/"
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div>
                {this.state.isMobile ? <div style={{ backgroundColor: "white" }} className="hidingfooter">
                    <div className="navbar-main-sec-other">
                        {this.state.popupright && this.state.completeData.cart_type === "popup" && <div className="cart-work-popup">
                            {this.state.cartData && this.state.cartData.cart_items.length > 0 ? this.state.cartData.cart_items.map((e) => {
                                return <div className="minicart-product" key={Math.random()} style={{ borderTop: 0 }}>
                                    <div className="mini-cartimage" onClick={() => {
                                        if (e.product_type === "product") {
                                            window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                        }
                                    }}>
                                        <img src={e.image_url} />
                                    </div>
                                    <div className="mini-cartinfo">
                                        <div className="mini-cart-small-title" onClick={() => {
                                            if (e.product_type === "product") {
                                                window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                            }
                                        }}>
                                            <font>{e.name}</font>
                                        </div>
                                        <div className="mini-cart-name" onClick={() => {
                                            if (e.product_type === "product") {
                                                window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                            }
                                        }}>
                                            <a>{e.plain_sku}</a>
                                            <br />
                                            <p>{e.title}</p>
                                        </div>
                                        <div className="mini-cart-details-bottom">
                                            <div className="mini-cart-pricing">
                                                <font onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>Aantal: {e.qty}</font>
                                                <br />
                                                <font style={{ cursor: "pointer" }} onClick={this.removeItem.bind(this, e)}>Verwijder item</font>
                                            </div>
                                            <div className="mini-cart-price-wrapper" onClick={() => {
                                                if (e.product_type === "product") {
                                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                }
                                            }}>
                                                {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <span className="mini-cart-price"> € {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }) : <p style={{ marginBottom: 0 }}>U heeft geen product(en) in uw winkelwagen.</p>}
                            {this.state.cartData && this.state.cartData.cart_items.length > 0 && <div className="bottom-cwp">
                                <div className="total-bcwp">
                                    <p>TOTAAL</p>
                                    <p>{this.state.cartData.total_retail_price}</p>
                                </div>
                                <div className="buttons-bcwp">
                                    <button onClick={() => {
                                        window.location.href = "/"
                                    }}>Verder winkelen</button>
                                    <button onClick={() => window.location.href = "/cart"}>Verder met bestellen</button>
                                </div>
                            </div>}
                        </div>}
                        <div className="top-nmso-mobile">
                            {this.state.completeData.own_logo ? <div className="logo-tnmm">
                                <img src={this.state.completeData.logo_url} onClick={() => window.location.href = "/"} />
                            </div> : <div className="logo-tnmm">
                                <p>{this.state.completeData.retailer_name}</p>
                                <img src={this.state.completeData.logo_url} onClick={() => window.location.href = "/"} />
                            </div>}
                            <div className="content-tnmm">
                                {this.state.completeData.onliny_payment && <div className="icon-ctnmm" onClick={() => {
                                    this.setState({ popupright: !this.state.popupright });
                                    document.getElementById("mainbody").style.width = "100%"
                                    document.getElementById("mainbody").style.position = "fixed"
                                }}>
                                    <div className="basket-icon" style={{ right: -3 }}>
                                        <span>{this.state.cartData ? this.state.cartData.total_products : 0}</span>
                                    </div>
                                    <img src={basket} className="cart-icon" />
                                    <p>
                                        Mandje
                                    </p>
                                </div>}
                                {this.state.completeData.onliny_payment && <div className="icon-ctnmm" onClick={() => {
                                    let usertoken = localStorage.getItem("usertoken")
                                    if (usertoken) {
                                        window.location.href = "/account"
                                    } else {
                                        window.location.href = "/login"
                                    }
                                }}>
                                    <PermIdentityOutlinedIcon />
                                    <p>Account</p>
                                </div>}
                                {this.state.menuOpen ? <div className="icon-ctnmm" onClick={() => {
                                    document.getElementById("root").style.position = "static"
                                    document.getElementById("root").style.overflowY = "auto"
                                    this.setState({ menuOpen: false, nestedMenuOpen: false })
                                }}>
                                    <CloseIcon />
                                    <p>Close</p>
                                </div> : <div className="icon-ctnmm" onClick={() => {
                                    document.getElementById("root").style.position = "fixed"
                                    document.getElementById("root").style.overflowY = "hidden"
                                    document.getElementById("root").style.width = "100%"
                                    this.setState({ menuOpen: true })
                                }}>
                                    <MenuIcon />
                                    <p>Menu</p>
                                </div>}
                            </div>
                        </div>
                        <div className="bottom-nmso-mobile" style={{ position: this.state.menuOpen && "fixed", top: this.state.menuOpen && "90px", height: this.state.menuOpen && "100%", paddingBottom: this.state.menuOpen && 120 }}>
                            <div className="input-nmsom">
                                <form action="/" onSubmit={this.submitFunction.bind(this)}>
                                    <input type="search" name="search" placeholder="Waar ben je naar op zoek?" value={this.state.seachValue} onChange={(e) => this.setState({ seachValue: e.target.value })} onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            window.location.href = `/searches/${this.state.seachValue}`
                                        }
                                    }} />
                                </form>
                                <SearchIcon onClick={(e) => {
                                    window.location.href = `/searches/${this.state.seachValue}`
                                }} />
                            </div>
                            {this.state.nestedMenuOpen ? <div className="lists-menu-mobile nestedmobilemenu">
                                <button onClick={() => this.setState({ childMenus: [], menuHovered: "", nestedMenuOpen: false })}><ArrowBackIosIcon />  Terug Naar Home</button>
                                <ul>
                                    <li onClick={() => {
                                        if (!this.state.otherPage) {
                                            window.location.href = `/hoofcategorie/${this.state.menuHoveredId}/products`
                                        }
                                    }}>{this.state.menuHovered}</li>
                                    {this.state.childMenus.length > 0 && this.state.childMenus.map((g) => {
                                        return g[1].map((h) => {
                                            return <li onClick={() => {
                                                if (this.state.otherPage) {
                                                    window.location.href = `${h.route}`
                                                } else {
                                                    if (this.state.menuHoveredId === h.id) {
                                                        window.location.href = `/hoofcategorie/${this.state.menuHoveredId}/products`
                                                    } else {
                                                        window.location.href = `/${this.state.menuHoveredId}/${h.id}`
                                                    }
                                                }
                                            }}>{h.name}</li>
                                        })
                                    })}
                                </ul>
                            </div> : this.state.menuOpen && <div className="lists-menu-mobile">
                                <ul>
                                    <li onClick={() => window.location.href = "/"}>HOME</li>
                                    {this.state.main_menus.length > 0 && this.state.main_menus.map((g) => {
                                        if (g.isActive) {
                                            return <li onClick={() => {
                                                if (g.has_sub_menu == "no") {
                                                    if (g.id === "giftcard") {
                                                        window.location.href = "/giftcard"
                                                    } else if (g.id === "uitverkoop") {
                                                        window.location.href = "/sales"
                                                    } else {
                                                        window.location.href = g.route
                                                    }
                                                } else {
                                                    if (g.is_main_category === "yes") {
                                                        let child_menus = this.state.child_menus
                                                        let filtered = child_menus.filter((h) => {
                                                            return h[0] === g.id
                                                        })
                                                        if (filtered.length > 0) {
                                                            let objected = Object.entries(filtered[0][1])
                                                            this.setState({ childMenus: objected, otherPage: false, menuHovered: g.name, menuHoveredId: g.id, nestedMenuOpen: true })
                                                        } else {
                                                            if (g.has_sub_menu === "no") {
                                                                window.location.href = `${g.route}`
                                                            } else if (g.id === "giftcard") {
                                                                window.location.href = "/giftcard"
                                                            } else if (g.id === "uitverkoop") {
                                                                window.location.href = "/sales"
                                                            }
                                                        }
                                                    } else {
                                                        let child_menus = this.state.child_menus
                                                        let filtered = child_menus.filter((h) => {
                                                            return h[0] === g.id
                                                        })
                                                        if (filtered.length > 0) {
                                                            this.setState({ childMenus: filtered, otherPage: true, menuHovered: g.name, menuHoveredId: g.id, nestedMenuOpen: true })
                                                        }
                                                    }
                                                }
                                            }}>{g.name}</li>
                                        }
                                    })}
                                </ul>
                            </div>}
                        </div>
                    </div>
                </div> : <div style={{ backgroundColor: "white", position: "relative" }} className="hidingfooter">
                    <div className="navbar-main-sec-other">
                        <div className="top-nmso">
                            <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_one}</span>
                            <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_two}</span>
                            <span><CheckIcon /> {this.state.completeData.dealer_top_header_title_three}</span>
                        </div>
                        <div className="mid-nmso">
                            <div className="container-midnmso">
                                <div className="left-cmidnmso">
                                    <p>Vragen? Bel: <font><a href={`tel:${this.state.phone}`}>{this.state.completeData.phone}</a></font> {this.state.completeData.whatsup_number && <font>| Whatsapp: <font><a href={`whatsapp://send?phone=${this.state.completeData.whatsup_number}`}>{this.state.completeData.whatsup_number}</a></font></font>}</p>
                                    <div className="search-lcmid">
                                        <input type="text" placeholder={this.state.completeData.search_text} value={this.state.seachValue} onChange={(e) => this.setState({ seachValue: e.target.value })} onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                window.location.href = `/searches/${this.state.seachValue}`
                                            }
                                        }} />
                                        <SearchIcon onClick={(e) => {
                                            window.location.href = `/searches/${this.state.seachValue}`
                                        }} />
                                    </div>
                                </div>
                                {this.state.completeData.own_logo ? <div className="mid-cmidnmso">
                                    <img src={this.state.completeData.logo_url} onClick={() => window.location.href = "/"} style={{ maxHeight: 300 }} />
                                </div> : <div className="mid-cmidnmso">
                                    <p>{this.state.completeData.retailer_name}</p>
                                    <img src={this.state.completeData.logo_url} onClick={() => window.location.href = "/"} style={{ maxHeight: 300 }} />
                                </div>}
                                <div className="right-cmidnmso" style={{ zIndex: !this.state.popupright && 999, position: "relative" }}>
                                    {this.state.isAdmin && <p onClick={() => {
                                        this.setState({ dropdownAdmin: !this.state.dropdownAdmin })
                                    }}>Edit</p>}
                                    {this.state.completeData.onliny_payment && this.state.wholeDataOfUser ? <p onClick={() => {
                                        this.setState({ popupAccount: !this.state.popupAccount })
                                    }}>{this.state.wholeDataOfUser.name} <ArrowDropDownIcon /> </p> : this.state.completeData.onliny_payment && <p onClick={() => {
                                        window.location.href = "/login"
                                    }}>Mijn account</p>}
                                    {this.state.completeData.onliny_payment && <p style={{ position: "relative" }} onClick={() => {
                                        this.setState({ popupright: !this.state.popupright });
                                        document.getElementById("mainbody").style.width = "100%"
                                        document.getElementById("mainbody").style.position = "fixed"
                                    }}>Winkelmand <img src={basket} className="cart-icon" />
                                        <div className="basket-icon">
                                            <span>{this.state.cartData ? this.state.cartData.total_products : 0}</span>
                                        </div>
                                    </p>}
                                    {this.state.popupright && this.state.completeData.cart_type === "popup" && <div className="cart-work-popup">
                                        {this.state.cartData && this.state.cartData.cart_items.length > 0 ? this.state.cartData.cart_items.map((e) => {
                                            console.log(e)
                                            return <div className="minicart-product" key={Math.random()} style={{ borderTop: 0 }}>
                                                <div className="mini-cartimage" onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>
                                                    <img src={e.image_url} />
                                                </div>
                                                <div className="mini-cartinfo">
                                                    <div className="mini-cart-small-title" onClick={() => {
                                                        if (e.product_type === "product") {
                                                            window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                        }
                                                    }}>
                                                        <font>{e.name}</font>
                                                    </div>
                                                    <div className="mini-cart-name" onClick={() => {
                                                        if (e.product_type === "product") {
                                                            window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                        }
                                                    }}>
                                                        <a>{e.plain_sku}</a>
                                                        <br />
                                                        <p>{e.title}</p>
                                                    </div>
                                                    <div className="mini-cart-details-bottom">
                                                        <div className="mini-cart-pricing">
                                                            <font onClick={() => {
                                                                if (e.product_type === "product") {
                                                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                                }
                                                            }}>Aantal: {e.qty}</font>
                                                            <br />
                                                            <font style={{ cursor: "pointer" }} onClick={this.removeItem.bind(this, e)}>Verwijder item</font>
                                                        </div>
                                                        <div className="mini-cart-price-wrapper" onClick={() => {
                                                            if (e.product_type === "product") {
                                                                window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                            }
                                                        }}>
                                                            {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <span className="mini-cart-price"> € {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }) : <p style={{ marginBottom: 0 }}>U heeft geen product(en) in uw winkelwagen.</p>}
                                        {this.state.cartData && this.state.cartData.cart_items.length > 0 && <div className="bottom-cwp">
                                            <div className="total-bcwp">
                                                <p>TOTAAL</p>
                                                <p>{this.state.cartData.total_retail_price}</p>
                                            </div>
                                            <div className="buttons-bcwp">
                                                <button onClick={() => {
                                                    window.location.href = "/"
                                                }}>Verder winkelen</button>
                                                <button onClick={() => window.location.href = "/cart"}>Verder met bestellen</button>
                                            </div>
                                        </div>}
                                    </div>}
                                </div>
                            </div>
                        </div>

                        {this.state.dropdownAdmin && <div className="dropdown-navforadmin" style={{ top: 90, right: 180 }}>
                            <ul>
                                <li onClick={() => window.location.href = "/menu-sorting"}>
                                    <MenuIcon />
                                    <font>Menu</font>
                                </li>
                                <li onClick={() => {
                                    let accesstoken = localStorage.getItem("adminKey")

                                    if (accesstoken) {
                                        var myHeaders = new Headers();
                                        myHeaders.append("accesstoken", accesstoken);

                                        var requestOptions = {
                                            method: 'GET',
                                            headers: myHeaders,
                                            redirect: 'follow'
                                        };

                                        fetch(`${gv}/api/v1/admin/get/menu/order`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                this.setState({ menuArr: result.data.menus_order })
                                            })
                                            .catch(error => console.log('error', error));


                                        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
                                            .then(response => response.json())
                                            .then(result => {
                                                this.setState({ allPagesSettings: result.data.other_pages })
                                                localStorage.setItem("allPagesSetting", JSON.stringify(result.data.other_pages))
                                            })
                                            .catch(error => console.log('error', error));
                                    }

                                    let arr = this.state.arrModals
                                    arr[0].createPage = true
                                    this.setState({ arrModals: arr, dropdownAdmin: false })
                                }}>
                                    <PostAddIcon />
                                    <font>Create Page</font>
                                </li>
                                <li onClick={() => {
                                    let accesstoken = localStorage.getItem("adminKey")

                                    var myHeaders = new Headers();
                                    myHeaders.append("accesstoken", accesstoken);

                                    var requestOptions = {
                                        method: 'GET',
                                        headers: myHeaders,
                                        redirect: 'follow'
                                    };

                                    fetch(`${gv}/api/v1/admin/get/theme/settings`, requestOptions)
                                        .then(response => response.json())
                                        .then(result => {
                                            let theme_settings = result.data.theme_settings
                                            localStorage.setItem("theme_settings", JSON.stringify(theme_settings))
                                            this.setState({
                                                backgroundHeader: theme_settings.header_background_color,
                                                backgroundFooter: theme_settings.footer_background_color,
                                                backgroundButton: theme_settings.button_background_color,
                                                titleFont: theme_settings.title_font_family,
                                                bodyFont: theme_settings.body_font_famiy,
                                                productDetailFirstColour: theme_settings.product_detail_colour,
                                                productDetailSecondColour: theme_settings.product_detail_right_colour,
                                                backgroundMenu: theme_settings.menu_background_color,
                                                menuFontColour: theme_settings.menu_font_colour,
                                                menuIconColour: theme_settings.menu_icon_colour,
                                                leftmenufontcolour: theme_settings.left_menu_font_colour,
                                                menuLeftSize: theme_settings.left_menu_font_size,
                                                menuSize: theme_settings.menu_font_size,
                                                menuHeight: theme_settings.menu_height,
                                                saleLabelColour: theme_settings.sale_label_colour,
                                                menuIconMobile: theme_settings.menu_icon_mobile,
                                                brandPageColour: theme_settings.brand_page_colour,
                                                overlayColor: theme_settings.overlay_color,
                                                overlayTextColor: theme_settings.overlay_text_color,
                                                backgroundOpacity: theme_settings.background_opacity,
                                                menuIconCloseColor: theme_settings.menu_close_colour,
                                                giftCardButtonsColor: theme_settings.gift_card_buttons_color,
                                                giftCardCartColor: theme_settings.gift_card_cart_color,
                                                giftCardTitleColor: theme_settings.gift_card_title_color,
                                                giftCardDescriptionColor: theme_settings.gift_card_desc_color
                                            })
                                        })
                                        .catch(error => console.log('error', error));

                                    let arr = this.state.arrModals
                                    arr[0].colorPopup = true
                                    this.setState({ arrModals: arr, dropdownAdmin: false })
                                }}>
                                    <ColorizeIcon />
                                    <font>Theme settings</font>
                                </li>
                                <li onClick={this.adminLogout.bind(this)} >
                                    <ExitToAppIcon />
                                    <font>Logout</font>
                                </li>
                            </ul>
                        </div>}
                        {this.state.popupAccount && <div className="dropdown-navforadmin" style={{ top: 100, right: 190 }}>
                            <ul>
                                <li onClick={() => window.location.href = "/account"}>
                                    <MenuIcon />
                                    <font>Dashboard</font>
                                </li>
                                <li onClick={this.logourAccount.bind(this)} >
                                    <ExitToAppIcon />
                                    <font>Logout</font>
                                </li>
                            </ul>
                        </div>}
                        {this.state.dropdownAdmin && <div className='closer-nmso' onMouseEnter={() => this.setState({ dropdownAdmin: false })}>

                        </div>}
                        {this.state.popupAccount && <div className='closer-nmso' onMouseEnter={() => this.setState({ popupAccount: false })}>

                        </div>}
                        {this.state.menuHovered && <div className='closer-nmso' onMouseOver={() => this.setState({ childMenus: [], menuHovered: "", menuImage: "", dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })}>

                        </div>}
                        <div className="lists-nmso">
                            <ul>
                                {this.state.main_menus.length > 0 && this.state.main_menus.map((g) => {
                                    if (g.isActive) {
                                        return <li key={Math.random()} onMouseEnter={() => {
                                            if (g.is_main_category === "yes") {
                                                let child_menus = this.state.child_menus
                                                let filtered = child_menus.filter((h) => {
                                                    return h[0] === g.id
                                                })
                                                if (g.id === "horloges") {
                                                    let objected = Object.entries(filtered[0][1])
                                                    let filteredInspiratie = objected.filter((i) => {
                                                        return i[0] === "INSPIRATIE"
                                                    })
                                                    if (filteredInspiratie.length > 0) {
                                                        this.setState({ inspiratePresent: true })
                                                    }
                                                    this.setState({ childMenus: objected, menuHovered: g.id, menuImage: g.image_url, dropdownPointer: "none", dropdownOpacity: 0, dropdownPointerHorloges: "all", dropdownOpacityHorloges: 1 })
                                                } else {
                                                    if (filtered.length > 0) {
                                                        let objected = Object.entries(filtered[0][1])
                                                        this.setState({ childMenus: objected, otherPage: false, menuHovered: g.id, menuImage: g.image_url, dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                                                    } else {
                                                        this.setState({ childMenus: [], otherPage: false, menuHovered: g.id, menuImage: "", dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })
                                                    }
                                                }
                                            } else {
                                                let child_menus = this.state.child_menus
                                                let filtered = child_menus.filter((h) => {
                                                    return h[0] === g.id
                                                })
                                                if (filtered.length > 0) {
                                                    this.setState({ childMenus: filtered, otherPage: true, menuHovered: g.id, menuImage: g.image_url, dropdownPointer: "all", dropdownOpacity: 1, dropdownPointerHorloges: "none", dropdownOpacityHorloges: 0 })
                                                } else {
                                                    this.setState({ childMenus: [], otherPage: false, menuHovered: g.id, menuImage: "", dropdownPointer: "none", dropdownOpacity: 0, dropdownOpacityHorloges: 0, dropdownPointerHorloges: "none" })
                                                }
                                            }
                                        }} onClick={() => {
                                            if (g.route) {
                                                window.location.href = `${g.route}`
                                            } else if (g.id === "giftcard") {
                                                window.location.href = "/giftcard"
                                            } else if (g.id === "uitverkoop") {
                                                window.location.href = "/sales"
                                            } else {
                                                window.location.href = `/hoofcategorie/${g.id}/products`
                                            }
                                        }} className={this.state.menuHovered === g.id ? "active" : ""} > {g.show_as}</li>
                                    }
                                })}
                            </ul>
                        </div>
                        <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacity, pointerEvents: this.state.dropdownPointer }}>
                            {/* <CloseIcon onClick={() => {
                                this.setState({ dropdownPointer: "none", dropdownOpacity: 0 })
                            }} style={{ position: "absolute", top: 10, right: 10 }} /> */}
                            {this.state.childMenus.length > 0 && this.state.childMenus.map((h) => {
                                return <div className="inner-dropdown-list-nmso" key={Math.random()}>
                                    <ul>
                                        <li>{h[0]}</li>
                                        {h[1].map((y) => {
                                            return <li onClick={() => {
                                                if (this.state.otherPage) {
                                                    window.location.href = `${y.route}`
                                                } else {
                                                    if (this.state.menuHovered === y.id) {
                                                        window.location.href = `/hoofcategorie/${this.state.menuHovered}/products`
                                                    } else {
                                                        window.location.href = `/${this.state.menuHovered}/${y.id}`
                                                    }
                                                }
                                            }}>{y.name}</li>
                                        })}
                                    </ul>
                                </div>
                            })}
                            {!this.state.otherPage && <div className="inner-dropdown-list-nmso">
                                <img src={this.state.menuImage} />
                                <p className="desc-idln">Bekijk alle {this.state.menuHovered}</p>
                                <button onClick={() => {
                                    window.location.href = `/hoofcategorie/${this.state.menuHovered}/products`
                                }}>SHOP NU</button>
                            </div>}
                        </div>
                        <div className="dropdown-lists-nmso" style={{ opacity: this.state.dropdownOpacityHorloges, pointerEvents: this.state.dropdownPointerHorloges, paddingBottom: this.state.inspiratePresent && 120 }}>
                            {/* <CloseIcon onClick={() => {
                                this.setState({ dropdownPointerHorloges: "none", dropdownOpacity: 0 })
                            }} style={{ position: "absolute", top: 10, right: 10 }} /> */}
                            {this.state.childMenus.length > 0 && this.state.childMenus.map((h) => {
                                console.log(h)
                                return <div className="inner-dropdown-list-nmso" key={Math.random()} style={{ position: h[0] === "INSPIRATIE" && "absolute", left: "9.3%", top: 110, width: h[0] === "INSPIRATIE" && 189 }}>
                                    <ul>
                                        <li>{h[0]}</li>
                                        {h[1].map((y) => {
                                            if (y.image_url) {
                                                return <li onClick={() => {
                                                    if (this.state.menuHovered === y.id) {
                                                        window.location.href = `/hoofcategorie/${this.state.menuHovered}/products`
                                                    } else {
                                                        window.location.href = `/${this.state.menuHovered}/${y.id}`
                                                    }
                                                    // window.location.href = `/${this.state.menuHovered}/${y.id}`
                                                }} style={{ display: "inline-block", textAlign: "center", margin: 10 }}>
                                                    <img src={y.image_url} style={{ maxWidth: 60 }} />
                                                    <p>{y.name}</p>
                                                </li>
                                            } else {
                                                return <li onClick={() => {
                                                    if (this.state.menuHovered === y.id) {
                                                        window.location.href = `/hoofcategorie/${this.state.menuHovered}/products`
                                                    } else {
                                                        window.location.href = `/${this.state.menuHovered}/${y.id}`
                                                    }
                                                    // window.location.href = `/${this.state.menuHovered}/${y.id}`
                                                }}>
                                                    <font>{y.name}</font>
                                                </li>
                                            }
                                        })}
                                    </ul>
                                </div>
                            })}
                            <div className="inner-dropdown-list-nmso">
                                <img src={this.state.menuImage} />
                                <p className="desc-idln">Bekijk alle {this.state.menuHovered}</p>
                                <button onClick={() => {
                                    window.location.href = `/hoofcategorie/${this.state.menuHovered}/products`
                                }}>SHOP NU</button>
                            </div>
                        </div>
                    </div>
                </div >}
                {
                    this.state.popupright && <div className="bg-overlaymini" style={{ zIndex: 9999999 }} onClick={() => {
                        this.setState({ popupright: false });
                        document.getElementById("mainbody").style.position = "static"
                    }}>
                    </div>
                }
                {this.state.popupright && this.state.completeData.cart_type === "side_menu" && <div className={`mnicart-flyout-inner ${this.state.popupright ? 'open' : null}`} style={{ zIndex: 99999999, maxWidth: 620 }}>
                    <div className="checkout-flyout-inner-content">
                        <div className="flyout-section">
                            <div className="minicart-flyout-wrapper">
                                <CloseIcon style={{ position: "absolute", cursor: "pointer", top: 34, fontSize: 24, left: 27 }} onClick={() => {
                                    this.setState({ popupright: false });
                                    document.getElementById("mainbody").style.position = "static"
                                }} />
                                <div className="minicart-flyout-header">
                                    <div className="centered">
                                        <h3>{this.state.cartData.cart_heading_1}</h3>
                                        {this.state.cartData && <span>{this.state.cartData.total_products} product</span>}
                                    </div>
                                </div>
                                <div className="flyout-body">
                                    {this.state.cartData.cart_items && this.state.cartData.cart_items.map((e) => {
                                        return <div className="minicart-product" key={Math.random()}>
                                            <div className="mini-cartimage" onClick={() => {
                                                if (e.product_type === "product") {
                                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                }
                                            }}>
                                                <img src={e.image_url} />
                                            </div>
                                            <div className="mini-cartinfo">
                                                <div className="mini-cart-small-title" onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>
                                                    <font>{e.name}</font>
                                                </div>
                                                <div className="mini-cart-name" onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>
                                                    <a>{e.plain_sku}</a>
                                                    <br />
                                                    <p>{e.title}</p>
                                                </div>
                                                <div className="mini-cart-details-bottom">
                                                    <div className="mini-cart-pricing">
                                                        <font onClick={() => {
                                                            if (e.product_type === "product") {
                                                                window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                            }
                                                        }}>Aantal: {e.qty}</font>
                                                        <br />
                                                        <font style={{ cursor: "pointer" }} onClick={this.removeItem.bind(this, e)}>Verwijder item</font>
                                                    </div>
                                                    <div className="mini-cart-price-wrapper" onClick={() => {
                                                        if (e.product_type === "product") {
                                                            window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                        }
                                                    }}>
                                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <span className="mini-cart-price"> € {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    {!!this.state.cartData.order_gift_url && <div className="gwp-minicart-wrapper">
                                        <div className="gwp-cart-banner">
                                            <div className="gwp-banner-progress-holder">
                                                <span style={{ width: "25%" }}></span>
                                            </div>
                                            <div className="gwp-cart-banner-title">
                                                <h4>{this.state.cartData.cart_heading_2}</h4>
                                            </div>
                                            <div className="gwp-cart-banner-image">
                                                <img src={this.state.cartData.order_gift_url} />
                                            </div>
                                            <div className="gwp-cart-banner-content">
                                                <p className="the-ritual-title">
                                                    {this.state.cart_desc}
                                                </p>
                                                <p style={{ marginTop: 10 }}>{this.state.cartData.cart_desc1}</p>
                                            </div>
                                            <div className="gwp-cart-banner-cta">
                                                {!this.state.userLogedIn && <a href="/login">Log in or sign up</a>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {this.state.popupright && <div className="minicart-flyout-checkout" style={{ maxWidth: "100%" }}>
                            <div className="total">
                                <div className="total-line">
                                    <span className="total-label">
                                        Totaal
                                        <span className="free-delivery-message" onClick={() => this.setState({ free_deliveryInput: true })} onBlur={() => this.setState({ free_deliveryInput: false })}>
                                            Delivery time {this.state.cartData.delivery_time} <br /> {this.state.free_delivery}
                                        </span>
                                    </span>
                                    <span className="total-value">
                                        € {this.state.cartData.total_retail_price}
                                    </span>
                                </div>
                            </div>
                            <div className="buttons-total">
                                <button className="btn-continue" onClick={() => {
                                    window.location.href = "/"
                                }}>Verder winkelen</button>
                                {this.state.cartData.cart_items && this.state.cartData.cart_items.length > 0 && <button className="btn-cart" onClick={() => window.location.href = "/cart"}>Verder met bestellen</button>}
                            </div>
                        </div>}
                    </div>
                </div>}
                {/* Create New Page */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals[0].createPage ? 1 : 0, pointerEvents: this.state.arrModals[0].createPage ? "all" : "none" }}>

                </div>
                {
                    this.state.arrModals[0].createPage && <div className="modal-new" style={{ opacity: this.state.arrModals[0].createPage ? 1 : 0, pointerEvents: this.state.arrModals[0].createPage ? "all" : "none" }}>
                        <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                            let arr = this.state.arrModals
                            arr[0].createPage = false
                            this.setState({ arrModals: arr, gettingDataHP: true, gettingDataPC: true, gettingDataBL: true, gettingDataContact: true })
                        }} />
                        {this.state.loaderCreate ? <div className="loader-working-fb" style={{ position: "static", minHeight: 400, display: "flex", justifyContent: "center", alignItems: "center", transform: "none" }}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div> : <div className="create-popup">
                            <div className="form-create-page">
                                <div>
                                    <label>Page Name</label>
                                    <input className="bodyText" type="text" style={{ fontFamily: "Roboto" }} value={this.state.pageName} onChange={(e) => {
                                        this.setState({ pageName: e.target.value, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                    }} />
                                    {this.state.pageNameAlert && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Please enter page name</p>}
                                    {this.state.routeTaken && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Page name already taken!</p>}
                                </div>
                                <div>
                                    <label>Page Title</label>
                                    <input className="bodyText" type="text" style={{ fontFamily: "Roboto" }} value={this.state.pageTitle} onChange={(e) => {
                                        this.setState({ pageTitle: e.target.value, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                    }} />
                                    {this.state.pageTitleAlert && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Please enter page title</p>}
                                    {this.state.nameTaken && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Page title already taken!</p>}
                                </div>
                                <div>
                                    <label>Copy from Page</label>
                                    <select onChange={(e) => {
                                        if (e.target.value !== "select") {
                                            this.setState({ selectedPageSections: JSON.parse(e.target.value) })
                                        } else {
                                            this.setState({ selectedPageSections: [] })
                                        }
                                    }}>
                                        <option value="select">Select Page</option>
                                        {this.state.allPagesSettings.length > 0 && this.state.allPagesSettings.map((e) => {
                                            if (e.sections.length > 0) {
                                                return <option value={JSON.stringify(e.sections)}>{e.name}</option>
                                            }
                                        })}
                                    </select>
                                </div>
                                <div>
                                    <label>{this.state.domainName}/{this.state.pageName}</label>
                                </div>
                                <div onClick={this.createNew.bind(this)}>
                                    <button>Save</button>
                                </div>
                            </div>
                        </div>}
                    </div>
                }
                {/* Colour Picker Page */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals[0].colorPopup ? 1 : 0, pointerEvents: this.state.arrModals[0].colorPopup ? "all" : "none" }}>

                </div>
                {
                    this.state.arrModals[0].colorPopup && <div className="modal-new" style={{ opacity: this.state.arrModals[0].colorPopup ? 1 : 0, pointerEvents: this.state.arrModals[0].colorPopup ? "all" : "none", zIndex: 9999999999999999999999999 }}>
                        <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                            let arr = this.state.arrModals
                            arr[0].colorPopup = false
                            this.setState({ arrModals: arr, gettingDataHP: true, gettingDataPC: true, gettingDataBL: true, gettingDataContact: true })
                        }} />
                        <div className="create-popup color-worker">
                            <div className="color-working-popup">
                                <div className="first-div-cwp">
                                    <label>Header Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundHeader}
                                        onChangeComplete={this.handleChangeComplete}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Footer Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundFooter}
                                        onChangeComplete={this.handleChangeCompleteFooter}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Buttons Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundButton}
                                        onChangeComplete={this.handleChangeCompleteButton}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Background Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundMenu}
                                        onChangeComplete={this.handleChangeCompleteMenu}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Product Detail Page Colour 1</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.productDetailFirstColour}
                                        onChangeComplete={this.handleChangeCompleteProductF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Product Detail Page Colour 2</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.productDetailSecondColour}
                                        onChangeComplete={this.handleChangeCompleteProductS}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Font Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuFontColour}
                                        onChangeComplete={this.handleChangeCompleteMenuF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Icon Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconColour}
                                        onChangeComplete={this.handleChangeCompleteMenuI}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Left Menu Font Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.leftmenufontcolour}
                                        onChangeComplete={this.handleChangeCompleteLeftMenuF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Sale Label Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.saleLabelColour}
                                        onChangeComplete={this.handleChangeCompleteSaleLabel}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Icon Mobile Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconMobile}
                                        onChangeComplete={this.handleChangeCompleteMobile}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Brandpage-color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.brandPageColour}
                                        onChangeComplete={this.handleChangeCompleteBrandPage}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Overlay Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.overlayColor}
                                        onChangeComplete={this.handleChangeCompleteOverlayColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Overlay Text colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.overlayTextColor}
                                        onChangeComplete={this.handleChangeCompleteOverlayTextColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Close Icon Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconCloseColor}
                                        onChangeComplete={this.handleChangeCompleteCloseColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Title Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardTitleColor}
                                        onChangeComplete={this.handleChangeCompleteGiftTitle}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Description Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardDescriptionColor}
                                        onChangeComplete={this.handleChangeCompleteGiftDescription}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Buttons Background Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardButtonsColor}
                                        onChangeComplete={this.handleChangeCompleteGiftCardButton}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card In Cart background Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardCartColor}
                                        onChangeComplete={this.handleChangeCompleteGiftCardCart}
                                    />
                                </div>
                            </div>
                            <div className="font-family-selector">
                                <div>
                                    <label>Select Title Font</label>
                                    <select value={this.state.titleFont} onChange={(e) => this.setState({ titleFont: e.target.value })}>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Oswald">Oswald</option>
                                        <option value="Poppin">Poppin</option>
                                        <option value="Bebas Neue">Bebas Neue</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Abel">Abel</option>
                                        <option value="ClassGarmnd BT">ClassGarmnd BT</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Body Font</label>
                                    <select value={this.state.bodyFont} onChange={(e) => this.setState({ bodyFont: e.target.value })}>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Oswald">Oswald</option>
                                        <option value="Poppin">Poppin</option>
                                        <option value="Bebas Neue">Bebas Neue</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Abel">Abel</option>
                                        <option value="ClassGarmnd BT">ClassGarmnd BT</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Left Menu Font Size</label>
                                    <select value={this.state.menuLeftSize} onChange={(e) => this.setState({ menuLeftSize: e.target.value })}>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Menu Font Size</label>
                                    <select value={this.state.menuSize} onChange={(e) => this.setState({ menuSize: e.target.value })}>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Menu Height</label>
                                    <select value={this.state.menuHeight} onChange={(e) => this.setState({ menuHeight: e.target.value })}>
                                        <option value="70">70</option>
                                        <option value="75">75</option>
                                        <option value="80">80</option>
                                        <option value="85">85</option>
                                        <option value="90">90</option>
                                        <option value="95">95</option>
                                        <option value="100">100</option>
                                        <option value="105">105</option>
                                        <option value="110">110</option>
                                        <option value="115">115</option>
                                        <option value="120">120</option>
                                        <option value="125">125</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Background Opacity</label>
                                    <select value={this.state.backgroundOpacity} onChange={(e) => this.setState({ backgroundOpacity: e.target.value })}>
                                        <option value="0.1">1</option>
                                        <option value="0.2">2</option>
                                        <option value="0.3">3</option>
                                        <option value="0.4">4</option>
                                        <option value="0.5">5</option>
                                        <option value="0.6">6</option>
                                        <option value="0.7">7</option>
                                        <option value="0.8">8</option>
                                        <option value="0.9">9</option>
                                    </select>
                                </div>
                            </div>
                            <div className="footer-allpopups-sections" style={{ bottom: -25 }}>
                                <div className="inner-faps" onClick={this.updateTheme.bind(this)}>
                                    <SaveIcon />
                                    <font>SAVE</font>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* Create New Page */}
                {this.state.show_tocp && < div className="accept-tocp">
                    <div className="inner-atocp">
                        <p>{this.state.cookie_text}</p>
                        <button onClick={this.acceptCookie.bind(this)}>Ok</button>
                        <button onClick={this.declineCookie.bind(this)}>Nee</button>
                        <button onClick={() => {
                            localStorage.setItem("tocp", true)
                            window.location.href = "/privacy-policy"
                        }}>Meer Iezen</button>
                    </div>
                </div>}
                {/* Colour Picker Page */}
            </div>
        )
    }
}

export default CompleteNavbar
