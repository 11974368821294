import React, { Component } from 'react'
import Navbar from '../../Components/Navbar'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet } from "react-helmet";
import { gv } from '../../Components/globalvar'
import './index.css'
import Leftmenu from '../../Components/Leftmenu';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import CompleteNavbar from '../../Components/CompleteNavbar';

export class Orders extends Component {

    constructor() {
        super()
        this.state = {
            modal: false,
            wholeData: '',
            arrPopup: [],
            isAdmin: false,
            heading: "",
            headingInput: ""
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }

        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            loginData = JSON.parse(loginData)
            if (!loginData.onliny_payment) {
                window.location.href = "/"
            }
        }

    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };


            fetch(`${gv}/api/v1/auth/get/customer/orders?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    resp.order_details = Object.entries(resp.order_details)
                    let arr = []
                    resp.order_details.map(() => {
                        let obj = {
                            popup: false
                        }
                        arr.push(obj)
                    })
                    this.setState({ wholeData: resp, arrPopup: arr, heading: resp.heading })
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { heading } = this.state

        let arr = [
            { key: "order_history_page_heading", value: heading },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    render() {
        return (
            <div className="amdiv">
                <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>
                <CompleteNavbar />
                <div className="account-main">
                    <div className="account-wrapper">
                        <h1 className="account-title" style={{ display: "flex", alignItems: "center" }}>
                            <span className="icon-user">
                                <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                            </span>
                            <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.page_title}</font>
                        </h1>
                        <div className="account-left-content">
                            <Leftmenu />
                        </div>
                        <div className="account-right-content">
                            <div className="content-wrapper-rc">
                                <div className="section-title">
                                    <h2>
                                        <span>
                                            <ShoppingCartIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2 }} />
                                        </span>
                                        {this.state.isAdmin ? <span onClick={() => this.setState({ headingInput: true })}>
                                            {!this.state.headingInput ? this.state.heading : <input type="text" autoFocus value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                        </span> : <span>
                                            {this.state.heading}
                                        </span>}
                                    </h2>
                                </div>
                                <div className="orders-container">
                                    {this.state.arrPopup.length > 0 ? this.state.wholeData.order_details.length > 0 && this.state.wholeData.order_details.map((e, i) => {
                                        let monthOfOrder = ""
                                        let yearOfOrder = ""
                                        let dateOfOrder = ""
                                        let timeOfOrder = ""
                                        if (e[1].pay_by === "AFREKENEN_IN_WINKEL") {
                                            let dayTimeOfOrder = JSON.parse(e[1].order_timings)
                                            monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                                            yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                                            dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                                            timeOfOrder = dayTimeOfOrder.time
                                        }
                                        return <div className="all-orders-list" key={Math.random()}>
                                            <div className="order-block">
                                                <div className="order-block-inner" onClick={() => {
                                                    let arrPopup = this.state.arrPopup
                                                    arrPopup[i].popup = !arrPopup[i].popup
                                                    this.setState({ arrPopup })
                                                }}>
                                                    {e[1].products && <div className="order-image">
                                                        <img src={e[1].products[0].image_url} alt="order" />
                                                    </div>}
                                                    <div className="order-details">
                                                        <div className="top-row">
                                                            <div className="order-detail-date">
                                                                <span>{e[1].date}</span>
                                                            </div>
                                                            <div className="order-detail-number">
                                                                <span>{e[1].order_id}</span>
                                                            </div>
                                                        </div>
                                                        <div className="bottom-row">
                                                            <div className="order-detail-price">
                                                                <span>€ {e[1].total_price}</span>
                                                            </div>
                                                            <div className="order-detail-size">
                                                                <span>{e[1].total_products} products</span>
                                                            </div>
                                                            <div className="order-detail-status">
                                                                <span>Status: {e[1].status}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="order-arrow">
                                                        <span>
                                                            <ExpandMoreIcon style={{ fontSize: 30 }} />
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="order-dropdown-details" style={{ display: this.state.arrPopup[i].popup ? "block" : "none" }} >
                                                    {e[1].products.map((f) => {
                                                        return <div className="product-row" key={Math.random()}>
                                                            <div className="product-image">
                                                                <img src={f.image_url} alt="product" />
                                                            </div>
                                                            <div className="order-details-right">
                                                                <div className="product-title">
                                                                    <font>{f.name}</font>
                                                                </div>
                                                                <div className="product-name">
                                                                    <font>{f.plain_sku}</font>
                                                                </div>
                                                                <div className="product-variation">

                                                                </div>
                                                                <div className="product-amount">
                                                                    <font>{f.qty}</font>
                                                                </div>
                                                                <div className="product-price">
                                                                    <font>€ {f.retail_price}</font>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}
                                                    <div className="order-totals-block">
                                                        <div className="order-total-price-row summary-row">
                                                            <div className="item">
                                                                <font>{e[1].total_products} PRODUCTS</font>
                                                            </div>
                                                            <div className="value">
                                                                <font>€ {e[1].products_price}</font>
                                                            </div>
                                                        </div>
                                                        {e[1].delivery_settings && <div className="order-total-price-row">
                                                            {e[1].delivery_settings.delivery_option === "collection_point" ? <span className="item">
                                                                Afhalen in de winkel
                                                    </span> : <span className="item">
                                                                Thuisbezorgd
                                                    </span>
                                                            }
                                                            {e[1].delivery_settings.delivery_prices ? <span className="value">
                                                                € {e[1].delivery_settings.delivery_prices}
                                                            </span> : <span className="value">
                                                                {e[1].delivery_desc_1_price}
                                                            </span>}
                                                        </div>}
                                                        {e[1].coupon_settings && e[1].coupon_settings.coupon_discount && <div className="order-total-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                            <span className="item">
                                                                Kortingscode
                                                    </span>
                                                            <span className="value">
                                                                € {e[1].coupon_settings.coupon_discount}
                                                            </span>
                                                        </div>}
                                                        {e[1].gift_settings && e[1].gift_settings.gift_price && <div className="order-total-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                            <span className="item">
                                                                Cadeauverpakking
                                                            </span>
                                                            <span className="value">
                                                                € {e[1].gift_settings.gift_price}
                                                            </span>
                                                        </div>}
                                                        <div className="order-total-price-row total">
                                                            <div className="item">
                                                                <font>TOTAL</font>
                                                            </div>
                                                            <div className="value">
                                                                <font>€ {e[1].total_price}</font>
                                                            </div>
                                                        </div>
                                                        <div className="order-total-price-row txt--rit-dark">
                                                            <div className="item">
                                                                <font>Verzendmethode</font>
                                                            </div>
                                                            <div className="value">
                                                                <font>{e[1].delivery_settings.delivery_option ? e[1].delivery_settings.delivery_option === "home_delivery" ? "Thuisbezorgd" : "Afhalen in de winkel" : "Thuisbezorgd"}</font>
                                                            </div>
                                                        </div>
                                                        <div className="order-total-price-row txt--rit-dark">
                                                            <div className="item">
                                                                <font>Verzendmethode</font>
                                                            </div>
                                                            <div className="value">
                                                                <font>{e[1].payment_method}</font>
                                                            </div>
                                                        </div>
                                                        {e[1].pay_by === "AFREKENEN_IN_WINKEL" && <div className="order-total-price-row txt--rit-dark">
                                                            <div className="item">
                                                                <font>Datum: </font>
                                                            </div>
                                                            <div className="value">
                                                                <font>{dateOfOrder + "-" + monthOfOrder + "-" + yearOfOrder}</font>
                                                            </div>
                                                        </div>}
                                                        {/* {e[1].pay_by === "AFREKENEN_IN_WINKEL" && <div className="order-total-price-row txt--rit-dark">
                                                            <div className="item">
                                                                <font>Tijd: </font>
                                                            </div>
                                                            <div className="value">
                                                                <font>{timeOfOrder}</font>
                                                            </div>
                                                        </div>} */}
                                                        {e[1].trace_info && <div className="order-total-price-row txt--rit-dark">
                                                            <span style={{ color: "#864", textDecoration: "underline" }} onClick={() => {
                                                                window.location.href = e[1].trace_info
                                                            }}>TRACE INFO</span>
                                                        </div>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }) : <div className="no-orders">
                                        <p>Er zijn geen winkelafspraken gepland</p>
                                        <div className="btn-container" onClick={() => window.location.href = "/"}>
                                            <button>SHOP</button>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Orders
