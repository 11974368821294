import React, { Component } from 'react'
import CK from '../Components/Ck'
import { gv } from '../Components/globalvar'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

export class FooterContactBanner extends Component {

    constructor() {
        super()
        this.state = {
            image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_13_banner_image.jpg",
            delete_image: false,
            heading: "",
            headingInput: false,
            desc: "<h3>HORLOGES</h3><p>Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text Demo text </p><ul><li>Hello</li><li>Hello</li></ul>",
            descInput: false,
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            fullWidth: true,
            crop: {
                // unit: '%',
                width: 1304,
                height: 565,
                aspect: 16 / 9,
            },
            createLoader: true,
            themeColors: false,
            headerData: "",
            phone_number: "",
            email: "123@gmail.com",
            address: "abc street",
            currentIndex: 0,
            backgroundColor: "black",
            fontColor: "white",
        }
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        let headerData = localStorage.getItem("headerData")

        if (headerData) {
            headerData = JSON.parse(headerData)
            this.setState({ phone_number: headerData.phone, email: headerData.email, linkedInUrl: headerData.linked_in_url, fb_url: headerData.fb_url, insta_url: headerData.instagram_url, })

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/shops/locations`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let data = result.data.shop_locations[this.state.currentIndex]
                    this.setState({ headerData, address: data.section_address, city: data.city, zipCode: data.zipcode, wholeDataContact: result.data.shop_locations })
                })
                .catch(error => console.log('error', error));
        }

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));

    }

    changeAlert(e) {
        this.setState({ desc: e })
    }

    close() {
        this.setState({ descInput: false })
    }

    createMarkup() {
        if (this.state.desc) {
            return { __html: this.state.desc };
        }
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 1919) {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 90,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 1400,
                                height: 500,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    removeImage() {
        const { image_url } = this.state
        this.setState({ loader: true })
        let splitted = image_url.split("/")
        if (splitted[splitted.length - 1] !== "layout_13_banner_image.jpg") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image_name", splitted[splitted.length - 1]);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_13_banner_image.jpg", delete_image: false, loader: false, })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    saveSection() {
        const { desc, image_url, wholeData } = this.state

        let obj = {
            type: "layout_11",
            image_url,
            space_top: this.state.spaceTop,
            space_bottom: this.state.spaceBottom,
            full_width: this.state.fullWidth,
            theming: this.state.themeColors,
            currentIndex: this.state.currentIndex,
            city: this.state.city,
            address: this.state.address,
            zip_code: this.state.zipCode,
            backgroundColor: this.state.backgroundColor,
            fontColor: this.state.fontColor,
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", "layout_8");
            formdata.append("image", file);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: result.data.image_url, delete_image: true, loader: false, croppedImageUrl: "", cropper: false })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/layout_13_banner_image.jpg", croppedImageUrl: "" })
    }


    handleChangeComplete = (color) => {
        this.setState({ fontColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSec = (color) => {
        this.setState({ overlayColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    buttonColorChange = (color) => {
        this.setState({ backgroundColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    buttonColorFontChange = (color) => {
        this.setState({ fontColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div style={{ position: "relative" }}>
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                        maxHeight={500}
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="bannersec-home" style={{ backgroundImage: `url(${this.state.image_url})` }}>
                        <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>
                        {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                            <DeleteIcon onClick={this.removeImage.bind(this)} />
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                        <div className={`inner-bsh ${this.state.themeColors && "themingibsh"}`}>
                            <div className="overlay-bsh" style={{ backgroundColor: this.state.backgroundColor, opacity: this.state.backgroundOpacity }}>

                            </div>
                            <div>
                                <p style={{ color: this.state.fontColor }} >{this.state.headerData.retailer_name}</p>
                                <p style={{ color: this.state.fontColor }} >{this.state.headerData.address}</p>
                                <p style={{ color: this.state.fontColor }} >{this.state.zipCode} {this.state.city}</p>
                                <p style={{ color: this.state.fontColor }} >{this.state.phone_number}</p>
                                <p style={{ color: this.state.fontColor }} >{this.state.email}</p>
                                <div className="icons-contactfb">
                                    {this.state.insta_url && <div className="inner-icfb">
                                        <FacebookIcon />
                                    </div>}
                                    {this.state.fb_url && <div className="inner-icfb">
                                        <InstagramIcon />
                                    </div>}
                                </div>
                            </div>
                            {/* {this.state.desc ? <div onClick={() => this.setState({ descInput: true })}>
                                {!this.state.descInput ? <div dangerouslySetInnerHTML={this.createMarkup()}></div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />}
                            </div> : <CK data={this.state.desc} onChildChange={this.changeAlert.bind(this)} onChildBlur={this.close.bind(this)} />} */}
                        </div>
                        <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                        </div>
                        <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none", height: 400 }} >
                            <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                            <div className="color-working-popup">
                                <div className="first-div-cwp">
                                    <label>Background colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundColor}
                                        onChangeComplete={this.buttonColorChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Font colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.fontColor}
                                        onChangeComplete={this.buttonColorFontChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections" style={{ bottom: -60 }}>
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <ColorizeIcon />
                        <font>ColourS</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                            this.setState({ fullWidth: !this.state.fullWidth })
                        }} />
                        <font>PAGINABREEDTE</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 1304x565, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default FooterContactBanner
