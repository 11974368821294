import React, { Component } from 'react'
import { gv } from '../Components/globalvar'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ColorizeIcon from '@material-ui/icons/Colorize';
import { SketchPicker } from 'react-color'
import CloseIcon from '@material-ui/icons/Close';

export class Banner extends Component {
    constructor() {
        super()
        this.state = {
            image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/banner_image.jpg",
            banner_type: "image",
            heading: "TITLE OF BANNER",
            headingInput: false,
            desc: "DEMO TEKST",
            descInput: false,
            buttonText: "SHOP THE COLLECTION",
            buttonInput: false,
            delete_image: false,
            wholeData: '',
            allRoutes: [],
            buttonUrl: '',
            selectRoute: true,
            selectedRoute: "",
            urlValidate: false,
            brandRoute: false,
            brandCategories: [],
            selectedCategory: "",
            allBrands: [],
            childmenulists: [],
            selectedRouteBrand: "",
            selectedBrandList: "",
            brandlists: [],
            listRoute: false,
            selectedRouteurl: '',
            soortRoute: false,
            soortArr: [],
            selectedSoort: "",
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            fullWidth: true,
            fullHeight: false,
            showText: true,
            showButton: true,
            showOverlay: false,
            is_soort: false,
            is_brand: false,
            crop: {
                // unit: '%',
                width: 1200,
                height: 700,
                aspect: 16 / 9,
            },
            image_url_duplicate: "https://saastoday.nl/public/retailer_phase_assets/sections/banner_image.jpg",
            banner_type_duplicate: "image",
            delete_image_dup: false,
            createLoader: true,
            onImageIndex: 0,
            showSubCategory: true,
            buttonColor: "#000000",
            buttonColorFont: "#fff",
        }
    }

    componentDidMount() {
        let accesstoken = localStorage.getItem("adminKey")

        let currentPage = window.location.pathname
        let layout_type = "layout_1"
        let currentTime = Math.floor(Date.now() / 1000)
        let pageName = ""
        if (currentPage === "/") {
            pageName = "landing_page"
        } else {
            if (currentPage.includes("%20")) {
                pageName = currentPage.split("%20").join("_")
            } else {
                pageName = currentPage
            }
            pageName = pageName.split("/").join('')
        }
        let sectionType = pageName + '_' + layout_type + '_' + currentTime
        this.setState({ sectionType })

        let browserType = ''
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false, })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, createLoader: false, })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));

        let allRoutes = localStorage.getItem("allRoutes")
        if (allRoutes) {
            let parsed = JSON.parse(allRoutes)
            let arr = ["subcategory", "maincategory"]
            parsed.map((f) => {
                if (f.split("/")[1] === "") {
                    arr.push("landing-page")
                } else {
                    arr.push(f.split("/")[1])
                }
            })
            this.setState({ allRoutes: arr })
        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let parsed = JSON.parse(headerData)
            let child_menus = Object.entries(parsed.menus.child_menus);
            let childmenulists = []
            let hello = child_menus.filter((f) => {
                return f[0] === parsed.main_categories[0].id
            });
            childmenulists = hello[0][1]

            let arr = []
            let objected = Object.entries(childmenulists)
            objected.map((g) => {
                g[1].map((h) => {
                    arr.push(h)
                })
            })
            this.setState({
                brandCategories: parsed.main_categories, selectedCategory: parsed.main_categories[0].id, childmenulists: arr
            })
        }
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            let type = e.target.files[0].type.split('/')[0]
            if (type === "video") {
                this.setState({ loader: true })

                e.preventDefault()

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("video", e.target.files[0]);

                e.target.value = null

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/video`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.setState({ image_url: result.data.image_url, delete_image: true, loader: false, banner_type: "video" })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                const reader = new FileReader();
                this.setState({ targettedFile: e.target.files[0] })
                reader.addEventListener('load', () => {
                    let img = new Image();
                    let width = ""
                    let height = ""
                    let result = reader.result
                    img.onload = (e) => {
                        width = e.target.width
                        height = e.target.height
                        this.setState({ originalImageWidth: width, originalImageHeight: height })
                        if (width > height) {
                            if (width > 1919) {
                                let crop = {
                                    unit: '%',
                                    width: 100,
                                    height: 90,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            } else {
                                let crop = {
                                    unit: 'px',
                                    width: 1400,
                                    height: 500,
                                    aspect: 16 / 9,
                                    x: 0,
                                    y: 0,
                                }
                                this.setState({ src: result, cropper: true, crop })
                            }
                        } else {
                            let crop = {
                                unit: '%',
                                width: 100,
                                height: 80,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    };
                    img.src = result;
                });
                reader.readAsDataURL(e.target.files[0]);
                e.target.value = null
            }
        }
    }

    removeImage() {
        if (this.state.is_soort) {
            let imageName = this.state.image_url.split('/')

            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", this.state.sectionType);
            formdata.append("image_name", imageName[imageName.length - 1]);
            formdata.append("brand_id", this.state.selectedSoort);
            formdata.append("main_category", "soort");
            formdata.append("image_type", "banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: result.data.default_url, loader: false, delete_image: false })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            if (this.state.banner_type === "image") {
                if (this.state.is_brand) {
                    this.setState({ loader: true })
                    if (this.state.selectedBrand) {
                        let imageName = this.state.image_url.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.selectedBrand);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.setState({ image_url: result.data.images, onImageIndex: 0, loader: false, delete_image: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        let imageName = this.state.image_url.split('/')

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", imageName[imageName.length - 1]);
                        formdata.append("brand_id", this.state.childmenulists[0].id);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.setState({ image_url: result.data.images, onImageIndex: 0, loader: false, delete_image: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    const { image_url } = this.state
                    let splitted = image_url.split("/")
                    if (splitted[splitted.length - 1] !== "banner_image.jpg") {
                        this.setState({ loader: true })

                        let accesstoken = localStorage.getItem("adminKey")

                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image_name", splitted[splitted.length - 1]);

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/banner_image.jpg", delete_image: false, loader: false, banner_type: "image" })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                }
            } else {
                this.setState({ loader: true })

                const { image_url } = this.state
                let splitted = image_url.split("/")

                let accesstoken = localStorage.getItem("adminKey")

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("video_name", splitted[splitted.length - 1]);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/remove/section/video`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.code === 200) {
                            this.setState({ image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/banner_image.jpg", delete_image: false, loader: false, banner_type: "image" })
                        }
                    })
                    .catch(error => console.log('error', error));

            }
        }
    }

    getBrandDetailsList(e, f) {
        this.setState({ selectedCategory: e.target.value })
        let val = e.target.value

        var requestOptions = {
            method: 'GET',
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/${val}/merken?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let childmenulists = result.data.data
                this.setState({ brandDetailsList: childmenulists, selectedRouteurl: `/${f}/${val}/${childmenulists[0].brand_id}` })
            })
            .catch(error => console.log('error', error));
    }

    saveSection() {
        const { heading, desc, buttonText, image_url, wholeData, buttonUrl } = this.state
        let urlBtn = ""
        let is_button_route = false
        if (this.state.selectRoute) {
            if (this.state.selectedRoute) {
                is_button_route = true
                if (this.state.selectedRoute === "/landing-page") {
                    urlBtn = "/"
                } else if (this.state.selectedRoute === "/subcategory" || this.state.selectedRoute === "/maincategory" || this.state.selectedRoute === "/soort" || this.state.selectedRoute === "/brand-detail") {
                    urlBtn = this.state.selectedRouteurl
                } else {
                    urlBtn = this.state.selectedRoute
                }
            } else {
                is_button_route = false
                urlBtn = this.state.selectedRoute
            }
        } else {
            var expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (buttonUrl) {
                if (buttonUrl.match(regex)) {
                    urlBtn = buttonUrl
                    is_button_route = true
                    this.setState({ urlValidate: false })
                } else {
                    urlBtn = ""
                    this.setState({ urlValidate: true })
                }
            } else {
                urlBtn = ""
                is_button_route = false
            }
        }


        let obj = {}

        if (Array.isArray(this.state.image_url)) {
            obj = {
                type: "layout_8",
                heading,
                desc,
                banner_image: image_url[this.state.onImageIndex],
                buttonText,
                is_button_route,
                button_url: urlBtn,
                urlType: this.state.selectRoute,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                banner_type: this.state.banner_type,
                full_width: this.state.fullWidth,
                full_height: this.state.fullHeight,
                show_text: this.state.showText,
                show_button: this.state.showButton,
                show_overlay: this.state.showOverlay,
                is_brand: this.state.is_brand,
                is_soort: this.state.is_soort,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
                showSubCategory: this.state.showSubCategory,
                buttonColor: this.state.buttonColor,
                buttonColorFont: this.state.buttonColorFont,
            }
        } else {
            obj = {
                type: "layout_8",
                heading,
                desc,
                banner_image: image_url,
                buttonText,
                is_button_route,
                button_url: urlBtn,
                urlType: this.state.selectRoute,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                show_overlay: this.state.showOverlay,
                banner_type: this.state.banner_type,
                full_width: this.state.fullWidth,
                full_height: this.state.fullHeight,
                show_text: this.state.showText,
                show_button: this.state.showButton,
                show_overlay: this.state.showOverlay,
                is_brand: this.state.is_brand,
                is_soort: this.state.is_soort,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
                showSubCategory: this.state.showSubCategory,
                buttonColor: this.state.buttonColor,
                buttonColorFont: this.state.buttonColorFont,
            }
        }

        this.setState({ createLoader: true })

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(Number(currentIndex) + 1, 0, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, createLoader: false })
            });
    }

    getBrand(e, g) {
        this.setState({ is_brand: true, is_soort: false })
        if (e === "firstTime") {
            this.setState({ image_url_duplicate: this.state.image_url, banner_type_duplicate: this.state.banner_type, delete_image_dup: this.state.delete_image, })

            if (this.state.showSubCategory) {
                this.setState({ selectedRouteurl: `/${this.state.selectedCategory}/${this.state.childmenulists[0].id}`, selectedBrand: this.state.childmenulists[0].id })
            } else {
                this.setState({ selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrand: this.state.childmenulists[0].id })
            }

            var formdata = new FormData();
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "banner");
            formdata.append("brand_id", this.state.selectedCategory);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ image_url: result.data.image_url, delete_image: false })
                })
                .catch(error => console.log('error', error));
        } else if (e === "secTime") {
            var selectedCateogrySend = ""
            selectedCateogrySend = this.state.selectedCategory
            if (this.state.showSubCategory) {
                this.setState({ selectedRouteurl: `/${this.state.selectedCategory}/${g.target.value}`, selectedBrand: g.target.value })
            } else {
                this.setState({ selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products`, selectedBrand: g.target.value })
            }
            var formdata = new FormData();
            formdata.append("main_category", selectedCateogrySend);
            formdata.append("image_type", "left_banner");
            formdata.append("brand_id", g.target.value);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ image_url: result.data.image_url, imageUploading: false })
                })
                .catch(error => console.log('error', error));
        } else {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === e.target.value) {
                        let arr = []
                        let objected = Object.entries(f[1])
                        objected.map((g) => {
                            g[1].map((h) => {
                                arr.push(h)
                            })
                        })
                        if (this.state.showSubCategory) {
                            this.setState({ childmenulists: arr, selectedCategory: e.target.value, selectedBrand: arr[0].id, selectedRouteurl: `/${e.target.value}/${arr[0].id}` })
                        } else {
                            this.setState({ childmenulists: arr, selectedCategory: e.target.value, selectedBrand: arr[0].id, selectedRouteurl: `/hoofcategorie/${e.target.value}/products` })
                        }

                        var formdata = new FormData();
                        formdata.append("main_category", e.target.value);
                        formdata.append("image_type", "banner");
                        formdata.append("brand_id", arr[0].id);

                        var requestOptions = {
                            method: 'POST',
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                this.setState({ image_url: result.data.image_url, delete_image: false })
                            })
                            .catch(error => console.log('error', error));
                    }
                })
            }
        }
    }

    resetBrand() {
        this.setState({ image_url: this.state.image_url_duplicate, onImageIndex: 0, banner_type: this.state.banner_type_duplicate, delete_image: this.state.delete_image_dup, is_brand: false, is_soort: false, selectedBrand: '' })
    }

    getSoort(e) {
        if (e === "firstTime") {
            this.setState({ selectedRouteurl: `/soort/${this.state.soortArr[0].id}`, selectedSoort: this.state.soortArr[0].id, is_soort: true, is_brand: false, })
            this.setState({ image_url_duplicate: this.state.image_url, banner_type_duplicate: this.state.banner_type, delete_image_dup: this.state.delete_image, })

            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "banner");
            formdata.append("brand_id", this.state.soortArr[0].id.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ image_url: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ image_url: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ selectedRouteurl: `/soort/${e.target.value}`, selectedSoort: e.target.value, is_soort: true, is_brand: false })
            var formdata = new FormData();
            formdata.append("main_category", "soort");
            formdata.append("image_type", "banner");
            formdata.append("brand_id", e.target.value.toLowerCase());

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.message === "its demo image.") {
                        this.setState({ image_url: result.data.image_url, delete_image: false })
                    } else {
                        this.setState({ image_url: result.data.image_url, delete_image: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        console.log(image.naturalWidth)
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.height = crop.height;
        canvas.width = crop.width
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            if (this.state.is_soort) {
                let accesstoken = localStorage.getItem("adminKey")

                this.setState({ loader: true })

                var myHeaders = new Headers();
                myHeaders.append("accesstoken", accesstoken);

                var formdata = new FormData();
                formdata.append("section_type", this.state.sectionType);
                formdata.append("image", file);
                formdata.append("brand_id", this.state.selectedSoort);
                formdata.append("main_category", "soort");
                formdata.append("image_type", "banner");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        this.setState({ loader: false })
                        if (result.data.sucess !== 0) {
                            this.setState({ image_url: result.data.image_url, delete_image: true, croppedImageUrl: "", cropper: false })
                        }
                    })
                    .catch(error => console.log('error', error));
            } else {
                let accesstoken = localStorage.getItem("adminKey")

                this.setState({ loader: true })

                if (this.state.is_brand) {
                    if (this.state.selectedBrand) {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.selectedBrand);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ loader: false })
                                if (result.data.sucess !== 0) {
                                    this.setState({ image_url: result.data.image_url, delete_image: true, croppedImageUrl: "", cropper: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    } else {
                        var myHeaders = new Headers();
                        myHeaders.append("accesstoken", accesstoken);

                        var formdata = new FormData();
                        formdata.append("section_type", this.state.sectionType);
                        formdata.append("image", file);
                        formdata.append("brand_id", this.state.childmenulists[0].id);
                        formdata.append("main_category", this.state.selectedCategory);
                        formdata.append("image_type", "banner");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                            .then(response => response.json())
                            .then((result) => {
                                this.setState({ loader: false })
                                if (result.data.sucess !== 0) {
                                    this.setState({ image_url: result.data.image_url, delete_image: true, croppedImageUrl: "", cropper: false })
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    this.setState({ loader: true })

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("section_type", this.state.sectionType);
                    formdata.append("image", file);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                        .then(response => response.json())
                        .then(result => {
                            if (result.code === 200) {
                                this.setState({ image_url: result.data.image_url, delete_image: true, loader: false, banner_type: "image", croppedImageUrl: "", cropper: false })
                            }
                        })
                        .catch(error => console.log('error', error));
                }
            }
        }
    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/retailer_phase_assets/sections/banner_image.jpg", croppedImageUrl: "" })
    }

    buttonColorChange = (color) => {
        this.setState({ buttonColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };
    
    buttonColorFontChange = (color) => {
        this.setState({ buttonColorFont: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    render() {
        return (
            <div className="banner-cr">
                {this.state.createLoader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={this.state.crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div
                    className="full-bg-hcs sectiles"
                    style={{
                        backgroundImage: this.state.banner_type === "image" && `url(${Array.isArray(this.state.image_url) ? this.state.image_url[this.state.onImageIndex] : this.state.image_url})`,
                        marginBottom: 70,
                        position: "relative",
                    }}>
                        {this.state.showOverlay && <div className="overlay-section-banner">

                        </div>}
                        {this.state.banner_type === "video" && <video muted src={this.state.image_url} autoPlay loop style={{ position: "absolute", width: "100%" }}></video>}
                        <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 10 }}>
                            <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*,video/*" />
                            <PublishIcon style={{ color: "white" }} />
                        </div>
                        {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 10 }}>
                            <DeleteIcon onClick={this.removeImage.bind(this)} />
                        </div>}
                        {Array.isArray(this.state.image_url) && this.state.image_url.length > 1 && this.state.onImageIndex > 0 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 10, top: 60 }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex - 1 })}>
                            <ChevronLeftIcon />
                        </div>}
                        {Array.isArray(this.state.image_url) && this.state.image_url.length > 1 && this.state.onImageIndex < this.state.image_url.length - 1 && <div className="file-uploadimg" style={{ zIndex: 9999, position: "absolute", left: 60, top: 60 }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex + 1 })}>
                            <ChevronRightIcon />
                        </div>}
                        {this.state.loader && <div className="loader-working-fb">
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div>}
                        <div className="closer-working" onClick={() => this.setState({ headingInput: false, descInput: false, buttonInput: false })}>

                        </div>
                        {this.state.showText && <div className="secchecks" style={{ textAlign: "center" }}>
                            {this.state.heading ? <span className={`the-ritual-title ${this.state.showOverlay && "overlayTextbg"}`} onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })} style={{ color: "white", textAlign: "center" }}>
                                {!this.state.headingInput ? this.state.heading : <input autoFocus type="text" style={{ color: "white" }} value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                            </span> : <input type="text" style={{ color: "white" }} value={this.state.heading} style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ heading: e.target.value, headingInput: true })} onBlur={() => this.setState({ headingInput: false })} />}
                        </div>}
                        {this.state.showText && <div className="secchecks" style={{ textAlign: "center", marginTop: 30 }}>
                            {this.state.desc ? <h2 className={this.state.showOverlay && "overlayTextbg"} onClick={() => this.setState({ descInput: true })} onBlur={() => this.setState({ descInput: false })}>
                                {!this.state.descInput ? this.state.desc : <textarea autoFocus style={{ color: "white" }} onChange={(e) => this.setState({ desc: e.target.value })} onBlur={() => this.setState({ descInput: false })}>
                                    {this.state.desc}
                                </textarea>}
                            </h2> : <textarea style={{ color: "white", backgroundColor: "#e7e7e7", marginBottom: 10 }} onChange={(e) => this.setState({ desc: e.target.value, descInput: true })} onBlur={() => this.setState({ descInput: false })}>
                                    {this.state.desc}
                                </textarea>}
                        </div>}
                        {this.state.showButton && <div className="secchecks" style={{ textAlign: "center" }}>
                            {!this.state.buttonInput ?
                                <button onClick={() => this.setState({ buttonInput: true })} onBlur={() => this.setState({ buttonInput: false })} style={{ backgroundColor: this.state.buttonColor, color: this.state.buttonColorFont }} >{this.state.buttonText}</button>
                                : <div style={{ width: "100%" }}>
                                    <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectRoute ? "ps" : "link"} onChange={(e) => {
                                        if (e.target.value === "ps") {
                                            this.setState({ selectRoute: true })
                                        } else {
                                            this.setState({ selectRoute: false })
                                        }
                                    }}>
                                        <option value="ps">Page selection</option>
                                        <option value="link">Own link</option>
                                    </select>
                                    {this.state.selectRoute ? <div style={{ width: "100%" }}>
                                        <select style={{ marginBottom: 10, backgroundColor: "white" }} value={this.state.selectedRoute} onChange={(e) => {
                                            this.setState({ selectedRoute: e.target.value })
                                            if (e.target.value === "/subcategory") {
                                                this.getBrand("firstTime")
                                                this.setState({ brandRoute: true, showSubCategory: true, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/${this.state.selectedCategory}/${this.state.childmenulists[0].id}` })
                                            } else if (e.target.value === "/maincategory") {
                                                this.getBrand("firstTime")
                                                this.setState({ brandRoute: true, showSubCategory: false, detailRoute: false, listRoute: false, soortRoute: false, selectedRouteurl: `/hoofcategorie/${this.state.selectedCategory}/products` })
                                            } else {
                                                this.resetBrand()
                                                this.setState({ brandRoute: false, listRoute: false, selectedRouteurl: "", detailRoute: false, soortRoute: false })
                                            }
                                        }}>
                                            <option value="">Select Route</option>
                                            {this.state.allRoutes.map((e) => {
                                                return <option key={Math.random()} value={`/${e}`}>{e}</option>
                                            })}
                                        </select>
                                        {this.state.brandRoute && <div style={{ width: "100%" }}>
                                            <select style={{ marginBottom: 10 }} onChange={(e) => this.getBrand(e, "brand-products", "left")} value={this.state.selectedCategory}>
                                                {this.state.brandCategories.map((e) => {
                                                    if (e.show_for_products) {
                                                        return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                    }
                                                })}
                                            </select>
                                            {this.state.showSubCategory && <select style={{ marginBottom: 10 }} value={this.state.selectedBrand} onChange={(e) => this.getBrand("secTime", e, "left")}>
                                                {this.state.childmenulists.map((e) => {
                                                    return <option key={Math.random()} value={e.id}>{e.name}</option>
                                                })}
                                            </select>}
                                        </div>}
                                    </div> : <input className="bodyText" placeholder="URL" style={{ color: "black", fontFamily: "Roboto", textAlign: "left", fontSize: 12, border: "1px solid black", padding: 10, marginBottom: 20, backgroundColor: "white" }} value={this.state.buttonUrl} onChange={(e) => {
                                        if (e.target.value !== "") {
                                            this.setState({ buttonUrl: e.target.value })
                                        } else {
                                            this.setState({ buttonUrl: e.target.value })
                                        }
                                    }} />}
                                    {this.state.urlValidate && <p className="bodyText" style={{ color: "red", fontFamily: "Roboto", fontSize: 12, textAlign: "left" }}>Please enter valid URL</p>}
                                    <button style={{ backgroundColor: "black", width: 300 }}>
                                        <input value={this.state.buttonText} style={{ color: "white" }} onChange={(e) => this.setState({ buttonText: e.target.value })} onBlur={() => this.setState({ buttonInput: false })} />
                                    </button>
                                </div>}
                        </div>}
                        <div className="overlay-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }}>

                        </div>
                        <div className="popup-theme-picker" style={{ opacity: this.state.themePickerOpen ? 1 : 0, pointerEvents: this.state.themePickerOpen ? "all" : "none" }} >
                            <CloseIcon style={{ position: "absolute", top: 20, right: 20 }} onClick={() => this.setState({ themePickerOpen: false })} />
                            <div className="color-working-popup">
                                <div className="first-div-cwp">
                                    <label>Button colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.buttonColor}
                                        onChangeComplete={this.buttonColorChange}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Button font colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.buttonColorFont}
                                        onChangeComplete={this.buttonColorFontChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>}
                {!this.state.cropper && !this.state.createLoader && <div className="footer-allpopups-sections" style={{ bottom: this.state.banner_type === "video" && -15 }}>
                    <div className="inner-faps" onClick={() => this.setState({ themePickerOpen: true })}>
                        <ColorizeIcon />
                        <font>Button Colour</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.showText} onChange={() => {
                            this.setState({ showText: !this.state.showText })
                        }} />
                        <font>TOON TEKSTEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.showButton} onChange={() => {
                            this.setState({ showButton: !this.state.showButton })
                        }} />
                        <font>TOON BUTTON</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.showOverlay} onChange={() => {
                            this.setState({ showOverlay: !this.state.showOverlay })
                        }} />
                        <font>TOON OVERLAY</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                            this.setState({ fullWidth: !this.state.fullWidth })
                        }} />
                        <font>PAGINABREEDTE</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullHeight} onChange={() => {
                            this.setState({ fullHeight: !this.state.fullHeight })
                        }} />
                        <font>volledige hoogte</font>
                    </div>
                    {this.state.wholeData && <div className="inner-faps" onClick={this.saveSection.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                {this.state.src && <img src={this.state.src} className="img-hidden-crop" id="imageWorker" />}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 1650x700, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default Banner
