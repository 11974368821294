import React, { Component } from 'react'
import NavCheck from '../../Components/NavCheck'
import CheckIcon from '@material-ui/icons/Check';
import HomeIcon from '@material-ui/icons/Home';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBus';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gv } from '../../Components/globalvar'
import { Helmet } from "react-helmet";
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import './index.css'

export class OrderConfirmation extends Component {

    constructor() {
        super()
        this.state = {
            modal: false,
            wholeData: '',
            is_deleting: false,
            // Editing
            isAdmin: false,
            heading_1: "",
            heading_1Input: false,
            right_desc: "",
            right_descInput: false,
            right_image: "",
            collectionPointOrder: false,
        }
    }

    componentDidMount() {
        let orderid = this.props.match.params.orderid
        let splitted = orderid.split("=")

        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType, useragent: ua })

        let usertoken = localStorage.getItem("usertoken")
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${usertoken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/auth/customer/get/order/${splitted[1]}?browser_type=${browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (!result.data) {
                    window.location.href = "/account"
                } else {
                    let resp = result.data.data
                    if (resp.order_details.delivery_option === "collection_point") {
                        let dayTimeOfOrder = JSON.parse(resp.order_details.order_timings)
                        let monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                        let yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                        let dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                        this.setState({ monthOfOrder, yearOfOrder, dateOfOrder, timeOfOrder: dayTimeOfOrder.time, collectionPointOrder: true, })
                    }
                    this.setState({ wholeData: resp, heading_1: resp.order_desc2, right_desc: resp.right_desc, right_image: resp.right_image, is_deleting: resp.check_right_image })
                }
            })
            .catch(error => console.log('error', error));

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }
    }


    updateSec() {
        const { heading_1, right_desc } = this.state

        let arr = [
            { key: "order_confirmation_page_order_desc2", value: heading_1 },
            { key: "order_confirmation_page_right_desc", value: right_desc },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    imgUploading(e) {
        e.preventDefault()

        let originalFileSize = e.target.files[0].size / 1024 / 1024

        if (Math.round(originalFileSize) < 8) {
            this.setState({ loader: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("image", e.target.files[0]);
            formdata.append("section_type", "order_confirmation_page_right_image");

            e.target.value = null

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.setState({ right_image: result.data.image_url, loader: false, is_deleting: true })
                    }
                })
                .catch(error => {
                    this.setState({ showErrorModal: true, loader: false })
                });
        } else {
            this.setState({ greaterSizeImage: true })
        }

    }

    removeImage() {
        this.setState({ loader: true })

        const { right_image } = this.state
        let splitted = right_image.split("/")
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("image_name", splitted[splitted.length - 1]);
        formdata.append("section_type", "order_confirmation_page_right_image");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                this.setState({ right_image: result.data.default_url, loader: false, is_deleting: false })
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }

    render() {
        return (
            <div>
                <NavCheck />
                {this.state.wholeData && <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>}
                <div className="checkout-holder confirmation-main">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="checkout-layout-left">
                                <div className="back-button-wrapper">

                                </div>
                                <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        {/* <h2 className="checkout-step-title">
                                            <span className="text">Bedankt voor je bestelling</span>
                                        </h2> */}
                                        <div className="checkout-step-buttons-holder">
                                            <div className="checkout-steps-buttons-list" style={{ justifyContent: this.state.collectionPointOrder && "center" }}>
                                                <div className="checkout-step-btn selected">
                                                    <span className="sr-only"></span>
                                                    <span className="circle">
                                                        <CheckIcon style={{ fontSize: 16 }} />
                                                    </span>
                                                    <span className="text">Bevestigd</span>
                                                </div>
                                                {!this.state.collectionPointOrder && <div className="checkout-step-btn disabled">
                                                    <span className="sr-only"></span>
                                                    <span className="circle">
                                                        <DirectionsBusIcon />
                                                    </span>
                                                    <span className="text">Sent</span>
                                                </div>}
                                                {!this.state.collectionPointOrder && <div className="checkout-step-btn disabled">
                                                    <span className="sr-only"></span>
                                                    <span className="circle">
                                                        <HomeIcon style={{ fontSize: 20 }} />
                                                    </span>
                                                    <span className="text">Delivered</span>
                                                </div>}
                                            </div>
                                        </div>
                                        {this.state.collectionPointOrder ? <div className="oc-content">
                                            <font>Hartelijk dank voor uw click & collect bestelling!</font>
                                            <br />
                                            <font className="bb-font">Wij nemen contact met u op om uw afspraak te bevestigen.</font>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <font>Datum: {this.state.dateOfOrder}-{this.state.monthOfOrder}-{this.state.yearOfOrder}</font>
                                            <br />
                                            {/* <font>Tijd: {this.state.timeOfOrder}</font> */}
                                        </div> : <div className="oc-content">
                                            <font>{this.state.wholeData.order_heading_1}</font>
                                            <br />
                                            <font className="bb-font">{this.state.wholeData.order_desc1}</font>
                                            <br />
                                            <br />
                                            <br />
                                            <font onClick={() => this.setState({ heading_1Input: true })}>
                                                {!this.state.heading_1Input ? this.state.heading_1 : <input style={{ width: "100%", backgroundColor: "transparent", border: "none", outline: "none" }} type="text" autoFocus value={this.state.heading_1} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} />}
                                            </font>
                                            <br />
                                            <font>{this.state.wholeData.email}</font>
                                        </div>}
                                        <div className="checkout-summary-inner">
                                            <div className="checkout-summary-order-totals">
                                                <div className="order-totals-table">
                                                    <div className="checkout-summary-price-row" onClick={() => this.setState({ modal: !this.state.modal })}>
                                                        <div className="cfin">
                                                            <font>ORDEROVERZICHT</font>
                                                        </div>
                                                        <div className="cfiv">
                                                            <font>
                                                                <ExpandMoreIcon />
                                                            </font>
                                                        </div>
                                                    </div>
                                                    {this.state.modal && <div style={{ width: "100%", height: 1, backgroundColor: "#e7e7e7" }}>

                                                    </div>}
                                                    <div className="order-dropdown-details" style={{ display: this.state.modal ? "block" : "none" }} >
                                                        {this.state.wholeData.order_products && this.state.wholeData.order_products.length > 0 && this.state.wholeData.order_products.map((e) => {
                                                            return <div className="product-row" key={Math.random()}>
                                                                <div className="product-image">
                                                                    <img src={e.image_url} alt="productorder" />
                                                                </div>
                                                                <div className="order-details-right" style={{ textAlign: "left" }}>
                                                                    <div className="product-title">
                                                                        <font>{e.name}</font>
                                                                    </div>
                                                                    <div className="product-name">
                                                                        <font>{e.plain_sku}</font>
                                                                    </div>
                                                                    <div className="product-variation">

                                                                    </div>
                                                                    <div className="product-amount">
                                                                        <font>{e.qty}</font>
                                                                    </div>
                                                                    <div className="product-price">
                                                                        <font>€ {e.retail_price}</font>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })}
                                                        <div className="order-totals-block">
                                                            <div className="order-total-price-row summary-row">
                                                                <div className="item">
                                                                    <font>{this.state.wholeData.total_products} PRODUCTS</font>
                                                                </div>
                                                                <div className="value">
                                                                    <font>€ {this.state.wholeData.total_retail_price}</font>
                                                                </div>
                                                            </div>
                                                            <div className="order-total-price-row">
                                                                <div className="item">
                                                                    <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.order_details && this.state.wholeData.order_details.delivery_option === "home_delivery" ? "Thuisbezorgd" : "Afhalen in de winkel"}</font>
                                                                </div>
                                                                <div className="value">
                                                                    <font>€ {this.state.wholeData.order_details && this.state.wholeData.order_details.shipping_cost}</font>
                                                                </div>
                                                            </div>
                                                            {this.state.wholeData.coupon_settings && this.state.wholeData.coupon_settings.coupon_discount && <div className="order-total-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                                <span className="item">
                                                                    Kortingscode
                                                    </span>
                                                                <span className="value">
                                                                    € {this.state.wholeData.coupon_settings.coupon_discount}
                                                                </span>
                                                            </div>}
                                                            <div className="order-total-price-row total">
                                                                <div className="item">
                                                                    <font>TOTAL</font>
                                                                </div>
                                                                <div className="value">
                                                                    <font>€ {this.state.wholeData.order_details && this.state.wholeData.order_details.total_price}</font>
                                                                </div>
                                                            </div>
                                                            <div className="order-total-price-row txt--rit-dark">
                                                                <div className="item">
                                                                    <font>Delivery</font>
                                                                </div>
                                                                <div className="value">
                                                                    <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.order_details && this.state.wholeData.order_details.delivery_option === "home_delivery" ? "Thuisbezorgd" : "Afhalen in de winkel"}</font>
                                                                </div>
                                                            </div>
                                                            <div className="order-total-price-row txt--rit-dark">
                                                                <div className="item">
                                                                    <font>Payment method</font>
                                                                </div>
                                                                <div className="value">
                                                                    <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.order_details && this.state.wholeData.order_details.payment_method}</font>
                                                                </div>
                                                            </div>
                                                            {this.state.wholeData.order_details && this.state.wholeData.order_details.payment_method === "AFREKENEN IN WINKEL" && <div className="order-total-price-row txt--rit-dark">
                                                                <div className="item">
                                                                    <font style={{ textTransform: "uppercase" }}>Datum: </font>
                                                                </div>
                                                                <div className="value">
                                                                    <font>{this.state.dateOfOrder + "-" + this.state.monthOfOrder + "-" + this.state.yearOfOrder}</font>
                                                                </div>
                                                            </div>}
                                                            {/* {this.state.wholeData.order_details && this.state.wholeData.order_details.payment_method === "AFREKENEN IN WINKEL" && <div className="order-total-price-row txt--rit-dark">
                                                                <div className="item">
                                                                    <font style={{ textTransform: "uppercase" }}>Tijdstip: </font>
                                                                </div>
                                                                <div className="value">
                                                                    <font>{this.state.timeOfOrder}</font>
                                                                </div>
                                                            </div>} */}
                                                        </div>
                                                    </div>
                                                    <div className="order-total">
                                                        <div className="cart-footer-total">
                                                            <div className="cfin">
                                                                <font>TOTAAL INCLUSIEF BTW</font>
                                                            </div>
                                                            <div className="cfiv">
                                                                <font>€ {this.state.wholeData.order_details && this.state.wholeData.order_details.total_price}</font>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="btn-submit-checkout" onClick={() => window.location.href = "/"}>VERDER WINKELEN</button>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout-layout-right">
                                <div className="checkout-summary-inner">
                                    <h4 onClick={() => this.setState({ right_descInput: true })}>
                                        {!this.state.right_descInput ? this.state.right_desc : <textarea style={{ minHeight: 120, width: "100%", border: "none", outline: "none" }} onChange={(e) => this.setState({ right_desc: e.target.value })} onBlur={() => this.setState({ right_descInput: false })} >{this.state.right_desc}</textarea>}
                                    </h4>
                                    <div style={{ position: "relative" }}>
                                        {this.state.isAdmin && <div className="btn-editingUpload" style={{ left: 0, top: 20 }}>
                                            <div className="uploading-beu">
                                                <input type="file" onChange={(e) => this.imgUploading(e)} accept="image/*" />
                                                <PublishIcon />
                                            </div>
                                            {this.state.is_deleting && <div className="uploading-beu">
                                                <DeleteIcon onClick={this.removeImage.bind(this)} />
                                            </div>}
                                        </div>}
                                        {this.state.loader && <div className="loader-working-fb">
                                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                                        </div>}
                                        <img src={this.state.right_image} alt="rightoc" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.greaterSizeImage}
                    onClose={() => this.setState({ greaterSizeImage: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">File size exceeds 7MB</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default OrderConfirmation
