import React, { Component } from 'react'
import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import { gv } from '../Components/globalvar'
import Select from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import SaveIcon from '@material-ui/icons/Save';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';

export class ProductCollectionEdit extends Component {
    constructor() {
        super()
        this.state = {
            image_url: "",
            heading: "",
            headingInput: false,
            desc: "",
            descInput: false,
            buttonText: "IN WINKELMAND",
            buttonInput: false,
            delete_image: false,
            wholeData: '',
            mainArrs: [],
            nestedArrs: [],
            greaterLength: false,
            product: [],
            imageUploading: false,
            // footer section work
            spaceTop: true,
            spaceBottom: true,
            fullWidth: false,
            crop: {
                // unit: '%',
                width: 700,
                height: 765,
                aspect: 16 / 9,
            },
            loader: true,
            onImageIndex: 0,
        }
    }

    componentDidMount() {

        let browserType = ""
        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }


        let data = localStorage.getItem("currentDataEdit")
        if (data) {
            let parsedData = JSON.parse(data)
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let data = JSON.parse(headerData)
                let inArr = Object.entries(data.menus.child_menus)
                inArr.map((f) => {
                    if (f[0] === parsedData.main_category) {
                        let objectedFiltered = Object.entries(f[1])
                        objectedFiltered[0][1].filter((e) => {
                            if (e.id === parsedData.brand_id) {
                                this.setState({ nestedArrs: objectedFiltered[0][1] })
                            }
                        })
                    }
                })
            }
            let obj = {
                label: parsedData.product_sku,
                value: parsedData.product_sku,
            }

            this.setState({
                heading: parsedData.heading,
                desc: parsedData.desc,
                selectedBrand: parsedData.brand_id,
                arrSelected: obj,
                buttonText: parsedData.right_text,
                product_type: parsedData.product_type,
                spaceTop: parsedData.space_top,
                spaceBottom: parsedData.space_bottom,
                fullWidth: parsedData.full_width,
                selectedCategory: parsedData.main_category,
                onImageIndex: parsedData.onImageIndex,
                image_url: parsedData.left_image,
                sectionType: parsedData.section_type,
                delete_image: parsedData.checkImageUpload,
            })

            if (!parsedData.checkImageUpload) {
                var formdata = new FormData();
                formdata.append("main_category", parsedData.main_category);
                formdata.append("image_type", "left_banner");
                formdata.append("brand_id", parsedData.brand_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        if (result.message === "its demo image.") {
                            this.setState({ image_url: result.data.image_url })
                        } else {
                            this.setState({ image_url: result.data.image_url })
                        }
                    })
                    .catch(error => console.log('error', error));
            }

            var formdata = new FormData();
            if (parsedData.product_sku.length > 0) {
                formdata.append("product_skus", JSON.stringify(parsedData.product_sku));
            }
            formdata.append("product_type", parsedData.product_type);
            formdata.append("layout_type", "layout_1");
            formdata.append("main_category", parsedData.main_category);
            formdata.append("brand_id", parsedData.brand_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.data[0].short_desc) {
                            if (result.data.data[0].short_desc.length > 199) {
                                result.data.data[0].short_desc = `${result.data.data[0].short_desc.substr(0, 200)} ....`
                            }
                        }
                        if (result.data.data[0].long_desc) {
                            if (result.data.data[0].long_desc.length > 199) {
                                result.data.data[0].long_desc = `${result.data.data[0].long_desc.substr(0, 200)} ....`
                            }
                        }
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));

            let adminKey = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", adminKey);

            var formdata = new FormData();
            formdata.append("main_category", parsedData.main_category);
            formdata.append("brand_id", parsedData.brand_id);


            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/available/product/types?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (Number(result.data.data.sale_product) > 0) {
                        this.setState({ showSale: true })
                    } else {
                        this.setState({ showSale: false })
                    }
                    if (Number(result.data.data.latest_products) > 0) {
                        this.setState({ showLS: true })
                    } else {
                        this.setState({ showLS: false })
                    }
                })
                .catch(error => console.log('error', error));

        }

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let data = JSON.parse(headerData)
            this.setState({ mainArrs: data.main_categories })
        }


        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(result => {
                result.data.other_pages.map((e, i) => {
                    let page = localStorage.getItem("pageName")
                    if (page) {
                        if (e.route === `/${page}`) {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, loader: false })
                        }
                    } else {
                        if (e.route === "/") {
                            this.setState({ wholeData: e, sendingData: result.data, onIndex: i, loader: false })
                        }
                    }
                })
            })
            .catch(error => console.log('error', error));
    }

    uploadImg(e) {
        e.preventDefault()

        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            this.setState({ targettedFile: e.target.files[0] })
            reader.addEventListener('load', () => {
                let img = new Image();
                let width = ""
                let height = ""
                let result = reader.result
                img.onload = (e) => {
                    width = e.target.width
                    height = e.target.height
                    if (width > height) {
                        if (width > 700 && height > 850) {
                            let crop = {
                                unit: 'px',
                                width: 700,
                                height: 765,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        } else {
                            let crop = {
                                unit: 'px',
                                width: 700,
                                height: 620,
                                aspect: 16 / 9,
                                x: 0,
                                y: 0,
                            }
                            this.setState({ src: result, cropper: true, crop })
                        }
                    } else {
                        let crop = {
                            unit: '%',
                            width: 100,
                            height: 80,
                            aspect: 16 / 9,
                            x: 0,
                            y: 0,
                        }
                        this.setState({ src: result, cropper: true, crop })
                    }
                };
                img.src = result;
            });
            reader.readAsDataURL(e.target.files[0]);
            e.target.value = null
        }
    }

    removeImage() {
        const { image_url } = this.state
        let splitted = image_url.split("/")
        if (splitted[splitted.length - 1] !== "right_image.jpg") {
            this.setState({ imageUploading: true })
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", this.state.sectionType);
            formdata.append("image_name", splitted[splitted.length - 1]);
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/remove/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ image_url: result.data.images, onImageIndex: 0, delete_image: false, imageUploading: false, })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    selectChange(e) {
        this.setState({ onImageIndex: 0 })
        if (e !== "yes" && e !== "no") {
            this.setState({ selectedBrand: "" })
            if (this.state.arrSelected.length !== 0 && this.state.arrSelected.label) {
                this.setState({ open: true, dataChaning: e.target.value })
            } else {
                let headerData = localStorage.getItem("headerData")
                if (headerData) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    inArr.map((f) => {
                        if (f[0] === e.target.value) {
                            let objected = Object.entries(f[1])
                            if (e.target.value === "horloges") {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === "modellen"
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            } else {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === f[0]
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            }
                        }
                    })
                }
            }
        } else {
            if (e === "yes") {
                let headerData = localStorage.getItem("headerData")
                if (headerData) {
                    let data = JSON.parse(headerData)
                    let inArr = Object.entries(data.menus.child_menus)
                    inArr.map((f) => {
                        if (f[0] === this.state.dataChaning) {
                            let objected = Object.entries(f[1])
                            if (e.target.value === "horloges") {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === "MODELLEN"
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            } else {
                                let filtered = objected.filter((u) => {
                                    return u[0].toLowerCase() === f[0]
                                })
                                this.setState({ nestedArrs: filtered[0][1], selectedCategory: e.target.value, open: false, })
                            }
                        }
                    })
                }
            } else {
                this.setState({ open: false })
            }
        }
    }

    selectChangeBrand(e) {
        this.setState({ selectedBrand: e.target.value, product_type: "", product: [], arrSelected: [], arrSuggestions: [] })

        var formdata = new FormData();
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("image_type", "left_banner");
        formdata.append("brand_id", e.target.value);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/get/category/brand/image`, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({ image_url: result.data.image_url, delete_image: false })
            })
            .catch(error => console.log('error', error));

        let adminKey = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", adminKey);

        var formdata = new FormData();
        formdata.append("main_category", this.state.selectedCategory);
        formdata.append("brand_id", e.target.value);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/get/available/product/types?browser_type=${this.state.browserType}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                if (Number(result.data.data.sale_product) > 0) {
                    this.setState({ showSale: true })
                } else {
                    this.setState({ showSale: false })
                }
                if (Number(result.data.data.latest_products) > 0) {
                    this.setState({ showLS: true })
                } else {
                    this.setState({ showLS: false })
                }
            })
            .catch(error => console.log('error', error));
    }

    getskus(e) {
        if (e !== "") {
            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("sku", e);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/get/product/skus`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    let arr = []
                    resp.map((e) => {
                        let id = e.sku
                        let label = e.sku
                        let obj = {
                            value: id,
                            label
                        }
                        arr.push(obj)
                    })
                    this.setState({ arrSuggestions: arr })
                })
                .catch(error => console.log('error', error));
        }
    }

    saveBrands() {
        const { wholeData } = this.state
        let arr = []
        arr.push(this.state.arrSelected.value)
        let product_sku = arr
        let is_right_button = false
        if (this.state.buttonText) {
            is_right_button = true
        } else {
            is_right_button = false
        }

        this.setState({ loader: true })

        let obj = {}

        if (Array.isArray(this.state.image_url)) {
            obj = {
                type: "layout_1",
                product_sku,
                heading: this.state.heading,
                desc: this.state.desc,
                left_image: this.state.image_url[this.state.onImageIndex],
                is_right_button,
                right_text: this.state.buttonText,
                brand_id: this.state.selectedBrand,
                main_category: this.state.selectedCategory,
                product_type: this.state.product_type,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                full_width: this.state.fullWidth,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
            }
        } else {
            obj = {
                type: "layout_1",
                product_sku,
                heading: this.state.heading,
                desc: this.state.desc,
                left_image: this.state.image_url,
                is_right_button,
                right_text: this.state.buttonText,
                brand_id: this.state.selectedBrand,
                main_category: this.state.selectedCategory,
                product_type: this.state.product_type,
                space_top: this.state.spaceTop,
                space_bottom: this.state.spaceBottom,
                full_width: this.state.fullWidth,
                onImageIndex: this.state.onImageIndex,
                section_type: this.state.sectionType,
                checkImageUpload: this.state.delete_image,
            }
        }

        let currentIndex = localStorage.getItem("currentOrder")
        wholeData.sections.splice(currentIndex, 1, obj)

        this.state.sendingData.other_pages.splice(this.state.onIndex, 1, wholeData)
        let stringified = JSON.stringify(this.state.sendingData.other_pages)

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("builder_settings", stringified);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true, loader: false })
            });
    }

    getProductInfo(e) {
        if (e !== "sale" && e !== "latest") {
            this.setState({ arrSelected: e })

            let arrWork = [e.value]

            var formdata = new FormData();
            formdata.append("product_skus", JSON.stringify(arrWork));
            formdata.append("product_type", this.state.product_type);
            formdata.append("layout_type", "layout_1");
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.data[0].short_desc) {
                            if (result.data.data[0].short_desc.length > 199) {
                                result.data.data[0].short_desc = `${result.data.data[0].short_desc.substr(0, 200)} ....`
                            }
                        }
                        if (result.data.data[0].long_desc) {
                            if (result.data.data[0].long_desc.length > 199) {
                                result.data.data[0].long_desc = `${result.data.data[0].long_desc.substr(0, 200)} ....`
                            }
                        }
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ arrSelected: e })

            var formdata = new FormData();
            formdata.append("product_type", e);
            formdata.append("layout_type", "layout_1");
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        this.setState({ product: result.data.data })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                this.state.targettedFile.name
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                this.setState({ realBlob: blob })
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }

    doneImage() {
        let file = new File([this.state.realBlob], this.state.targettedFile.name, { type: this.state.targettedFile.type });

        if (file) {
            this.setState({ imageUploading: true })

            let accesstoken = localStorage.getItem("adminKey")

            var myHeaders = new Headers();
            myHeaders.append("accesstoken", accesstoken);

            var formdata = new FormData();
            formdata.append("section_type", this.state.sectionType);
            formdata.append("image", file);
            formdata.append("brand_id", this.state.selectedBrand);
            formdata.append("main_category", this.state.selectedCategory);
            formdata.append("image_type", "left_banner");

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/upload/section/image`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.data.image_url) {
                        this.setState({ croppedImageUrl: "", image_url: result.data.image_url, delete_image: true, imageUploading: false, cropper: false })
                    } else {
                        this.setState({ imageUploading: false })
                    }
                })
                .catch(error => console.log('error', error));
        }

    }

    showCurrentImage() {
        this.setState({ cropper: false, image_url: "https://saastoday.nl/public/brand_banner_images/horloges/diesel/right_image.jpg", croppedImageUrl: "" })
    }

    render() {
        const { crop } = this.state;
        return (
            <div className="second-content-last-cs sectiles" style={{ marginTop: 0 }}>
                {this.state.loader && <div className="loader-create-sections">
                    <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
                </div>}
                <div className="closer-working" onClick={() => this.setState({ headingInput: false, descInput: false, buttonInput: false })}>

                </div>
                {this.state.cropper ? <div style={{ position: "relative", zIndex: 99999999999, }}>
                    <ReactCrop
                        src={this.state.src}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                        locked={true}
                        keepSelection
                        maxHeight={500}
                    />
                    <div style={{ position: "absolute", bottom: 62 }} onClick={() => {
                        let elem = document.getElementsByClassName("ReactCrop__image")[0]
                        if (this.state.crop.height < elem.height - 25 && this.state.crop.width < elem.width - 25) {
                            this.state.crop.width = this.state.crop.width + 25
                            this.state.crop.height = this.state.crop.height + 25
                            let obj = {
                                width: this.state.crop.width,
                                height: this.state.crop.height,
                                aspect: 16 / 9,
                                x: this.state.crop.x > 25 ? this.state.crop.x - 25 : 0,
                                y: this.state.crop.y > 25 ? this.state.crop.y - 25 : 0,
                            }
                            this.setState({ crop: obj })
                        }
                    }}>
                        <ZoomInIcon style={{ fontSize: 36 }} />
                    </div>
                    <div style={{ position: "absolute", bottom: 62, left: 50 }} onClick={() => {
                        this.state.crop.width = this.state.crop.width - 25
                        this.state.crop.height = this.state.crop.height - 25
                        let obj = {
                            width: this.state.crop.width,
                            height: this.state.crop.height,
                            aspect: 16 / 9,
                            x: this.state.crop.x,
                            y: this.state.crop.y,
                        }
                        this.setState({ crop: obj })
                    }}>
                        <ZoomOutIcon style={{ fontSize: 36 }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 99999999999999999999999999, position: "absolute", top: 10, left: 10 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    <div className="file-uploadimg" style={{ zIndex: 9999999999999999999, position: "absolute", top: 10, left: 50, margin: "0px 10px" }}>
                        <DeleteIcon onClick={this.showCurrentImage.bind(this)} />
                    </div>
                    <div className="footer-allpopups-sections">
                        <div className="inner-faps" onClick={this.doneImage.bind(this)}>
                            <font>DONE</font>
                        </div>
                    </div>
                </div> : <div className="img-secc-last-cs" style={{ backgroundImage: `url(${Array.isArray(this.state.image_url) ? this.state.image_url[this.state.onImageIndex] : this.state.image_url})`, position: "relative", zIndex: 999999, marginBottom: 80 }} >
                    <div className="file-uploadimg" style={{ zIndex: 9999 }}>
                        <input type="file" onChange={(e) => this.uploadImg(e)} accept="image/*" />
                        <PublishIcon style={{ color: "white" }} />
                    </div>
                    {this.state.delete_image && <div className="file-uploadimg" style={{ zIndex: 9999, left: "96%", top: 75 }}>
                        <DeleteIcon onClick={this.removeImage.bind(this)} />
                    </div>}
                    {this.state.imageUploading && <div className="loader-working-fb">
                        <div className="lds-facebook"><div></div><div></div><div></div></div>
                    </div>}
                    {Array.isArray(this.state.image_url) && this.state.image_url.length > 1 && <div>
                        {this.state.onImageIndex > 0 && <div className="file-uploadimg" style={{ top: 80, zIndex: 9999, }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex - 1 })}>
                            <ChevronLeftIcon />
                        </div>}
                        {this.state.onImageIndex < this.state.image_url.length - 1 && <div className="file-uploadimg" style={{ top: 170, left: "102%", zIndex: 9999, position: "absolute" }} onClick={() => this.setState({ onImageIndex: this.state.onImageIndex + 1 })}>
                            <ChevronRightIcon />
                        </div>}
                    </div>}
                </div>}
                {!this.state.cropper && <div className="content-secc-last-cs" data-aos="fade-left" >
                    <div className="slider-inner-wrap" style={{ zIndex: 999999 }}>
                        <div className="secchecks">
                            {this.state.heading ? <h3 onClick={() => this.setState({ headingInput: true })} onBlur={() => this.setState({ headingInput: false })}>
                                {!this.state.headingInput ? this.state.heading : <input type="text" autoFocus value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value })} onBlur={() => this.setState({ headingInput: false })} />}
                            </h3> : <input type="text" style={{ backgroundColor: "#e7e7e7", marginBottom: 10 }} value={this.state.heading} onChange={(e) => this.setState({ heading: e.target.value, headingInput: true })} onBlur={() => this.setState({ headingInput: false })} />}
                        </div>
                        <div className="secchecks">
                            {this.state.desc ? <p onClick={() => this.setState({ descInput: true })} onBlur={() => this.setState({ descInput: false })}>
                                {!this.state.descInput ? this.state.desc : <textarea autoFocus onChange={(e) => this.setState({ desc: e.target.value })} onBlur={() => this.setState({ descInput: false })}>
                                    {this.state.desc}
                                </textarea>}
                            </p> : <textarea style={{ backgroundColor: "#e7e7e7" }} onChange={(e) => this.setState({ desc: e.target.value, descInput: true })} onBlur={() => this.setState({ descInput: false })}>
                                {this.state.desc}
                            </textarea>}
                        </div>
                        <div className="secchecks" style={{ width: "100%", padding: 20 }}>
                            <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={this.selectChange.bind(this)}>
                                <option>Select Category</option>
                                {this.state.mainArrs.map((e) => {
                                    if (e.show_for_products) {
                                        return <option key={Math.random()} selected={this.state.selectedCategory === e.id} value={e.id}>{e.id}</option>
                                    }
                                })}
                            </select>
                        </div>
                        {this.state.nestedArrs.length > 0 && <div className="secchecks" style={{ width: "100%", padding: "0px 20px", paddingBottom: 20 }}>
                            <select className="bodyText" style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", letterSpacing: 1 }} onChange={this.selectChangeBrand.bind(this)}>
                                <option>Select Sub Category</option>
                                {this.state.nestedArrs.map((e) => {
                                    return <option key={Math.random()} selected={this.state.selectedBrand === e.id} value={e.id}>{e.name}</option>
                                })}
                            </select>
                        </div>}
                        {this.state.selectedBrand && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20, textAlign: "left" }}>
                            <select value={this.state.product_type} style={{ width: "100%", padding: 10, textTransform: "uppercase", fontFamily: "Roboto", marginBottom: 20, letterSpacing: 1 }} onChange={(e) => {
                                if (e.target.value !== "sku") {
                                    this.setState({ product_type: e.target.value, arrSelected: [], })
                                    this.getProductInfo(e.target.value)
                                } else {
                                    this.setState({ product_type: e.target.value })
                                }
                            }}>
                                <option>Select Product Type</option>
                                {this.state.showLS && <option value="sku">SKU</option>}
                                {this.state.showSale && <option value="sale">Sale</option>}
                                {this.state.showLS && <option value="latest">Latest</option>}
                            </select>
                            {this.state.product_type === "sku" && <Select
                                // isMulti
                                name="colors"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                options={this.state.arrSuggestions}
                                value={this.state.arrSelected}
                                onChange={(e) => {
                                    this.getProductInfo(e)
                                }}
                                onInputChange={(e) => {
                                    this.getskus(e)
                                }}
                            />}
                        </div>}
                        {this.state.greaterLength && <div style={{ width: "100%", padding: "0px 20px", paddingBottom: 20, textAlign: "left" }}>
                            <p className="bodyText" style={{ fontFamily: "Roboto", fontSize: 13, color: "red" }}>only 1 sku is required</p>
                        </div>}
                        {this.state.product.length > 0 && this.state.product.map((e) => {
                            return <div className="card-cslc" key={Math.random()}>
                                <div className="card-cs-img">
                                    <img src={e.image_url} alt="pi" width="100%" height="100%" />
                                </div>
                                <div className="content-cslc">
                                    <span style={{ textTransform: "uppercase" }} className="heading-hss">{e.name}</span>
                                    {e.short_desc ? <h1>{e.short_desc}</h1> : <h1>{e.long_desc}</h1>}
                                    {!!e && e.sale_price && Number(e.sale_price) !== 0 && <p style={{ textDecorationLine: "line-through", fontSize: 18, color: "#b2b2b2" }} >€ {e.show_retail_price}</p>}
                                    {!!e && e.sale_price && Number(e.sale_price) !== 0 ? <p>€ {e.show_sale_price}</p> : !!e && <p>€ {e.show_retail_price}</p>}
                                    <div className="secchecks" style={{ textAlign: "center" }}>
                                        {!this.state.buttonInput ? <button onClick={() => this.setState({ buttonInput: true })} onBlur={() => this.setState({ buttonInput: false })}>{this.state.buttonText}</button>
                                            : <button>
                                                <input value={this.state.buttonText} autoFocus onChange={(e) => this.setState({ buttonText: e.target.value })} onBlur={() => this.setState({ buttonInput: false })} />
                                            </button>}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </div>}
                {!this.state.cropper && !this.state.loader && <div className="footer-allpopups-sections">
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceBottom} onChange={() => {
                            this.setState({ spaceBottom: !this.state.spaceBottom })
                        }} />
                        <font>RUIMTE ONDER</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.spaceTop} onChange={() => {
                            this.setState({ spaceTop: !this.state.spaceTop })
                        }} />
                        <font>RUIMTE BOVEN</font>
                    </div>
                    <div className="inner-faps">
                        <Switch color="primary" inputProps={{ 'aria-label': 'primary checkbox' }} checked={this.state.fullWidth} onChange={() => {
                            this.setState({ fullWidth: !this.state.fullWidth })
                        }} />
                        <font>PAGINABREEDTE</font>
                    </div>
                    {this.state.product.length > 0 && <div className="inner-faps" onClick={this.saveBrands.bind(this)}>
                        <SaveIcon />
                        <font>SAVE</font>
                    </div>}
                </div>}
                <Dialog
                    open={this.state.smallNotification}
                    onClose={() => this.setState({ smallNotification: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999, padding: 30 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Minimum size for this image should be 700x765, kindly provide valid image</DialogContentText>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.open}
                    onClose={() => this.setState({ open: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">Als je gaat wijzigen van categorie, dan dien je opnieuw de merken te selecteren.</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.selectChange.bind(this, "no")} color="primary">No</Button>
                        <Button onClick={this.selectChange.bind(this, "yes")} color="primary">Yes</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default ProductCollectionEdit
