import React, { Component } from 'react'
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import EventNoteIcon from '@material-ui/icons/EventNote';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { gv } from '../../Components/globalvar'
import CloseIcon from '@material-ui/icons/Close';
import './index.css'
import Leftmenu from '../../Components/Leftmenu';
import CompleteNavbar from '../../Components/CompleteNavbar';

export class EditProfile extends Component {
    constructor() {
        super()
        this.state = {
            firstName: "",
            fnPopup: false,
            lastName: "",
            lnPopup: false,
            password: "",
            phone: "",
            phonePopup: false,
            sex: "",
            sexPopup: false,
            confirmPassword: "",
            cpPopup: false,
            passwordType: true,
            passwordTypeSec: true,
            arr: [1, 2],
            modal: false,
            wholeData: []
        }
    }

    componentDidMount() {
        let usertoken = localStorage.getItem("usertoken")
        if (!usertoken) {
            window.location.href = "/login"
        }
        let wholeData = JSON.parse(localStorage.getItem("dashboardData"))
        if (wholeData) {
            this.setState({ wholeData })
        }
        this.getData()
    }

    getData() {
        let usertoken = localStorage.getItem("usertoken")
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", `Bearer ${usertoken}`);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };


        fetch(`${gv}/api/v1/auth/getuser`, requestOptions)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                console.log(resp)
                localStorage.setItem("dashboardData", JSON.stringify(resp))
                this.setState({ firstName: resp.name, lastName: resp.last_name, email: resp.email, phone: resp.phone, sex: resp.sex })
            })
            .catch(error => console.log('error', error));
    }

    updateAddress() {
        const { firstName, lastName, email, password, confirmPassword, sex, phone } = this.state
        if (firstName !== "" && lastName !== "" && email !== "" && sex !== "" && phone !== "" && password === confirmPassword) {
            let usertoken = localStorage.getItem("usertoken")
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("name", firstName);
            formdata.append("last_name", lastName);
            formdata.append("email", email);
            if (password === confirmPassword) {
                formdata.append("password", password);
                formdata.append("confirm_password", confirmPassword);
            }
            formdata.append("sex", sex);
            formdata.append("phone", phone);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/update/details`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.getData()
                    this.setState({ fnPopup: false, lnPopup: false, sexPopup: false, phonePopup: false, cpPopup: false })
                })
                .catch(error => console.log('error', error));
        } else {
            if (firstName === "") {
                this.setState({ fnPopup: true })
            } else {
                this.setState({ fnPopup: false })
            }
            if (lastName === "") {
                this.setState({ lnPopup: true })
            } else {
                this.setState({ lnPopup: false })
            }
            if (sex === "") {
                this.setState({ sexPopup: true })
            } else {
                this.setState({ sexPopup: false })
            }
            if (phone === "") {
                this.setState({ phonePopup: true })
            } else {
                this.setState({ phonePopup: false })
            }
            if (password !== confirmPassword) {
                this.setState({ cpPopup: true })
            } else {
                this.setState({ cpPopup: false })
            }
        }
    }

    render() {
        return (
            <div>
                <CompleteNavbar />
                <div className="account-main">
                    <div className="account-wrapper">
                        <h1 className="account-title" style={{ display: "flex", alignItems: "center" }}>
                            <span className="icon-user">
                                <AccountCircleOutlinedIcon style={{ fontSize: 34 }} />
                            </span>
                            <font style={{ textTransform: "uppercase" }}>{this.state.wholeData.name} {this.state.wholeData.last_name}</font>
                        </h1>
                        <div className="account-left-content">
                            <Leftmenu />
                        </div>
                        <div className="account-right-content">
                            <div className="create-account-wrapper">
                                <div className="ca-inner-wrapper">
                                    <div className="ca-container">
                                        <div className="ca-content">
                                            <div className="personal-details-container" style={{ marginLeft: 0 }}>
                                                <div className="section-title">
                                                    <h2>
                                                        <EventNoteIcon style={{ position: "absolute", transform: "translateY(-50%)", top: "50%", left: 2, color: "rgba(0,0,0,.6)" }} />
                                                        My details
                                                    </h2>
                                                </div>
                                                {/* Address Block */}
                                                <div className="content-wrapper-rc" style={{ marginTop: 40, marginBottom: 40 }}>
                                                    {this.state.arr.length > 0 ? <div className="address-list-container" style={{ padding: 0 }}>
                                                        <ul className="address-list">
                                                            <li className="address-tile default">
                                                                <h3 className="secondary-title">
                                                                    ADDRESS MYRITUALS PROFILE
                                                                </h3>
                                                                <div className="address-wrapper">
                                                                    <div className="mini-address-name">
                                                                        <font>Maurice Reijersen</font>
                                                                    </div>
                                                                    <div className="mini-address-name">

                                                                    </div>
                                                                    <div className="mini-address-location">
                                                                        <address>
                                                                            <font>Winde 18</font>
                                                                            <br />
                                                                            <font>Borne, 7623 NE</font>
                                                                            <br />
                                                                            <font>The Netherlands</font>
                                                                        </address>
                                                                    </div>
                                                                    <div className="address-actions">
                                                                        <a>
                                                                            Modify
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <div className="btn-container">
                                                                    <button onClick={() => {
                                                                        this.setState({ modal: true });
                                                                    }}>ADD ADDRESS</button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div> : <div className="no-addresses">
                                                            <p>There are currently no addresses associated with your account.</p>
                                                            <div className="btn-container">
                                                                <button onClick={() => {
                                                                    this.setState({ modal: true });
                                                                }}>ADD ADDRESS</button>
                                                            </div>
                                                        </div>}
                                                </div>
                                                <div className="form-ca" style={{ backgroundColor: "white", padding: 20 }}>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>E-mail</label>
                                                            <input className="read-only" readOnly type="text" value={this.state.email} />
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>Sex</label>
                                                            <select value={this.state.sex} onChange={(e) => this.setState({ sex: e.target.value })} >
                                                                <option value="">Select</option>
                                                                <option value="men">Man</option>
                                                                <option value="woman">Woman</option>
                                                                <option value="other">Other</option>
                                                            </select>
                                                            <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                                <ExpandMoreIcon />
                                                            </button>
                                                        </div>
                                                        {this.state.sexPopup && <span className="alert-fields">Field is required.</span>}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>First Name</label>
                                                            <input type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                                        </div>
                                                        {this.state.fnPopup && <span className="alert-fields">First name is required.</span>}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>Last Name</label>
                                                            <input type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                                        </div>
                                                        {this.state.lnPopup && <span className="alert-fields">Last name is required.</span>}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>New Password</label>
                                                            <label>Use 8-20 characters. That's the only rule for a password.</label>
                                                            <input type={this.state.passwordType ? "password" : "text"} value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                                            <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                                {this.state.passwordType ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label>Confirm Password</label>
                                                            <label>Use 8-20 characters. That's the only rule for a password.</label>
                                                            <input type={this.state.passwordTypeSec ? "password" : "text"} value={this.state.confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} />
                                                            <button className="password-reveal" onClick={() => this.setState({ passwordTypeSec: !this.state.passwordTypeSec })}>
                                                                {this.state.passwordTypeSec ? <VisibilityOffIcon style={{ fontSize: 20 }} /> : <VisibilityIcon style={{ fontSize: 20 }} />}
                                                            </button>
                                                        </div>
                                                        {this.state.cpPopup && <span className="alert-fields">Password and confirm password should matched.</span>}
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="field-wrapper">
                                                            <label><span>Telephone Number </span><span style={{ color: "#767676", fontSize: 10, letterSpacing: 0.4 }}>Optional</span></label>
                                                            <label>So that we can text you updates about your order</label>
                                                            <input type="number" placeholder="Voorbeeld: +31621345678" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                                        </div>
                                                        {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>}
                                                    </div>
                                                    <div className="form-row form-row-button">
                                                        <button onClick={this.updateAddress.bind(this)}>SAVE</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.state.modal && <div style={{ position: "fixed", top: 0, left: 0, right: 0, bottom: 0, width: "100%", height: "100%", backgroundColor: "rgba(0,0,0,0.8)" }} onClick={() => this.setState({ modal: false })}></div>}
                    <div className={`generic-dialog-flyout ${this.state.modal ? "open" : ""}`}>
                        <div className="generic-flyout-content">
                            <div className="address-flyout-inner">
                                <h2 className="edit-address-title">
                                    Add address
                            </h2>
                                <fieldset>
                                    <div className="addresslist-fieldset-inner">
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>First Name</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Last Name</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Sex</label>
                                                <select>
                                                    <option>Man</option>
                                                    <option>Woman</option>
                                                    <option>Other</option>
                                                </select>
                                                <button className="password-reveal" onClick={() => this.setState({ passwordType: !this.state.passwordType })}>
                                                    <ExpandMoreIcon />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="form-row three-inline">
                                            <div className="inputfield-row">
                                                <label>Postal Code</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row three-inline">
                                            <div className="inputfield-row">
                                                <label>House Number</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row three-inline">
                                            <div className="inputfield-row">
                                                <label>Addition <span className="bodyText" style={{ color: "#ccc", fontSize: 11, fontFamily: "Roboto", marginLeft: 5 }}>Optional</span> </label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>Street</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="inputfield-row">
                                                <label>City</label>
                                                <input className="inputfield-text" />
                                            </div>
                                        </div>
                                        <div className="form-row form-row-button">
                                            <button className="btn cancelbtn">
                                                Cancel
                                        </button>
                                            <button className="btn confirmbtn">
                                                Confirm
                                        </button>
                                        </div>
                                    </div>
                                </fieldset>
                                <button className="slide-close" onClick={() => this.setState({ modal: false })}>
                                    <CloseIcon style={{ color: "#453f3f", fontSize: 26 }} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default EditProfile
