import React, { Component } from 'react'
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { gv } from '../Components/globalvar'

export class HorizontalProductsData extends Component {

    constructor() {
        super()
        this.state = {
            transition: false,
            allProducts: [],
            right: false,
            rightSecond: false,
            selectedTemplate: '',
            data: '',
        }
    }


    slideLeft() {
        let element = document.getElementById(`first-slider${this.props.indexes}`)
        element.scrollLeft += 200
        if (element.scrollLeft > -100) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    slideRight() {
        let element = document.getElementById(`first-slider${this.props.indexes}`)
        element.scrollLeft -= 200
        if (element.scrollLeft > 250) {
            this.setState({ right: true })
        } else {
            this.setState({ right: false })
        }
    }

    componentDidMount() {

        let browserType = ""
        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }

        this.setState({ data: this.props.data })
        let products_skus = JSON.stringify(this.props.data.product_skus)

        if (products_skus) {
            var formdata = new FormData();
            if (this.props.data.product_skus.length > 0) {
                formdata.append("product_skus", products_skus);
            }
            formdata.append("product_type", this.props.data.product_type);
            formdata.append("layout_type", "layout_7");
            formdata.append("brand_id", this.props.data.brand_id);
            formdata.append("main_category", this.props.data.main_category);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/get/product/by/skus?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ allProducts: result.data.data })
                })
                .catch(error => console.log('error', error));
        }
    }

    render() {
        return (
            <div className="third-sec-main horizontal-products" style={{ paddingTop: 0, margin: "0px auto", paddingBottom: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, paddingLeft: 0, paddingRight: 0, maxWidth: 1280, position: "relative", backgroundColor: "white" }}>
                {!this.props.data.left_area && <div className="sp-card hiddenMob" style={{ width: "100%", textAlign: "center" }}>
                    <h2>{this.state.data.heading1}</h2>
                </div>}
                <div className="slider-products" id={`first-slider${this.props.indexes}`} style={{ marginTop: 0 }}>
                    {this.state.allProducts.length > 0 && this.state.allProducts.map((e) => {
                        return <div className="sp-card" key={Math.random()} onClick={() => {
                            window.location.href = `/product-detail/${e.main_category.toLowerCase()}/${e.brand_id}/${e.plain_sku}`
                        }}>
                            <div className="img-cardsp">
                                <img alt="showingyet" src={e.image_url} />
                            </div>
                            <div className="content-cardsp">
                                <h1 className="product-title">{e.name}</h1>
                                <p className="product-price" style={{ marginTop: 10 }}>
                                    {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) && <font style={{ textDecorationLine: "line-through", marginBottom: 10 }}>
                                        € {e.show_retail_price}
                                    </font>}
                                    <br />
                                    <font>
                                        € {e.sale_price !== "0.00" && Number(e.sale_price) < Number(e.retail_price) ? e.show_sale_price : e.show_retail_price}
                                    </font>
                                </p>
                            </div>
                        </div>
                    })}
                    {this.state.allProducts.length > 0 && !this.props.data.buttonHidden && <button className="shop-sp-btn" style={{ bottom: 20 }} onClick={() => window.location.href = `${this.props.data.main_category}/${this.props.data.brand_id}`}>SHOP {this.props.data.brand_id} </button>}
                </div>
                {this.state.allProducts.length > 0 && this.state.allProducts.length > 4 && <div className="slider-actions">
                    <button className={`slider-button ${!this.state.right && "hidden"}`} style={{ backgroundColor: this.props.data.arrowBackgroundColor }} onClick={this.slideRight.bind(this, "first")}>
                        <ChevronLeftIcon style={{ fontSize: 44, color: this.props.data.arrowIconColor }} />
                    </button>
                    <button className="slider-button" style={{ backgroundColor: this.props.data.arrowBackgroundColor }} onClick={this.slideLeft.bind(this, "first")}>
                        <ChevronRightIcon style={{ fontSize: 44, color: this.props.data.arrowIconColor }} />
                    </button>
                </div>}
            </div>
        )
    }
}

export default HorizontalProductsData
