import React, { Component } from 'react';
import NavCheck from '../../Components/NavCheck'
import CheckIcon from '@material-ui/icons/Check';
import { gv } from '../../Components/globalvar';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './index.css';
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Radio, { RadioProps } from '@material-ui/core/Radio';

export class Payment extends Component {


    constructor() {
        super()
        this.state = {
            wholeData: '',
            useragent: '',
            bank_id: '',
            method: '',
            bankPopup: false,
            // Edit Working
            isAdmin: false,
            heading_1: "",
            heading_1Input: false,
            button_text: "",
            button_textInput: false,
            headerData: "",
            cartNav: "",
        }


        this.getData = this.getData.bind(this);
    }



    componentDidMount() {
        this.getData()


        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            this.setState({ headerData: JSON.parse(headerData) })
        }

        let cartNav = localStorage.getItem("cartNav")
        if (cartNav) {
            this.setState({ cartNav: JSON.parse(cartNav) })
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        } else {
            this.setState({ isAdmin: false })
        }

    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType, useragent: ua })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/twopointone?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    let monthOfOrder = ""
                    let yearOfOrder = ""
                    let dateOfOrder = ""
                    let timeOfOrder = ""
                    if (resp.order_timings) {
                        let dayTimeOfOrder = JSON.parse(resp.order_timings)
                        monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                        yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                        dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                        timeOfOrder = dayTimeOfOrder.time
                    }
                    this.setState({
                        wholeData: resp, heading_1: resp.cart_heading_1, button_text: resp.button_text, coupon_code: resp.coupon_settings.coupon_code, monthOfOrder,
                        yearOfOrder,
                        dateOfOrder,
                        timeOfOrder,
                    })
                })
                .catch(error => console.log('error', error));
        } else {
            window.location.href = "/cart"
        }
    }

    removeCoupon() {
        let usertoken = localStorage.getItem("usertoken")

        console.log(this.state.coupon_code)

        if (!usertoken) {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));

        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));
        }
    }

    toPay() {
        const { useragent, bank_id, method } = this.state
        if (bank_id !== "" && method !== "") {
            let usertoken = localStorage.getItem("usertoken")
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);


            var formdata = new FormData();
            formdata.append("bank_id", bank_id);
            formdata.append("method", method);
            formdata.append("user_agent", useragent);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/pay/order?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    window.location.href = result.data.redirect_url
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ bankPopup: true })
        }
    }

    updateSec() {
        const { heading_1, button_text } = this.state

        let arr = [
            { key: "payment_page_cart_heading_1", value: heading_1 },
            { key: "payment_page_cart_button_text", value: button_text },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }



    render() {

        return (
            <div className="checkoutfooterhide">
                <NavCheck />
                {this.state.wholeData && <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>}
                <div className="checkout-holder">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="checkout-layout-left">
                                <div className="back-button-wrapper">

                                </div>
                                <div className="checkout-step-buttons-holder">
                                    <div className="checkout-steps-buttons-list">
                                        <div className="checkout-step-btn">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                <CheckIcon style={{ fontSize: 16 }} />
                                            </span>
                                            <span className="text">Shopping</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                2
                                            </span>
                                            <span className="text">Bezorging</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">3</span>
                                            <span className="text">Controleren</span>
                                        </div>
                                        <div className="checkout-step-btn selected">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">4</span>
                                            <span className="text">Betaling</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        {this.state.isAdmin ? <h2 className="checkout-step-title">
                                            <span className="number">4</span>
                                            <span className="text" onClick={() => this.setState({ heading_1Input: true })}>
                                                {!this.state.heading_1Input ? this.state.heading_1 : <input type="text" autoFocus value={this.state.heading_1} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </span>
                                        </h2> : <h2 className="checkout-step-title">
                                            <span className="number">4</span>
                                            <span className="text">
                                                {this.state.heading_1}
                                            </span>
                                        </h2>}
                                        <div className="rituals-payment-options">
                                            {this.state.wholeData.payment_methods &&
                                                this.state.wholeData.payment_methods.length > 0 &&
                                                this.state.wholeData.payment_methods.map((e) => {
                                                    if (e.banks.length > 0) {
                                                        return <Accordion>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                id={'panel' + Math.random() + 'a-header'}
                                                                style={{ paddingLeft: 40 }}
                                                            >
                                                                <Typography ><span className="icon-holder"><img src={e.logo.size2x} alt="banklogo" /></span>{e.name}</Typography></AccordionSummary>
                                                            {e.banks.map((banks) => {
                                                                return (<AccordionDetails>
                                                                    <Typography>
                                                                        <label className="checkout-payment-radio"  >
                                                                            {/* <input type="radio" name="brandCode" className="input-radio" checked={this.state.bank_id === banks.value && true} /> */}
                                                                            <span className="icon-holder">
                                                                                <img src={banks.logo.size2x} alt="banklogo" />
                                                                            </span>
                                                                            <span className="payment-method-name">
                                                                                <font>{banks.name}</font>
                                                                            </span>
                                                                            <Radio
                                                                                checked={this.state.bank_id === banks.value && this.state.method === e.value}
                                                                                onClick={() => {
                                                                                    this.setState({ bank_id: banks.value })
                                                                                    this.setState({ method: e.value })
                                                                                }}
                                                                                value={banks.value}
                                                                                name="brandCode"
                                                                                inputProps={{ 'aria-label': banks.value }}
                                                                            />

                                                                        </label>
                                                                    </Typography>
                                                                </AccordionDetails>);
                                                            })}
                                                        </Accordion>
                                                    } else {
                                                        return <Accordion
                                                            expanded={false}
                                                            style={{ backgroundColor: "white", opacity: 1 }}>
                                                            <AccordionSummary
                                                                aria-controls={'panela-content' + Math.random()}
                                                                id={'panela-header' + Math.random()}
                                                                style={{ backgroundColor: "white", opacity: 1 }}
                                                            >
                                                                <Typography style={{ color: "black" }}>
                                                                    <label className="checkout-payment-radio" >

                                                                        <span className="icon-holder">
                                                                            <img src={e.logo.size2x} alt="banklogo" />
                                                                        </span>
                                                                        <span className="payment-method-name">
                                                                            <font>{e.name}</font>
                                                                        </span>
                                                                        <Radio
                                                                            checked={this.state.bank_id === 'empty' && this.state.method === e.value}
                                                                            onClick={() => {
                                                                                this.setState({ bank_id: 'empty' })
                                                                                this.setState({ method: e.value })
                                                                            }}
                                                                            value={e.value}
                                                                            name="brandCode"
                                                                            inputProps={e.name}
                                                                        />

                                                                    </label>
                                                                </Typography>
                                                            </AccordionSummary>
                                                        </Accordion>
                                                    }

                                                })}
                                            {this.state.bankPopup && <p style={{ textAlign: "left", color: "red", letterSpacing: 1, marginTop: 8 }}>Please select a payment method</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="checkout-layout-right">
                                <div className="checkout-summary-inner">
                                    <h3 className="section-header" style={{ marginBottom: 0 }}>
                                        <font><a href="/cart">WIJZIG</a> MIJN WINKELMAND</font>
                                    </h3>
                                    <div className="checkout-summary-minicart">
                                        {this.state.wholeData.cart_items && this.state.wholeData.cart_items.map((e) => {
                                            return <div className="mini-cart-product" style={{ padding: 15 }} key={Math.random()}>
                                                <div className="mini-cart-image">
                                                    <img src={e.image_url} alt="mci" />
                                                </div>
                                                <div className="mini-cart-info">
                                                    <div className="mini-cart-small-title">
                                                        <font>{e.name}</font>
                                                    </div>
                                                    <div className="mini-cart-name">
                                                        <font>{e.plain_sku}</font>
                                                    </div>
                                                    <div className="mini-cart-details-bottom">
                                                        <div className="mini-cart-pricing">
                                                            <span className="label">Aantal: </span>
                                                            <span className="value">{e.qty}</span>
                                                        </div>
                                                        <div className="mini-cart-price-wrapper">
                                                            {Number(e.sale_price) !== 0 ? <span className="mini-cart-price">€ {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="checkout-summary-order-totals">
                                        <div className="order-totals-table">
                                            <div className="order-SUBTOTAAL">
                                                <div className="cfin">
                                                    <font>Totaal</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>€ {this.state.wholeData.total_retail_price}</font>
                                                </div>
                                            </div>
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option ? <div className="checkout-summary-price-row">
                                                {(() => {
                                                    if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "home_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_1}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_1_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "collection_point") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_2}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_2_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "local_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_3}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_3_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })()}
                                            </div> : <div className="checkout-summary-price-row">
                                                <div className="cfin">
                                                    <font>{this.state.wholeData.delivery_desc_1}</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>{this.state.wholeData.delivery_desc_1_price}</font>
                                                </div>
                                            </div>}
                                            {this.state.wholeData.coupon_settings && this.state.wholeData.coupon_settings.coupon_discount && <div className="checkout-summary-price-row">
                                                <span className="cfin">
                                                    Kortingscode
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.coupon_settings.coupon_code && <font style={{ color: "#865", textDecoration: "underline", textTransform: "uppercase", fontSize: 12, cursor: "pointer", marginRight: 30 }} className="bodyText" onClick={this.removeCoupon.bind(this)}>
                                                        verwijderen
                                                        </font>}
                                                    € {this.state.wholeData.coupon_settings.coupon_discount}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.gift_settings && this.state.wholeData.gift_settings.gift_price && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Cadeauverpakking
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.gift_settings.gift_price}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Datum
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.dateOfOrder + "-" + this.state.monthOfOrder + "-" + this.state.yearOfOrder}
                                                </span>
                                            </div>}
                                            {/* {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Tijd
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.timeOfOrder}
                                                </span>
                                            </div>} */}
                                            <div className="order-total">
                                                <div className="cart-footer-total">
                                                    <div className="cfin">
                                                        <font>Totaal incl. btw</font>
                                                    </div>
                                                    <div className="cfiv">
                                                        <font>{this.state.wholeData.default_total}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shipping-info">
                                            <div className="content-asset" style={{ paddingLeft: 15 }}>
                                                <ul>
                                                    {this.state.cartNav && this.state.cartNav.detail_page_delivery_text && this.state.online_payment && <li style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.cartNav.detail_page_delivery_text}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_1 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_1}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_2 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_2}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_3 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_3}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.pick_up_store && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.pick_up_store}
                                                        </span>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-footer-cart">
                    <div className="sticky-order-container">
                        <div className="details-container">
                            <span style={{ marginRight: 7 }}>{this.state.wholeData.total_products}</span>
                            <span>PRODUCT</span>
                        </div>
                        <div className="price-container">
                            <div style={{ display: "flex", alignItems: "center", marginleft: 10 }} >
                                <div className="order-total-label">
                                    <span>Total</span>
                                </div>
                                <div className="order-total-amount">
                                    <span>
                                        {this.state.wholeData.default_total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-button-container" onClick={this.toPay.bind(this)}>
                        <span>
                            {this.state.button_text}
                        </span>
                    </div>
                </div>
                {this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                    <SaveAltIcon />
                    <button onClick={this.updateSec.bind(this)}>SAVE</button>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Payment
