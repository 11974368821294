import React, { Component } from 'react'
import NavCheck from '../../Components/NavCheck'
import CheckIcon from '@material-ui/icons/Check';
import { gv } from '../../Components/globalvar'
import cities from '../../Data/cities.json'
import countries from '../../Data/countries.json'
import statesCountry from '../../Data/states.json'
import { Helmet } from "react-helmet";
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import './index.css'
import CloseIcon from '@material-ui/icons/Close';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

export class Delivery extends Component {

    constructor() {
        super()
        this.state = {
            popupright: false,
            wholeData: [],
            addressType: '',
            country: '',
            region: '',
            countries: [],
            emailWants: false,
            email: '',
            emailPopup: false,
            countryid: '155',
            allCities: [],
            allCitiesSec: [],
            stateid: '',
            statePopup: false,
            firstName: '',
            fnPopup: false,
            lastName: '',
            lnPopup: false,
            zipcode: '',
            zipPopup: false,
            houseNumber: '',
            hnPopup: false,
            country: '',
            countryPopup: false,
            city: '',
            cityPopup: false,
            street: '',
            streetPopup: false,
            phone: '',
            phonePopup: false,
            sex: '',
            sexPopup: false,
            dob: '',
            dobPopup: false,
            passwordType: true,
            passwordTypeSec: true,
            nestedFields: false,
            countryidSec: '155',
            countryPopupSec: false,
            streetSec: '',
            streetPopupSec: false,
            zipcodeSec: '',
            zipPopupSec: false,
            houseNumberSec: '',
            hnPopupSec: false,
            citySec: '',
            cityPopupSec: false,
            // Login
            logInchecked: false,
            emailLogin: '',
            passwordLogin: '',
            emailLoginPopup: false,
            passwordLoginPopup: false,
            emailTaken: false,
            // Editing Working
            heading_1: "",
            heading_1Input: false,
            heading_2: "",
            heading_2Input: false,
            order_overview: "",
            order_overviewInput: false,
            to_deliver: "",
            to_deliverInput: false,
            isAdmin: false,
            loginMessage: "",
            loginShowMessage: false,
            forgetTextWork: false,
            headerData: "",
            cartNav: "",
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        this.getData()

        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            this.setState({ headerData: JSON.parse(headerData) })
        }

        let cartNav = localStorage.getItem("cartNav")
        if (cartNav) {
            this.setState({ cartNav: JSON.parse(cartNav) })
        }

        let adminKey = localStorage.getItem("adminKey")
        if (adminKey) {
            this.setState({ isAdmin: true })
        }
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }
        this.setState({ browserType })

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/submit/checkout/step/one?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    let resp = result.data.data
                    let countryid = ""
                    let allCities = []
                    let allCitiesSec = []
                    this.setState({ wholeData: resp, heading_1: resp.cart_heading_1, heading_2: resp.cart_heading_2, order_overview: resp.delivery_page_order_overview, to_deliver: resp.to_deliver, coupon_code: resp.coupon_settings.coupon_code })
                    if (resp.shipping_address) {
                        countryid = countries.filter((e) => {
                            return e.name === resp.shipping_address.country
                        })
                        let statesC = statesCountry.filter((f) => {
                            return f.country_id === Number(countryid[0].id)
                        })
                        statesC.filter((g) => {
                            cities.map((h) => {
                                if (g.id === h.state_id) {
                                    allCities.push(h)
                                    allCitiesSec.push(h)
                                }
                            })
                        })
                        this.setState({
                            allCities,
                            allCitiesSec,
                            popupright: true,
                            addressType: "shipping",
                            firstName: resp.shipping_address.first_name,
                            lastName: resp.shipping_address.last_name,
                            zipcode: resp.shipping_address.zip_code,
                            houseNumber: resp.shipping_address.house_number,
                            street: resp.shipping_address.street,
                            phone: resp.shipping_address.phone,
                            countryid: countryid[0].id,
                            city: resp.shipping_address.city,
                        })
                    }
                    if (resp.delivery_settings && resp.delivery_settings.delivery_option === "collection_point") {
                        console.log(resp)
                        this.setState({
                            firstName: resp.user_info.first_name,
                            lastName: resp.user_info.last_name,
                            phone: resp.user_info.phone,
                        })
                    }
                    let monthOfOrder = ""
                    let yearOfOrder = ""
                    let dateOfOrder = ""
                    let timeOfOrder = ""
                    if (resp.order_timings) {
                        let dayTimeOfOrder = JSON.parse(resp.order_timings)
                        monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                        yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                        dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                        timeOfOrder = dayTimeOfOrder.time
                    }
                    this.setState({
                        monthOfOrder,
                        yearOfOrder,
                        dateOfOrder,
                        timeOfOrder,
                    })
                    this.setState({
                        emailWants: false,
                    })
                    if (resp.diffrent_shipping) {
                        let countryidec = countries.filter((e) => {
                            return e.name === resp.diffrent_shipping.country
                        })
                        this.setState({ nestedFields: true, streetSec: resp.diffrent_shipping.street, citySec: resp.diffrent_shipping.city, houseNumberSec: resp.diffrent_shipping.house_number, zipcodeSec: resp.diffrent_shipping.zip_code, countryidSec: countryidec[0].id })
                    }
                    if (!resp.diffrent_shipping) {
                        if (resp.shipping_address) {
                            this.setState({
                                zipcodeSec: resp.shipping_address.zip_code,
                                houseNumberSec: resp.shipping_address.house_number,
                                streetSec: resp.shipping_address.street,
                                countryidSec: countryid[0].id,
                                citySec: resp.shipping_address.city,
                            })
                        }
                    }
                })
                .catch(error => console.log('error', error));
        } else {
            let cookie_id = localStorage.getItem("guestUser")
            this.setState({ emailWants: true })
            var formdata = new FormData();
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/submit/checkout/step/one?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then((result) => {
                    let resp = result.data.data
                    if (resp.shipping_address) {
                        let countryid = countries.filter((e) => {
                            return e.name === resp.shipping_address.country
                        })
                        let statesC = statesCountry.filter((f) => {
                            return f.country_id === Number(countryid[0].id)
                        })
                        let allCities = []
                        let allCitiesSec = []
                        statesC.filter((g) => {
                            cities.map((h) => {
                                if (g.id === h.state_id) {
                                    allCities.push(h)
                                    allCitiesSec.push(h)
                                }
                            })
                        })
                        this.setState({
                            allCities,
                            allCitiesSec,
                            popupright: true,
                            addressType: "shipping",
                            firstName: resp.shipping_address.first_name,
                            lastName: resp.shipping_address.last_name,
                            zipcode: resp.shipping_address.zip_code,
                            houseNumber: resp.shipping_address.house_number,
                            street: resp.shipping_address.street,
                            phone: resp.shipping_address.phone,
                            countryid: countryid[0].id,
                            city: resp.shipping_address.city,

                        })
                        if (resp.diffrent_shipping) {
                            let countryidec = countries.filter((e) => {
                                return e.name === resp.diffrent_shipping.country
                            })
                            this.setState({ nestedFields: true, streetSec: resp.diffrent_shipping.street, citySec: resp.diffrent_shipping.city, houseNumberSec: resp.diffrent_shipping.house_number, zipcodeSec: resp.diffrent_shipping.zip_code, countryidSec: countryidec[0].id })
                        }
                        if (!resp.diffrent_shipping) {
                            this.setState({
                                zipcodeSec: resp.shipping_address.zip_code,
                                houseNumberSec: resp.shipping_address.house_number,
                                streetSec: resp.shipping_address.street,
                                countryidSec: countryid[0].id,
                                citySec: resp.shipping_address.city,
                            })
                        }
                    }
                    if (resp.delivery_settings && resp.delivery_settings.delivery_option === "collection_point") {
                        console.log("get info")
                    }
                    let monthOfOrder = ""
                    let yearOfOrder = ""
                    let dateOfOrder = ""
                    let timeOfOrder = ""
                    if (resp.order_timings) {
                        let dayTimeOfOrder = JSON.parse(resp.order_timings)
                        monthOfOrder = new Date(dayTimeOfOrder.day).getMonth() + 1
                        yearOfOrder = new Date(dayTimeOfOrder.day).getFullYear()
                        dateOfOrder = new Date(dayTimeOfOrder.day).getDate()
                        timeOfOrder = dayTimeOfOrder.time
                    }
                    this.setState({
                        monthOfOrder,
                        yearOfOrder,
                        dateOfOrder,
                        timeOfOrder,
                    })
                    this.setState({ wholeData: resp, heading_1: resp.cart_heading_1, heading_2: resp.cart_heading_2, order_overview: resp.delivery_page_order_overview, to_deliver: resp.to_deliver, coupon_code: resp.coupon_settings.coupon_code })
                })
                .catch(error => console.log('error', error));
        }
    }

    toDeliver() {
        const { firstName, lastName, zipcode, houseNumber, street, phone, city, countryid, zipcodeSec, houseNumberSec, streetSec, citySec, countryidSec, email } = this.state
        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
            let filteredCountry = []
            let filteredCountrySec = []
            if (firstName === "") {
                this.setState({ fnPopup: true })
            } else {
                this.setState({ fnPopup: false })
            }
            if (lastName === "") {
                this.setState({ lnPopup: true })
            } else {
                this.setState({ lnPopup: false })
            }
            if (zipcode === "") {
                this.setState({ zipPopup: true })
            } else {
                this.setState({ zipPopup: false })
            }
            if (houseNumber === "") {
                this.setState({ hnPopup: true })
            } else {
                this.setState({ hnPopup: false })
            }
            if (street === "") {
                this.setState({ streetPopup: true })
            } else {
                this.setState({ streetPopup: false })
            }
            if (phone === "") {
                this.setState({ phonePopup: true })
            } else {
                this.setState({ phonePopup: false })
            }
            if (city === "") {
                this.setState({ cityPopup: true })
            } else {
                this.setState({ cityPopup: false })
            }
            if (countryid === "") {
                this.setState({ countryPopup: true })
            } else {
                this.setState({ countryPopup: false })
                filteredCountry = countries.filter((e) => {
                    return e.id === Number(countryid)
                })
            }
            if (zipcodeSec === "") {
                this.setState({ zipPopupSec: true })
            } else {
                this.setState({ zipPopupSec: false })
            }
            if (houseNumberSec === "") {
                this.setState({ hnPopupSec: true })
            } else {
                this.setState({ hnPopupSec: false })
            }
            if (streetSec === "") {
                this.setState({ streetPopupSec: true })
            } else {
                this.setState({ streetPopupSec: false })
            }
            if (citySec === "") {
                this.setState({ cityPopupSec: true })
            } else {
                this.setState({ cityPopupSec: false })
            }
            if (countryidSec === "") {
                this.setState({ countryPopupSec: true })
            } else {
                this.setState({ countryPopupSec: false })
                filteredCountrySec = countries.filter((e) => {
                    return e.id === Number(countryidSec)
                })
            }
            if (firstName !== "" && lastName !== "" && zipcode !== "" && houseNumber !== "" && phone !== "" && city !== "" && countryid !== "") {
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    if (this.state.nestedFields) {
                        if (zipcodeSec !== "" && houseNumberSec !== "" && streetSec !== "" && citySec !== "" && countryidSec !== "") {
                            var formdata = new FormData();
                            formdata.append("first_name", firstName);
                            formdata.append("last_name", lastName);
                            formdata.append("street", street);
                            formdata.append("house_number", houseNumber);
                            formdata.append("zip_code", zipcode);
                            formdata.append("phone", phone);
                            formdata.append("city", city);
                            formdata.append("country", filteredCountry[0].name);
                            formdata.append("address_type", "shipping");

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                .then(response => response.json())
                                .catch(error => console.log('error', error));

                            var formdataSec = new FormData();
                            formdataSec.append("first_name", firstName);
                            formdataSec.append("last_name", lastName);
                            formdataSec.append("street", streetSec);
                            formdataSec.append("house_number", houseNumberSec);
                            formdataSec.append("zip_code", zipcodeSec);
                            formdataSec.append("phone", phone);
                            formdataSec.append("city", citySec);
                            formdataSec.append("country", filteredCountrySec[0].name);
                            formdataSec.append("address_type", "diffrent_shipping");

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdataSec,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result.code === 200) {
                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                            window.location.href = "/checkout"
                                        } else {
                                            window.location.href = "/payment"
                                        }
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                    } else {
                        var formdata = new FormData();
                        formdata.append("first_name", firstName);
                        formdata.append("last_name", lastName);
                        formdata.append("street", street);
                        formdata.append("house_number", houseNumber);
                        formdata.append("zip_code", zipcode);
                        formdata.append("phone", phone);
                        formdata.append("city", city);
                        formdata.append("country", filteredCountry[0].name);
                        formdata.append("address_type", "shipping");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                            .then(response => response.json())
                            .catch(error => console.log('error', error));

                        var formdataSec = new FormData();
                        formdataSec.append("first_name", "");
                        formdataSec.append("last_name", "");
                        formdataSec.append("street", "");
                        formdataSec.append("house_number", "");
                        formdataSec.append("zip_code", "");
                        formdataSec.append("phone", "");
                        formdataSec.append("city", "");
                        formdataSec.append("country", "");
                        formdataSec.append("address_type", "diffrent_shipping");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdataSec,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                        window.location.href = "/checkout"
                                    } else {
                                        window.location.href = "/payment"
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    if (email === "") {
                        this.setState({ emailPopup: true })
                    } else {
                        this.setState({ emailPopup: false })
                        let cookie_id = localStorage.getItem("guestUser")
                        var formdataNet = new FormData();
                        formdataNet.append("name", firstName);
                        formdataNet.append("last_name", lastName);
                        formdataNet.append("email", email);
                        formdataNet.append("phone", phone);
                        formdataNet.append("cart_items_cookie", cookie_id);

                        var requestOptionsNet = {
                            method: 'POST',
                            body: formdataNet,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/guest/submit/to/customer`, requestOptionsNet)
                            .then(response => response.json())
                            .then((result) => {
                                if (result.data.token) {
                                    let usertokenSec = result.data.token
                                    localStorage.setItem("usertoken", result.data.token)
                                    var myHeaders = new Headers();
                                    myHeaders.append("Accept", "application/json");
                                    myHeaders.append("Authorization", `Bearer ${usertokenSec}`);
                                    if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                        if (this.state.nestedFields) {
                                            if (zipcodeSec !== "" && houseNumberSec !== "" && streetSec !== "" && citySec !== "" && countryidSec !== "") {
                                                var formdata = new FormData();
                                                formdata.append("first_name", firstName);
                                                formdata.append("last_name", lastName);
                                                formdata.append("street", street);
                                                formdata.append("house_number", houseNumber);
                                                formdata.append("zip_code", zipcode);
                                                formdata.append("phone", phone);
                                                formdata.append("city", city);
                                                formdata.append("country", filteredCountry[0].name);
                                                formdata.append("address_type", "shipping");

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: formdata,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                    .then(response => response.json())
                                                    .catch(error => console.log('error', error));

                                                var formdataSec = new FormData();
                                                formdataSec.append("first_name", firstName);
                                                formdataSec.append("last_name", lastName);
                                                formdataSec.append("street", streetSec);
                                                formdataSec.append("house_number", houseNumberSec);
                                                formdataSec.append("zip_code", zipcodeSec);
                                                formdataSec.append("phone", phone);
                                                formdataSec.append("city", citySec);
                                                formdataSec.append("country", filteredCountrySec[0].name);
                                                formdataSec.append("address_type", "diffrent_shipping");

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: formdataSec,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        if (result.code === 200) {
                                                            if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                                                window.location.href = "/checkout"
                                                            } else {
                                                                window.location.href = "/payment"
                                                            }
                                                        }
                                                    })
                                                    .catch(error => console.log('error', error));
                                            }
                                        } else {
                                            var formdata = new FormData();
                                            formdata.append("first_name", firstName);
                                            formdata.append("last_name", lastName);
                                            formdata.append("street", street);
                                            formdata.append("house_number", houseNumber);
                                            formdata.append("zip_code", zipcode);
                                            formdata.append("phone", phone);
                                            formdata.append("city", city);
                                            formdata.append("country", filteredCountry[0].name);
                                            formdata.append("address_type", "shipping");

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: formdata,
                                                redirect: 'follow'
                                            };

                                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                .then(response => response.json())
                                                .catch(error => console.log('error', error));

                                            var formdataSec = new FormData();
                                            formdataSec.append("first_name", "");
                                            formdataSec.append("last_name", "");
                                            formdataSec.append("street", "");
                                            formdataSec.append("house_number", "");
                                            formdataSec.append("zip_code", "");
                                            formdataSec.append("phone", "");
                                            formdataSec.append("city", "");
                                            formdataSec.append("country", "");
                                            formdataSec.append("address_type", "diffrent_shipping");

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: formdataSec,
                                                redirect: 'follow'
                                            };

                                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    if (result.code === 200) {
                                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                                            window.location.href = "/checkout"
                                                        } else {
                                                            window.location.href = "/payment"
                                                        }
                                                    }
                                                })
                                                .catch(error => console.log('error', error));
                                        }
                                    } else {
                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                            window.location.href = "/checkout"
                                        } else {
                                            window.location.href = "/payment"
                                        }
                                    }
                                } else {
                                    this.setState({ emailTaken: true })
                                }
                            })
                            .catch(error => console.log('error', error));

                    }
                }
            }
        } else {
            let filteredCountry = []
            let filteredCountrySec = []
            if (firstName === "") {
                this.setState({ fnPopup: true })
            } else {
                this.setState({ fnPopup: false })
            }
            if (lastName === "") {
                this.setState({ lnPopup: true })
            } else {
                this.setState({ lnPopup: false })
            }
            // if (zipcode === "") {
            //     this.setState({ zipPopup: true })
            // } else {
            //     this.setState({ zipPopup: false })
            // }
            // if (houseNumber === "") {
            //     this.setState({ hnPopup: true })
            // } else {
            //     this.setState({ hnPopup: false })
            // }
            // if (street === "") {
            //     this.setState({ streetPopup: true })
            // } else {
            //     this.setState({ streetPopup: false })
            // }
            if (phone === "") {
                this.setState({ phonePopup: true })
            } else {
                this.setState({ phonePopup: false })
            }
            // if (city === "") {
            //     this.setState({ cityPopup: true })
            // } else {
            //     this.setState({ cityPopup: false })
            // }
            // if (countryid === "") {
            //     this.setState({ countryPopup: true })
            // } else {
            //     this.setState({ countryPopup: false })
            //     filteredCountry = countries.filter((e) => {
            //         return e.id === Number(countryid)
            //     })
            // }
            if (zipcodeSec === "") {
                this.setState({ zipPopupSec: true })
            } else {
                this.setState({ zipPopupSec: false })
            }
            if (houseNumberSec === "") {
                this.setState({ hnPopupSec: true })
            } else {
                this.setState({ hnPopupSec: false })
            }
            if (streetSec === "") {
                this.setState({ streetPopupSec: true })
            } else {
                this.setState({ streetPopupSec: false })
            }
            if (citySec === "") {
                this.setState({ cityPopupSec: true })
            } else {
                this.setState({ cityPopupSec: false })
            }
            if (countryidSec === "") {
                this.setState({ countryPopupSec: true })
            } else {
                this.setState({ countryPopupSec: false })
                filteredCountrySec = countries.filter((e) => {
                    return e.id === Number(countryidSec)
                })
            }
            if (firstName !== "" && lastName !== "" && phone !== "") {
                let usertoken = localStorage.getItem("usertoken")
                if (usertoken) {
                    var myHeaders = new Headers();
                    myHeaders.append("Accept", "application/json");
                    myHeaders.append("Authorization", `Bearer ${usertoken}`);

                    if (this.state.nestedFields) {
                        if (zipcodeSec !== "" && houseNumberSec !== "" && streetSec !== "" && citySec !== "" && countryidSec !== "") {
                            var formdata = new FormData();
                            formdata.append("first_name", firstName);
                            formdata.append("last_name", lastName);
                            // formdata.append("street", street);
                            // formdata.append("house_number", houseNumber);
                            // formdata.append("zip_code", zipcode);
                            formdata.append("phone", phone);
                            // formdata.append("city", city);
                            // formdata.append("country", "");
                            formdata.append("address_type", "shipping");

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdata,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                .then(response => response.json())
                                .catch(error => console.log('error', error));

                            var formdataSec = new FormData();
                            formdataSec.append("first_name", firstName);
                            formdataSec.append("last_name", lastName);
                            formdataSec.append("street", streetSec);
                            formdataSec.append("house_number", houseNumberSec);
                            formdataSec.append("zip_code", zipcodeSec);
                            formdataSec.append("phone", phone);
                            formdataSec.append("city", citySec);
                            formdataSec.append("country", filteredCountrySec[0].name);
                            formdataSec.append("address_type", "diffrent_shipping");

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdataSec,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    if (result.code === 200) {
                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                            window.location.href = "/checkout"
                                        } else {
                                            window.location.href = "/payment"
                                        }
                                    }
                                })
                                .catch(error => console.log('error', error));
                        }
                    } else {
                        var formdata = new FormData();
                        formdata.append("first_name", firstName);
                        formdata.append("last_name", lastName);
                        // formdata.append("street", street);
                        // formdata.append("house_number", houseNumber);
                        // formdata.append("zip_code", zipcode);
                        formdata.append("phone", phone);
                        // formdata.append("city", city);
                        // formdata.append("country", filteredCountry[0].name);
                        formdata.append("address_type", "shipping");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdata,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                            .then(response => response.json())
                            .catch(error => console.log('error', error));

                        var formdataSec = new FormData();
                        formdataSec.append("first_name", "");
                        formdataSec.append("last_name", "");
                        formdataSec.append("street", "");
                        formdataSec.append("house_number", "");
                        formdataSec.append("zip_code", "");
                        formdataSec.append("phone", "");
                        formdataSec.append("city", "");
                        formdataSec.append("country", "");
                        formdataSec.append("address_type", "diffrent_shipping");

                        var requestOptions = {
                            method: 'POST',
                            headers: myHeaders,
                            body: formdataSec,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                            .then(response => response.json())
                            .then(result => {
                                if (result.code === 200) {
                                    if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                        window.location.href = "/checkout"
                                    } else {
                                        window.location.href = "/payment"
                                    }
                                }
                            })
                            .catch(error => console.log('error', error));
                    }
                } else {
                    if (email === "") {
                        this.setState({ emailPopup: true })
                    } else {
                        this.setState({ emailPopup: false })
                        let cookie_id = localStorage.getItem("guestUser")
                        var formdataNet = new FormData();
                        formdataNet.append("name", firstName);
                        formdataNet.append("last_name", lastName);
                        formdataNet.append("email", email);
                        formdataNet.append("phone", phone);
                        formdataNet.append("cart_items_cookie", cookie_id);

                        var requestOptionsNet = {
                            method: 'POST',
                            body: formdataNet,
                            redirect: 'follow'
                        };

                        fetch(`${gv}/api/v1/guest/submit/to/customer`, requestOptionsNet)
                            .then(response => response.json())
                            .then((result) => {
                                if (result.data.token) {
                                    let usertokenSec = result.data.token
                                    localStorage.setItem("usertoken", result.data.token)
                                    var myHeaders = new Headers();
                                    myHeaders.append("Accept", "application/json");
                                    myHeaders.append("Authorization", `Bearer ${usertokenSec}`);
                                    if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                        if (this.state.nestedFields) {
                                            if (zipcodeSec !== "" && houseNumberSec !== "" && streetSec !== "" && citySec !== "" && countryidSec !== "") {
                                                var formdata = new FormData();
                                                formdata.append("first_name", firstName);
                                                formdata.append("last_name", lastName);
                                                formdata.append("street", street);
                                                formdata.append("house_number", houseNumber);
                                                formdata.append("zip_code", zipcode);
                                                formdata.append("phone", phone);
                                                formdata.append("city", city);
                                                formdata.append("country", filteredCountry[0].name);
                                                formdata.append("address_type", "shipping");

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: formdata,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                    .then(response => response.json())
                                                    .catch(error => console.log('error', error));

                                                var formdataSec = new FormData();
                                                formdataSec.append("first_name", firstName);
                                                formdataSec.append("last_name", lastName);
                                                formdataSec.append("street", streetSec);
                                                formdataSec.append("house_number", houseNumberSec);
                                                formdataSec.append("zip_code", zipcodeSec);
                                                formdataSec.append("phone", phone);
                                                formdataSec.append("city", citySec);
                                                formdataSec.append("country", filteredCountrySec[0].name);
                                                formdataSec.append("address_type", "diffrent_shipping");

                                                var requestOptions = {
                                                    method: 'POST',
                                                    headers: myHeaders,
                                                    body: formdataSec,
                                                    redirect: 'follow'
                                                };

                                                fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                    .then(response => response.json())
                                                    .then(result => {
                                                        if (result.code === 200) {
                                                            if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                                                window.location.href = "/checkout"
                                                            } else {
                                                                window.location.href = "/payment"
                                                            }
                                                        }
                                                    })
                                                    .catch(error => console.log('error', error));
                                            }
                                        } else {
                                            var formdata = new FormData();
                                            formdata.append("first_name", firstName);
                                            formdata.append("last_name", lastName);
                                            // formdata.append("street", street);
                                            // formdata.append("house_number", houseNumber);
                                            // formdata.append("zip_code", zipcode);
                                            formdata.append("phone", phone);
                                            // formdata.append("city", city);
                                            // formdata.append("country", filteredCountry[0].name);
                                            formdata.append("address_type", "shipping");

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: formdata,
                                                redirect: 'follow'
                                            };

                                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                .then(response => response.json())
                                                .catch(error => console.log('error', error));

                                            var formdataSec = new FormData();
                                            formdataSec.append("first_name", "");
                                            formdataSec.append("last_name", "");
                                            formdataSec.append("street", "");
                                            formdataSec.append("house_number", "");
                                            formdataSec.append("zip_code", "");
                                            formdataSec.append("phone", "");
                                            formdataSec.append("city", "");
                                            formdataSec.append("country", "");
                                            formdataSec.append("address_type", "diffrent_shipping");

                                            var requestOptions = {
                                                method: 'POST',
                                                headers: myHeaders,
                                                body: formdataSec,
                                                redirect: 'follow'
                                            };

                                            fetch(`${gv}/api/v1/auth/customer/update/address`, requestOptions)
                                                .then(response => response.json())
                                                .then(result => {
                                                    if (result.code === 200) {
                                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                                            window.location.href = "/checkout"
                                                        } else {
                                                            window.location.href = "/payment"
                                                        }
                                                    }
                                                })
                                                .catch(error => console.log('error', error));
                                        }
                                    } else {
                                        if (this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point") {
                                            window.location.href = "/checkout"
                                        } else {
                                            window.location.href = "/payment"
                                        }
                                    }
                                } else {
                                    this.setState({ emailTaken: true })
                                }
                            })
                            .catch(error => console.log('error', error));

                    }
                }
            }
        }
    }

    login() {
        const { emailLogin, passwordLogin } = this.state
        let cookie_id = localStorage.getItem("guestUser")
        if (emailLogin === "") {
            this.setState({ emailLoginPopup: true })
        } else {
            this.setState({ emailLoginPopup: false })
        }
        if (passwordLogin === "") {
            this.setState({ passwordLoginPopup: true })
        } else {
            this.setState({ passwordLoginPopup: false })
        }
        if (emailLogin !== "" && passwordLogin !== "") {
            var formdata = new FormData();
            formdata.append("email", emailLogin);
            formdata.append("password", passwordLogin);
            formdata.append("user_type", "guest");
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 200) {
                        if (result.data.notification) {
                            this.setState({ loginShowMessage: true, loginMessage: result.data.notification })
                        }
                        localStorage.setItem("usertoken", result.data.token)
                        this.setState({ logInchecked: false })
                        this.getData()
                    } else {
                        this.setState({ normalPopup: true })
                    }
                })
                .catch(error => console.log('error', error));
        }
    }

    updateSec() {
        const { heading_1, heading_2, order_overview, to_deliver } = this.state

        let arr = [
            { key: "delivery_page_cart_heading_1", value: heading_1 },
            { key: "delivery_page_cart_heading_2", value: heading_2 },
            { key: "delivery_page_order_overview", value: order_overview },
            { key: "delivery_page_to_deliver", value: to_deliver },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    removeCoupon() {
        let usertoken = localStorage.getItem("usertoken")

        if (!usertoken) {
            let cookie_id = localStorage.getItem("guestUser")

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);
            formdata.append("cart_items_cookie", cookie_id);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));

        } else {
            var myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${usertoken}`);
            myHeaders.append("Accept", "application/json");

            var formdata = new FormData();
            formdata.append("coupon_code", this.state.coupon_code);

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/customer/remove/coupon?browser_type=${this.state.browserType}`, requestOptions)
                .then(response => response.json())
                .then(() => {
                    window.location.reload()
                })
                .catch(error => console.log('error', error));
        }
    }

    forgetPassword() {
        if (this.state.emailLoginForget !== "") {
            var formdata = new FormData();
            formdata.append("resend_email_input", this.state.emailLoginForget);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/auth/resend/password`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    this.setState({ forgetWorking: false, forgetStatus: result.data.success, forgetMessage: result.data.message, forgetTextWork: true })
                })
                .catch(error => console.log('error', error));
        } else {
            this.setState({ emailLoginPopupForget: true })
        }
    }


    render() {
        return (
            <div className="checkoutfooterhide">
                <NavCheck />
                {this.state.wholeData && <Helmet>
                    <title>{this.state.wholeData.page_title}</title>
                </Helmet>}
                <div className="checkout-holder delivery-main">
                    <div className="inner-wrapper">
                        <div className="inner-wrapper-small">
                            <div className="checkout-layout-left">
                                <div className="back-button-wrapper">

                                </div>
                                <div className="checkout-step-buttons-holder">
                                    <div className="checkout-steps-buttons-list">
                                        <div className="checkout-step-btn">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                <CheckIcon style={{ fontSize: 16 }} />
                                            </span>
                                            <span className="text">Shopping</span>
                                        </div>
                                        <div className="checkout-step-btn selected">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">
                                                2
                                            </span>
                                            <span className="text">Bezorging</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">3</span>
                                            <span className="text">Controleren</span>
                                        </div>
                                        <div className="checkout-step-btn disabled">
                                            <span className="background-line"></span>
                                            <span className="sr-only"></span>
                                            <span className="circle">4</span>
                                            <span className="text">Betaling</span>
                                        </div>
                                    </div>
                                </div>
                                {this.state.logInchecked && <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        {this.state.isAdmin ? <h2 className="checkout-step-title">
                                            <span className="number">2</span>
                                            <span className="text" onClick={() => this.setState({ heading_1Input: true })}>
                                                {!this.state.heading_1Input ? this.state.heading_1 : <input type="text" autoFocus value={this.state.heading_1} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </span>
                                        </h2> : <h2 className="checkout-step-title">
                                            <span className="number">2</span>
                                            <span className="text">
                                                {this.state.heading_1}
                                            </span>
                                        </h2>}
                                        <div className="addresslist-fieldset-inner" style={{ paddingBottom: 0, marginBottom: 30, borderBottom: 0 }}>
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    {this.state.isAdmin ? <h3 style={{ textAlign: "center", marginBottom: 30, color: "#453f3f", letterSpacing: 2 }} onClick={() => this.setState({ heading_2Input: true })}>
                                                        {!this.state.heading_2Input ? this.state.heading_2 : <input type="text" autoFocus value={this.state.heading_2} onChange={(e) => this.setState({ heading_2: e.target.value })} onBlur={() => this.setState({ heading_2Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                                    </h3> : <h3 style={{ textAlign: "center", marginBottom: 30, color: "#453f3f", letterSpacing: 2 }}>
                                                        {this.state.forgetTextWork ? "UW WACHTWOORD IS VERSTUURD, LOG OPNIEUW IN:" : this.state.heading_2}
                                                    </h3>}
                                                </div>
                                            </div>
                                            <div className="newsletter-content" style={{ marginTop: 30 }}>
                                                <label className="signup-label">
                                                    <div className="newsletter-checkbox">
                                                        <div className="inputfield-row">
                                                            <input type="checkbox" className="input-checkbox" checked={this.state.logInchecked} onChange={(e) => e.target.checked ? this.setState({ logInchecked: true }) : this.setState({ logInchecked: false })} />
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                    <span className="newsletter-label">Log In</span>
                                                </label>
                                            </div>
                                            {this.state.forgetWorking ? <div style={{ width: "100%", position: "relative" }}>
                                                <div className="form-row">
                                                    <div className="field-wrapper">
                                                        <label>Email</label>
                                                        <input type="text" value={this.state.emailLoginForget} onChange={(e) => this.setState({ emailLoginForget: e.target.value })} />
                                                        {this.state.emailLoginPopupForget && <span className="alert-fields">Email is required.</span>}
                                                    </div>
                                                </div>
                                                <span onClick={() => this.setState({ forgetWorking: false })} style={{ position: "absolute", bottom: 10, right: 10, cursor: "pointer", letterSpacing: 1, fontSize: 13 }}>
                                                    <CloseIcon style={{ marginRight: 8 }} />
                                                    SLUIT
                                                </span>
                                                <button className="btn-submit-checkout" onClick={this.forgetPassword.bind(this)} style={{ margin: "0px auto", marginTop: 60 }}>Submit</button>
                                            </div> : <div style={{ width: "100%" }}>
                                                <div className="form-row">
                                                    <div className="field-wrapper">
                                                        <label>Email</label>
                                                        <input type="text" value={this.state.emailLogin} onChange={(e) => this.setState({ emailLogin: e.target.value })} />
                                                        {this.state.emailLoginPopup && <span className="alert-fields">Email is required.</span>}
                                                    </div>
                                                </div>
                                                <div className="form-row">
                                                    <div className="inputfield-row">
                                                        <label>Wachtwoord</label>
                                                        <input type="password" className="inputfield-text" value={this.state.passwordLogin} onChange={(e) => this.setState({ passwordLogin: e.target.value })} />
                                                        {this.state.passwordLoginPopup && <span className="alert-fields">Wachtwoord is required.</span>}
                                                    </div>
                                                </div>
                                                <a className="password-reset" onClick={() => this.setState({ forgetWorking: true, emailLoginForget: "", emailLogin: "", passwordLogin: "" })} style={{ marginBottom: 40 }}>Wachtwoord vergeten?</a>
                                                <button className="btn-submit-checkout" onClick={this.login.bind(this)} style={{ margin: "0px auto", marginTop: 60 }}>Login</button>
                                            </div>}
                                        </div>
                                    </div>
                                </div>}
                                {!this.state.logInchecked && <div className="checkout-inner">
                                    <div className="checkout-step-content">
                                        {this.state.isAdmin ? <h2 className="checkout-step-title">
                                            <span className="number">2</span>
                                            <span className="text" onClick={() => this.setState({ heading_1Input: true })}>
                                                {!this.state.heading_1Input ? this.state.heading_1 : <input type="text" autoFocus value={this.state.heading_1} onChange={(e) => this.setState({ heading_1: e.target.value })} onBlur={() => this.setState({ heading_1Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                            </span>
                                        </h2> : <h2 className="checkout-step-title">
                                            <span className="number">2</span>
                                            <span className="text">
                                                {this.state.heading_1}
                                            </span>
                                        </h2>}
                                        <div className="addresslist-fieldset-inner" style={{ paddingBottom: 0, marginBottom: 30, position: "relative" }}>
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    {this.state.isAdmin ? <h3 style={{ textAlign: "center", marginBottom: 30, color: "#453f3f", letterSpacing: 2 }} onClick={() => this.setState({ heading_2Input: true })}>
                                                        {!this.state.heading_2Input ? this.state.heading_2 : <input type="text" autoFocus value={this.state.heading_2} onChange={(e) => this.setState({ heading_2: e.target.value })} onBlur={() => this.setState({ heading_2Input: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}
                                                    </h3> : <h3 style={{ textAlign: "center", marginBottom: 30, color: "#453f3f", letterSpacing: 2 }}>
                                                        {this.state.heading_2}
                                                    </h3>}
                                                </div>
                                            </div>
                                            {this.state.emailWants && <div className="newsletter-content" style={{ marginTop: 30 }}>
                                                <label className="signup-label">
                                                    <div className="newsletter-checkbox">
                                                        <div className="inputfield-row">
                                                            <input type="checkbox" className="input-checkbox" checked={this.state.logInchecked} onChange={(e) => e.target.checked ? this.setState({ logInchecked: true }) : this.setState({ logInchecked: false })} />
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                    <span className="newsletter-label">Log In</span>
                                                </label>
                                            </div>}
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>Voornaam</label>
                                                    <input type="text" value={this.state.firstName} onChange={(e) => this.setState({ firstName: e.target.value })} />
                                                    {this.state.fnPopup && <span className="alert-fields">Fist name is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>Achternaam</label>
                                                    <input type="text" value={this.state.lastName} onChange={(e) => this.setState({ lastName: e.target.value })} />
                                                    {this.state.lnPopup && <span className="alert-fields">Last name is required.</span>}
                                                </div>
                                            </div>
                                            {this.state.emailWants && <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>E-mail</label>
                                                    <input type="text" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                                    {this.state.emailPopup && <span className="alert-fields">Email is required.</span>}
                                                    {this.state.emailTaken && <span className="alert-fields">Email is already taken.</span>}
                                                </div>
                                            </div>}
                                            {this.state.wholeData.delivery_settings ? this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Straatnaam</label>
                                                    <input className="inputfield-text" value={this.state.street} onChange={(e) => this.setState({ street: e.target.value })} />
                                                    {this.state.streetPopup && <span className="alert-fields">Street is required.</span>}
                                                </div>
                                            </div> : <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Straatnaam</label>
                                                    <input className="inputfield-text" value={this.state.street} onChange={(e) => this.setState({ street: e.target.value })} />
                                                    {this.state.streetPopup && <span className="alert-fields">Street is required.</span>}
                                                </div>
                                            </div>}
                                            {this.state.wholeData.delivery_settings ? this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Huisnummer</label>
                                                    <input className="inputfield-text" value={this.state.houseNumber} onChange={(e) => this.setState({ houseNumber: e.target.value })} />
                                                    {this.state.hnPopup && <span className="alert-fields">House number is required.</span>}
                                                </div>
                                            </div> : <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Huisnummer</label>
                                                    <input className="inputfield-text" value={this.state.houseNumber} onChange={(e) => this.setState({ houseNumber: e.target.value })} />
                                                    {this.state.hnPopup && <span className="alert-fields">House number is required.</span>}
                                                </div>
                                            </div>}
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>Postcode</label>
                                                    <input type="text" value={this.state.zipcode} onChange={(e) => this.setState({ zipcode: e.target.value })} />
                                                    {this.state.zipPopup && <span className="alert-fields">Zip code is required.</span>}
                                                </div>
                                            </div>}
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Plaats</label>
                                                    <input type="text" value={this.state.city} onChange={(e) => this.setState({ city: e.target.value })} />
                                                </div>
                                                {this.state.cityPopup && <span className="alert-fields">City is required.</span>}
                                            </div>}
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Land</label>
                                                    <select onChange={(e) => {
                                                        if (e.target.value !== "Select Country...") {
                                                            this.setState({ countryid: e.target.value, stateid: '' })
                                                            let statesC = statesCountry.filter((f) => {
                                                                return f.country_id === Number(e.target.value)
                                                            })
                                                            let allCities = []
                                                            statesC.filter((g) => {
                                                                cities.map((h) => {
                                                                    if (g.id === h.state_id) {
                                                                        allCities.push(h)
                                                                    }
                                                                })
                                                            })
                                                            this.setState({ allCities })
                                                        } else {
                                                            this.setState({ countryid: '' })
                                                        }
                                                    }}>
                                                        {countries.length > 0 && countries.map((e) => {
                                                            return <option selected={e.id === Number(this.state.countryid)} key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {this.state.countryPopup && <span className="alert-fields">Country is required.</span>}
                                            </div>}
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label><span>Telefoon</span></label>
                                                    <input type="number" value={this.state.phone} onChange={(e) => this.setState({ phone: e.target.value })} />
                                                </div>
                                                {this.state.phonePopup && <span className="alert-fields">Phone number is required.</span>}
                                            </div>
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option !== "collection_point" && <div className="newsletter-content" style={{ marginTop: 30 }}>
                                                <label className="signup-label">
                                                    <div className="newsletter-checkbox">
                                                        <div className="inputfield-row">
                                                            <input type="checkbox" className="input-checkbox" checked={this.state.nestedFields} onChange={(e) => e.target.checked ? this.setState({ nestedFields: true }) : this.setState({ nestedFields: false })} />
                                                            <label></label>
                                                        </div>
                                                    </div>
                                                    <span className="newsletter-label">Ander verzendadres</span>
                                                </label>
                                            </div>}
                                        </div>
                                        {this.state.nestedFields && <div className="addresslist-fieldset-inner">
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Street</label>
                                                    <input className="inputfield-text" value={this.state.streetSec} onChange={(e) => this.setState({ streetSec: e.target.value })} />
                                                    {this.state.streetPopupSec && <span className="alert-fields">Street is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>House Number</label>
                                                    <input className="inputfield-text" value={this.state.houseNumberSec} onChange={(e) => this.setState({ houseNumberSec: e.target.value })} />
                                                    {this.state.hnPopupSec && <span className="alert-fields">House number is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="field-wrapper">
                                                    <label>Zip Code</label>
                                                    <input type="text" value={this.state.zipcodeSec} onChange={(e) => this.setState({ zipcodeSec: e.target.value })} />
                                                    {this.state.zipPopupSec && <span className="alert-fields">Zip code is required.</span>}
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>City</label>
                                                    <input type="text" placeholder="Enter city" value={this.state.citySec} onChange={(e) => this.setState({ citySec: e.target.value })} />
                                                </div>
                                                {this.state.cityPopupSec && <span className="alert-fields">City is required.</span>}
                                            </div>
                                            <div className="form-row">
                                                <div className="inputfield-row">
                                                    <label>Country</label>
                                                    <select onChange={(e) => {
                                                        if (e.target.value !== "Select Country...") {
                                                            this.setState({ countryidSec: e.target.value, stateid: '' })
                                                            let statesC = statesCountry.filter((f) => {
                                                                return f.country_id === Number(e.target.value)
                                                            })
                                                            let allCitiesSec = []
                                                            statesC.filter((g) => {
                                                                cities.map((h) => {
                                                                    if (g.id === h.state_id) {
                                                                        allCitiesSec.push(h)
                                                                    }
                                                                })
                                                            })
                                                            this.setState({ allCitiesSec })
                                                        } else {
                                                            this.setState({ countryidSec: '' })
                                                        }
                                                    }}>
                                                        {countries.length > 0 && countries.map((e) => {
                                                            return <option selected={e.id === Number(this.state.countryidSec)} key={Math.random()} value={e.id}>{e.name}</option>
                                                        })}
                                                    </select>
                                                </div>
                                                {this.state.countryPopupSec && <span className="alert-fields">Country is required.</span>}
                                            </div>
                                        </div>}
                                        {/* <button className="btn-submit-checkout" onClick={this.toDeliver.bind(this)}>TO DELIVER</button> */}
                                    </div>
                                </div>}
                            </div>
                            <div className="checkout-layout-right">
                                <div className="checkout-summary-inner">
                                    {this.state.isAdmin ? <h3 className="section-header" style={{ marginBottom: 0 }} onClick={() => this.setState({ order_overviewInput: true })} >
                                        <font><a href="/cart">WIJZIG</a> {!this.state.order_overviewInput ? this.state.order_overview : <input type="text" autoFocus value={this.state.order_overview} onChange={(e) => this.setState({ order_overview: e.target.value })} onBlur={() => this.setState({ order_overviewInput: false })} style={{ backgroundColor: "transparent", border: "none", width: "100%", outline: "none" }} />}</font>
                                    </h3> : <h3 className="section-header" style={{ marginBottom: 0 }}>
                                        <font><a href="/cart">WIJZIG</a> {this.state.order_overview}</font>
                                    </h3>}
                                    <div className="checkout-summary-minicart">
                                        {this.state.wholeData.cart_items && this.state.wholeData.cart_items.map((e) => {
                                            return <div className="mini-cart-product" style={{ padding: 15 }} key={Math.random()}>
                                                <div className="mini-cart-image">
                                                    <img src={e.image_url} alt="mci" />
                                                </div>
                                                <div className="mini-cart-info">
                                                    <div className="mini-cart-small-title">
                                                        <font>{e.name}</font>
                                                    </div>
                                                    <div className="mini-cart-name">
                                                        <font>{e.plain_sku}</font>
                                                    </div>
                                                    <div className="mini-cart-details-bottom">
                                                        <div className="mini-cart-pricing">
                                                            <span className="label">Aantal: </span>
                                                            <span className="value">{e.qty}</span>
                                                        </div>
                                                        <div className="mini-cart-price-wrapper">
                                                            {Number(e.sale_price) !== 0 ? <span className="mini-cart-price">€ {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div className="checkout-summary-order-totals">
                                        <div className="order-totals-table">
                                            <div className="order-SUBTOTAAL">
                                                <div className="cfin">
                                                    <font>SUBTOTAAL</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>€ {this.state.wholeData.total_retail_price}</font>
                                                </div>
                                            </div>
                                            {this.state.loginShowMessage && <span style={{ fontSize: 13, color: "red" }}>{this.state.loginMessage}</span>}
                                            {this.state.wholeData.delivery_settings && this.state.wholeData.delivery_settings.delivery_option ? <div className="checkout-summary-price-row" style={{ marginTop: 10 }}>
                                                {(() => {
                                                    if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "home_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_1}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_1_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "collection_point") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_2}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_2_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    } else if (this.state.wholeData.delivery_settings.delivery_option !== "" && this.state.wholeData.delivery_settings.delivery_option === "local_delivery") {
                                                        return (
                                                            <React.Fragment>
                                                                <div className="cfin"><font>{this.state.wholeData.delivery_desc_3}</font></div>
                                                                <div className="cfiv"><font> {this.state.wholeData.delivery_desc_3_price}</font></div>
                                                            </React.Fragment>
                                                        )
                                                    }
                                                })()}

                                            </div> : <div className="checkout-summary-price-row">
                                                <div className="cfin">
                                                    <font>{this.state.wholeData.delivery_desc_1}</font>
                                                </div>
                                                <div className="cfiv">
                                                    <font>{this.state.wholeData.delivery_desc_1_price}</font>
                                                </div>
                                            </div>}
                                            {this.state.wholeData.coupon_settings && this.state.wholeData.coupon_settings.coupon_discount && <div className="checkout-summary-price-row">
                                                <span className="cfin">
                                                    Kortingscode
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.coupon_settings.coupon_code && <font style={{ color: "#865", textDecoration: "underline", textTransform: "uppercase", fontSize: 12, cursor: "pointer", marginRight: 30 }} className="bodyText" onClick={this.removeCoupon.bind(this)}>
                                                        verwijderen
                                                        </font>}
                                                    € {this.state.wholeData.coupon_settings.coupon_discount}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.gift_settings && this.state.wholeData.gift_settings.gift_price && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Cadeauverpakking
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.wholeData.gift_settings.gift_price}
                                                </span>
                                            </div>}
                                            {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Datum
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.dateOfOrder + "-" + this.state.monthOfOrder + "-" + this.state.yearOfOrder}
                                                </span>
                                            </div>}
                                            {/* {this.state.wholeData.order_timings && <div className="checkout-summary-price-row" style={{ marginBottom: 0, paddingBottom: 5 }}>
                                                <span className="cfin">
                                                    Tijdstip
                                                    </span>
                                                <span className="cfiv">
                                                    {this.state.timeOfOrder}
                                                </span>
                                            </div>} */}
                                            <div className="order-total">
                                                <div className="cart-footer-total">
                                                    <div className="cfin">
                                                        <font>Totaal incl. btw</font>
                                                    </div>
                                                    <div className="cfiv">
                                                        <font>{this.state.wholeData.default_total}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="shipping-info">
                                            <div className="content-asset" style={{ paddingLeft: 15 }}>
                                                <ul>
                                                    {this.state.cartNav && this.state.cartNav.detail_page_delivery_text && this.state.online_payment && <li style={{ display: "flex", alignItems: "center" }}>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.cartNav.detail_page_delivery_text}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_1 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_1}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_2 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_2}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.point_3 && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.point_3}
                                                        </span>
                                                    </li>}
                                                    {this.state.wholeData.pick_up_store && <li>
                                                        <span className="icon-check">
                                                            <CheckIcon style={{ fontSize: 10, color: "#285151" }} />
                                                        </span>
                                                        <span>
                                                            {this.state.wholeData.pick_up_store}
                                                        </span>
                                                    </li>}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-footer-cart">
                    <div className="sticky-order-container">
                        <div className="details-container">
                            <span style={{ marginRight: 7 }}>{this.state.wholeData.total_products}</span>
                            <span>PRODUCT</span>
                        </div>
                        <div className="price-container">
                            <div style={{ display: "flex", alignItems: "center", marginleft: 10 }} >
                                <div className="order-total-label">
                                    <span>Total</span>
                                </div>
                                <div className="order-total-amount">
                                    <span>
                                        {this.state.wholeData.default_total}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sticky-button-container" onClick={this.toDeliver.bind(this)}>
                        <span>
                            {this.state.to_deliver}
                        </span>
                    </div>
                </div>
                {
                    this.state.isAdmin && <div className="btnsavecontent" style={{ zIndex: 999 }}>
                        <SaveAltIcon />
                        <button onClick={this.updateSec.bind(this)}>SAVE</button>
                    </div>
                }
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Delivery
