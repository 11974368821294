import React, { Component } from 'react'
import LockIcon from '@material-ui/icons/Lock';
import logo from '../assets/logowhite.png'
import './index.css'

export class NavCheck extends Component {

    constructor() {
        super()
        this.state = {
            wholeData: '',
        }
    }

    componentDidMount() {
        let headerData = JSON.parse(localStorage.getItem("headerData"))
        this.setState({ wholeData: headerData })
    }

    render() {
        return (
            <header className="navCheck">
                {this.state.wholeData && this.state.wholeData.own_logo ? <nav className="navresponsive" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <img alt="header" className="header-empty--logo" src={this.state.wholeData.logo_url} style={{ width: 140, height: 39, position: "static", transform: "none" }} onClick={() => window.location.href = "/"} />
                </nav> : this.state.wholeData && <nav className="navresponsive" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <p className="retailernametop">{this.state.wholeData.retailer_name}</p>
                    <img alt="header" className="header-empty--logo" src={this.state.wholeData.logo_url} style={{ width: 140, height: 39, position: "static", transform: "none" }} onClick={() => window.location.href = "/"} />
                </nav>}
            </header>
        )
    }
}

export default NavCheck
