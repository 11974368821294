import React, { Component } from 'react'
import './index.css'
import { Helmet } from "react-helmet";
import { gv } from '../../Components/globalvar'
import CompleteNavbar from '../../Components/CompleteNavbar';

export class OrderCancellation extends Component {

    constructor() {
        super()
        this.state = {
            is_banner_image: true,
            desc: "",
        }
    }

    componentDidMount() {
        let headerData = localStorage.getItem("headerData")
        if (headerData) {
            let resp = JSON.parse(headerData)
            this.setState({ is_banner_image: resp.check_order_cancellation_page_banner_image, desc: resp.order_cancellation_page_desc, title: resp.order_cancellation_page_title, bannerImage: resp.order_cancellation_page_banner_image })
        }
        let loginData = localStorage.getItem("headerData")
        if (loginData) {
            let resp = JSON.parse(loginData)
            this.setState({ is_banner_image: resp.check_order_cancellation_page_banner_image, desc: resp.order_cancellation_page_desc, title: resp.order_cancellation_page_title, bannerImage: resp.order_cancellation_page_banner_image })
        }

        fetch(`${gv}/api/v1/list/of/values`)
            .then(response => response.json())
            .then(result => {
                let resp = result.data
                localStorage.setItem("headerData", JSON.stringify(resp))
                this.setState({ is_banner_image: resp.check_order_cancellation_page_banner_image, desc: resp.order_cancellation_page_desc, title: resp.order_cancellation_page_title, bannerImage: resp.order_cancellation_page_banner_image })
            })
            .catch(error => console.log('error', error));

    }

    render() {
        return (
            <div style={{ backgroundColor: "#fbfbfb", paddingBottom: 60 }} ref={r => this.reference = r}>
                {/* Navbar Working */}
                <Helmet>
                    <title>Order Cancellation</title>
                </Helmet>
                <CompleteNavbar />
                <div className="bg-main-secondh" >
                    <div
                        className="full-bg-hcs"
                        style={{
                            backgroundImage: `url(${this.state.bannerImage})`,
                            height: 400,
                            position: "relative",
                        }}>
                    </div>
                </div>
                <div>
                    <div style={{ margin: "60px 0px" }} className="ocs-banner">
                        <div style={{ textAlign: "center" }}>
                            <h3>{this.state.title}</h3>
                            <p>{this.state.desc}</p>
                            <button onClick={() => window.location.href = "/"}>CONTINUE SHOPPING</button>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

export default OrderCancellation
