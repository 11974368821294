import React, { Component } from 'react'
import { gv } from '../Components/globalvar'

export class BrandListData extends Component {
    constructor() {
        super()
        this.state = {
            wholeData: [],
        }
    }

    componentDidMount() {
        let browserType = ""
        // Broswe Detections
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
                this.setState({ browserType: "chrome" })
            } else {
                browserType = "safari"
                this.setState({ browserType: "safari" })
            }
        }
    }

    render() {
        return (
            <div className="blists-main" style={{ backgroundColor: "#f6f4f2", padding: 0, marginBottom: !this.props.data.space_bottom ? 0 : 60, marginTop: !this.props.data.space_top ? 0 : 60, }}>
                <div className="inner-bmain" style={{ padding: 0 }}>
                    <div className="sectiles" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        {this.props.data.allBrands && this.props.data.allBrands.map((e, i) => {
                            return <div className="card-bmain" data-aos="fade-in" key={Math.random()} onClick={() => {
                                console.log(this.props.data)
                                if (this.props.data.category_type === "maincategory") {
                                    window.location.href = `/hoofcategorie/${e.brand_id}/products`
                                } else {
                                    window.location.href = `/${this.props.data.main_category}/${e.brand_id}`
                                }
                            }}>
                                <div className="card-inner-bmain">
                                    <p style={{ fontWeight: 550, fontSize: 16 }}>
                                        {e.name}
                                    </p>
                                    {i === 0 ? this.props.data.deleteImageFirst ? <img src={this.props.data.imageFirst} alt="imgbl" /> : <img src={this.props.data.firstImage} alt="imgbl" /> :
                                        i === 1 ? this.props.data.deleteImageSec ? <img src={this.props.data.imageSecond} alt="imgbl" /> : <img src={this.props.data.secondImage} alt="imgbl" /> :
                                            i === 2 ? this.props.data.deleteImageThird ? <img src={this.props.data.imageThird} alt="imgbl" /> : <img src={this.props.data.thirdImage} alt="imgbl" /> :
                                                i === 3 && this.props.data.deleteImageFourth ? <img src={this.props.data.imageFourth} alt="imgbl" /> : <img src={this.props.data.fourthImage} alt="imgbl" /
                                                >}
                                    <a>LEES MEER</a>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </div>
        )
    }
}

export default BrandListData
