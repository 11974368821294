import React, { Component } from 'react'
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import ShoppingCartRoundedIcon from '@material-ui/icons/ShoppingCartRounded';
import './index.css'
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
import { gv } from './globalvar'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PostAddIcon from '@material-ui/icons/PostAdd';
import ColorizeIcon from '@material-ui/icons/Colorize';
import SaveIcon from '@material-ui/icons/Save';
import { SketchPicker } from 'react-color'
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { Helmet } from "react-helmet";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle'

AOS.init();

export class Navbar extends Component {
    constructor() {
        super()
        this.state = {
            active: false,
            active1: true,
            active2: false,
            active3: false,
            active4: false,
            active5: false,
            active6: false,
            search: false,
            closeDisplay: false,
            menuOpen: false,
            producten: false,
            collecties: false,
            cadeaus: false,
            magazine: false,
            popupright: false,
            // Dynamic Configuring
            logourl: '',
            main_menus: [],
            child_menus: [],
            childid: '',
            childmenulists: [],
            maincategoryid: '',
            cartData: '',
            userLogedIn: '',
            // Content changing
            cart_heading_1: "",
            cart_heading_1Input: false,
            cart_heading_2: "",
            cart_heading_2Input: false,
            cart_desc: "",
            cart_descInput: false,
            free_delivery: "",
            free_deliveryInput: false,
            isAdmin: false,
            dropdownAdmin: false,
            arrModals: [
                {
                    colorPopup: false,
                    createPage: false,
                }
            ],
            pageName: "",
            pageNameAlert: false,
            pageTitle: "",
            pageTitleAlert: false,
            hideImg: false,
            isMobile: false,
            online_payment: true,
            show_tocp: false,
            menuLeftSize: "14",
            menuSize: "14",
            menuHeight: "70",
            allPagesSettings: [],
            selectedPageSections: [],
            isBigScreen: false,
        }
        this.getData = this.getData.bind(this)
    }

    componentDidMount() {
        let checkTOCP = localStorage.getItem("tocp")
        if (checkTOCP) {
            checkTOCP = JSON.parse(checkTOCP)
            if (checkTOCP === true) {
                localStorage.setItem("tocp", true)
                this.setState({ show_tocp: true })
            }
        } else {
            localStorage.setItem("tocp", true)
            this.setState({ show_tocp: false })
        }
        localStorage.setItem("tocp", false)

        this.getData()
        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            this.setState({ userLogedIn: true })
        } else {
            this.setState({ userLogedIn: false })
        }

        let adminToken = localStorage.getItem("adminToken")
        if (adminToken) {
            var myHeaders = new Headers();
            myHeaders.append("authkey", adminToken);

            var formdata = new FormData();

            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: formdata,
                redirect: 'follow'
            };

            fetch(`${gv}/api/v1/admin/login`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.code === 401) {
                        localStorage.setItem("adminKey", "")
                        localStorage.setItem("adminToken", "")
                        window.location.reload()
                    } else {
                        this.setState({ isAdmin: true })
                    }
                })
                .catch(error => console.log('error', error));
        }


        if (window.innerWidth < 1600 && window.innerWidth > 768) {
            this.setState({ isBigScreen: true })
        }

        if (window.innerWidth < 768) {
            this.setState({ isMobile: true, isBigScreen: false })
        }

        if (this.props.headerData) {
            let resp = this.props.headerData
            this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })
        } else {
            let loginData = localStorage.getItem("headerData")
            if (loginData) {
                let resp = JSON.parse(loginData)
                this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })
            }
            fetch(`${gv}/api/v1/list/of/values`)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data
                    localStorage.setItem("headerData", JSON.stringify(resp))
                    localStorage.setItem("theme_settings", JSON.stringify(resp.theme_settings))
                    this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), main_categories: resp.main_categories, online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text, fav_icon: resp.fav_icon })
                })
                .catch(error => console.log('error', error));
        }

        if (this.props.searchText) {
            this.setState({ seachValue: this.props.searchText })
        }
    }

    getData() {
        let browserType = ""
        var ua = navigator.userAgent.toLowerCase();
        if (ua.indexOf('safari') !== -1) {
            if (ua.indexOf('chrome') > -1) {
                browserType = "chrome"
            } else {
                browserType = "safari"
            }
        }

        let usertoken = localStorage.getItem("usertoken")
        if (usertoken) {
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", `Bearer ${usertoken}`);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };
            fetch(`${gv}/api/v1/auth/customer/get/cart/items?browser_type=${browserType}`, requestOptions)
                .then(response => response.json())
                .then(result => {
                    let resp = result.data.data
                    localStorage.setItem("cartNav", JSON.stringify(resp))
                    this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text })
                })
                .catch(error => console.log('error', error));
        } else {
            var myHeaders = new Headers();

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            if (!localStorage.getItem("guestUser")) {
                fetch(`${gv}/api/v1/get/customer/cookie?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        localStorage.setItem("guestUser", result.data.cart_items_cookie)
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                fetch(`${gv}/api/v1/customer/get/cart/items?browser_type=${browserType}&cart_items_cookie=${cookie_id}`)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartNav", JSON.stringify(resp))
                        this.setState({ cartData: resp, cart_heading_1: resp.cart_heading_1, cart_heading_2: resp.cart_heading_2, cart_desc: resp.cart_desc, free_delivery: resp.free_delivery_text })
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    componentWillUnmount() {
        this.setState({ menuOpen: false })
    }

    removeItem(e) {
        if (e.product_type === "gift") {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then((result) => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })

                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")
                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("qty", 1);
                formdata.append("product_type", "gift");
                formdata.append("price", e.retail_price);
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        } else {
            let usertoken = localStorage.getItem("usertoken")
            if (usertoken) {
                var myHeaders = new Headers();
                myHeaders.append("Accept", "application/json");
                myHeaders.append("Authorization", `Bearer ${usertoken}`);

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");

                var requestOptions = {
                    method: 'POST',
                    headers: myHeaders,
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/auth/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            } else {
                let cookie_id = localStorage.getItem("guestUser")

                var formdata = new FormData();
                formdata.append("product_id", e.product_id);
                formdata.append("product_type", "product");
                formdata.append("cart_items_cookie", cookie_id);

                var requestOptions = {
                    method: 'POST',
                    body: formdata,
                    redirect: 'follow'
                };

                let browserType = ""
                var ua = navigator.userAgent.toLowerCase();
                if (ua.indexOf('safari') !== -1) {
                    if (ua.indexOf('chrome') > -1) {
                        browserType = "chrome"
                    } else {
                        browserType = "safari"
                    }
                }
                fetch(`${gv}/api/v1/customer/remove/cart/item?browser_type=${browserType}`, requestOptions)
                    .then(response => response.json())
                    .then(result => {
                        let resp = result.data.data
                        localStorage.setItem("cartData", JSON.stringify(resp))
                        this.setState({ cartData: resp })
                        if (this.props) {
                            this.props.removeItem()
                        }
                        this.getData()
                    })
                    .catch(error => console.log('error', error));
            }
        }
    }

    componentWillUpdate(e) {
        if (e.getData) {
            this.getData()
        }
    }

    updateSec() {
        const { cart_heading_1, cart_heading_2, cart_desc, free_delivery } = this.state

        let arr = [
            { key: "get_cart_items_cart_heading_1", value: cart_heading_1 },
            { key: "get_cart_items_cart_heading_2", value: cart_heading_2 },
            { key: "get_cart_items_cart_cart_desc", value: cart_desc },
            { key: "get_cart_items_cart_free_delivery_text", value: free_delivery },
        ]

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();
        formdata.append("content_json", JSON.stringify(arr));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/static/page/content`, requestOptions)
            .then(response => response.json())
            .then(() => {
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    adminLogout() {
        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        var formdata = new FormData();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/logout`, requestOptions)
            .then(response => response.json())
            .then(() => {
                localStorage.setItem("adminKey", "")
                window.location.reload()
            })
            .catch(error => console.log('error', error));
    }

    componentWillReceiveProps(e) {
        if (e.refreshData) {
            let headerData = localStorage.getItem("headerData")
            if (headerData) {
                let resp = JSON.parse(headerData)
                this.setState({ logourl: resp.logo_url, main_menus: resp.menus.main_manus, child_menus: Object.entries(resp.menus.child_menus), online_payment: resp.onliny_payment, placeHolder: resp.search_text, cookie_text: resp.accept_cookie_text })
            }
        }
    }

    acceptCookie() {
        let guestUser = localStorage.getItem("guestUser")
        var formdata = new FormData();
        formdata.append("cart_items_cookie", guestUser);

        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/guest/customer/submit/accept/terms`, requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem("readCookie", true)
                localStorage.setItem("tocp", false)
                this.setState({ show_tocp: false })
            })
            .catch(error => console.log('error', error));
    }

    declineCookie() {
        localStorage.setItem("tocp", false)
        localStorage.setItem("readCookie", false)
        this.setState({ show_tocp: false })
    }

    // Theme update

    handleChangeComplete = (color) => {
        this.setState({ backgroundHeader: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteFooter = (color) => {
        this.setState({ backgroundFooter: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteButton = (color) => {
        this.setState({ backgroundButton: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteProductF = (color) => {
        this.setState({ productDetailFirstColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteProductS = (color) => {
        this.setState({ productDetailSecondColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenu = (color) => {
        this.setState({ backgroundMenu: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenuF = (color) => {
        this.setState({ menuFontColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMenuI = (color) => {
        this.setState({ menuIconColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteLeftMenuF = (color) => {
        this.setState({ leftmenufontcolour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteSaleLabel = (color) => {
        this.setState({ saleLabelColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteMobile = (color) => {
        this.setState({ menuIconMobile: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteBrandPage = (color) => {
        this.setState({ brandPageColour: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteOverlayColor = (color) => {
        this.setState({ overlayColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteOverlayTextColor = (color) => {
        this.setState({ overlayTextColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteCloseColor = (color) => {
        this.setState({ menuIconCloseColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftTitle = (color) => {
        this.setState({ giftCardTitleColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftDescription = (color) => {
        this.setState({ giftCardDescriptionColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftCardCart = (color) => {
        this.setState({ giftCardCartColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    handleChangeCompleteGiftCardButton = (color) => {
        this.setState({ giftCardButtonsColor: color.hex, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false });
    };

    updateTheme() {
        const { backgroundHeader, backgroundFooter, backgroundButton, backgroundMenu } = this.state

        let accesstoken = localStorage.getItem("adminKey")

        var myHeaders = new Headers();
        myHeaders.append("accesstoken", accesstoken);

        let obj = {
            header_background_color: backgroundHeader,
            footer_background_color: backgroundFooter,
            button_background_color: backgroundButton,
            menu_background_color: backgroundMenu,
            body_font_famiy: this.state.bodyFont,
            title_font_family: this.state.titleFont,
            product_detail_colour: this.state.productDetailFirstColour,
            product_detail_right_colour: this.state.productDetailSecondColour,
            menu_icon_colour: this.state.menuIconColour,
            menu_font_colour: this.state.menuFontColour,
            left_menu_font_colour: this.state.leftmenufontcolour,
            left_menu_font_size: this.state.menuLeftSize,
            menu_font_size: this.state.menuSize,
            menu_height: this.state.menuHeight,
            sale_label_colour: this.state.saleLabelColour,
            menu_icon_mobile: this.state.menuIconMobile,
            brand_page_colour: this.state.brandPageColour,
            overlay_color: this.state.overlayColor,
            overlay_text_color: this.state.overlayTextColor,
            background_opacity: this.state.backgroundOpacity,
            menu_close_colour: this.state.menuIconCloseColor,
            gift_card_buttons_color: this.state.giftCardButtonsColor,
            gift_card_cart_color: this.state.giftCardCartColor,
            gift_card_title_color: this.state.giftCardTitleColor,
            gift_card_desc_color: this.state.giftCardDescriptionColor,
        }

        var formdata = new FormData();
        formdata.append("theme_settings", JSON.stringify(obj));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };

        fetch(`${gv}/api/v1/admin/update/theme/settings`, requestOptions)
            .then(response => response.json())
            .then((result) => {
                localStorage.setItem("theme_settings", JSON.stringify(result.data.theme_settings))
                window.location.reload()
            })
            .catch(error => {
                this.setState({ showErrorModal: true })
            });
    }

    // Create Page

    createNew() {
        const { pageName, pageTitle } = this.state
        if (pageName === "") {
            this.setState({ pageNameAlert: true })
        } else {
            this.setState({ pageNameAlert: false })
        }
        if (pageTitle === "") {
            this.setState({ pageTitleAlert: true })
        } else {
            this.setState({ pageTitleAlert: false })
        }
        if (pageName !== "" && pageTitle !== "") {
            let obj = {}
            if (this.state.selectedPageSections.length > 0) {
                obj = {
                    name: pageTitle,
                    route: `/${pageName}`,
                    sections: [],
                    isActive: false,
                    sections: this.state.selectedPageSections
                }
            } else {
                obj = {
                    name: pageTitle,
                    route: `/${pageName}`,
                    sections: [],
                    isActive: false,
                }
            }

            let dataSettings = localStorage.getItem("allPagesSetting")
            if (dataSettings) {
                let sendingData = JSON.parse(dataSettings)

                let filterName = sendingData.filter((e) => {
                    return e.name === pageTitle
                })

                let filterRoute = sendingData.filter((e) => {
                    return e.route === `/${pageName}`
                })

                if (filterName.length <= 0 && filterRoute.length <= 0) {
                    this.setState({ loaderCreate: true })
                    sendingData.push(obj)

                    let objSec = {
                        name: pageTitle,
                        route: `/${pageName}`,
                        isActive: false,
                        has_sub_menu: "no",
                        is_main_category: "no",
                        show_as: pageTitle,
                    }
                    this.state.menuArr.push(objSec)

                    let stringified = JSON.stringify(sendingData)

                    let accesstoken = localStorage.getItem("adminKey")

                    var myHeaders = new Headers();
                    myHeaders.append("accesstoken", accesstoken);

                    var formdata = new FormData();
                    formdata.append("builder_settings", stringified);

                    var requestOptions = {
                        method: 'POST',
                        headers: myHeaders,
                        body: formdata,
                        redirect: 'follow'
                    };

                    fetch(`${gv}/api/v1/admin/update/web/builder/settings`, requestOptions)
                        .then(response => response.json())
                        .then(() => {
                            var formdataSec = new FormData();
                            formdataSec.append("menus_order", JSON.stringify(this.state.menuArr));

                            var requestOptions = {
                                method: 'POST',
                                headers: myHeaders,
                                body: formdataSec,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/admin/update/menu/order`, requestOptions)
                                .then(response => response.json())
                                .then(() => {
                                    window.location.href = `/${pageName}`
                                })
                                .catch(error => {
                                    this.setState({ showErrorModal: true })
                                });
                        })
                        .catch(error => {
                            this.setState({ showErrorModal: true })
                        });
                } else {
                    console.log(filterName)
                    console.log(filterRoute)
                    if (filterRoute.length > 0) {
                        this.setState({ routeTaken: true })
                    } else {
                        this.setState({ routeTaken: false })
                    }
                    if (filterName.length > 0) {
                        this.setState({ nameTaken: true })
                    } else {
                        this.setState({ nameTaken: false })
                    }
                }

            }
        }
    }

    submitFunction(e) {
        e.preventDefault();
        return false;
    }

    render() {
        if (!!this.state.menuOpen) {
            document.getElementById("root").style.position = "fixed";
            document.getElementById("root").style.width = "100%";
        } else {
            document.getElementById("root").style.position = "static";
            document.getElementById("root").style.width = "auto";
        }
        return (
            <div>
                <Helmet>
                    <link rel="apple-touch-icon" href={this.state.fav_icon} />
                </Helmet>
                <div className="header-main" id="headercolourchanging">
                    <div className="inner-wrapper">
                        <div className="header-bottom">
                            {!this.state.hideImg && <div className="heading-hb">
                                <img src={this.state.logourl} style={{ width: "100%", maxHeight: "80%" }} alt="logofirst" onClick={() => window.location.href = "/"} />
                            </div>}
                            {!this.state.hideMenu && <div className="lists-hb" id="headercolourchangingSec">
                                <button className="navbar-toggler d-sm-block d-md-none" type="button" onClick={() => {
                                    this.setState({ active: !this.state.active, menuOpen: false })
                                    if (this.props.checkForGC) {
                                        this.props.checkForGC()
                                    }
                                }} style={{ outline: "none" }}>
                                    {this.state.active ? <CloseIcon style={{ fontSize: 22, fill: "black" }} /> : <MenuIcon className="menuopen" style={{ fontSize: 22, fill: "white" }} />}
                                    <a href="#" className="mobilemenu-close"></a>
                                </button>
                                <header className={`header1 ${!!this.state.active ? 'headeractive' : ''}`}>
                                    <nav className="navbar navbar-expand-lg">
                                        <div className="mymenu" id="navbarSupportedContent">
                                            <ul className="navbar-nav slideractive" id="menu">
                                                {this.state.main_menus.map((e) => {
                                                    if (e.isActive || this.state.isAdmin) {
                                                        if (e.has_sub_menu !== "yes") {
                                                            return <li key={Math.random()} className="nav-item" onClick={() => {
                                                                if (e.route) {
                                                                    window.location.href = e.route
                                                                } else if (e.id === "giftcard") {
                                                                    window.location.href = "/giftcard"
                                                                } else if (e.id === "uitverkoop") {
                                                                    window.location.href = "/sales"
                                                                } else if (e.id === "merken") {
                                                                    let child_menus = this.state.child_menus
                                                                    child_menus.map((e) => {
                                                                        if (e[0] === "merken") {
                                                                            window.location.href = `/${e[1][0].id}/merken`
                                                                        }
                                                                    })
                                                                }
                                                            }}>
                                                                <a className="nav-link bodyText" style={{ fontFamily: "Roboto" }}>{e.show_as}</a>
                                                            </li>
                                                        } else {

                                                            if(e.is_main_category == "yes"){
                                                                let child_menus = this.state.child_menus;
                                                                let childmenulists = this.state.childmenulists;
                                                                childmenulists = []
                                                                let hello = child_menus.filter((f) => {
                                                                    return f[0] === e.id
                                                                });
                                                                childmenulists = hello[0][1]
                                                                if(childmenulists.length == 1){
                                                                    return <li key={Math.random()} className="nav-item" onClick={() => {
                                                                        
                                                                        if (e.id === "soort") {
                                                                            window.location.href = `/soort/${childmenulists[0].id}`
                                                                        } else {
                                                                            window.location.href = `/brand-products/${e.id}/${childmenulists[0].id}`
                                                                        }
                                                                    }}>
                                                                        <a className="nav-link bodyText" style={{ fontFamily: "Roboto" }}>{e.show_as}</a>
                                                                    </li>
                                                                }else{
                                                                    return <li key={Math.random()} className="nav-item" onClick={() => {
                                                                        this.setState({ menuOpen: true, active: false, childid: e.id, childmenulists: [], maincategoryid: e.id });
                                                                        let child_menus = this.state.child_menus;
                                                                        let childmenulists = this.state.childmenulists;
                                                                        childmenulists = []
                                                                        let hello = child_menus.filter((f) => {
                                                                            return f[0] === e.id
                                                                        });
                                                                        childmenulists = hello[0][1]
                                                                        this.setState({ childmenulists })
                                                                        let filteredSec = this.state.main_categories.filter((g) => {
                                                                            return g.id === e.id
                                                                        })
                                                                        if (filteredSec.length > 0) {
                                                                            this.setState({ brand_productsLeft: true })
                                                                        }
                                                                    }}>
                                                                        <a className="nav-link bodyText" style={{ fontFamily: "Roboto" }}>{e.show_as}</a>
                                                                    </li>
                                                                }                                                                
                                                            }else{
                                                                return <li key={Math.random()} className="nav-item" onClick={() => {
                                                                    this.setState({ menuOpen: true, active: false, childid: e.id, childmenulists: [], maincategoryid: e.id });
                                                                    let child_menus = this.state.child_menus;
                                                                    let childmenulists = this.state.childmenulists;
                                                                    childmenulists = []
                                                                    let hello = child_menus.filter((f) => {
                                                                        return f[0] === e.id
                                                                    });
                                                                    childmenulists = hello[0][1]
                                                                    this.setState({ childmenulists })
                                                                    let filteredSec = this.state.main_categories.filter((g) => {
                                                                        return g.id === e.id
                                                                    })
                                                                    if (filteredSec.length > 0) {
                                                                        this.setState({ brand_productsLeft: true })
                                                                    }
                                                                }}>
                                                                    <a className="nav-link bodyText" style={{ fontFamily: "Roboto" }}>{e.show_as}</a>
                                                                </li>
                                                            }
                                                        }
                                                    } else {
                                                        let child_menus = this.state.child_menus;
                                                        let childmenulists = this.state.childmenulists;
                                                        childmenulists = []
                                                        let hello = child_menus.filter((f) => {
                                                            return f[0] === e.id
                                                        });
                                                        let checkForTrue = ""
                                                        if (hello.length > 0) {
                                                            checkForTrue = hello[0][1].filter((g) => {
                                                                return g.isActive === true
                                                            })
                                                            if (checkForTrue.length > 0) {
                                                                return <li key={Math.random()} className="nav-item" onClick={() => {
                                                                    this.setState({ menuOpen: true, active: false, childid: e.id, childmenulists: [], maincategoryid: e.id });
                                                                    let child_menus = this.state.child_menus;
                                                                    let childmenulists = this.state.childmenulists;
                                                                    childmenulists = []
                                                                    let hello = child_menus.filter((f) => {
                                                                        return f[0] === e.id
                                                                    });
                                                                    childmenulists = hello[0][1]
                                                                    this.setState({ childmenulists })
                                                                    let filteredSec = this.state.main_categories.filter((g) => {
                                                                        return g.id === e.id
                                                                    })
                                                                    if (filteredSec.length > 0) {
                                                                        this.setState({ brand_productsLeft: true })
                                                                    }
                                                                }}>
                                                                    <a className="nav-link bodyText" style={{ fontFamily: "Roboto" }}>{e.show_as}</a>
                                                                </li>
                                                            }
                                                        }
                                                    }
                                                })}
                                            </ul>
                                        </div>
                                    </nav>
                                </header>
                            </div>}
                            <div className="right-hb" id="headercolourchangingTr">
                                <div className="inut-rhbn" id="headercolourchangingFr" style={{ borderRight: !this.state.online_payment && "none" }}>
                                    <SearchIcon style={{ color: "white", fontSize: 30, marginRight: this.state.hideMenu && 0 }} onClick={() => {
                                        if (window.innerWidth < 768) {
                                            this.setState({ hideImg: true })
                                        }
                                        if (window.innerWidth < 1601) {
                                            this.setState({ hideMenu: true })
                                        }
                                        this.setState({ closeDisplay: true })
                                    }} />
                                    {this.state.isBigScreen ? this.state.closeDisplay && <form action="/" onSubmit={this.submitFunction.bind(this)}>
                                        <input type="search" name="search" autoFocus placeholder={this.state.placeHolder} id="headercolourchangingFi" value={this.state.seachValue} onChange={(e) => this.setState({ seachValue: e.target.value })} onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                window.location.href = `/searches/${this.state.seachValue}`
                                            }
                                        }} className="search" onClick={() => {
                                            if (window.innerWidth < 1601) {
                                                this.setState({ hideMenu: true })
                                            }
                                            this.setState({ closeDisplay: true })
                                        }} onBlur={() => {
                                            this.setState({ hideMenu: false, closeDisplay: false })
                                        }} />
                                    </form> : this.state.isMobile ? this.state.closeDisplay && <form action="/" onSubmit={this.submitFunction.bind(this)}>
                                        <input type="search" name="search" autoFocus placeholder={this.state.placeHolder} id="headercolourchangingFi" value={this.state.seachValue} onChange={(e) => this.setState({ seachValue: e.target.value })} onKeyDown={(e) => {
                                            if (e.keyCode === 13) {
                                                window.location.href = `/searches/${this.state.seachValue}`
                                            }
                                        }} className="search" onClick={() => {
                                            if (window.innerWidth < 768) {
                                                this.setState({ hideImg: true })
                                            }
                                            this.setState({ closeDisplay: true })
                                        }} />
                                    </form> : <input type="search" name="search" placeholder={this.state.placeHolder} id="headercolourchangingFi" value={this.state.seachValue} onChange={(e) => this.setState({ seachValue: e.target.value })} onKeyDown={(e) => {
                                        if (e.keyCode === 13) {
                                            window.location.href = `/searches/${this.state.seachValue}`
                                        }
                                    }} className="search" onClick={() => {
                                        if (window.innerWidth < 768) {
                                            this.setState({ hideImg: true })
                                        }
                                        this.setState({ closeDisplay: true })
                                    }} onBlur={() => {
                                        if (window.innerWidth < 768) {
                                            this.setState({ hideImg: false })
                                        }
                                        this.setState({ closeDisplay: false })
                                    }} />
                                    }
                                    {this.state.closeDisplay && <CloseIcon style={{ color: "white" }} onClick={() => this.setState({ hideImg: false, closeDisplay: false, hideMenu: false })} />}
                                    {/* {this.state.isBigScreen && <CloseIcon style={{ color: "white" }} onClick={() => this.setState({ hideMenu: false, closeDisplay: false })} />} */}
                                </div>
                                {this.state.online_payment && <span style={{ cursor: "pointer" }}>
                                    <AccountCircleOutlinedIcon style={{ fill: "white", fontSize: 28 }} onClick={() => window.location.href = "/login"} />
                                </span>}
                                {this.state.online_payment && <span style={{ cursor: "pointer", position: "relative" }} onClick={() => {
                                    this.setState({ popupright: !this.state.popupright });
                                    document.getElementById("mainbody").style.width = "100%"
                                    document.getElementById("mainbody").style.position = "fixed"
                                }}>
                                    <ShoppingCartRoundedIcon style={{ fill: "white", fontSize: 28 }} className="cart-icon" />
                                    <span className="bodyText cartNumber" style={{ width: 18, height: 15, borderRadius: "50%", backgroundColor: "gray", position: "absolute", right: -20, fontSize: 11, color: "white", textAlign: "center", fontFamily: "Roboto" }}>
                                        {this.state.cartData ? this.state.cartData.total_products : 0}
                                    </span>
                                </span>}
                                {this.state.isAdmin && <span className="hiddenAdmin" style={{ margin: 0, cursor: "pointer" }} onClick={() => this.setState({ dropdownAdmin: !this.state.dropdownAdmin })}>
                                    <EditIcon style={{ fill: "white", fontSize: 28 }} />
                                </span>}
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.dropdownAdmin && <div className="dropdown-navforadmin">
                    <ul>
                        <li onClick={() => window.location.href = "/menu-sorting"}>
                            <MenuIcon />
                            <font>Menu</font>
                        </li>
                        <li onClick={() => {
                            let accesstoken = localStorage.getItem("adminKey")

                            if (accesstoken) {
                                var myHeaders = new Headers();
                                myHeaders.append("accesstoken", accesstoken);

                                var requestOptions = {
                                    method: 'GET',
                                    headers: myHeaders,
                                    redirect: 'follow'
                                };

                                fetch(`${gv}/api/v1/admin/get/menu/order`, requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        this.setState({ menuArr: result.data.menus_order })
                                    })
                                    .catch(error => console.log('error', error));


                                fetch(`${gv}/api/v1/get/web/builder/settings`, requestOptions)
                                    .then(response => response.json())
                                    .then(result => {
                                        this.setState({ allPagesSettings: result.data.other_pages })
                                        localStorage.setItem("allPagesSetting", JSON.stringify(result.data.other_pages))
                                    })
                                    .catch(error => console.log('error', error));
                            }

                            let arr = this.state.arrModals
                            arr[0].createPage = true
                            this.setState({ arrModals: arr, dropdownAdmin: false })
                        }}>
                            <PostAddIcon />
                            <font>Create Page</font>
                        </li>
                        <li onClick={() => {
                            let accesstoken = localStorage.getItem("adminKey")

                            var myHeaders = new Headers();
                            myHeaders.append("accesstoken", accesstoken);

                            var requestOptions = {
                                method: 'GET',
                                headers: myHeaders,
                                redirect: 'follow'
                            };

                            fetch(`${gv}/api/v1/admin/get/theme/settings`, requestOptions)
                                .then(response => response.json())
                                .then(result => {
                                    let theme_settings = result.data.theme_settings
                                    localStorage.setItem("theme_settings", JSON.stringify(theme_settings))
                                    this.setState({
                                        backgroundHeader: theme_settings.header_background_color,
                                        backgroundFooter: theme_settings.footer_background_color,
                                        backgroundButton: theme_settings.button_background_color,
                                        titleFont: theme_settings.title_font_family,
                                        bodyFont: theme_settings.body_font_famiy,
                                        productDetailFirstColour: theme_settings.product_detail_colour,
                                        productDetailSecondColour: theme_settings.product_detail_right_colour,
                                        backgroundMenu: theme_settings.menu_background_color,
                                        menuFontColour: theme_settings.menu_font_colour,
                                        menuIconColour: theme_settings.menu_icon_colour,
                                        leftmenufontcolour: theme_settings.left_menu_font_colour,
                                        menuLeftSize: theme_settings.left_menu_font_size,
                                        menuSize: theme_settings.menu_font_size,
                                        menuHeight: theme_settings.menu_height,
                                        saleLabelColour: theme_settings.sale_label_colour,
                                        menuIconMobile: theme_settings.menu_icon_mobile,
                                        brandPageColour: theme_settings.brand_page_colour,
                                        overlayColor: theme_settings.overlay_color,
                                        overlayTextColor: theme_settings.overlay_text_color,
                                        backgroundOpacity: theme_settings.background_opacity,
                                        menuIconCloseColor: theme_settings.menu_close_colour,
                                        giftCardButtonsColor: theme_settings.gift_card_buttons_color,
                                        giftCardCartColor: theme_settings.gift_card_cart_color,
                                        giftCardTitleColor: theme_settings.gift_card_title_color,
                                        giftCardDescriptionColor: theme_settings.gift_card_desc_color
                                    })
                                })
                                .catch(error => console.log('error', error));

                            let arr = this.state.arrModals
                            arr[0].colorPopup = true
                            this.setState({ arrModals: arr, dropdownAdmin: false })
                        }}>
                            <ColorizeIcon />
                            <font>Theme settings</font>
                        </li>
                        <li onClick={this.adminLogout.bind(this)} >
                            <ExitToAppIcon />
                            <font>Logout</font>
                        </li>
                    </ul>
                </div>}
                {
                    this.state.menuOpen && <div className="overlay-bg" onClick={() => {
                        this.setState({ menuOpen: false, childid: '', childmenulists: [] });
                    }}>
                    </div>
                }
                <div className={`menu-sidebar ${!!this.state.menuOpen ? 'active' : ''}`}>
                    {this.state.menuOpen && <ul className="navbar-nav closeNav">
                        <li className="nav-item" onClick={() => {
                            this.setState({ menuOpen: false, childid: '', childmenulists: [] });
                        }}>
                            <a className="nav-link">
                                <CloseIcon className="color-close-icon" />
                            </a>
                        </li>
                    </ul>}
                    <div className="nav-desktop-inner-menu">
                        <ul className="nav-inner-subnav">
                            {this.state.childmenulists.map((e) => {
                                if (this.state.maincategoryid === "soort") {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/soort/${e.id}`}>{e.name}</a>
                                    </li>
                                } else if (this.state.maincategoryid === "merken") {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/${e.id}/merken`}>{e.name}</a>
                                    </li>
                                } else if (this.state.brand_productsLeft) {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/brand-products/${this.state.maincategoryid}/${e.id}`}>{e.name}</a>
                                    </li>
                                } else {
                                    if (e.isActive || this.state.isAdmin) {
                                        return <li key={Math.random()} onClick={() => {
                                            this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                        }}>
                                            <a href={e.route}>{e.show_as}</a>
                                        </li>
                                    }
                                }
                            })}
                        </ul>
                    </div>
                </div >
                <div className={`menu-sidebar mobile-menu ${!!this.state.menuOpen ? 'active' : ''}`}>
                    {this.state.menuOpen && <ul className="navbar-nav closeNav">
                        <li className="nav-item" onClick={() => this.setState({ menuOpen: false })}>
                            <a className="nav-link">
                                <CloseIcon />
                            </a>
                        </li>
                    </ul>}
                    <div className="nav-desktop-inner-menu">
                        <ul className="nav-inner-subnav">
                            {this.state.childmenulists.map((e) => {
                                if (this.state.maincategoryid === "soort") {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/soort/${e.id}`}>{e.name}</a>
                                    </li>
                                } else if (this.state.maincategoryid === "merken") {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/${e.id}/merken`}>{e.name}</a>
                                    </li>
                                } else if (this.state.maincategoryid === "horloges" || this.state.maincategoryid === "smartwatches" || this.state.maincategoryid === "sieraden") {
                                    return <li key={Math.random()} onClick={() => {
                                        this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                    }}>
                                        <a href={`/brand-products/${this.state.maincategoryid}/${e.id}`}>{e.name}</a>
                                    </li>
                                } else {
                                    if (e.isActive || this.state.isAdmin) {
                                        return <li key={Math.random()} onClick={() => {
                                            this.setState({ menuOpen: false, childmenulists: [], childid: "", brandname: e.id })
                                        }}>
                                            <a href={e.route}>{e.name}</a>
                                        </li>
                                    }
                                }
                            })}
                        </ul>
                    </div>
                </div>
                {
                    this.state.popupright && <div className="bg-overlaymini" style={{ zIndex: 9999 }} onClick={() => {
                        this.setState({ popupright: false });
                        document.getElementById("mainbody").style.position = "static"
                    }}>
                    </div>
                }
                <div className={`mnicart-flyout-inner ${this.state.popupright ? 'open' : null}`} style={{ zIndex: 99999, maxWidth: 620 }}>
                    <div className="checkout-flyout-inner-content">
                        <div className="flyout-section">
                            <div className="minicart-flyout-wrapper">
                                <CloseIcon style={{ position: "absolute", cursor: "pointer", top: 34, fontSize: 24, left: 27 }} onClick={() => {
                                    this.setState({ popupright: false });
                                    document.getElementById("mainbody").style.position = "static"
                                }} />
                                <div className="minicart-flyout-header">
                                    <div className="centered">
                                        <h3>{this.state.cartData.cart_heading_1}</h3>
                                        {this.state.cartData && <span>{this.state.cartData.total_products} product</span>}
                                    </div>
                                </div>
                                <div className="flyout-body">
                                    {this.state.cartData.cart_items && this.state.cartData.cart_items.map((e) => {
                                        return <div className="minicart-product" key={Math.random()}>
                                            <div className="mini-cartimage" onClick={() => {
                                                if (e.product_type === "product") {
                                                    window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                }
                                            }}>
                                                <img src={e.image_url} />
                                            </div>
                                            <div className="mini-cartinfo">
                                                <div className="mini-cart-small-title" onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>
                                                    <font>{e.name}</font>
                                                </div>
                                                <div className="mini-cart-name" onClick={() => {
                                                    if (e.product_type === "product") {
                                                        window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                    }
                                                }}>
                                                    <a>{e.plain_sku}</a>
                                                </div>
                                                <div className="mini-cart-details-bottom">
                                                    <div className="mini-cart-pricing">
                                                        <font onClick={() => {
                                                            if (e.product_type === "product") {
                                                                window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                            }
                                                        }}>Aantal: {e.qty}</font>
                                                        <br />
                                                        <font style={{ cursor: "pointer" }} onClick={this.removeItem.bind(this, e)}>Verwijder item</font>
                                                    </div>
                                                    <div className="mini-cart-price-wrapper" onClick={() => {
                                                        if (e.product_type === "product") {
                                                            window.location.href = `/product-detail/${e.main_category}/${e.brand_id}/${e.plain_sku}`
                                                        }
                                                    }}>
                                                        {Number(e.sale_price) !== 0 && Number(e.sale_price) < Number(e.retail_price) ? <span className="mini-cart-price"> € {e.show_sale_price}</span> : <span className="mini-cart-price">€ {e.show_retail_price}</span>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                    {!!this.state.cartData.order_gift_url && <div className="gwp-minicart-wrapper">
                                        <div className="gwp-cart-banner">
                                            <div className="gwp-banner-progress-holder">
                                                <span style={{ width: "25%" }}></span>
                                            </div>
                                            <div className="gwp-cart-banner-title">
                                                <h4>{this.state.cartData.cart_heading_2}</h4>
                                            </div>
                                            <div className="gwp-cart-banner-image">
                                                <img src={this.state.cartData.order_gift_url} />
                                            </div>
                                            <div className="gwp-cart-banner-content">
                                                <p className="the-ritual-title">
                                                    {this.state.cart_desc}
                                                </p>
                                                <p style={{ marginTop: 10 }}>{this.state.cartData.cart_desc1}</p>
                                            </div>
                                            <div className="gwp-cart-banner-cta">
                                                {!this.state.userLogedIn && <a href="/login">Log in or sign up</a>}
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                        {this.state.popupright && <div className="minicart-flyout-checkout" style={{ maxWidth: "100%" }}>
                            <div className="total">
                                <div className="total-line">
                                    <span className="total-label">
                                        ORDER SUBTOTAAL
                                        <span className="free-delivery-message" onClick={() => this.setState({ free_deliveryInput: true })} onBlur={() => this.setState({ free_deliveryInput: false })}>
                                            Delivery time {this.state.cartData.delivery_time} <br /> {this.state.free_delivery}
                                        </span>
                                    </span>
                                    <span className="total-value">
                                        € {this.state.cartData.total_retail_price}
                                    </span>
                                </div>
                            </div>
                            <div className="buttons-total">
                                <button className="btn-continue" onClick={() => {
                                    this.setState({ popupright: false });
                                    document.getElementById("mainbody").style.position = "static"
                                }}>CONTINUE SHOPPING</button>
                                {this.state.cartData.cart_items && this.state.cartData.cart_items.length > 0 && <button className="btn-cart" onClick={() => window.location.href = "/cart"}>GO TO CART</button>}
                            </div>
                        </div>}
                    </div>
                </div>
                {/* Popups */}
                {/* Colour Picker Page */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals[0].colorPopup ? 1 : 0, pointerEvents: this.state.arrModals[0].colorPopup ? "all" : "none" }}>

                </div>
                {
                    this.state.arrModals[0].colorPopup && <div className="modal-new" style={{ opacity: this.state.arrModals[0].colorPopup ? 1 : 0, pointerEvents: this.state.arrModals[0].colorPopup ? "all" : "none", zIndex: 9999999999999999999999999 }}>
                        <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                            let arr = this.state.arrModals
                            arr[0].colorPopup = false
                            this.setState({ arrModals: arr, gettingDataHP: true, gettingDataPC: true, gettingDataBL: true, gettingDataContact: true })
                        }} />
                        <div className="create-popup color-worker">
                            <div className="color-working-popup">
                                <div className="first-div-cwp">
                                    <label>Header Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundHeader}
                                        onChangeComplete={this.handleChangeComplete}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Footer Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundFooter}
                                        onChangeComplete={this.handleChangeCompleteFooter}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Buttons Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundButton}
                                        onChangeComplete={this.handleChangeCompleteButton}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Background Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.backgroundMenu}
                                        onChangeComplete={this.handleChangeCompleteMenu}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Product Detail Page Colour 1</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.productDetailFirstColour}
                                        onChangeComplete={this.handleChangeCompleteProductF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Product Detail Page Colour 2</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.productDetailSecondColour}
                                        onChangeComplete={this.handleChangeCompleteProductS}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Font Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuFontColour}
                                        onChangeComplete={this.handleChangeCompleteMenuF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Icon Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconColour}
                                        onChangeComplete={this.handleChangeCompleteMenuI}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Left Menu Font Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.leftmenufontcolour}
                                        onChangeComplete={this.handleChangeCompleteLeftMenuF}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Sale Label Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.saleLabelColour}
                                        onChangeComplete={this.handleChangeCompleteSaleLabel}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Icon Mobile Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconMobile}
                                        onChangeComplete={this.handleChangeCompleteMobile}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Brandpage-color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.brandPageColour}
                                        onChangeComplete={this.handleChangeCompleteBrandPage}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Overlay Colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.overlayColor}
                                        onChangeComplete={this.handleChangeCompleteOverlayColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Overlay Text colour</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.overlayTextColor}
                                        onChangeComplete={this.handleChangeCompleteOverlayTextColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Menu Close Icon Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.menuIconCloseColor}
                                        onChangeComplete={this.handleChangeCompleteCloseColor}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Title Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardTitleColor}
                                        onChangeComplete={this.handleChangeCompleteGiftTitle}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Description Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardDescriptionColor}
                                        onChangeComplete={this.handleChangeCompleteGiftDescription}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card Buttons Background Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardButtonsColor}
                                        onChangeComplete={this.handleChangeCompleteGiftCardButton}
                                    />
                                </div>
                                <div className="first-div-cwp">
                                    <label>Gift Card In Cart background Color</label>
                                    {/* <input /> */}
                                    <SketchPicker
                                        color={this.state.giftCardCartColor}
                                        onChangeComplete={this.handleChangeCompleteGiftCardCart}
                                    />
                                </div>
                            </div>
                            <div className="font-family-selector">
                                <div>
                                    <label>Select Title Font</label>
                                    <select value={this.state.titleFont} onChange={(e) => this.setState({ titleFont: e.target.value })}>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Oswald">Oswald</option>
                                        <option value="Poppin">Poppin</option>
                                        <option value="Bebas Neue">Bebas Neue</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Abel">Abel</option>
                                        <option value="ClassGarmnd BT">ClassGarmnd BT</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Body Font</label>
                                    <select value={this.state.bodyFont} onChange={(e) => this.setState({ bodyFont: e.target.value })}>
                                        <option value="Times New Roman">Times New Roman</option>
                                        <option value="Roboto">Roboto</option>
                                        <option value="Oswald">Oswald</option>
                                        <option value="Poppin">Poppin</option>
                                        <option value="Bebas Neue">Bebas Neue</option>
                                        <option value="Open Sans">Open Sans</option>
                                        <option value="Abel">Abel</option>
                                        <option value="ClassGarmnd BT">ClassGarmnd BT</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Left Menu Font Size</label>
                                    <select value={this.state.menuLeftSize} onChange={(e) => this.setState({ menuLeftSize: e.target.value })}>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Menu Font Size</label>
                                    <select value={this.state.menuSize} onChange={(e) => this.setState({ menuSize: e.target.value })}>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Select Menu Height</label>
                                    <select value={this.state.menuHeight} onChange={(e) => this.setState({ menuHeight: e.target.value })}>
                                        <option value="70">70</option>
                                        <option value="75">75</option>
                                        <option value="80">80</option>
                                        <option value="85">85</option>
                                        <option value="90">90</option>
                                        <option value="95">95</option>
                                        <option value="100">100</option>
                                        <option value="105">105</option>
                                        <option value="110">110</option>
                                        <option value="115">115</option>
                                        <option value="120">120</option>
                                        <option value="125">125</option>
                                    </select>
                                </div>
                                <div>
                                    <label>Background Opacity</label>
                                    <select value={this.state.backgroundOpacity} onChange={(e) => this.setState({ backgroundOpacity: e.target.value })}>
                                        <option value="0.1">1</option>
                                        <option value="0.2">2</option>
                                        <option value="0.3">3</option>
                                        <option value="0.4">4</option>
                                        <option value="0.5">5</option>
                                        <option value="0.6">6</option>
                                        <option value="0.7">7</option>
                                        <option value="0.8">8</option>
                                        <option value="0.9">9</option>
                                    </select>
                                </div>
                            </div>
                            <div className="footer-allpopups-sections" style={{ bottom: -25 }}>
                                <div className="inner-faps" onClick={this.updateTheme.bind(this)}>
                                    <SaveIcon />
                                    <font>SAVE</font>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {/* Colour Picker Page */}
                {/* Create New Page */}
                <div className="modal-popup-new" style={{ opacity: this.state.arrModals[0].createPage ? 1 : 0, pointerEvents: this.state.arrModals[0].createPage ? "all" : "none" }}>

                </div>
                {
                    this.state.arrModals[0].createPage && <div className="modal-new" style={{ opacity: this.state.arrModals[0].createPage ? 1 : 0, pointerEvents: this.state.arrModals[0].createPage ? "all" : "none" }}>
                        <ClearIcon style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }} onClick={() => {
                            let arr = this.state.arrModals
                            arr[0].createPage = false
                            this.setState({ arrModals: arr, gettingDataHP: true, gettingDataPC: true, gettingDataBL: true, gettingDataContact: true })
                        }} />
                        {this.state.loaderCreate ? <div className="loader-working-fb" style={{ position: "static", minHeight: 400, display: "flex", justifyContent: "center", alignItems: "center", transform: "none" }}>
                            <div className="lds-facebook"><div></div><div></div><div></div></div>
                        </div> : <div className="create-popup">
                                <div className="form-create-page">
                                    <div>
                                        <label>Page Name</label>
                                        <input className="bodyText" type="text" style={{ fontFamily: "Roboto" }} value={this.state.pageName} onChange={(e) => {
                                            this.setState({ pageName: e.target.value, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                        }} />
                                        {this.state.pageNameAlert && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Please enter page name</p>}
                                        {this.state.routeTaken && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Page name already taken!</p>}
                                    </div>
                                    <div>
                                        <label>Page Title</label>
                                        <input className="bodyText" type="text" style={{ fontFamily: "Roboto" }} value={this.state.pageTitle} onChange={(e) => {
                                            this.setState({ pageTitle: e.target.value, gettingDataHP: false, gettingDataPC: false, gettingDataBL: false, gettingDataContact: false })
                                        }} />
                                        {this.state.pageTitleAlert && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Please enter page title</p>}
                                        {this.state.nameTaken && <p className="bodyText" style={{ color: "red", letterSpacing: 2, fontSize: 15, fontFamily: "Roboto" }}>Page title already taken!</p>}
                                    </div>
                                    <div>
                                        <label>Copy from Page</label>
                                        <select onChange={(e) => {
                                            if (e.target.value !== "select") {
                                                this.setState({ selectedPageSections: JSON.parse(e.target.value) })
                                            } else {
                                                this.setState({ selectedPageSections: [] })
                                            }
                                        }}>
                                            <option value="select">Select Page</option>
                                            {this.state.allPagesSettings.length > 0 && this.state.allPagesSettings.map((e) => {
                                                if (e.sections.length > 0) {
                                                    return <option value={JSON.stringify(e.sections)}>{e.name}</option>
                                                }
                                            })}
                                        </select>
                                    </div>
                                    <div>
                                        <label>{this.state.domainName}/{this.state.pageName}</label>
                                    </div>
                                    <div onClick={this.createNew.bind(this)}>
                                        <button>Save</button>
                                    </div>
                                </div>
                            </div>}
                    </div>
                }
                {/* Create New Page */}
                {this.state.show_tocp && < div className="accept-tocp">
                    <div className="inner-atocp">
                        <p>{this.state.cookie_text}</p>
                        <button onClick={this.acceptCookie.bind(this)}>Ok</button>
                        <button onClick={this.declineCookie.bind(this)}>Nee</button>
                        <button onClick={() => {
                            localStorage.setItem("tocp", true)
                            window.location.href = "/privacy-policy"
                        }}>Meer Iezen</button>
                    </div>
                </div>}
                <Dialog
                    open={this.state.showErrorModal}
                    onClose={() => this.setState({ showErrorModal: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    style={{ zIndex: 99999999999999999 }}
                >
                    <DialogTitle id="alert-dialog-title">LET OP</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">kindly try again</DialogContentText>
                    </DialogContent>
                </Dialog>
            </div >
        )
    }
}

export default Navbar
